import Box from "@mui/material/Box";
import { Dialog, Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { PrimaryButton } from "./PrimaryButton";
import { DataGrid } from "@mui/x-data-grid";
import SkillCrudView from "./SkillCrudView";

const StyledDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#d8d8d8",
    lineHeight: "1.4",
  },
  "& .MuiDataGrid-cell": {
    borderRight: "2px solid #eee", // Add a black vertical line between cells
    borderBottomWidth: "0px",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(246, 246, 246, 1)" },
    },
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#111", // Changes the color of sort icons to blue
  },
  "& .MuiDataGrid-columnSeparator": {
    color: "#777", // Changes the color of sort icons to blue
  },
});

function SkillsView() {
  const [skills, setSkills] = useState([]);

  const [openSkillDialog, setOpenSkillDialog] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      editable: false,
    },
    {
      field: "synonyms",
      headerName: "Synonyms",
      minWidth: 150,
      flex: 2,
      editable: false,
    },
  ];

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      showSkills();
    };
    load1();
  }, []);

  function addTableForSkills() {
    return (
      <Box style={{ margin: "0px" }}>
        <StyledDataGrid
          rows={skills}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          style={{ backgroundColor: "white" }}
          onRowClick={handleRowClick}
        />
      </Box>
    );
  }

  async function showSkills() {
    let s = await ApplicationState.getSkillsForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    s = s.sort((a, b) => (a.name > b.name ? 1 : -1))
    setSkills(s);
  }

  const addSkills = () => {
    if (!skills) {
      return;
    }
    return addTableForSkills();
  };

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box>{addAddSkillButton()}</Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Skills</Typography>
        </Box>
      </Typography>
    );
  }

  function addAddSkillButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add skill"
          buttonClick={() => newSkillClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function newSkillClick() {
    ApplicationState.setSkillToEdit(undefined);
    setOpenSkillDialog(true);
  }

  async function addedSkill() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let s = await ApplicationState.getSkillsForOrganization(orgId);
    setSkills(s);
    setOpenSkillDialog(false);
  }

  async function editedSkill() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let s = await ApplicationState.getSkillsForOrganization(orgId);
    setSkills(s);
    setOpenSkillDialog(false);
  }

  async function deletedSkill() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let s = await ApplicationState.getSkillsForOrganization(orgId);
    setSkills(s);
    setOpenSkillDialog(false);
  }

  const handleRowClick = (params) => {
      ApplicationState.setSkillToEdit(params.row);
      setOpenSkillDialog(true);
  };

  return (
    <Grid>
        <Box>{addHeader()}</Box>
        <Box>{addSkills()}</Box>
        <Dialog
          open={openSkillDialog}
          onClose={() => setOpenSkillDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <SkillCrudView
            deletedSkill={deletedSkill}
            addedSkill={addedSkill}
            editedSkill={editedSkill}
            cancelClick={() => setOpenSkillDialog(false)}
          ></SkillCrudView>
        </Dialog>
      
    </Grid>
  );
}

export default SkillsView;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ActivityKeywordDto from "../Dtos/ActivityKeywordDto";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "60px",
});

function ActivityKeywordEditView(props) {
  const [keywordField, setKeywordField] = useState(undefined);
  const [activityField, setActivityField] = useState(-1);
  const [projectField, setProjectField] = useState(-1);
  const [editMode, setEditMode] = useState(false);
  const [userToAddKeywordFor, setUserToAddKeywordFor] = useState(false);
  const [activitiesForProject, setActivitiesForProject] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let projects = ApplicationState.getState().projects;
      if (projects !== undefined || projects.length !== 0) {
        setProjectField(projects[0].id);
      }
      fillActivitiesForProject(projects[0].id);
      if (ApplicationState.getUserToAddActivityKeywordFor()) {
        setUserToAddKeywordFor(
          ApplicationState.getUserToAddActivityKeywordFor()
        );
      }
      if (ApplicationState.getKeywordToAddActivityKeywordFor()) {
        setKeywordField(
          ApplicationState.getKeywordToAddActivityKeywordFor()
        );
      }
      if (ApplicationState.getActivityKeywordToEdit()) {
        setEditMode(true);
        setKeywordField(ApplicationState.getActivityKeywordToEdit().keyword);

        setUserToAddKeywordFor(
          ApplicationState.getActivityKeywordToEdit().user
        );
        setProjectField(
          ApplicationState.getActivityKeywordToEdit().activity.project.id
        );
        fillActivitiesForProject(
          ApplicationState.getActivityKeywordToEdit().activity.project.id
        );
        setActivityField(
          ApplicationState.getActivityKeywordToEdit().activity.id
        );
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let activity = ApplicationState.getState().activities.filter(
      (a) => a.id == activityField
    )[0];
    let dto = new ActivityKeywordDto(
      ApplicationState.generateGuid(),
      keywordField,
      activity,
      userToAddKeywordFor,
      new Date()
    );
    if (ApplicationState.getActivityKeywordToEdit()) {
      dto.id = ApplicationState.getActivityKeywordToEdit().id;
      await ApplicationState.saveUpdatedActivityKeyword(dto);
      props.addedKeyword();
    } else {
      await ApplicationState.saveNewActivityKeyword(dto);
      props.editedKeyword();
    }

    ApplicationState.setUserToAddActivityKeywordFor(undefined);
    ApplicationState.setActivityKeywordToEdit(undefined);
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteActivityKeyword(
      ApplicationState.getActivityKeywordToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete activity keyword with id: " +
          ApplicationState.getActivityKeywordToEdit().id
      );
      return;
    }
    props.deletedKeyword();
    ApplicationState.setUserToAddActivityKeywordFor(undefined);
    ApplicationState.setActivityKeywordToEdit(undefined);
  }

  function fillActivitiesForProject(projectId) {
    let activities = ApplicationState.getState().activities;
    if (activities !== undefined || activities.length !== 0) {
    }
    let activitiesForProject = activities.filter(
      (x) => x.project.id == projectId
    );
    let activitiesForProjectOptions = [];
    activitiesForProject
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((val) => {
        activitiesForProjectOptions.push(
          <option key={val.id} value={val.id}>
            {val.name}
          </option>
        );
      });
    setActivitiesForProject(activitiesForProjectOptions);
    setActivityField(activitiesForProject[0].id);
  }

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }
  const getHeaderText = () => {
    let headerText = "Add keyword";
    if (editMode) {
      headerText = "Edit keyword";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <Box sx={{float: {sm: "none", md: "left" }}}>
          <InputLabelSelect
            id="projectSelectLabel"
            htmlFor="projectSelect"
            variant="outlined"
            shrink={true}
          >
            Project
          </InputLabelSelect>
          <Select
            native
            onChange={(event) => {
              setProjectField(event.target.value);
              fillActivitiesForProject(event.target.value);
            }}
            id="projectSelect"
            variant="outlined"
            sx={{margin: "8px", fontWeight: "500", width: {xs: "95%", sm: "300px"}}}
            value={projectField}
          >
            {ApplicationState.getState()
              .projects.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((project) => {
                return addProject(project);
              })}
          </Select>
        </Box>
        <Box sx={{float: {sm: "none", md: "left" }}}>
          <InputLabelSelect
            id="activitySelectLabel"
            htmlFor="activitySelect"
            variant="outlined"
            shrink={true}
          >
            Activity
          </InputLabelSelect>
          <Select
            native
            onChange={(event) => {
              setActivityField(event.target.value);
            }}
            id="activitySelect"
            variant="outlined"
            sx={{margin: "8px", fontWeight: "500", width: {xs: "95%", sm: "300px"}}}
            value={activityField}
          >
            {activitiesForProject}
          </Select>
        </Box>
        <Box style={{marginTop: "16px"}}>
        <TextField
          id="keywordTextField"
          label="Keyword"
          type="text"
          onChange={(event) => setKeywordField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={keywordField}
          
          sx={{margin: "8px", width: {xs: "95%", sm: "300px", md: "616px"} }}
        />
        </Box>
      </Box>
    );
  };
  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ActivityKeywordEditView;

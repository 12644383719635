export default class FileExporterDto {
  constructor(id, name, description, organization, created, email, fileFormat, trigger, triggerDayInMonth) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.organization = organization;
    this.created = created;
    this.email = email;
    this.fileFormat = fileFormat;
    this.trigger = trigger;
    this.triggerDayInMonth = triggerDayInMonth;
  }
}

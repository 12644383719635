import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ApplicationState from "../State/ApplicationState";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import ActivityKeywordDto from "../Dtos/ActivityKeywordDto";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Divider from "@mui/material/Divider";
import { PrimaryButton } from '../Components/PrimaryButton';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";

export default function TimeEntryKeywordView(props) {

  const [existingKeywordsForActivity, setExistingKeywordsForActivity] =
    useState([]);
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [suggestedKeyword1, setSuggestedKeyword1] = useState(undefined);
  const [suggestedKeyword2, setSuggestedKeyword2] = useState(undefined);
  const [suggestedKeyword3, setSuggestedKeyword3] = useState(undefined);
  const [suggestedKeyword4, setSuggestedKeyword4] = useState(undefined);
  const [suggestedKeyword5, setSuggestedKeyword5] = useState(undefined);
  const [suggestedKeyword6, setSuggestedKeyword6] = useState(undefined);
  const [suggestedKeyword7, setSuggestedKeyword7] = useState(undefined);
  const [suggestedKeyword8, setSuggestedKeyword8] = useState(undefined);
  const [suggestedKeyword9, setSuggestedKeyword9] = useState(undefined);
  const [suggestedKeyword10, setSuggestedKeyword10] = useState(undefined);
  const [suggestedKeywordTextfield, setSuggestedKeywordTextfield] = useState(undefined);
  const [suggestedKeyword1Selected, setSuggestedKeyword1Selected] = useState(false);
  const [suggestedKeyword2Selected, setSuggestedKeyword2Selected] = useState(false);
  const [suggestedKeyword3Selected, setSuggestedKeyword3Selected] = useState(false);
  const [suggestedKeyword4Selected, setSuggestedKeyword4Selected] = useState(false);
  const [suggestedKeyword5Selected, setSuggestedKeyword5Selected] = useState(false);
  const [suggestedKeyword6Selected, setSuggestedKeyword6Selected] = useState(false);
  const [suggestedKeyword7Selected, setSuggestedKeyword7Selected] = useState(false);
  const [suggestedKeyword8Selected, setSuggestedKeyword8Selected] = useState(false);
  const [suggestedKeyword9Selected, setSuggestedKeyword9Selected] = useState(false);
  const [suggestedKeyword10Selected, setSuggestedKeyword10Selected] = useState(false);

  useEffect(() => {
    const load = async () => {
      let existingKeywords = await ApplicationState.getActivityKeywordsForUser(
        ApplicationState.getState().user.email
      );
      existingKeywords = existingKeywords.filter(
        (x) =>
          x.activity.id ===
          ApplicationState.getTimeEntryToAddKeywordsFor().activity.id
      );
      setExistingKeywordsForActivity(existingKeywords);
      let keywordsToConsider = ApplicationState.getSuggestedKeywordsForUserData();
      let keywordsToAdd = [];
      keywordsToConsider.map((keyword) => {
        if (!existingKeywords.find((x) => x.keyword === keyword)) {
          keywordsToAdd.push(keyword);
        }
      });
      setSuggestedKeywords(keywordsToAdd);
      let counter = 1;
      keywordsToAdd.forEach((keyword) => {
        switch(counter){
          case 1: 
            setSuggestedKeyword1(keyword);
            break;
          case 2: 
            setSuggestedKeyword2(keyword);
            break;
          case 3: 
            setSuggestedKeyword3(keyword);
            break;
          case 4: 
            setSuggestedKeyword4(keyword);
            break;
          case 5: 
            setSuggestedKeyword5(keyword);
            break;
          case 6: 
            setSuggestedKeyword6(keyword);
            break;
          case 7: 
            setSuggestedKeyword7(keyword);
            break;
          case 8: 
            setSuggestedKeyword8(keyword);
            break;
          case 9: 
            setSuggestedKeyword9(keyword);
            break;
          case 10: 
            setSuggestedKeyword10(keyword);
            break;
        }
        counter++;
      })
    };
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function deleteKeyword(keywordToDelete) {
    await ApplicationState.deleteActivityKeyword(keywordToDelete.id);
    let keywords = existingKeywordsForActivity.slice();
    const index = keywords.indexOf(keywordToDelete);
    if (index > -1) {
      keywords.splice(index, 1);
    }
    setExistingKeywordsForActivity(keywords);
  }

  function skip() {
    props.cancelClick();
  }

  async function saveKeywords() {
    if(suggestedKeyword1Selected) {
      saveSelectedNewKeyword(suggestedKeyword1);
    }
    if(suggestedKeyword2Selected) {
      saveSelectedNewKeyword(suggestedKeyword2);
    }
    if(suggestedKeyword3Selected) {
      saveSelectedNewKeyword(suggestedKeyword3);
    }
    if(suggestedKeyword4Selected) {
      saveSelectedNewKeyword(suggestedKeyword4);
    }
    if(suggestedKeyword5Selected) {
      saveSelectedNewKeyword(suggestedKeyword5);
    }
    if(suggestedKeyword6Selected) {
      saveSelectedNewKeyword(suggestedKeyword6);
    }
    if(suggestedKeyword7Selected) {
      saveSelectedNewKeyword(suggestedKeyword7);
    }
    if(suggestedKeyword8Selected) {
      saveSelectedNewKeyword(suggestedKeyword8);
    }
    if(suggestedKeyword9Selected) {
      saveSelectedNewKeyword(suggestedKeyword9);
    }
    if(suggestedKeyword10Selected) {
      saveSelectedNewKeyword(suggestedKeyword10);
    }
    if(suggestedKeywordTextfield && suggestedKeywordTextfield.length > 0) {
      saveSelectedNewKeyword(suggestedKeywordTextfield);
    }
    props.cancelClick();
  }

  async function saveSelectedNewKeyword(keywordToSave){
    let keyword = new ActivityKeywordDto(
      ApplicationState.generateGuid(),
      keywordToSave,
      ApplicationState.getTimeEntryToAddKeywordsFor().activity,
      ApplicationState.getState().user,
      new Date()
    );
    await ApplicationState.saveNewActivityKeyword(keyword);
  }

  async function saveNewKeyword(keywordToSave) {
    let keyword = new ActivityKeywordDto(
      ApplicationState.generateGuid(),
      keywordToSave,
      ApplicationState.getTimeEntryToAddKeywordsFor().activity,
      ApplicationState.getState().user,
      new Date()
    );
    await ApplicationState.saveNewActivityKeyword(keyword);
    let keywordsExisting = existingKeywordsForActivity.slice();
    let keywordsSuggested = suggestedKeywords.slice();
    let suggestedKeywordToRemove = keywordsSuggested.find(
      (x) => x === keywordToSave
    );
    if (suggestedKeywordToRemove) {
      const index = keywordsSuggested.indexOf(suggestedKeywordToRemove);
      if (index > -1) {
        keywordsSuggested.splice(index, 1);
      }
      setSuggestedKeywords(keywordsSuggested);
    }
    keywordsExisting.push(keyword);
    setExistingKeywordsForActivity(keywordsExisting);
  }

  function addExistingKeyword(k) {
    return (
      <Chip
        sx={{backgroundColor: "#93264A",
        color: "#fff",
        margin: "8px",
        fontSize: "larger",
        padding: "4px",
        height: "42px",
        "&:hover": {
          backgroundColor: "#93264A",
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: "#93264A",
          color: "#fff",
        },}}
        onDelete={() => deleteKeyword(k)}
        label={<b style={{ fontWeight: "400" }}>{k.keyword}</b>}
        style={{maxWidth: "375px"}}
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  function addSuggestedKeyword(k) {
    return (
      <Chip
        sx={{backgroundColor: "#575757",
        color: "#fff",
        margin: "8px",
        fontSize: "larger",
        padding: "4px",
        height: "42px",
        "&:hover": {
          backgroundColor: "#575757",
          color: "#fff",
        },
        "&:focus": {
          backgroundColor: "#575757",
          color: "#fff",
        },}}
        clickable
        onClick={() => {
          saveNewKeyword(k);
        }}
        onDelete={() => {
          saveNewKeyword(k);
        }}
        deleteIcon={<AddCircleIcon style={{ color: "#fff" }} />}
        label={<b style={{ fontWeight: "400" }}>{k}</b>}
        style={{maxWidth: "375px"}}
      />
    );
  }

  function addSuggestedKeywordsWithCheckboxes() {
    if (!suggestedKeywords || suggestedKeywords.length < 1) {
      return (
        <Typography variant="subtitle1">
          <i>No suggested keywords found</i>
        </Typography>
      );
    }
    let counter = 0;
    return suggestedKeywords.map((keyword) => {
        counter++;
        switch(counter){
          case 1: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword1Selected, setSuggestedKeyword1Selected, counter);
          case 2: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword2Selected, setSuggestedKeyword2Selected, counter);
          case 3: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword3Selected, setSuggestedKeyword3Selected, counter);
          case 4: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword4Selected, setSuggestedKeyword4Selected, counter);
          case 5: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword5Selected, setSuggestedKeyword5Selected, counter);
          case 6: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword6Selected, setSuggestedKeyword6Selected, counter);
          case 7: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword7Selected, setSuggestedKeyword7Selected, counter);
          case 8: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword8Selected, setSuggestedKeyword8Selected, counter);
          case 9: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword9Selected, setSuggestedKeyword9Selected, counter);
          case 10: 
            return addSuggestedKeywordWithCheckbox(keyword, suggestedKeyword10Selected, setSuggestedKeyword10Selected, counter);
        }
        
      })
  }

  function addSuggestedKeywordWithCheckbox(k, selected, method, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0){
      bgColor = "#f6f6f6";
    }
    return (
      <ListItem
          style={{backgroundColor: bgColor}}
        >
          <ListItemIcon>
            <Checkbox defaultChecked color="primary" checked={selected} onChange={(event) => {
                method(event.target.checked);
              }} />
          </ListItemIcon>
          <ListItemText primary={k} />
        </ListItem>
    );
  }

  function addKewyordsWithCheckboxes(){
    return(
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ padding: "0px" }}
      >
        {addSuggestedKeywordsWithCheckboxes()}
        <ListItem
        >
          <Divider />
          <TextField
          id="keywordTextField"
          label="Custom keyword"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setSuggestedKeywordTextfield(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={suggestedKeywordTextfield}
        />
        </ListItem>
      </Grid>
    );
  }

  return (
    <Box>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box style={{float: "left"}}>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box style={{backgroundColor: "#f6f6f6"}} paddingBottom="16px">
        <Box p={1}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Box p={1} paddingLeft={3}>
              <Typography variant="h5">
                <b>Improve future time reports</b>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
      
      <Typography variant="h6" style={{padding: "16px", paddingLeft: "28px", fontWeight: "400"}}>
        Select keywords to always be assigned to: 
        <br /><br /><b>{ApplicationState.getTimeEntryToAddKeywordsFor().activity.project.name}</b>  
        <br /><i>{ApplicationState.getTimeEntryToAddKeywordsFor().activity.name}</i>
        <br /><br />
      </Typography>
      <Divider />
      {addKewyordsWithCheckboxes()}
      <Divider />
      <Box p={2} style={{backgroundColor: "#93264a"}}>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box p={1}>

          </Box>

          <Box p={1}>
            <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveKeywords()}
              >
            </PrimaryButton>
            <PrimaryButton
                icon="cancel"
                text="Skip"
                buttonClick={() => skip()}
              >
            </PrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

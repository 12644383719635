import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import EmailExporterDto from "../Dtos/EmailExporterDto";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { styled } from '@mui/material/styles';
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "58px",
});

function EmailExporterEditView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [format, setFormat] = useState("SummaryJson");
  const [email, setEmail] = useState(undefined);
  const [trigger, setTrigger] = useState("SendReport");
  const [triggerDayInMonth, setTriggerDayInMonth] = useState(1);

  useEffect(() => {
    if (ApplicationState.getEmailExporterToEdit()) {
      setEditMode(true);
      setNameField(ApplicationState.getEmailExporterToEdit().name);
      setDescriptionField(ApplicationState.getEmailExporterToEdit().description);
      setEmail(ApplicationState.getEmailExporterToEdit().email);
      setFormat(ApplicationState.getEmailExporterToEdit().format);
      setTrigger(ApplicationState.getEmailExporterToEdit().trigger);
      setTriggerDayInMonth(ApplicationState.getEmailExporterToEdit().triggerDayInMonth);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new EmailExporterDto(
      ApplicationState.generateGuid(),
      nameField,
      descriptionField,
      ApplicationState.getState().user.organization,
      new Date(Date.now()),
      email,
      format,
      trigger, 
      triggerDayInMonth
    );
    if (ApplicationState.getEmailExporterToEdit()) {
      dto.id = ApplicationState.getEmailExporterToEdit().id;
      await ApplicationState.saveUpdatedEmailExporter(dto);
      ApplicationState.setEmailExporterToView(dto);
      props.editedExporter();
    } else {
      await ApplicationState.saveNewEmailExporter(dto);
      ApplicationState.setEmailExporterToView(dto);
      props.addedExporter();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteEmailExporter(
      ApplicationState.getEmailExporterToEdit().id
    );
    if (result !== "Ok") {
      alert(
        "Error occured when trying to delete Email exporter with id: " +
          ApplicationState.getEmailExporterToEdit().id
      );
      return;
    }
    props.deletedExporter();
  }

  const getHeaderText = () => {
    let headerText = "Add Email exporter";
    if (editMode) {
      headerText = "Edit Email exporter";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
        <FormControl variant="outlined">
          <Box style={{float: "left"}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Format
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setFormat(event.target.value);
              }}
              id="triggerSelect"
              variant="outlined"
              style={{minWidth: "100%", margin: "8px",fontWeight: "500"}}
              value={format}
              labelId="triggerSelectLabel"
            >
              {addOption("Summary JSON", "SummaryJson")}
              {addOption("Detailed Plain Text", "DetailedPlainText")}
            </Select>
          </Box>
        </FormControl>
        <br />
        <TextField
          id="emailTextField"
          label="Email"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setEmail(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={email}
        />
        <br />
        <FormControl variant="outlined">
          <Box style={{float: "left"}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Trigger
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setTrigger(event.target.value);
              }}
              id="triggerSelect"
              variant="outlined"
              style={{minWidth: "100%", margin: "8px",fontWeight: "500"}}
              value={trigger}
              labelId="triggerSelectLabel"
            >
              {addOption("Send report", "SendReport")}
            </Select>
          </Box>
        </FormControl>
        <br />
      </Box>
    );
  };

function addOption(text, value){
  return (<option value={value} style={{ color: "#111" }}>
  {text}
</option>);
}

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default EmailExporterEditView;

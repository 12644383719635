import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import UserDto from "../Dtos/UserDto";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "160px",
});

function UserEditView(props) {
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [emailField, setEmailField] = useState(undefined);
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [organization, setOrganization] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTimeReportApprover, setIsTimeReportApprover] = useState(false);
  const [timeReportApprover, setTimeReportApprover] = useState(undefined);
  const [employmentNumber, setEmploymentNumber] = useState(undefined);
  const [mondayMinutesToWork, setMondayMinutesToWork] = useState(480);
  const [tuesdayMinutesToWork, setTuesdayMinutesToWork] = useState(480);
  const [wednesdayMinutesToWork, setWednesdayMinutesToWork] = useState(480);
  const [thursdayMinutesToWork, setThursdayMinutesToWork] = useState(480);
  const [fridayMinutesToWork, setFridayMinutesToWork] = useState(480);
  const [saturdayMinutesToWork, setSaturdayMinutesToWork] = useState(0);
  const [sundayMinutesToWork, setSundayMinutesToWork] = useState(0);
  const [timeReportApprovers, setTimeReportApprovers] = useState([]);
  const [secondaryEmailField, setSecondaryEmailField] = useState(undefined);
  const [firstWorkingDay, setFirstWorkingDay] = useState(undefined);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [isSubContractor, setIsSubContractor] = useState(false);
  const [subContractorCompanyName, setSubContractorCompanyName] = useState(undefined);
  const [subContractorManagerName, setSubContractorManagerName] = useState(undefined);
  const [subContractorManagerEmail, setSubContractorManagerEmail] = useState(undefined);
  const [subContractorManagerPhoneNumber, setSubContractorManagerPhoneNumber] = useState(undefined);
  const [subContractorInvoiceEmail, setSubContractorInvoiceEmail] = useState(undefined);
  const [subContractorGiroNumber, setSubContractorGiroNumber] = useState(undefined);
  const [useFlexTime, setUseFlexTime] = useState(false);
  const [subContractorIsSolo, setSubContractorIsSolo] = useState(false);
  const [isActive, setIsActive] = useState(true);


  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      if (ApplicationState.getOrganizationForUserToAdd()) {
        setOrganization(ApplicationState.getOrganizationForUserToAdd());
        setTimeReportApprovers(await ApplicationState.getUsersForOrganization(ApplicationState.getOrganizationForUserToAdd().organizationId));
      }
      if (ApplicationState.getUserToEdit()) {
        setEditMode(true);
        setDescriptionField(ApplicationState.getUserToEdit().description);
        setEmailField(ApplicationState.getUserToEdit().email);
        setSecondaryEmailField(ApplicationState.getUserToEdit().secondaryEmail);
        setFirstName(ApplicationState.getUserToEdit().firstName);
        setLastName(ApplicationState.getUserToEdit().lastName);
        setOrganization(ApplicationState.getUserToEdit().organization);
        setIsAdmin(ApplicationState.getUserToEdit().isAdmin);
        setIsTimeReportApprover(ApplicationState.getUserToEdit().isTimeReportApprover);
        if(ApplicationState.getUserToEdit().timeReportApprover){
          setTimeReportApprover(ApplicationState.getUserToEdit().timeReportApprover.userId);
        }
        setEmploymentNumber(ApplicationState.getUserToEdit().employmentNumber);

        let targetWorkingTime = await ApplicationState.getUserTargetWorkingTime(ApplicationState.getUserToEdit().email);
        setMondayMinutesToWork(targetWorkingTime.mondayMinutesToWork);
        setTuesdayMinutesToWork(targetWorkingTime.tuesdayMinutesToWork);
        setWednesdayMinutesToWork(targetWorkingTime.wednesdayMinutesToWork);
        setThursdayMinutesToWork(targetWorkingTime.thursdayMinutesToWork);
        setFridayMinutesToWork(targetWorkingTime.fridayMinutesToWork);
        setSaturdayMinutesToWork(targetWorkingTime.saturdayMinutesToWork);
        setSundayMinutesToWork(targetWorkingTime.sundayMinutesToWork);

        setTimeReportApprovers(await ApplicationState.getUsersForOrganization(ApplicationState.getUserToEdit().organization.organizationId));
        if(ApplicationState.getUserToEdit().firstWorkingDay){
          setFirstWorkingDay(new Date(ApplicationState.getUserToEdit().firstWorkingDay).toLocaleDateString("sv-SE"));
        }
        setOnboardingCompleted(ApplicationState.getUserToEdit().onboardingCompleted);
        setPhoneNumber(ApplicationState.getUserToEdit().phoneNumber);
        setCity(ApplicationState.getUserToEdit().city);
        setIsSubContractor(ApplicationState.getUserToEdit().isSubContractor);
        setSubContractorCompanyName(ApplicationState.getUserToEdit().subContractorCompanyName);
        setSubContractorManagerName(ApplicationState.getUserToEdit().subContractorManagerName);
        setSubContractorManagerEmail(ApplicationState.getUserToEdit().subContractorManagerEmail);
        setSubContractorManagerPhoneNumber(ApplicationState.getUserToEdit().subContractorManagerPhoneNumber);
        setSubContractorInvoiceEmail(ApplicationState.getUserToEdit().subContractorInvoiceEmail);
        setSubContractorGiroNumber(ApplicationState.getUserToEdit().subContractorGiroNumber);
        setUseFlexTime(ApplicationState.getUserToEdit().useFlexTime);
        setSubContractorIsSolo(ApplicationState.getUserToEdit().subContractorIsSolo);
        setIsActive(ApplicationState.getUserToEdit().isActive);
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let minutesToWorkPerDay = 0;
    let timeReportApproverToSave = timeReportApprover;
    if(timeReportApprover === "-1"){
      timeReportApproverToSave = undefined;
    } else {
      timeReportApproverToSave = timeReportApprovers.find((x) => x.userId === timeReportApprover);
    }

    let fixedFirstWorkingDay = undefined;
    if(firstWorkingDay){
      fixedFirstWorkingDay = new Date(firstWorkingDay).toLocaleDateString("sv-SE");
    }

    let dto = new UserDto(
      ApplicationState.generateGuid(),
      firstName,
      lastName,
      emailField,
      descriptionField,
      organization,
      isAdmin,
      employmentNumber,
      minutesToWorkPerDay,
      isTimeReportApprover,
      timeReportApproverToSave,
      secondaryEmailField,
      fixedFirstWorkingDay,
      onboardingCompleted,
      city,
      phoneNumber,
      isSubContractor,
      subContractorCompanyName,
      subContractorManagerName,
      subContractorManagerEmail,
      subContractorManagerPhoneNumber,
      subContractorInvoiceEmail,
      subContractorGiroNumber,
      useFlexTime,
      subContractorIsSolo,
      isActive
    );

    if (ApplicationState.getUserToEdit()) {
      dto.userId = ApplicationState.getUserToEdit().userId;
      await ApplicationState.saveUpdatedUser(dto);
      let targetWorkingTime = await ApplicationState.getUserTargetWorkingTime(dto.email);
      targetWorkingTime.mondayMinutesToWork = mondayMinutesToWork;
      targetWorkingTime.tuesdayMinutesToWork = tuesdayMinutesToWork;
      targetWorkingTime.wednesdayMinutesToWork = wednesdayMinutesToWork;
      targetWorkingTime.thursdayMinutesToWork = thursdayMinutesToWork;
      targetWorkingTime.fridayMinutesToWork = fridayMinutesToWork;
      targetWorkingTime.saturdayMinutesToWork = saturdayMinutesToWork;
      targetWorkingTime.sundayMinutesToWork = sundayMinutesToWork;
      await ApplicationState.saveUpdatedUserTargetWorkingTime(targetWorkingTime);
      ApplicationState.setUserToView(dto);
      ApplicationState.setOrganizationForUserToAdd(undefined);
      ApplicationState.setUserToEdit(undefined);
      props.editedUser();
    } else {
      await ApplicationState.saveNewUser(dto);
      let targetWorkingTime = await ApplicationState.getUserTargetWorkingTime(dto.email);
      targetWorkingTime.mondayMinutesToWork = mondayMinutesToWork;
      targetWorkingTime.tuesdayMinutesToWork = tuesdayMinutesToWork;
      targetWorkingTime.wednesdayMinutesToWork = wednesdayMinutesToWork;
      targetWorkingTime.thursdayMinutesToWork = thursdayMinutesToWork;
      targetWorkingTime.fridayMinutesToWork = fridayMinutesToWork;
      targetWorkingTime.saturdayMinutesToWork = saturdayMinutesToWork;
      targetWorkingTime.sundayMinutesToWork = sundayMinutesToWork;
      await ApplicationState.saveUpdatedUserTargetWorkingTime(targetWorkingTime);
      ApplicationState.setUserToView(dto);
      ApplicationState.setOrganizationForUserToAdd(undefined);
      ApplicationState.setUserToEdit(undefined);
      props.addedUser();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteUser(
      ApplicationState.getUserToEdit().userId
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete user with id: " +
          ApplicationState.getUserToEdit().userId
      );
      return;
    }

    ApplicationState.setOrganizationForUserToAdd(undefined);
    ApplicationState.setUserToEdit(undefined);
    props.deletedUser();
  }

  const getHeaderText = () => {
    let headerText = "Add user";
    if (editMode) {
      headerText = "Edit user";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
       <TextField
        id="firstnameTextField"
        label="First name"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setFirstName(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={firstName}
      />
      <br />
      <TextField
        id="lastnameTextField"
        label="Last name"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setLastName(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={lastName}
      />
      <br />
      <TextField
        id="emailTextField"
        label="E-mail"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setEmailField(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={emailField}
      />
      <br />
      <TextField
        id="emailSecondaryTextField"
        label="Secondary e-mail"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSecondaryEmailField(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={secondaryEmailField}
      />
      <br />
      <TextField
        id="phoneNumberTextField"
        label="Phone number"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setPhoneNumber(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"  
        value={phoneNumber}
      />
      <br />
      <TextField
        id="cityTextField"
        label="City"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setCity(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={city}
      />
      <br />
      <TextField
        id="employmentNumberTextField"
        label="Employment number"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setEmploymentNumber(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={employmentNumber}
      />
      <br />
      <TextField
        id="descriptionTextField"
        label="Description"
        type="text"
        multiline
        rows={3}
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setDescriptionField(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={descriptionField}
      />
      <br />
      <TextField
                id="firstWorkingDayTextField"
                type="date"
                label="First working day"
                style={{ margin: "8px" }}
                onChange={(event) => setFirstWorkingDay(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={firstWorkingDay}
              />
              <FormGroup row>
        <FormControlLabel
          control={
            <Box style={{paddingLeft: "8px"}}>
              <Checkbox
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
                name="checkboxIsActive"
                color="primary"
              />
            </Box>
          }
          label="User is active"
        />
      </FormGroup>
      <br />
      
      <Box style={{padding: "8px"}}>
        <Typography variant="subtitle1">Roles</Typography>
      </Box>
      <FormGroup row>
        <FormControlLabel
          control={
            <Box style={{paddingLeft: "8px"}}>
              <Checkbox
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
                name="checkedB"
                color="primary"
                disabled={isSubContractor}
              />
            </Box>
          }
          label="Admin"
        />
        </FormGroup>
        <FormGroup row>
        <FormControlLabel
          control={
            <Box style={{paddingLeft: "8px"}}>
              <Checkbox
                checked={isTimeReportApprover}
                onChange={() => setIsTimeReportApprover(!isTimeReportApprover)}
                name="checkboxIsTimeReportApprover"
                color="primary"
                disabled={isSubContractor}
              />
            </Box>
          }
          label="Time report approver"
        />
      </FormGroup>
      
      <br />
      <Box style={{ paddingLeft: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSubContractor}
                  onChange={(event) => {
                    setIsSubContractor(event.target.checked);
                    if(event.target.checked === true){
                      setIsAdmin(false);
                      setIsTimeReportApprover(false);
                      setUseFlexTime(false);
                      setMondayMinutesToWork(0);
                      setTuesdayMinutesToWork(0);
                      setWednesdayMinutesToWork(0);
                      setThursdayMinutesToWork(0);
                      setFridayMinutesToWork(0);
                      setSaturdayMinutesToWork(0);
                      setSundayMinutesToWork(0);
                    }
                  }}
                  name="onging"
                  color="primary"
                />
              }
              label="Subcontractor"
            />
          </Box>
      { isSubContractor && (
      <React.Fragment>
        <br />
      <TextField
        id="subContractorCompanyNameTextField"
        label="Sub contractor company name"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorCompanyName(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorCompanyName}
      />
      <br />
      <Box style={{ paddingLeft: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={subContractorIsSolo}
                  onChange={(event) => {
                    setSubContractorIsSolo(event.target.checked);
                  }}
                  name="onging"
                  color="primary"
                />
              }
              label="Solo subcontractor"
            />
          </Box>
      <br />
      <TextField
        id="subContractorManagerNameTextField"
        label="Sub contractor manager name"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorManagerName(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorManagerName}
      />
      <br />
      <TextField
        id="subContractorManagerEmailTextField"
        label="Sub contractor manager email"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorManagerEmail(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorManagerEmail}
      />
      <br />
      <TextField
        id="subContractorManagerPhoneNumberTextField"
        label="Sub contractor manager phone number"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorManagerPhoneNumber(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorManagerPhoneNumber}
      />
      <br />
      <TextField
        id="subContractorInvoiceEmailTextField"
        label="Sub contractor invoice email"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorInvoiceEmail(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorInvoiceEmail}
      />
      <br />
      <TextField
        id="subContractorGiroNumberTextField"
        label="Sub contractor giro number"
        type="text"
        style={{margin: "8px", width: "400px"}}
        onChange={(event) => setSubContractorGiroNumber(event.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={subContractorGiroNumber}
      />
      <br />
      </React.Fragment>
      )}
      <br />
      
      <Box style={{paddingLeft: "8px", paddingBottom: "4px"}}>
        <Typography variant="subtitle1">Target work time</Typography>
      </Box>
      <Box style={{ paddingLeft: "8px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useFlexTime}
                  onChange={(event) => {
                    setUseFlexTime(event.target.checked);
                  }}
                  name="onging"
                  color="primary"
                  disabled={isSubContractor}
                />
              }
              label="Use flex time"
            />
          </Box>
          <TextField
                id="mondayTextField"
                label="Monday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setMondayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={mondayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />
              <TextField
                id="tuesdayTextField"
                label="Tuesday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setTuesdayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={tuesdayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />
              <TextField
                id="wednesdayTextField"
                label="Wednesday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setWednesdayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={wednesdayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />

              <TextField
                id="thursdayTextField"
                label="Thursday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setThursdayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={thursdayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />

              <TextField
                id="fridayTextField"
                label="Friday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setFridayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={fridayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />

              <TextField
                id="saturdayTextField"
                label="Saturday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setSaturdayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={saturdayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />

              <TextField
                id="sundayTextField"
                label="Sunday (in minutes)"
                type="number"
                style={{width: "142px", margin: "8px"}}
                onChange={(event) => setSundayMinutesToWork(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "0",
                    max: "999",
                    step: "1",
                  }
                }}
                variant="outlined"
                value={sundayMinutesToWork}
                disabled={isSubContractor}
              />
              <br />

              <Box>
              <InputLabelSelect
            id="reportApproverSelectLabel"
            htmlFor="userSelect"
            variant="outlined"
            shrink={true}
          >
            Time report approver
          </InputLabelSelect>
        
            <Select
            native
            onChange={(event) => {
              setTimeReportApprover(event.target.value);
            }}
            id="userSelect"
            variant="outlined"
            sx={{
              margin: "8px",
              fontWeight: "500",
              width: {xs: "95%", sm: "400px"},
            }}
            value={timeReportApprover}
          >
            <option
              key="-1"
              label="None"
              value="-1"
            ></option>
            {timeReportApprovers
              .filter((x) => x.isTimeReportApprover)
              .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
              .map((user) => {
                return addUserInSelect(user);
              })}
          </Select>
          
        </Box>
          
      <br />
      </Box>
    );
  };

  function addUserInSelect(user) {
    return (
      <option
        key={user.userId}
        label={user.firstName + " " + user.lastName + " - " + user.email}
        value={user.userId}
      ></option>
    );
  }

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default UserEditView;

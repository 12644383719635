import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react"
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const DButton = styled(Button)({
  maxHeight: "58px",
  minHeight: "58px",
  maxWidth: "58px",
  minWidth: "58px",
  marginRight: "8px",
});

export const DeleteButton = (props) => {
    return <DButton
    variant="outlined"
    color="secondary"
    size="large"
    onClick={props.onClick}
  >
    <DeleteForeverIcon></DeleteForeverIcon>
  </DButton>;
};
import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import ClientView from "./ClientView";
import ClientEditView from "./ClientEditView";
import { PrimaryButton } from './PrimaryButton';

function ClientsView() {
  const [clients, setClients] = useState([]);
  const [clientToView, setClientToView] = useState(undefined);
  const [openUserDialog, setOpenClientDialog] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let orgId = ApplicationState.getState().user.organization.organizationId;
      let clients = await ApplicationState.getClientsForOrganization(orgId);
      setClients(clients);
      setClientToView(clients[0]);
    }
    load();
  }, []);

  async function editedClient() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let clients = await ApplicationState.getClientsForOrganization(orgId);
    setClients(clients);
    setClientToView(clients.find((x) => x.id === clientToView.id));
    setOpenClientDialog(false);
  }

  async function addedClient() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let clients = await ApplicationState.getClientsForOrganization(orgId);
    setClients(clients);
    setClientToView(
      clients.find((x) => x.id === ApplicationState.getClientToView().id)
    );
    setOpenClientDialog(false);
  }

  async function deletedClient() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let clients = await ApplicationState.getClientsForOrganization(orgId);
    setClients(clients);
    setClientToView(clients[0]);
    setOpenClientDialog(false);
  }

  function viewClient(clientToView2) {
    setClientToView(clientToView2);
  }

  function newClientClick() {
    ApplicationState.setClientToEdit(undefined);
    setOpenClientDialog(true);
  }

  function addClient(client) {
    let primaryText = "";
    if(client.name && client.name.length > 0){
      primaryText = client.name;
    }

    return (
      <Box>
        {clientToView && clientToView.id === client.id && (
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => viewClient(client)}
            style={{
              backgroundColor: "#781938",
              color: "white",
              padding: "16px",
            }}
          >
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={primaryText} />
          </ListItem>
        )}
        {!clientToView ||
          (clientToView.id != client.id && (
            <ListItem
              key={ApplicationState.generateGuid()}
              button
              onClick={() => viewClient(client)}
            >
              <ListItemAvatar></ListItemAvatar>
              <ListItemText primary={primaryText} />
            </ListItem>
          ))}
      </Box>
    );
  }

  function addClients() {
    return clients
      .sort((a, b) => (a.date < b.date ? 1 : -1))
      .map((element) => {
        return addClient(element);
      });
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Clients</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add client"
                buttonClick={() => newClientClick()}
              >
        </PrimaryButton>
      </Box>
    );
  }

  return (
    <Box p={0}>
      <Box>{addHeader()}</Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={4} style={{ backgroundColor: "#f6f6f6" }}>
          <List style={{padding: "0px"}}>{addClients()}</List>
        </Grid>
        <Grid item xs={8}>
          {clientToView && (
            <ClientView
              selectedClient={clientToView}
              editedClient={editedClient}
              deletedClient={deletedClient}
            ></ClientView>
          )}
          {!clientToView && <Box>No client selected</Box>}
          <Dialog
            open={openUserDialog}
            onClose={() => setOpenClientDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <ClientEditView
              selectedClient={clientToView}
              deletedClient={deletedClient}
              addedClient={addedClient}
              editedClient={editedClient}
              cancelClick={() => setOpenClientDialog(false)}
            ></ClientEditView>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ClientsView;

import DateTimeHelper from "../Utils/DateTimeHelper";

export default class TimeReportDTO {
    constructor(
      id,
      user,
      from,
      to,
      created,
      sent,
      approved,
      approver,
      approverComment,
      userComment
    ) {
      this.id = id;
      this.user = user;
      this.from = new Date(from);
      this.to = new Date(to);
      this.created = created;
      this.sent = sent;
      this.week = DateTimeHelper.getWeekNumber(this.from);
      this.month = this.from.getMonth();
      this.year = this.from.getFullYear();
      this.approved = approved;
      this.approver = approver;
      this.approverComment = approverComment;
      this.userComment = userComment;
    }
    
  }
  
export default class TaskDto {
  constructor(id, completedDateTime, title, description, taskListName, userData) {
    this.id = id;
    this.completedDateTime = completedDateTime;
    this.title = title;
    this.description = description;
    this.taskListName = taskListName;
    this.userData = userData;
  }
}

export default class UserDataServiceSelectionDto {
  constructor(id, accessToken, refreshToken, user, service, accountIdentifier, dynamicTimeAccessCode, name, username) {
    this.id = id;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.user = user;
    this.userDataService = service;
    this.accountIdentifier = accountIdentifier;
    this.create = new Date();
    this.dynamicTimeAccessCode = dynamicTimeAccessCode;
    this.name = name;
    this.username = username;
  }
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import EmailExporterPropertyDto from "../Dtos/EmailExporterPropertyDto";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";

function EmailExporterPropertyEditView(props) {
  let history = useHistory();

  const [valueField, setValueField] = useState(undefined);
  const [nameField, setNameField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [emailExporterField, setEmailExporterField] = useState(undefined);
  const [requiredForActivities, setRequiredForActivities] = useState(false);
  const [requiredForProjects, setRequiredForProjects] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      if (ApplicationState.getEmailExporterToAddPropertyFor()) {
        setEmailExporterField(
          ApplicationState.getEmailExporterToAddPropertyFor().id
        );
      }
      if (ApplicationState.getEmailExporterPropertyToEdit()) {
        setEditMode(true);
        setNameField(ApplicationState.getEmailExporterPropertyToEdit().name);
        setRequiredForActivities(ApplicationState.getEmailExporterPropertyToEdit().requiredForActivities);
        setRequiredForProjects(ApplicationState.getEmailExporterPropertyToEdit().requiredForProjects);
        setEmailExporterField(
          ApplicationState.getEmailExporterPropertyToEdit().emailExporter.id
        );
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new EmailExporterPropertyDto(
      ApplicationState.generateGuid(),
      nameField,
      ApplicationState.getEmailExporterToAddPropertyFor(),
      requiredForActivities,
      requiredForProjects,
      new Date()
    );
    if (ApplicationState.getEmailExporterPropertyToEdit()) {
      dto.id = ApplicationState.getEmailExporterPropertyToEdit().id;
      dto.emailExporter =
        ApplicationState.getEmailExporterPropertyToEdit().emailExporter;
      await ApplicationState.saveUpdatedEmailExporterProperty(dto);
      props.editedEmailExporterProperty();
    } else {
      await ApplicationState.saveNewEmailExporterProperty(dto);
      props.addedEmailExporterProperty();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteEmailExporterProperty(
      ApplicationState.getEmailExporterPropertyToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete email exporter property with id: " +
          ApplicationState.getEmailExporterPropertyToEdit().id
      );
      return;
    }
    if (ApplicationState.getEmailExporterPropertyToEdit()) {
      ApplicationState.setEmailExporterPropertyToEdit(undefined);
      props.deletedEmailExporterProperty();
    } else {
      ApplicationState.setEmailExporterToAddPropertyFor(undefined);
      props.deletedEmailExporterProperty();
    }
  }

  const getHeaderText = () => {
    let headerText = "Add email exporter property";
    if (editMode) {
      headerText = "Edit email exporter property";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={requiredForActivities}
              onChange={(event) => {
                setRequiredForActivities(event.target.checked);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Required for activities"
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={requiredForProjects}
              onChange={(event) => {
                setRequiredForProjects(event.target.checked);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Required for projects"
        />
        <br />
      </Box>
    );
  };

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default EmailExporterPropertyEditView;

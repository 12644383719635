import Box from "@mui/material/Box";
import { CircularProgress, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import ServicesView from "./ServicesView";
import StandardTimeView from "./StandardTimeView";
import UserKeywordsView from "./UserKeywordsView";
import AutoFlexView from "./AutoFlexView";
import IgnoreKeywordsView from "./IgnoreKeywordsView";
import ApplicationState from "../State/ApplicationState";
import { PrimaryButton } from "./PrimaryButton";
import TimeReportView from "./TimeReportView";
import KeywordSuggestionsView from "./KeywordSuggestionsView";
import { useHistory } from "react-router-dom";

function OnboardingView() {
  const [step, setStep] = React.useState("Start");
  const [generatingReport, setGeneratingReport] = React.useState(false);
  const history = useHistory();

  //Kör vid uppstart
  useEffect(() => {}, []);

  async function reGenerateTimeReport() {
    setGeneratingReport(true);
    await ApplicationState.reGenerateCurrentTimeReportForUser(
      ApplicationState.getUser().userId
    );
    await ApplicationState.loadTimeReportsForUser();
    setGeneratingReport(false);
    setStep("EventsNotInTimeReport");
  }

  function addStepsOverview() {
    return (
      <Box style={{ margin: "16px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {step != "Start" && step != "ReportGeneration" && (
            <PrimaryButton
              icon="cancel"
              text="Back"
              buttonClick={() => {
                if (step === "Services") {
                  setStep("Start");
                }
                if (step === "StandardWeek") {
                  setStep("Services");
                }
                if (step === "EventsNotInTimeReport") {
                  setStep("StandardWeek");
                }
                if (step === "Keywords") {
                  setStep("EventsNotInTimeReport");
                }
                if (step === "AutoFlex") {
                  setStep("Keywords");
                }
                if (step === "Ignores") {
                  setStep("AutoFlex");
                }
                if (step === "Done") {
                  setStep("Ignores");
                }
              }}
            />
          )}
          {step === "Start" && (
            <Box style={{ padding: "16px", color: "white" }}></Box>
          )}
          {step === "Services" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 1 of 7</Box>
          )}
          {step === "StandardWeek" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 2 of 7</Box>
          )}
          {step === "ReportGeneration" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 3 of 7</Box>
          )}
          {step === "EventsNotInTimeReport" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 4 of 7</Box>
          )}
          {step === "Keywords" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 5 of 7</Box>
          )}
          {step === "AutoFlex" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 6 of 7</Box>
          )}
          {step === "Ignores" && (
            <Box style={{ padding: "16px", color: "white" }}>Step 7 of 7</Box>
          )}
          {step === "Done" && (
            <Box style={{ padding: "16px", color: "white" }}></Box>
          )}
          {step != "Done" && step != "ReportGeneration" && (
            <PrimaryButton
              icon="sdfsd"
              text="Next"
              buttonClick={() => {
                if (step === "Start") {
                  setStep("Services");
                }
                if (step === "Services") {
                  setStep("StandardWeek");
                }
                if (step === "StandardWeek") {
                  setStep("ReportGeneration");
                  reGenerateTimeReport();
                }
                // Not possible becasue interupting time report is bad and cause bad data and errors
                // if (step === "ReportGeneration") {
                //   setStep("EventsNotInTimeReport");
                // }
                if (step === "EventsNotInTimeReport") {
                  setStep("Keywords");
                }
                if (step === "Keywords") {
                  setStep("AutoFlex");
                }
                if (step === "AutoFlex") {
                  setStep("Ignores");
                }
                if (step === "Ignores") {
                  setStep("Done");
                  let userToUpdate = ApplicationState.getUser();
                  userToUpdate.onboardingCompleted = true;
                  ApplicationState.saveUpdatedUser(userToUpdate);
                }
              }}
            />
          )}
           <PrimaryButton
              icon="cancel"
              text="Exit"
              buttonClick={() => {
                setStep("Done");
                let userToUpdate = ApplicationState.getUser();
                userToUpdate.onboardingCompleted = true;
                ApplicationState.saveUpdatedUser(userToUpdate);
                history.push("/timereport");
              }}
            />
        </Grid>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Box
            style={{
              paddingBottom: "16px",
              paddingTop: "16px",
              color: "white",
            }}
          >  bbbbbbbbbb 
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {addStepsOverview()}
                <Paper elevation={7} style={{ width: "100%" }}>
                  <Box style={{ padding: "24px" }}>
                    {step === "Start" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Welcome to Dynamic Times onboarding!
                        </Typography>
                        <br />
                        <Typography>
                          This guide will help you automate your time reporting
                          with Dynamic Time
                        </Typography>
                        <Typography>
                          In this tutorial Dynamic Time will analyze your data
                          and you will help it to better understand how and with
                          what you work.
                        </Typography>
                        <br />
                        1. Gather data
                        <br />
                        2. Analyze data
                        <br />
                        3. Refine data
                        <br />
                      </React.Fragment>
                    )}
                    {step === "StandardWeek" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Gather data - Standard week
                        </Typography>
                        <Typography variant="subtitle1">
                          Dynamic Time generates a time report by analyzing the
                          data in your connected services.
                          <br />
                          <br />
                          <b>Fills up your day</b>
                          <br />
                          Standard week helps Dynamic Time to fill the gaps in
                          your time reports when it does not know what you have
                          worked with on a specific hour and day.
                          <br />
                          <i>Example:</i>
                          <br />
                          Your standard time on Monday is 8h for Customer A.
                          <br />
                          Dynamic Time only finds a 2h meeting with Customer A
                          and nothing else on Monday
                          <br />
                          Result: 8h on Customer A.
                          <br />
                          <br />
                          <b>Can be overwritten</b>
                          <br />
                          Standard week is only used when there are gaps to
                          fill. Data found in your connected services will
                          overwrite the time configured in your Standard week.
                          <br />
                          <i>Example:</i>
                          <br />
                          Your standard time on Monday is 8h for Customer A.
                          <br />
                          Dynamic Time finds a 3h meeting with Customer B and
                          nothing else on Monday
                          <br />
                          Result: 3h on Customer B and 5h on Customer A
                          <br />
                          <br />
                          <b>Control how standard time is overwritten</b>
                          <br />
                          To control how your standard time is overwritten when
                          data is found you can assign priorities to make sure
                          the correct standard time is used first.
                          <br />
                          <i>Example:</i>
                          <br />
                          Your standard time on Monday is 4h for Customer A with
                          High priority and 4h for Customer B with Normal
                          priority.
                          <br />
                          Dynamic Time finds a 6h meeting with Customer C and
                          nothing else on Monday
                          <br />
                          Result: 2h on Customer A, 0h on Customer B and 6h on
                          Customer C
                          <br />
                          <br />
                          <b>How to add standard time?</b>
                          <br />
                          1. Click Add time
                          <br />
                          2. Select project and activity and weekday and time
                          and priority
                          <br />
                          3. Done
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "Services" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Gather Data - Connect your services
                        </Typography>
                        <Typography variant="subtitle1">
                          Dynamic Time automate time reporting by analyzing data
                          from your connected services.
                          <br />
                          <br />
                          <b>Whare are events?</b>
                          <br />
                          Events are that data collected from your connected
                          services. It can be emails sent, meetings attend,
                          documents edited and so on.
                          <br />
                          <b>How to connect a service?</b>
                          <br />
                          1. Click on the service you want to connect
                          <br />
                          2. In the dialog select the data you want to analyze,
                          e.g. e-mail and calendar
                          <br />
                          3. In the dialog select the account you want to
                          connect and accept the permissions Dynamic Time asks
                          for
                          <br />
                          4. Done - Dynamic Time will now generate a new time
                          report every night with data from you connected
                          services
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "Keywords" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Refine data - Keywords
                        </Typography>
                        <Typography variant="subtitle1">
                          Keywords are words or phrases that help Dynamic Time
                          know to which activities and projects events shall be
                          assigned.
                          <br />
                          <br />
                          <b>What is a good keyword?</b>
                          <br />
                          Keywords should be unique and only associated with a
                          specific project and activity.
                          <br />
                          Good keywords are customer names, project names, IDs.
                          E.g. Customer A, Project B, @customerX.com, #12345
                          <br />
                          Bad keywords are common words e.g. meeting, budget,
                          file, stuff
                          <br />
                          <br />
                          <b>How to add a keyword?</b>
                          <br />
                          1. Click on Add keyword or scroll down to the bottom
                          of the page an choose from the suggestions
                          <br />
                          2. Select what project and activity you want to
                          associate the keyword with
                          <br />
                          3. Enter the keyword and click Save
                          <br />
                          4. Done - Dynamic Time will now use the keywords to
                          improve your time reports
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "ReportGeneration" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Analyze Data - Time report generation
                        </Typography>
                        <Typography variant="subtitle1">
                          Dynamic Time generates your first time report and will
                          from now on generate new time reports every night.
                          <br />
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "EventsNotInTimeReport" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Refine data - Add events to time report
                        </Typography>
                        <Typography variant="subtitle1">
                          Here is list of all the events from your connected
                          services that Dynamic Time couldn't include in your
                          time report.
                          <br />
                          <br />
                          <b>Why you should add events to your time report?</b>
                          <br />
                          Adding events helps Dynamic Time to generate better
                          time reports over time. The more events you add, the
                          better your time reports will be.
                          <br />
                          <br />
                          <b>What are keywords?</b>
                          <br />
                          Keywords are words or phrases that help Dynamic Time
                          know to which activities and projects events shall be
                          assigned.
                          <br />
                          <br />
                          <b>How to add an event?</b>
                          <br />
                          1. Click on the event you want to add
                          <br />
                          2. Select what project and activity it belongs to
                          <br />
                          3. In the dialog select relevent keywords from the
                          suggestions or add your own.
                          <br />
                          4. Done - Dynamic Time will now generate event better
                          reports
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "AutoFlex" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Refine data - Automate flex time
                        </Typography>
                        <Typography variant="subtitle1">
                          AutoFlex let Dynamic Time helping you to keep track of
                          when you work too much or too little.
                          <br />
                          <br />
                          <b>
                            How does Dynamic Time know when I work too MUCH?
                          </b>
                          <br />
                          By adding your normal working hours Dynamic Time will
                          treat every event found outside those hours as extra
                          time to add to your time report.
                          <br /> E.g. if your working hours are 09:00 to 17:00
                          and you attend a meeting between 18:00 and 19:00 and
                          extra hour will be added to your time report.
                          <br />
                          <br />
                          <b>
                            How does Dynamic Time know when I work too LITTLE?
                          </b>
                          <br />
                          By adding keywords for when to reduce your working
                          time Dynamic Time will reduce the time for the
                          meetings that match those keywords.
                          <br />
                          E.g. you have conmfigured #Doctor as a keyword and
                          Dynamic Time find a meeting called #Doctor that is 1h
                          long Dynamic Time will remove one hour from your time
                          report.
                          <br />
                          <br />
                          <b>How to normal working time?</b>
                          <br />
                          1. Click on the '+' next to the weekday you want to
                          add time for
                          <br />
                          2. Select start and end time and click Save
                          <br />
                          3. Done - Dynamic Time will now consider your normal
                          working times.
                          <br />
                          <br />
                          <b>How to add keyword to reduce working time?</b>
                          <br />
                          1. Type the keyword in the textbox New keyword
                          <br />
                          2. Click the '+' next to the New keyword textbox to
                          Save
                          <br />
                          3. Done - Dynamic Time will now reduce your working
                          time if the keyword is found
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "Ignores" && (
                      <React.Fragment>
                        <Typography variant="h4">
                          Refine data - Ignore events with keywords
                        </Typography>
                        <Typography variant="subtitle1">
                          Ignore keywords help Dynamic Time to exclude irrelvant
                          events from your time report and prevent Dynamic Time
                          from assiggn events to wrong project and activity.
                          <br />
                          <br />
                          <b>What is type 'Word'?</b>
                          <br />
                          Type 'Word' should be used when you want the event to
                          be analyzed by Dynamic Time and just a specific word
                          or phrase to be ignored during report generation.
                          <br />
                          <i>Example:</i>
                          <br />
                          You have added 'daniel@customerX.com' as an Ignore
                          keyword with type 'Word'.
                          <br />
                          You have a meeting where 'daniel@customerX.com' and
                          'anders@customerA.com' is a participant.
                          <br />
                          Result: 'daniel@customerX.com' is ignored by Dynamic
                          Time when analyzing events but 'anders@customerA.com'
                          is included.
                          <br />
                          <br />
                          <b>What is type 'Event'?</b>
                          <br />
                          Type 'Event' means that if the title of an event
                          contains the ignore keyword Dynamic Time will ignore
                          the event and not even add it to Events not in time
                          report.
                          <br />
                          <i>Example</i>
                          <br />
                          You have added 'Drive kids to school' as an Ignore
                          keyword with type 'Event'.
                          <br /> In your calendar you have a meeting called
                          'Drive kids to school'
                          <br />
                          Result: The meeting is NOT included in your time
                          report
                          <br />
                          <br />
                          <b>How to add an Ignore Keyword?</b>
                          <br />
                          1. Click on Add keyword
                          <br />
                          2. Select project and activity and type
                          <br />
                          3. Type the keyword and click Save
                          <br />
                          3. Done - Dynamic Time will now consider your ignore
                          keywords.
                          <br />
                        </Typography>
                      </React.Fragment>
                    )}
                    {step === "Done" && (
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h4">Completed!</Typography>

                        <Typography variant="subtitle1">
                          <center>
                            You're now ready to enjoy automatic creation of time
                            reports!
                            <br />
                            <br />
                            You can always go to Settings if you in the future
                            need to edit the settings you just configured.
                          </center>
                        </Typography>
                      </Grid>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>

          <Paper elevation={7}>
            <Box p={0}>
              {step === "Start" && <React.Fragment></React.Fragment>}
              {step === "Services" && (
                <React.Fragment>
                  <ServicesView />
                </React.Fragment>
              )}
              {step === "StandardWeek" && (
                <React.Fragment>
                  <StandardTimeView />
                </React.Fragment>
              )}
              {step === "Keywords" && (
                <React.Fragment>
                  <UserKeywordsView />
                </React.Fragment>
              )}

              {step === "AutoFlex" && (
                <React.Fragment>
                  <AutoFlexView />
                </React.Fragment>
              )}
              {step === "Ignores" && (
                <React.Fragment>
                  <IgnoreKeywordsView />
                </React.Fragment>
              )}
            </Box>
          </Paper>
          {step === "ReportGeneration" && (
            <React.Fragment>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <CircularProgress
                  size={60}
                  color="secondary"
                  style={{ paddingLeft: "4px" }}
                ></CircularProgress>
                <Typography style={{ marginTop: "12px", color: "white" }}>
                  <center>
                    Generating time report... This can take a few minutes
                  </center>
                </Typography>
              </Grid>
              <Box style={{ padding: "24px" }}></Box>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Typography variant="h5" style={{ color: "white" }}>
                  Watch this while you wait for your time report to be done :-)
                </Typography>
                <Box>
                  <center>
                    <iframe
                      width="647"
                      height="1151"
                      src="https://www.youtube.com/embed/0Z2_jywoWBw"
                      title="Demonskrivaren på HDAB"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                      style={{ maxWidth: "100%" }}
                    ></iframe>
                  </center>
                </Box>
              </Grid>
            </React.Fragment>
          )}
          {step === "EventsNotInTimeReport" && (
            <React.Fragment>
              <TimeReportView isOnboarding={true} style={{ width: "100%" }} />
            </React.Fragment>
          )}
          {step === "Keywords" && (
            <React.Fragment>
              <KeywordSuggestionsView />
            </React.Fragment>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default OnboardingView;

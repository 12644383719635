export default class TimeItemViewDto {
  constructor(name, duration, project, activity, showActivityText) {
    this.name = name;
    this.duration = duration;
    this.project = project;
    this.activity = activity;
    this.showActivityText = showActivityText
  }
  //TODO: DURATION SKA INTE VARA MED, MEN HUR FÅ BORT?
  // constructor(timeCode, duration, id, name, parent){
  //     this.timeCode = timeCode;
  //     this.duration = duration;
  //     this.id = id;
  //     this.name = name;
  //     this.parent = parent;
  // }
}

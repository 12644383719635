import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from "./PrimaryButton";
import { DeleteButton } from "./DeleteButton";
import ProjectExtensionDto from "../Dtos/ProjectExtensionDto";
import {
  FormControlLabel,
  Switch,
} from "@mui/material";

function ProjectExtensionCrudView(props) {
  const [editMode, setEditMode] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [activated, setActivated] = useState(false);
  const [extensionNumber, setExtensionNumber] = useState(0);
  const [description, setDescription] = useState(undefined);

  useEffect(() => {
    const load1 = async () => {
    };
    load1();

    if (ApplicationState.getProjectExtensionToEdit()) {
      setEditMode(true);
      setStartDate(new Date(ApplicationState.getProjectExtensionToEdit().startDate).toLocaleDateString("sv-SE"));
      setEndDate(new Date(ApplicationState.getProjectExtensionToEdit().endDate).toLocaleDateString("sv-SE"));
      setActivated(ApplicationState.getProjectExtensionToEdit().activated);
      setExtensionNumber(ApplicationState.getProjectExtensionToEdit().extensionNumber);
      setDescription(ApplicationState.getProjectExtensionToEdit().description);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new ProjectExtensionDto(
      ApplicationState.generateGuid(),
      ApplicationState.getProjectToAddProjectExtensionFor(),
      startDate,
      endDate,
      activated,
      extensionNumber,
      new Date(Date.now()),
      description
    );
    if (ApplicationState.getProjectExtensionToEdit()) {
      dto.id = ApplicationState.getProjectExtensionToEdit().id;
      dto.project = ApplicationState.getProjectExtensionToEdit().project;
      dto.created = ApplicationState.getProjectExtensionToEdit().created;
      await ApplicationState.saveUpdatedProjectExtension(dto);
      props.editedProjectExtension();
    } else {
      await ApplicationState.saveNewProjectExtension(dto);
      props.addedProjectExtension();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteProjectExtension(
      ApplicationState.getProjectExtensionToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete projectExtension with id: " +
          ApplicationState.getProjectExtensionToEdit().id
      );
      return;
    }
    props.deletedProjectExtension();
  }

  const getHeaderText = () => {
    let headerText = "Add Project Extension";
    if (editMode) {
      headerText = "Edit Project Extension";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="startDateTextField"
          label="Start Date"
          type="date"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setStartDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={startDate}
        />
        <br />
        <TextField
          id="endDateTextField"
          label="End Date"
          type="date"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setEndDate(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={endDate}
        />
        <br />
        <TextField
          id="extensionNumberTextField"
          label="Extension Number"
          type="number"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setExtensionNumber(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={extensionNumber}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          multiline
          rows={4}
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setDescription(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={description}
        />
        <br />
        <Box style={{ paddingLeft: "8px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={activated}
                  onChange={(event) => {
                    setActivated(event.target.checked);
                  }}
                  name="activated"
                  color="primary"
                />
              }
              label="Activated"
            />
          </Box>
        <br />
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              ></PrimaryButton>
              {editMode && <DeleteButton onClick={deleteClick}></DeleteButton>}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectExtensionCrudView;

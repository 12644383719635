export default class OrganizationSettingDto {
  constructor(id, organization, created, absenceProject, reportingInterval, useFlexTime, flexWithdrawalActivity) {
    this.id = id;
    this.organization = organization;
    this.created = created;
    this.absenceProject = absenceProject;
    this.reportingInterval = reportingInterval;
    this.useFlexTime = useFlexTime;
    this.flexWithdrawalActivity = flexWithdrawalActivity;
  }
}

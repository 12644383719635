import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { PrimaryButton } from "../Components/PrimaryButton";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function TimeReportUserView() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [timerReportFiles, setTimerReportFiles] = useState(undefined);
  const [commentField, setCommentField] = useState(undefined);
  const [flexTime, setFlexTime] = useState("0h");
  const [flexTimeDifference, setFlexTimeDifference] = useState("0h");
  const [flexTimeMaxDate, setFlexTimeMaxDate] = useState(
    new Date().toLocaleDateString("sv-SE")
  );

  let history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      await showTimeEntries();
      await showFlexTime();
    };
    load1();
  }, []);

  function addTableForTimeEntries() {
    let projectsForTimeEntries = [];
    timeEntries
      .sort((a, b) => {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((timeEntry) => {
        if (
          !projectsForTimeEntries.some(
            (foo) => foo.name === timeEntry.activity.project.name
          )
        ) {
          projectsForTimeEntries.push(timeEntry.activity.project);
        }
      });

    return (
      <CustomGrid item component={Card} xs={12} sx={{
        margin: {xs: "0px", md: "8px"},
        marginBottom: {xs: "8px", md: "8px"},
        marginTop: {xs: "8px", md: "8px"}
      }}>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#d8d8d8" }}>
                  {getTableHeaderForReportData("Date")}
                  {getTableHeaderForReportData("Activity")}
                  {getTableHeaderForReportData("Time")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForTimeEntries()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
    );
  }

  async function showFlexTime() {
    let maxDate = new Date(ApplicationState.getTimeReportToView().to);
    setFlexTimeMaxDate(new Date(maxDate).toLocaleDateString("sv-SE"));
    
    let cft = await ApplicationState.getCurrentFlexTime(
      ApplicationState.getTimeReportToView().user.email,
      maxDate.toLocaleDateString("sv-SE")
    );
    cft = cft * 60;
    let isNegative = false;
    if (cft < 0) {
      cft = Math.abs(cft);
      isNegative = true;
    }
    cft = DateTimeHelper.getHourMinuteStringFromHours(cft);
    if (isNegative) {
      cft = "-" + cft;
    } else if (cft === "0h") {
      cft = cft;
    } else {
      cft = "+" + cft;
    }
    setFlexTime(cft);

    let cft2 = await ApplicationState.getFlexTimeDifferenceForUser(
      ApplicationState.getTimeReportToView().user.email,
      maxDate.toLocaleDateString("sv-SE")
    );
    cft2 = cft2 * 60;
    let isNegative2 = false;
    if (cft2 < 0) {
      cft2 = Math.abs(cft2);
      isNegative2 = true;
    }
    cft2 = DateTimeHelper.getHourMinuteStringFromHours(cft2);
    if (isNegative2) {
      cft2 = "-" + cft2;
    } else if (cft2 === "0h") {
      cft2 = cft2;
    } else {
      cft2 = "+" + cft2;
    }
    setFlexTimeDifference(cft2);
  }

  function addFileList() {
    let counter = 1;
    
    let expenses = timerReportFiles.filter(x => x.type === "expenseToBeInvoiced");
    let totalExpensesExVAT = 0;

    expenses.forEach((expense) => {
      totalExpensesExVAT += expense.amountExcludingVAT
    });

    return(
        <List style={{ padding: "0px", borderBottom: "2px solid #eee" }}>
        <Box style={{backgroundColor: "#d8d8d8", padding: "16px"}}>
          <Typography variant="h6">
            Attachments
          </Typography>
          <Typography variant="h6">
            <i>Totalt expenses Ex VAT: {totalExpensesExVAT} SEK</i>
          </Typography>
          
        </Box>
      {timerReportFiles.map((file) => {
        let color = "#fff";
        if(counter % 2 === 0){
          color = "#f6f6f6"
        }
        counter++;
        return( 
          <ListItem
              button
              style={{ backgroundColor: color, padding: "16px" }}
            >
              <ListItemText
                style={{
                  paddingRight: "16px",
                  wordBreak: "break-word",
                }}
                onClick={() => 
                  ApplicationState.getFileForTimeReport(
                    file.timeReport.user.email,
                    new Date(file.timeReport.from).getFullYear(),
                    new Date(file.timeReport.from).getMonth(),
                    file.name.split(".").pop(),
                    file.id
                  )}
              >
                <strong>
                {file.name}
                </strong>
                {file.type === "expenseToBeInvoiced" && (
                  <React.Fragment>
                    <br />
                    Expense to be invoiced
                  </React.Fragment>
                )}
                {file.type === "reportFromOtherSystem" && (
                  <React.Fragment>
                    <br />
                    Report from other system
                  </React.Fragment>
                )}
                {file.amountExcludingVAT && file.amountExcludingVAT > 0 && (
                  <React.Fragment>
                    {" with "}{file.amountExcludingVAT} SEK Ex VAT
                  </React.Fragment>
                )}
                {file.amountIncludingVAT && file.amountIncludingVAT > 0 && (
                  <React.Fragment>
                    {" and "}{file.amountIncludingVAT} SEK Inc VAT
                  </React.Fragment>
                )}
                {file.comment && file.comment.length > 1 && (
                  <React.Fragment>
                    <br />
                    <i>{file.comment}</i>
                  </React.Fragment>
                )}
              </ListItemText>
            </ListItem>
        )
      })}
      </List>
    ) 
  }

  function addFilesAndApproveActionbar() {
    return (
      <CustomGrid
        item
        component={Card}
        style={{ backgroundColor: "#93264a", minHeight: "300px" }}
        xs={12}
        sx={{
              margin: {xs: "0px", md: "8px"},
              marginBottom: {xs: "8px", md: "8px"},
              marginTop: {xs: "8px", md: "8px"}
            }}
      >
        {timerReportFiles && (
          <React.Fragment>
          {addFileList()}
          </React.Fragment>
        )}
        
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Grid item>
            <Box
              margin="auto"
              justifyContent="center"
            >
              <Box>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h6" style={{ color: "#fff" }}>
                    What do you want to do?
                  </Typography>
                </Grid>
              </Box>
              <Box>
                <Grid container justifyContent="center" alignItems="center">
                  <Box p={1}>
                    {ApplicationState.getTimeReportToView().sent &&
                      !ApplicationState.getTimeReportToView().approved && (
                        <React.Fragment>
                          <Box>
                            <PrimaryButton
                              icon="reject"
                              text="Reject"
                              buttonClick={() =>
                                rejectClick(
                                  ApplicationState.getTimeReportToView()
                                )
                              }
                            ></PrimaryButton>
                            <PrimaryButton
                              icon="approve"
                              text="Approve"
                              buttonClick={() =>
                                approveClick(
                                  ApplicationState.getTimeReportToView()
                                )
                              }
                            ></PrimaryButton>
                          </Box>
                          <Box>
                            <TextField
                              id="commentTextField"
                              placeholder="Add a comment that will be emailed to the user"
                              multiline
                              rows={2}
                              type="text"
                              style={{ margin: "8px" }}
                              onChange={(event) =>
                                setCommentField(event.target.value)
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                style: { backgroundColor: "white" },
                              }}
                              variant="outlined"
                              value={commentField}
                            />
                            {ApplicationState.getTimeReportToView()
                              .userComment &&
                              ApplicationState.getTimeReportToView().userComment
                                .length > 0 && (
                                <Box p={1} style={{ color: "white" }}>
                                  {
                                    ApplicationState.getTimeReportToView().user
                                      .firstName
                                  }
                                  {" added the following comment:"}
                                  <br></br>
                                  {
                                    ApplicationState.getTimeReportToView()
                                      .userComment
                                  }
                                </Box>
                              )}
                          </Box>
                        </React.Fragment>
                      )}
                    {ApplicationState.getTimeReportToView().sent &&
                      ApplicationState.getTimeReportToView().approved && (
                        <PrimaryButton
                          icon="unlock"
                          text="Unlock"
                          buttonClick={() =>
                            unlockClick(ApplicationState.getTimeReportToView())
                          }
                        ></PrimaryButton>
                      )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomGrid>
    );
  }

  function getTableRowsForTimeEntries() {
    let counter = 1;
    return timeEntries.map((timeEntry) =>
      getTableRowForTimeEntry(timeEntry, counter++)
    );
  }

  function getTableRowForTimeEntry(timeEntry, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }
    return (
      <TableRow key={timeEntry.id} style={{ backgroundColor: "#f6f6f6" }}>
        <CustomTableCell align="left">
          {new Date(timeEntry.date).toLocaleDateString("sv-SE")}
        </CustomTableCell>
        <CustomTableCell align="left">
          <b>{timeEntry.activity.project.name}</b>
          <br />
          <i>{timeEntry.activity.name}</i>
        </CustomTableCell>
        <CustomTableCell align="right" style={{maxWidth: "142px"}}>
          {DateTimeHelper.getHourMinuteStringFromHours(
            ApplicationState.getDurationForTimeEntry(timeEntry)
          )}
        </CustomTableCell>
      </TableRow>
    );
  }
  function getTableHeaderForReportData(name) {
    return (
      <TableCell
        style={{ padding: "8px", borderBottomWidth: "0px", fontSize: "16px" }}
        align="left"
      >
        <Box
          style={{
            backgroundColor: "#d8d8d8",
            margin: "8px",
            padding: "4px",
            borderRadius: "8px",
            lineHeight: "1.4",
          }}
        >
          {name}
        </Box>
      </TableCell>
    );
  }

  async function showTimeEntries() {
    let te = await ApplicationState.getTimeEntriesForUser(
      ApplicationState.getTimeReportToView().user.email,
      new Date(ApplicationState.getTimeReportToView().from).toLocaleDateString(
        "sv-SE"
      ),
      new Date(ApplicationState.getTimeReportToView().to).toLocaleDateString(
        "sv-SE"
      )
    );
    setTimeEntries(te);
    setCommentField(ApplicationState.getTimeReportToView().comment);
    let files = await ApplicationState.getTimeReportFilesForTimeReport(ApplicationState.getTimeReportToView().id);
    setTimerReportFiles(files);
  }

  const addTimeEntriesForTimePeriod = () => {
    if (!timeEntries) {
      return;
    }
    return addTableForTimeEntries();
  };

  function addOverviewBox() {
    return (
      <CustomGrid item component={Card} xs={12} sx={{
        margin: {xs: "0px", md: "8px"},
              marginBottom: {xs: "8px", md: "8px"},
              marginTop: {xs: "8px", md: "8px"}
        }}>
        <Box p={2}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            {addHeaderText()}
          </Grid>
        </Box>

        <Divider />

        <List style={{ paddinBottom: "0px" }}>
          {ApplicationState.getTimeItemsViewFromTimeEntries(timeEntries).map(
            (element) => {
              return (
                <ListItem key={element.id} button>
                  <ListItemText key={element.id + "a"}>
                  <strong>{element.project.name}</strong>
                    {element.showActivityText === true && (
                      <React.Fragment>
                        <br /> <i>{element.activity.name}</i>
                      </React.Fragment>
                    )}
                    {addOverTimeDetails(timeEntries.filter(x => x.activity.id === element.activity.id))}
                  </ListItemText>

                  <Box>
                    <strong>
                      {DateTimeHelper.getHourMinuteStringFromHours(
                        element.duration
                      )}
                    </strong>
                  </Box>
                </ListItem>
              );
            }
          )}
        </List>
        <Box
          p={0}
          style={{
            backgroundColor: "#111",
            color: "#fff",
            position: "relative",
            top: "1px",
            marginTop: "auto"
          }}
          bottom
        >
          {ApplicationState.getUseFlexTime() && ApplicationState.getTimeReportToView().user.useFlexTime && (
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={6}
                style={{ backgroundColor: "#444", padding: "16px" }}
              >
                <Box p={1}>
                  <Typography variant="h6">
                    Flex {flexTime}
                    {"  "}
                    <Tooltip
                      title={
                        <Typography variant="subtitle1">
                          Flex time balance based on time entries up to and
                          including {flexTimeMaxDate}
                        </Typography>
                      }
                      aria-label="flex tooltip"
                    >
                      <InfoOutlinedIcon
                        style={{ verticalAlign: "super", fontSize: "large" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Typography>
                  <Typography>
                    Diff {flexTimeDifference}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end", padding: "16px" }}>
                <Box p={1}>
                  <Typography variant="h5">
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      ApplicationState.getDurationForTimeEntries(timeEntries)
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {!ApplicationState.getUseFlexTime() && ApplicationState.getTimeReportToView().user.useFlexTime && (
            <Grid container justifyContent="space-between">
              <Grid item xs={6} style={{ textAlign: "start", padding: "16px" }}>
                <Box p={1}>
                  <Typography variant="h6">Total</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end", padding: "16px" }}>
                <Box p={1}>
                  <Typography variant="h5">
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      ApplicationState.getDurationForTimeEntries(timeEntries)
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </CustomGrid>
    );
  }

  const addOverTimeDetails = (timeEntriesForDate, usePadding) => { 
    let overtime1 = 0;
    let overtime2 = 0;
    let normaltime = 0;
    timeEntriesForDate.forEach((element) => {
      normaltime += element.durationMinutes;
      if(element.durationOvertime1 > 0){
        overtime1 += element.durationOvertime1;
      }
      if(element.durationOvertime2 > 0){
        overtime2 += element.durationOvertime2;
      }
    })
    normaltime = normaltime - overtime1 - overtime2;
    let padding = "0px";
    if(usePadding === true){
      padding = "12px";
    }
    return(
      <Typography variant="subtitle2" style={{paddingLeft: {padding}, fontWeight: "400", color: "#111"}}>
        {(overtime1 > 0 || overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(normaltime * 60)}{" Normal time"}<br />
            </React.Fragment>
        )}
        {(overtime1 > 0 && overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime1 * 60)}{" Overtime1 "}<br />
              {DateTimeHelper.getHourMinuteStringFromHours(overtime2 * 60)}{" Overtime2 "}<br />
            </React.Fragment>
        )}
        {(overtime1 > 0 && overtime2 <= 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime1 * 60)}{" Overtime1"}<br />
            </React.Fragment>
        )}
        {(overtime1 <= 0 && overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime2 * 60)}{" Overtime2"}<br />
            </React.Fragment>
        )}
      </Typography>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">
            Time report for{" "}
            {ApplicationState.getTimeReportToView().user.firstName +
              " " +
              ApplicationState.getTimeReportToView().user.lastName}
          </Typography>
          <Typography>
            {new Date(
              ApplicationState.getTimeReportToView().from
            ).toLocaleDateString("sv-SE")}{" "}
            to{" "}
            {new Date(
              ApplicationState.getTimeReportToView().to
            ).toLocaleDateString("sv-SE")}
          </Typography>
        </Box>
      </Typography>
    );
  }

  async function rejectClick(timeReport) {
    timeReport.comment = commentField;
    timeReport.from = new Date(timeReport.from).toLocaleDateString("sv-SE");
    timeReport.to = new Date(timeReport.to).toLocaleDateString("sv-SE");
    await ApplicationState.rejectTimeReport(timeReport);
    history.push("/timereports");
  }

  async function approveClick(timeReport) {
    timeReport.comment = commentField;
    timeReport.approver = ApplicationState.getState().user;
    timeReport.from = new Date(timeReport.from).toLocaleDateString("sv-SE");
    timeReport.to = new Date(timeReport.to).toLocaleDateString("sv-SE");
    await ApplicationState.approveReport(timeReport);
    history.push("/timereports");
  }

  async function unlockClick(timeReport) {
    await ApplicationState.unlockApprovedTimeReport(timeReport);
    history.push("/timereports");
  }

  return (
    <Grid container justifyContent="center" spacing={0}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Grid container>
          {addOverviewBox()}
          {addFilesAndApproveActionbar()}
        </Grid>
        <Box>{addTimeEntriesForTimePeriod()}</Box>
      </Grid>
    </Grid>
  );
}

export default TimeReportUserView;

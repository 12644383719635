import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import ExporterEditView from "./FileExporterEditView";
import EmailExporterEditView from "./EmailExporterEditView";
import Divider from "@mui/material/Divider";
import FileExporterPropertyEditView from "./FileExporterPropertyEditView";
import FileExporterProjectSelectionDto from "../Dtos/FileExporterProjectSelectionDto"
import EmailExporterPropertyEditView from "./EmailExporterPropertyEditView";
import EmailExporterProjectSelectionDto from "../Dtos/EmailExporterProjectSelectionDto"
import { PrimaryButton } from '../Components/PrimaryButton';
import ApiExporterProjectSelectionDto from "../Dtos/ApiExporterProjectSelectionDto";
import ApiExporterCrudView from "./ApiExporterCrudView";

function ExporterView(props) {
  const [projectsAdded, setProjectsAdded] = useState([]);
  const [projectToAdd, setProjectToAdd] = useState("");
  const [projectsForOrganization, setProjectsForOrganization] = useState([]);
  const [projectsNotAdded, setProjectsNotAdded] = useState([]);
  const [propertiesAdded, setPropertiesAdded] = useState([]);
  const [openFileExporterDialog, setOpenFileExporterDialog] = useState(false);
  const [openFileExporterPropertyDialog, setOpenFileExporterPropertyDialog] = useState(false);
  const [openEmailExporterDialog, setOpenEmailExporterDialog] = useState(false);
  const [openEmailExporterPropertyDialog, setOpenEmailExporterPropertyDialog] = useState(false);
  const [openApiExporterDialog, setOpenApiExporterDialog] = useState(false);
  
  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      await loadProjects();
      await loadPropertiesForExporter();
    }
    load();
  }, [props.selectedExporter]);

  const loadExporter = async () => {
    await loadProjects();
    await loadPropertiesForExporter();
  };

  async function loadProjects() {
    let projectsForOrganization = await ApplicationState.getProjectsForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setProjectsForOrganization(projectsForOrganization);

    let projectsForExporter = [];
    
    if(props.selectedExporter.type === "FileExporter"){
      projectsForExporter = await ApplicationState.getProjectsForFileExporter(
        props.selectedExporter.exporter.id
      );
    }
    if(props.selectedExporter.type === "EmailExporter"){
      projectsForExporter = await ApplicationState.getProjectsForEmailExporter(
        props.selectedExporter.exporter.id
      );
    }
    if(props.selectedExporter.type === "ApiExporter"){
      projectsForExporter = await ApplicationState.getProjectsForApiExporter(
        props.selectedExporter.exporter.id
      );
    }
    setProjectsAdded(projectsForExporter);

    let projectsNotAddedForFileExporter = [];
    projectsForOrganization.map((element) => {
      if (!projectsForExporter.find((x) => x.id === element.id)) {
        projectsNotAddedForFileExporter.push(element);
      }
    });
    setProjectsNotAdded(projectsNotAddedForFileExporter);
    if (projectsNotAddedForFileExporter && projectsNotAddedForFileExporter.length > 0) {
      setProjectToAdd(projectsNotAddedForFileExporter[0]);
    }
  }

  async function loadPropertiesForExporter() {
    let properties = [];
    if(props.selectedExporter.type === "FileExporter") {
      properties = await ApplicationState.getPropertiesForFileExporter(props.selectedExporter.id);
    }
    if(props.selectedExporter.type === "EmailExporter") {
      properties = await ApplicationState.getPropertiesForEmailExporter(props.selectedExporter.id);
    }
    if(props.selectedExporter.type === "ApiExporter") {
      //NOT IMPLEMENTED YET
      //properties = await ApplicationState.getPropertiesForApiExporter(props.selectedExporter.id);
    }
    setPropertiesAdded(properties);
  }

  function editFileExporterClick() {
    ApplicationState.setFileExporterToEdit(props.selectedExporter.exporter);
    setOpenFileExporterDialog(true);
  }

  function editEmailExporterClick() {
    ApplicationState.setEmailExporterToEdit(props.selectedExporter.exporter);
    setOpenEmailExporterDialog(true);
  }

  function editApiExporterClick() {
    ApplicationState.setApiExporterToEdit(props.selectedExporter.exporter);
    setOpenApiExporterDialog(true);
  }

  function addProperty(property) {
    let primaryText = property.name;
    let secondaryText = property.requiredForActivities + " " + property.requiredForProjects;

    if(props.selectedExporter.type === "FileExporter"){
      return (
        <Box>
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => editFileExporterProperty(property)}
          >
            <ListItemText primary={primaryText} secondary={secondaryText} />
          </ListItem>
          <Divider></Divider>
        </Box>
      );
    }
    return (
      <Box>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
          onClick={() => editEmailExporterProperty(property)}
        >
          <ListItemText primary={primaryText} secondary={secondaryText} />
        </ListItem>
        <Divider></Divider>
      </Box>
    );
  }

  function addProjectInSelect(project) {
    return (
      <option
        key={project.id}
        label={project.name}
        value={project.id}
      ></option>
    );
  }

  function addProject(project) {
    let primaryText = project.name;

    return (
      <Box>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
        >
          <ListItemText primary={primaryText} />
          <Button onClick={() => deleteProjectFromExporter(project)}>
            <DeleteForeverIcon
              style={{color: "#93264A"}}
            ></DeleteForeverIcon>
          </Button>
        </ListItem>
        <Divider></Divider>
      </Box>
    );
  }

  function addProperties() {
    if (propertiesAdded.length < 1) {
      return (
        <Box p={2}>
          <i>No properties have been added</i>
        </Box>
      );
    }
    return propertiesAdded
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((element) => {
        return addProperty(element);
      });
  }

  function addProjects() {
    if (projectsAdded.length < 1) {
      return (
        <Box p={2}>
          <i>No projects have been added</i>
        </Box>
      );
    }
    return projectsAdded
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((element) => {
        return addProject(element);
      });
  }

  async function addProjectToExporter() {
    let project = projectsForOrganization.find((x) => x.id === projectToAdd);
    if (!project) {
      project = projectsNotAdded[0];
    }
    if(props.selectedExporter.type === "FileExporter") {
      let dto = new FileExporterProjectSelectionDto(
        ApplicationState.generateGuid(),
        props.selectedExporter.exporter,
        project,
        new Date()
      );
      await ApplicationState.saveNewFileExporterProjectSelection(dto);
    }
    if(props.selectedExporter.type === "EmailExporter") {
      let dto = new EmailExporterProjectSelectionDto(
        ApplicationState.generateGuid(),
        props.selectedExporter.exporter,
        project,
        new Date()
      );
      await ApplicationState.saveNewEmailExporterProjectSelection(dto);
    }
    if(props.selectedExporter.type === "ApiExporter") {
      let dto = new ApiExporterProjectSelectionDto(
        ApplicationState.generateGuid(),
        props.selectedExporter.exporter,
        project,
        new Date()
      );
      await ApplicationState.saveNewApiExporterProjectSelection(dto);
    }
    await loadProjects();  
  }
    
  

  async function deleteProjectFromExporter(project) {
    
    if(props.selectedExporter.type === "FileExporter") {
      let selections = await ApplicationState.getFileExporterProjectSelectionsForFileExporter(
        props.selectedExporter.exporter.id
      );
      let idToDelete = selections.find(
        (x) => x.project.id === project.id
      ).id;
      await ApplicationState.deleteFileExporterProjectSelection(idToDelete);  
    }
    if(props.selectedExporter.type === "EmailExporter") {
      let selections = await ApplicationState.getEmailExporterProjectSelectionsForEmailExporter(
        props.selectedExporter.exporter.id
      );
      let idToDelete = selections.find(
        (x) => x.project.id === project.id
      ).id;
      await ApplicationState.deleteEmailExporterProjectSelection(idToDelete);  
    }
    if(props.selectedExporter.type === "ApiExporter") {
      let selections = await ApplicationState.getApiExporterProjectSelectionsForApiExporter(
        props.selectedExporter.exporter.id
      );
      let idToDelete = selections.find(
        (x) => x.project.id === project.id
      ).id;
      await ApplicationState.deleteApiExporterProjectSelection(idToDelete);
    }
    await loadProjects();
  }

  async function deleteFileExporterProperty(property) {   
    await ApplicationState.deleteFileExporterProperty(property.id);
    await loadPropertiesForExporter();
    setOpenFileExporterPropertyDialog(false);
  }

  async function deleteEmailExporterProperty(property) {   
    await ApplicationState.deleteEmailExporterProperty(property.id);
    await loadPropertiesForExporter();
    setOpenEmailExporterPropertyDialog(false);
  }

  function addHeader(headerText, buttonText) {
    return (
      <Box style={{padding: "16px", backgroundColor: "#d8d8d8", color: "#000"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText(headerText)}
              </Grid>
            </Box>
            {buttonText && buttonText.length > 0 && (
              <Box>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {addAddButton(buttonText)}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText(headerText) {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">{headerText}</Typography>
        </Box>
      </Typography>
    );
  }

  function editFileExporterProperty(fileExporterpRopertyToEdit) {
    ApplicationState.setFileExporterPropertyToEdit(fileExporterpRopertyToEdit);
    ApplicationState.setFileExporterToAddPropertyFor(undefined);
    setOpenFileExporterPropertyDialog(true);
    setOpenEmailExporterPropertyDialog(false);
  }

  function editEmailExporterProperty(emailExporterpRopertyToEdit) {
    ApplicationState.setEmailExporterPropertyToEdit(emailExporterpRopertyToEdit);
    ApplicationState.setEmailExporterToAddPropertyFor(undefined);
    setOpenFileExporterPropertyDialog(false);
    setOpenEmailExporterPropertyDialog(true);
  }

  function addNewFileExporterProperty() {
    ApplicationState.setFileExporterPropertyToEdit(undefined);
    ApplicationState.setFileExporterToAddPropertyFor(props.selectedExporter.exporter);
    setOpenFileExporterPropertyDialog(true);
    setOpenEmailExporterPropertyDialog(false);
  }

  function addNewEmailExporterProperty() {
    ApplicationState.setEmailExporterPropertyToEdit(undefined);
    ApplicationState.setEmailExporterToAddPropertyFor(props.selectedExporter.exporter);
    setOpenFileExporterPropertyDialog(false);
    setOpenEmailExporterPropertyDialog(true);
  }

  function addExporterInfo() {
    if(props.selectedExporter.type === "FileExporter"){
      return(
        <Box p={2}>
          <Typography paragraph>{"File exporter"}</Typography>
          <Typography paragraph><i>{props.selectedExporter.exporter.description}</i></Typography>
          <Typography paragraph>{props.selectedExporter.exporter.fileFormat}</Typography>
          <Typography paragraph>{props.selectedExporter.exporter.email}</Typography>
          
          {props.selectedExporter.exporter.trigger === "DayInMonth" && (
            <Typography paragraph>Trigger report at day {props.selectedExporter.exporter.triggerDayInMonth} of every month</Typography>
          )}
          {props.selectedExporter.exporter.trigger === "SendReport" && (
            <Typography paragraph>Trigger when user click Send report</Typography>
          )}
        </Box>
    );
    }
    if(props.selectedExporter.type === "EmailExporter"){
      return(
        <Box p={2}>
          <Typography paragraph>{"Email exporter"}</Typography>
          <Typography paragraph><i>{props.selectedExporter.exporter.description}</i></Typography>
          <Typography paragraph>{props.selectedExporter.exporter.format}</Typography>
          <Typography paragraph>{props.selectedExporter.exporter.email}</Typography>
          
          {props.selectedExporter.exporter.trigger === "DayInMonth" && (
            <Typography paragraph>Trigger report at day {props.selectedExporter.exporter.triggerDayInMonth} of every month</Typography>
          )}
          {props.selectedExporter.exporter.trigger === "SendReport" && (
            <Typography paragraph>Trigger when user click Send report</Typography>
          )}
        </Box>
    );
    }
    if(props.selectedExporter.type === "ApiExporter"){
      return(
        <Box p={2}>
          <Typography paragraph>{"Api exporter"}</Typography>
          <Typography paragraph><i>{props.selectedExporter.exporter.description}</i></Typography>
          <Typography paragraph>{props.selectedExporter.exporter.format}</Typography>
          <Typography paragraph>{props.selectedExporter.exporter.token}</Typography>
          <Typography paragraph>{props.selectedExporter.exporter.url}</Typography>
        </Box>
    );
    }
  }

  function addAddButton(buttonText) {
    let func = editFileExporterClick;
    let edit = false;
    if (buttonText === "Add property" && props.selectedExporter.type === "FileExporter") {
      func = addNewFileExporterProperty;
    }
    if (buttonText === "Add property" && props.selectedExporter.type === "EmailExporter") {
      func = addNewEmailExporterProperty;
    }
    if (buttonText === "Edit" && props.selectedExporter.type === "FileExporter") {
      func = editFileExporterClick;
      edit = true;
    }
    if (buttonText === "Edit" && props.selectedExporter.type === "EmailExporter") {
      func = editEmailExporterClick;
      edit = true;
    }
    if(buttonText === "Edit" && props.selectedExporter.type === "ApiExporter") {
      func = editApiExporterClick;
      edit = true;
    }

    return (
      <Box p={0}>
        {edit && (
          <PrimaryButton
          icon="edit"
          text={buttonText}
          buttonClick={() => func()}
        >
        </PrimaryButton>
        )}
        {!edit && (
          <PrimaryButton
          icon="add"
          text={buttonText}
          buttonClick={() => func()}
        >
        </PrimaryButton>
        )}
      </Box>
    );
  }

  const editedExporter = async () => {
    await loadExporter();
    setOpenFileExporterDialog(false);
    setOpenEmailExporterDialog(false);
    setOpenApiExporterDialog(false);
    props.editedExporter();
  };

  const deletedExporter = async () => {
    setOpenFileExporterDialog(false);
    setOpenEmailExporterDialog(false);
    setOpenApiExporterDialog(false);
    props.deletedExporter();
  };

  const addedFileExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenFileExporterPropertyDialog(false);
  };

  const editedFileExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenFileExporterPropertyDialog(false);
  };

  const deletedFileExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenFileExporterPropertyDialog(false);
  };

  const addedEmailExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenEmailExporterPropertyDialog(false);
  };

  const editedEmailExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenEmailExporterPropertyDialog(false);
  };

  const deletedEmailExporterProperty = async () => {
    await loadPropertiesForExporter();
    setOpenEmailExporterPropertyDialog(false);
  };

  return (
    <Box>
      <Box p={0}>
        {addHeader(props.selectedExporter.name + "", "Edit")}
        {addExporterInfo()}
      </Box>

      <Box p={0}>
        {addHeader("Properties", "Add property")}
        <List style={{padding: "0px"}}>{addProperties()}</List>
      </Box>

      <Box p={0}>
        {addHeader("Projects", "")}
        <Grid container alignItems="center" style={{ padding: "16px" }}>
          <Select
            native
            onChange={(event) => {
              setProjectToAdd(event.target.value);
            }}
            id="projectSelect"
            variant="outlined"
            style={{margin: "8px"}}
            value={projectToAdd}
          >
            {projectsNotAdded
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((project) => {
                return addProjectInSelect(project);
              })}
          </Select>
          <Button
            style={{margin: "4px"}}
            variant="text"
            color="primary"
            onClick={addProjectToExporter}
          >
            Add project
          </Button>
        </Grid>
        <List style={{padding: "0px"}}>{addProjects()}</List>
      </Box>

      <Dialog
        open={openFileExporterDialog}
        onClose={() => setOpenFileExporterDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <ExporterEditView
          deletedExporter={deletedExporter}
          editedExporter={editedExporter}
          cancelClick={() => setOpenFileExporterDialog(false)}
        ></ExporterEditView>
      </Dialog>
      <Dialog
        open={openFileExporterPropertyDialog}
        onClose={() => setOpenFileExporterPropertyDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <FileExporterPropertyEditView
          deletedFileExporterProperty={deletedFileExporterProperty}
          editedFileExporterProperty={editedFileExporterProperty}
          addedFileExporterProperty={addedFileExporterProperty}
          cancelClick={() => setOpenFileExporterPropertyDialog(false)}
        ></FileExporterPropertyEditView>
      </Dialog>

      <Dialog
        open={openEmailExporterDialog}
        onClose={() => setOpenEmailExporterDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <EmailExporterEditView
          deletedExporter={deletedExporter}
          editedExporter={editedExporter}
          cancelClick={() => setOpenEmailExporterDialog(false)}
        ></EmailExporterEditView>
      </Dialog>
      <Dialog
        open={openEmailExporterPropertyDialog}
        onClose={() => setOpenEmailExporterPropertyDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <EmailExporterPropertyEditView
          deletedEmailExporterProperty={deletedEmailExporterProperty}
          editedEmailExporterProperty={editedEmailExporterProperty}
          addedEmailExporterProperty={addedEmailExporterProperty}
          cancelClick={() => setOpenEmailExporterPropertyDialog(false)}
        ></EmailExporterPropertyEditView>
      </Dialog>
      <Dialog
        open={openApiExporterDialog}
        onClose={() => setOpenApiExporterDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <ApiExporterCrudView
          deletedExporter={deletedExporter}
          editedExporter={editedExporter}
          cancelClick={() => setOpenApiExporterDialog(false)}
        ></ApiExporterCrudView>
      </Dialog>        
      </Box>

  );
}

export default ExporterView;

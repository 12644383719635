export default class SkillDto {
  constructor(
    id,
    name,
    category,
    synonyms,
    created
  ) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.synonyms = synonyms;
    this.created = created;
  }
}

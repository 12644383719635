import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ProjectDto from "../Dtos/ProjectDto";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ProjectSettingDto from "../Dtos/ProjectSettingDto";
import { PrimaryButton } from "../Components/PrimaryButton";
import { DeleteButton } from "./DeleteButton";
import { Select } from "@mui/material";
import { FormControlLabel, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ProjectExtensionDto from "../Dtos/ProjectExtensionDto";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "8px",
  top: "24px",
  width: "170px",
});

function ProjectEditView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [activeFrom, setActiveFrom] = useState(undefined);
  const [activeTo, setActiveTo] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [exporterProps, setExportProps] = useState([]);
  const [projectSettings, setProjectSettings] = useState([]);
  const [frameworkAgreement, setFrameworkAgreement] = useState(undefined);
  const [externalId, setExternalId] = useState(undefined);
  const [clientId, setClientId] = useState(undefined);
  const [clientContactPerson, setClientContactPerson] = useState(undefined);
  const [role, setRole] = useState(undefined);
  const [extentMinimumPerMonth, setExtentMinimumPerMonth] = useState(undefined);
  const [extentMaximumPerMonth, setExtentMaximumPerMonth] = useState(undefined);
  const [hourlyRateToInvoice, setHourlyRateToInvoice] = useState(undefined);
  const [hourlyRateToSubcontractor, setHourlyRateToSubcontractor] =
    useState(undefined);
  const [linkToFolder, setLinkToFolder] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [haveExtensions, setHaveExtensions] = useState(false);
  const [extension1StartDate, setExtension1StartDate] = useState(undefined);
  const [extension1EndDate, setExtension1EndDate] = useState(undefined);
  const [extension2StartDate, setExtension2StartDate] = useState(undefined);
  const [extension2EndDate, setExtension2EndDate] = useState(undefined);
  const [extension3StartDate, setExtension3StartDate] = useState(undefined);
  const [extension3EndDate, setExtension3EndDate] = useState(undefined);
  const [extensions, setExtensions] = useState([]);
  const [isTimeReportingAllowedForAll, setIsTimeReportingAllowedForAll] = useState(false);
  const [clients, setClients] = useState([]);
  const [exportId, setExportId] = useState(undefined);

  useEffect(() => {
    const load2 = async () => {
      let c = await ApplicationState.getClientsForOrganization(ApplicationState.getUser().organization.organizationId);
      setClients(c);
      if (ApplicationState.getProjectToEdit()) {
        setEditMode(true);

        let exportProperties = [];
        let fileExportProperties =
          await ApplicationState.getFileExporterPropertiesForProject(
            ApplicationState.getProjectToEdit().id
          );
        let emailExportProperties =
          await ApplicationState.getEmailExporterPropertiesForProject(
            ApplicationState.getProjectToEdit().id
          );

        fileExportProperties.map((element) => {
          exportProperties.push(element);
        });
        emailExportProperties.map((element) => {
          exportProperties.push(element);
        });

        setExportProps(exportProperties);
        let settings = await ApplicationState.getProjectSettingsForProject(
          ApplicationState.getProjectToEdit().id
        );
        await Promise.all(
          exportProperties.map(async (element) => {
            if (!settings.some((s) => s.name === element.name)) {
              let as = new ProjectSettingDto(
                ApplicationState.generateGuid(),
                ApplicationState.getProjectToEdit(),
                undefined,
                element.name,
                "-",
                new Date()
              );
              await ApplicationState.saveNewProjectSetting(as);
            }
          })
        );
        settings = await ApplicationState.getProjectSettingsForProject(
          ApplicationState.getProjectToEdit().id
        );
        setProjectSettings(settings);

        let ext = await ApplicationState.getProjectExtensionsForProject(
          ApplicationState.getProjectToEdit().id
        );
        ext.sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
        setExtensions(ext);

        if(ext.length > 0) {
          setHaveExtensions(true);
          setExtension1StartDate(new Date(ext[0].startDate).toLocaleDateString("sv-SE"));
          setExtension1EndDate(new Date(ext[0].endDate).toLocaleDateString("sv-SE"));
        }
        if(ext.length > 1) {
          setExtension2StartDate(new Date(ext[1].startDate).toLocaleDateString("sv-SE"));
          setExtension2EndDate(new Date(ext[1].endDate).toLocaleDateString("sv-SE"));
        }
        if(ext.length > 2) {
          setExtension3StartDate(new Date(ext[2].startDate).toLocaleDateString("sv-SE"));
          setExtension3EndDate(new Date(ext[2].endDate).toLocaleDateString("sv-SE"));
        }

        setNameField(ApplicationState.getProjectToEdit().name);
        setDescriptionField(ApplicationState.getProjectToEdit().description);

        if (ApplicationState.getProjectToEdit().activeFrom) {
          setActiveFrom(
            new Date(
              ApplicationState.getProjectToEdit().activeFrom
            ).toLocaleDateString("sv-SE")
          );
        }
        if (ApplicationState.getProjectToEdit().activeTo) {
          setActiveTo(
            new Date(
              ApplicationState.getProjectToEdit().activeTo
            ).toLocaleDateString("sv-SE")
          );
        }
        setFrameworkAgreement(
          ApplicationState.getProjectToEdit().frameworkAgreement
        );
        setExternalId(ApplicationState.getProjectToEdit().externalId);
        setClientId(ApplicationState.getProjectToEdit().clientId);
        setClientContactPerson(
          ApplicationState.getProjectToEdit().clientContactPerson
        );
        setRole(ApplicationState.getProjectToEdit().role);
        setExtentMinimumPerMonth(
          ApplicationState.getProjectToEdit().extentMinimumPerMonth
        );
        setExtentMaximumPerMonth(
          ApplicationState.getProjectToEdit().extentMaximumPerMonth
        );
        setHourlyRateToInvoice(
          ApplicationState.getProjectToEdit().hourlyRateToInvoice
        );
        setHourlyRateToSubcontractor(
          ApplicationState.getProjectToEdit().hourlyRateToSubcontractor
        );
        setLinkToFolder(ApplicationState.getProjectToEdit().linkToFolder);
        setStatus(ApplicationState.getProjectToEdit().status);
        setIsTimeReportingAllowedForAll(ApplicationState.getProjectToEdit().isTimeReportingAllowedForAll);
        setExportId(ApplicationState.getProjectToEdit().exportId);
      }
    };
    load2();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let from = undefined;
    let to = undefined;
    if (activeFrom) {
      from = new Date(activeFrom).toLocaleDateString("sv-SE");
    }
    if (activeTo) {
      to = new Date(activeTo).toLocaleDateString("sv-SE");
    }
    let clientIdToSave = clientId;
    if(clientId === "") {
      clientIdToSave = undefined;
    }
    let dto = new ProjectDto(
      ApplicationState.generateGuid(),
      nameField,
      descriptionField,
      ApplicationState.getState().user.organization,
      new Date(Date.now()),
      from,
      to,
      frameworkAgreement,
      externalId,
      clientIdToSave,
      clientContactPerson,
      role,
      extentMinimumPerMonth,
      extentMaximumPerMonth,
      hourlyRateToInvoice,
      hourlyRateToSubcontractor,
      linkToFolder,
      status,
      isTimeReportingAllowedForAll,
      exportId
    );
    if (ApplicationState.getProjectToEdit()) {
      dto.id = ApplicationState.getProjectToEdit().id;
      dto.created = ApplicationState.getProjectToEdit().created;
      await ApplicationState.saveUpdatedProject(dto);
      ApplicationState.setProjectToView(dto);
      projectSettings.forEach((element) => {
        ApplicationState.saveUpdatedProjectSetting(element);
      });
      for (let i = 0; i < extensions.length; i++) {
        if(i === 0)  {
          extensions[i].startDate = new Date(extension1StartDate);
          extensions[i].endDate = new Date(extension1EndDate);
        }
        if(i === 1)  {
          extensions[i].startDate = new Date(extension2StartDate);
          extensions[i].endDate = new Date(extension2EndDate);
        }
        if(i === 2)  {
          extensions[i].startDate = new Date(extension3StartDate);
          extensions[i].endDate = new Date(extension3EndDate);
        }
        ApplicationState.saveUpdatedProjectExtension(extensions[i]);
      }
      if(extension1StartDate && extension1EndDate && extensions.length <= 0) {
        let extension1 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension1StartDate),
          new Date(extension1EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension1);
      }
      if(extension2StartDate && extension2EndDate && extensions.length <= 1) {
        let extension2 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension2StartDate),
          new Date(extension2EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension2);
      }
      if(extension3StartDate && extension3EndDate && extensions.length <= 2) {
        let extension3 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension3StartDate),
          new Date(extension3EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension3);
      }
    
      props.editedProject();
    } else {
      await ApplicationState.saveNewProject(dto);
      if(extension1StartDate && extension1EndDate) {
        let extension1 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension1StartDate),
          new Date(extension1EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension1);
      }
      if(extension2StartDate && extension2EndDate) {
        let extension2 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension2StartDate),
          new Date(extension2EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension2);
      }
      if(extension3StartDate && extension3EndDate) {
        let extension3 = new ProjectExtensionDto(
          ApplicationState.generateGuid(),
          dto,
          new Date(extension3StartDate),
          new Date(extension3EndDate)
        );
        await ApplicationState.saveNewProjectExtension(extension3);
      }
      ApplicationState.setProjectToView(dto);
      props.addedProject();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteProject(
      ApplicationState.getProjectToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete project with id: " +
          ApplicationState.getProjectToEdit().id
      );
      return;
    }
    props.deletedProject();
  }

  const getTodaysDate = () => {
    let currentTime = new Date();
    return currentTime.toLocaleDateString("sv-SE");
  };

  const getHeaderText = () => {
    let headerText = "Add project";
    if (editMode) {
      headerText = "Edit project";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          multiline
          rows={3}
          id="descriptionTextField"
          label="Description"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
        <Box style={{ minWidth: "300px", paddingLeft: "8px" }}>
        <FormControlLabel
              control={
                <Checkbox
                  checked={isTimeReportingAllowedForAll}
                  onChange={(event) => {
                    setIsTimeReportingAllowedForAll(event.target.checked);
                  }}
                  name="isTimeReportingAllowedForAll"
                  color="primary"
                />
              }
              label="Time reporting allowed for all"
            />
        </Box>
        <Grid container justifyContent="space-between" alignItems="center">
          <TextField
            id="activeFromTextField"
            type="date"
            label="Active from"
            style={{ margin: "8px" }}
            onChange={(event) => setActiveFrom(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={activeFrom}
          />

          <TextField
            id="activeToTextField"
            type="date"
            label="Active to"
            style={{ margin: "8px" }}
            onChange={(event) => setActiveTo(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={activeTo}
          />
        </Grid>
        <Box style={{ minWidth: "300px", paddingLeft: "8px" }}>
        <FormControlLabel
              control={
                <Checkbox
                  checked={haveExtensions}
                  onChange={(event) => {
                    setHaveExtensions(event.target.checked);
                  }}
                  name="haveExtensions"
                  color="primary"
                />
              }
              label="Have extensions"
            />
        </Box>
        {haveExtensions && (
          <Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                id="extension1StartDateTextField"
                type="date"
                label="Extension 1 - Start"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension1StartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension1StartDate}
              />
              <TextField
                id="extension1EndDateTextField"
                type="date"
                label="Extension 1 - End"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension1EndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension1EndDate}
              />
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                id="extension2StartDateTextField"
                type="date"
                label="Extension 2 - Start"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension2StartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension2StartDate}
              />
              <TextField
                id="extension2EndDateTextField"
                type="date"
                label="Extension 2 - End"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension2EndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension2EndDate}
              />
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <TextField
                id="extension3StartDateTextField"
                type="date"
                label="Extension 3 - Start"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension3StartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension3StartDate}
              />
              <TextField
                id="extension3EndDateTextField"
                type="date"
                label="Extension 3 - End"
                style={{ margin: "8px" }}
                onChange={(event) => setExtension3EndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={extension3EndDate}
              />
            </Grid>
          </Box>
          )}
     
        <Box style={{ minWidth: "300px" }}>
          <InputLabelSelect
            id="statusSelectLabel"
            htmlFor="statusSelect"
            variant="outlined"
            shrink={true}
          >
            Status
          </InputLabelSelect>
          <Select
            native
            onChange={(event) => {
              setStatus(event.target.value);
            }}
            id="statusSelect"
            variant="outlined"
            style={{ margin: "8px", fontWeight: "500", width: "350px" }}
            value={status}
          >
            <option key=" " label="" value={undefined} />
            <option
              key="Väntar Avrop"
              label="Väntar Avrop"
              value="Väntar Avrop"
            />
            <option
              key="Väntar Tilldelning"
              label="Väntar Tilldelning"
              value="Väntar Tilldelning"
            />
            <option
              key="Väntar Avtal"
              label="Väntar Avtal"
              value="Väntar Avtal"
            />
            <option
              key="Väntar Konfiguration"
              label="Väntar Konfiguration"
              value="Väntar Konfiguration"
            />
            <option key="Klar" label="Klar" value="Klar" />
          </Select>
        </Box>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box style={{ minWidth: "300px" }}>
            <InputLabelSelect
              id="frameworkAgreementSelectLabel"
              htmlFor="frameworkAgreementSelect"
              variant="outlined"
              shrink={true}
            >
              Framework agreement
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setFrameworkAgreement(event.target.value);
              }}
              id="frameworkAgreementSelect"
              variant="outlined"
              style={{ margin: "8px", fontWeight: "500", width: "350px" }}
              value={frameworkAgreement}
            >
              <option key="None" label="" value={undefined} />
              <option key="Pulsen" label="Pulsen" value="Pulsen" />
              <option key="HDAB" label="HDAB" value="HDAB" />
              <option key="Combitech" label="Combitech" value="Combitech" />
              
            </Select>
          </Box>
        </Grid>
        <TextField
          id="externalIdTextField"
          label="External id"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setExternalId(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={externalId}
        />
        <br />
        <Grid container justifyContent="space-between" alignItems="center">
          <Box style={{ minWidth: "300px" }}>
            <InputLabelSelect
              id="clientSelectLabel"
              htmlFor="clientSelect"
              variant="outlined"
              shrink={true}
            >
              Client
            </InputLabelSelect>
        <Select
            native
            onChange={(event) => {
              setClientId(event.target.value);
            }}
            id="clientSelect"
            variant="outlined"
            style={{ margin: "8px", fontWeight: "500", width: "350px" }}
            value={clientId}
          >
            <option value="" />
            {clients
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((client) => {
                return addClientInSelect(client);
              })}
          </Select>
          </Box>
        </Grid>
        <br />
        <TextField
          id="exportIdTextField"
          label="Export Id"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setExportId(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={exportId}
        />
        <br />
        <TextField
          id="clientContactPersonTextField"
          label="Client contact person"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setClientContactPerson(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={clientContactPerson}
        />
        <br />
        <TextField
          id="roleTextField"
          label="Role"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setRole(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={role}
        />
        <br />
        <TextField
          id="extentMinimumPerMonthTextField"
          label="Extent minimum per month"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setExtentMinimumPerMonth(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={extentMinimumPerMonth}
        />
        <br />
        <TextField
          id="extentMaximumPerMonthTextField"
          label="Extent maximum per month"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setExtentMaximumPerMonth(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={extentMaximumPerMonth}
        />
        <br />
        <Grid container justifyContent="space-between" alignItems="center">
          <TextField
            id="hourlyRateToInvoiceTextField"
            label="Hourly rate to Invoice"
            type="number"
            style={{ margin: "8px", width: "160px" }}
            onChange={(event) => setHourlyRateToInvoice(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={hourlyRateToInvoice}
          />
          <TextField
            id="hourlyRateToSubcontractorTextField"
            label="Hourly rate to Friend"
            type="number"
            style={{ margin: "8px", width: "160px" }}
            onChange={(event) =>
              setHourlyRateToSubcontractor(event.target.value)
            }
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={hourlyRateToSubcontractor}
          />
        </Grid>
        <TextField
          id="linkToFolderTextField"
          label="Link to folder"
          type="text"
          style={{ margin: "8px", width: "350px" }}
          onChange={(event) => setLinkToFolder(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={linkToFolder}
        />
        <br />
      </Box>
    );
  };

  function addClientInSelect(client) {
    return (
      <option
        key={client.id}
        label={client.name}
        value={client.id}
      ></option>
    );
  }

  const onSettingChange = (value, index) => {
    let al = [];
    projectSettings[index].value = value;
    projectSettings.forEach((element) => {
      al.push(element);
    });
    setProjectSettings(al);
  };

  const addSettings = () => {
    let foo = projectSettings;
    return (
      <Box>
        {foo
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((input, index) => (
            <Box>
              <TextField
                id="nameTextField"
                label={input.name}
                type="text"
                style={{ margin: "8px" }}
                onChange={(event) => onSettingChange(event.target.value, index)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={input.value}
              />
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      {exporterProps && exporterProps.length > 0 && (
        <Box p={2}>{addSettings()}</Box>
      )}
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              ></PrimaryButton>
              {editMode && <DeleteButton onClick={deleteClick}></DeleteButton>}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ProjectEditView;

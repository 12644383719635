export default class CertificateDto {
  constructor(id, user, name, issuer, description, startYear, endYear, referenceLink, created) {
    this.id = id;
    this.user = user;
    this.name = name;
    this.issuer = issuer;
    this.description = description;
    this.startYear = startYear;
    this.endYear = endYear;
    this.referenceLink = referenceLink;
    this.created = created;
  }
}

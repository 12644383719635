import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography, Container, Box } from '@mui/material';

const NotAuthorizedView = () => {
  return (
    <Container>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        style={{ height: '100vh' }}
      >
        <WarningIcon style={{ fontSize: 72, color: '#ff9800' }} />
        <Typography variant="h4" gutterBottom style={{color: "#fff"}}>
          Not Authorized!
        </Typography>
        <Typography variant="body1" style ={{color: "#fff"}}>
          You do not have permission to view this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default NotAuthorizedView;

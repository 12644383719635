import EmailLoginButton from "./EmailLoginButton";
import Box from "@mui/material/Box";
import logo from "./../logo_white_text.svg";
import { Grid, Typography, Hidden } from "@mui/material";
import { useHistory } from "react-router-dom";
import ApplicationState from "../State/ApplicationState";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";

function LoginEmailView(props) {
  let history = useHistory();

  const [email, setEmail] = useState(""); // State for email
  const [code, setCode] = useState(""); // State for code

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update email state
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value); // Update code state
  };

  useEffect(() => {
    if (ApplicationState.getToken() && ApplicationState.getToken().length > 0) {
      history.push("/timereport");
    }
  }, []);

  // Check if the "code" parameter is in the query string.
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid container justifyContent="center" spacing={2}>
        <img
          src={logo}
          style={{ height: 112, margin: "10px 5px 10px 5px", alignContent: "" }}
          width="85%"
        />
      </Grid>

      <Divider
        style={{
          width: "168px",
          marginBottom: "28px",
          marginTop: "12px",
          backgroundColor: "white",
        }}
      ></Divider>

      <Box p="8px" m="8px">
        <Box p="8px" m="8px">
          <TextField
            id="emailTextField"
            type="text"
            style={{ margin: "8px", color: "white" }}
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            InputLabelProps={{
              style: { color: "white" }, // White color for label text
            }}
            InputProps={{
              style: { color: "white" }, // White color for input text
            }}
            sx={{
              '& label': {
                color: 'white', // or theme.palette.common.white for the theme white
              },
              '& label.Mui-focused': {
                color: 'white', // or theme.palette.common.white for the theme white
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
                '&:hover fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white', // or theme.palette.common.white for the theme white
              },
            }}
          />
          <br />
          <TextField
            id="codeTextField"
            type="text"
            style={{ margin: "8px" }}
            label="Code"
            variant="outlined"
            value={code}
            onChange={handleCodeChange}
            InputLabelProps={{
              style: { color: "white" }, // White color for label text
            }}
            InputProps={{
              style: { color: "white" }, // White color for input text
            }}
            sx={{
              '& label': {
                color: 'white', // or theme.palette.common.white for the theme white
              },
              '& label.Mui-focused': {
                color: 'white', // or theme.palette.common.white for the theme white
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
                '&:hover fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white', // or theme.palette.common.white for the theme white
                },
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: 'white', // or theme.palette.common.white for the theme white
              },
            }}
          />
        </Box>
        <EmailLoginButton
          style={{ padding: "4px" }}
          doLoginFunction={props.doLoginFunction}
          code={code}
          email={email}
        />
      </Box>
      <Link
        onClick={() => {
          history.push("/faq");
        }}
        style={{ color: "White", padding: "8px", paddingBottom: "16px" }}
      >
        <i>Do you need help?</i>
      </Link>
    </Grid>
  );
}

export default LoginEmailView;

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Dialog, Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';
import AssignmentCrudView from "./AssignmentCrudView";
import { PrimaryButton } from "./PrimaryButton";
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from "react-router-dom";

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#d8d8d8',
    lineHeight: "1.4",
  },
  '& .MuiDataGrid-cell': {
    borderRight: '2px solid #eee', // Add a black vertical line between cells
    borderBottomWidth: '0px'
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(246, 246, 246, 1)" }
    }
  },
  '& .MuiDataGrid-sortIcon': {
    color: '#111', // Changes the color of sort icons to blue
  },
  '& .MuiDataGrid-columnSeparator': {
    color: '#777', // Changes the color of sort icons to blue
  },

});

function AssignmentsView() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [skillExperienceOverview, setSkillExperienceOverview] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [name, setName] = useState("");
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 150,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'user',
      headerName: 'User',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 160,
      valueGetter: (params) =>
        `${params.row.user.firstName || ''} ${params.row.user.lastName || ''}`,
    },
  ];
  
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];
  const history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    setFromDate(getInitialFromDate());
    setToDate(getInitialToDate());
    const load1 = async () => {
      showAssignments();
      showSkillExperienceOverview();
    };
    load1();
  }, []);

  function addTableForAssignments() {

    return (
      <React.Fragment>
       <Box style={{margin: "8px"}}>
       <StyledDataGrid
         rows={assignments}
         columns={columns}
         initialState={{
           pagination: {
             paginationModel: {
               pageSize: 10,
             },
           },
         }}
         pageSizeOptions={[10]}
         style={{backgroundColor: "white"}}
         onRowClick={handleRowClick} 
       />
     </Box>
     <CustomGrid item component={Card} xs>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: "#d8d8d8"}}>
                  {getTableHeaderForReportData("User")}
                  {getTableHeaderForReportData("Client")}
                  {getTableHeaderForReportData("Title")}
                  {getTableHeaderForReportData("Description")}
                  {getTableHeaderForReportData("Duration")}
                  {getTableHeaderForReportData("Skills")}
                  {getTableHeaderForReportData("Created")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForAssignments()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
     </React.Fragment>
    );
  }

  function addTableForSkillExperienceOverview() {
    skillExperienceOverview
      .sort((a, b) => {
        if (a.skill.name > b.skill.name) {
          return 1;
        }
        if (a.skill.name < b.skill.name) {
          return -1;
        }
        return 0;
      })

    return (
      <CustomGrid item component={Card} xs>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: "#d8d8d8"}}>
                  {getTableHeaderForReportData("Skill")}
                  {getTableHeaderForReportData("Overview")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForSkillExperienceOverview()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
    );
  }

  function getTableRowsForAssignments() {
    let counter = 1;
    return assignments.map((assignment) =>
      getTableRowForAssignment(assignment, counter++)
    );
  }

  function getTableRowForAssignment(assignment, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }
    
      return (
        <TableRow key={assignment.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {assignment.user.firstName}{" "}{assignment.user.lastName}
          </CustomTableCell>
          <CustomTableCell align="left">
            {assignment.client}
          </CustomTableCell>
          <CustomTableCell align="left">
            {assignment.title}
          </CustomTableCell>
          <CustomTableCell align="left">
            {assignment.description}
          </CustomTableCell>
          <CustomTableCell align="left">
            {new Date(assignment.startDate).toLocaleDateString("sv-SE")}
            {" to "}
            {new Date(assignment.endDate).toLocaleDateString("sv-SE")}
          </CustomTableCell>
          <CustomTableCell align="left">
            {assignment.skills}
          </CustomTableCell>
          <CustomTableCell align="left">
            {new Date(assignment.created).toLocaleDateString("sv-SE")}
          </CustomTableCell>
        </TableRow>
      );
  }

  function getTableRowsForSkillExperienceOverview() {
    let counter = 1;
    return skillExperienceOverview.map((overview) =>
      getTableRowForSkillExperienceOverview(overview, counter++)
    );
  }

  function addExperiencesContent(experience) {
    if(experience === 0){
      return;
    }
    let years = parseInt(experience) / parseInt("12");
    let months = parseInt(experience) % 12;
    return <Box>{parseInt(years)}{" y "}{months}{" m"}</Box>
  }

  function getTableRowForSkillExperienceOverview(overview, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }
      return (
        <TableRow key={overview.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {overview.skill.name}
          </CustomTableCell>
          <CustomTableCell align="left">
            {
              overview.experiences.map((experience) => {
                return addExperiencesContent(experience);
              })
            }
          </CustomTableCell>
        </TableRow>
      );
    
   
  }

  function getTableHeaderForReportData(name) {
    return (
      <TableCell style={{padding: "8px", borderBottomWidth: "0px", fontSize: "16px"}} align="left">
        <Box style={{
          backgroundColor: "#d8d8d8",
          margin: "8px",
          padding: "4px",
          borderRadius: "8px",
          lineHeight: "1.4"}}>
          {name}
        </Box>
      </TableCell>
    );
  }

  async function showAssignments() {
    let ass = await ApplicationState.getAssignmentsForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setAssignments(ass);
  }

  async function showSkillExperienceOverview() {
    let overview = await ApplicationState.getSkillExperienceOverviewForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setSkillExperienceOverview(overview);
  }

  const getInitialFromDate = () => {
    let currentTime = new Date();
    currentTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    return currentTime.toLocaleDateString("sv-SE");
  };

  const getInitialToDate = () => {
    let currentTime = new Date();
    return currentTime.toLocaleDateString("sv-SE");
  };

  
  const addSkillExperienceOverview = () => {
    if (!skillExperienceOverview) {
      return;
    }
    return addTableForSkillExperienceOverview();
  };

  const addAssignments = () => {
    if (!assignments) {
      return;
    }
    return addTableForAssignments();
  };

  function addOverviewBox() {
    return (
      <CustomGrid
        item
        component={Card}
        xs={12}
        md={6}
      >
        <Box p={2}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Typography variant="h6">
              <Box>Projects</Box>
            </Typography>
          </Grid>
        </Box>

        <Divider />

        <List style={{ paddinBottom: "0px" }}>
          {ApplicationState.getTimeItemsViewFromTimeEntries(timeEntries).map(
            (element) => {
              return (
                <ListItem key={element.id} button>
                  <ListItemText key={element.id + "a"}>
                    <strong>{element.name}</strong>
                  </ListItemText>

                  <strong>
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      element.duration
                    )}
                  </strong>
                </ListItem>
              );
            }
          )}
        </List>

        <Box p={1} style={{backgroundColor: "#111", color: "#fff"}} bottom>
          <Grid container justifyContent="space-between">
            <Box p={1}>
              <Typography variant="h6">Total</Typography>
            </Box>
            <Box p={1}>
              <Typography variant="h6">
                {DateTimeHelper.getHourMinuteStringFromHours(
                  ApplicationState.getDurationForTimeEntries(timeEntries)
                )}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </CustomGrid>
    );
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Assignments</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box p={1}>
            <Box p={1} alignItems="center" justifyContent="flex-end">
              <Typography variant="h6">
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="nameTextField"
                      placeholder="Name for user"
                      type="text"
                      variant="outlined"
                      onChange={(event) => setName(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={name}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />
                    <TextField
                      id="fromTextField"
                      // label="From"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setFromDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={fromDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />
                    <TextField
                      id="toTextField"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setToDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={toDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => showAssignments()}
                      style={{
                        maxHeight: "56px",
                        minHeight: "56px",
                        maxWidth: "56px",
                        minWidth: "56px",
                        marginLeft: "16px",
                      }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        style={{color: "#eee"}}
                      ></SearchIcon>
                    </Button>
                  </Grid>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }

  function addAddAssignmentButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add assignment"
                buttonClick={() => newAssignmentClick()}
              >
        </PrimaryButton>
      </Box>
    );
  }

  function newAssignmentClick() {
    ApplicationState.setAssignmentToEdit(undefined);
    setOpenAssignmentDialog(true);
  }

  async function addedAssignment() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let ass = await ApplicationState.getAssignmentsForOrganization(orgId);
    setAssignments(ass);

    let overview = await ApplicationState.getSkillExperienceOverviewForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setSkillExperienceOverview(overview);
    setOpenAssignmentDialog(false);
  }

  const handleRowClick = (params) => {
    // Navigate to another page, passing the row id in the URL
    history.push(`/assignment/${params.id}`);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7} style={{paddingTop: "15px", padding: "5px", backgroundColor: "#93264A"}}>
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>

        <Grid container style={{ flexWrap: "nowrap" }}>
        </Grid>
        <Box>{addAddAssignmentButton()}</Box>
        <Box>{addAssignments()}</Box>
        <Box>{addSkillExperienceOverview()}</Box>
        <Dialog
            open={openAssignmentDialog}
            onClose={() => setOpenAssignmentDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <AssignmentCrudView
              addedAssignment={addedAssignment}
              cancelClick={() => setOpenAssignmentDialog(false)}
            ></AssignmentCrudView>
          </Dialog>
      </Grid>
    </Grid>
  );
}

export default AssignmentsView;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from '../Components/PrimaryButton';
import HolidayDto from "../Dtos/HolidayDto";
import { DeleteButton } from "./DeleteButton";

function HolidayEditView(props) {
  
  const [nameField, setNameField] = useState(undefined);
  const [dateField, setDateField] = useState(new Date().toLocaleDateString("sv-SE"));
  const [perecentageField, setPercentageField] = useState(100);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (ApplicationState.getHolidayToEdit()) {
      setEditMode(true);
      setNameField(ApplicationState.getHolidayToEdit().name);
      setDateField(new Date(ApplicationState.getHolidayToEdit().date).toLocaleDateString("sv-SE"));
      setPercentageField(ApplicationState.getHolidayToEdit().percentage);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new HolidayDto (
      ApplicationState.generateGuid(),
      nameField,
      dateField, 
      perecentageField,
      ApplicationState.getState().user.organization,
      new Date(Date.now())
    );
    if (ApplicationState.getHolidayToEdit()) {
      dto.id = ApplicationState.getHolidayToEdit().id;
      await ApplicationState.saveUpdatedHoliday(dto);
      ApplicationState.setHolidayToView(dto);
      props.editedHoliday();
    } else {
      await ApplicationState.saveNewHoliday(dto);
      ApplicationState.setHolidayToView(dto);
      props.addedHoliday();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteHoliday(
      ApplicationState.getHolidayToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete holiday with id: " +
          ApplicationState.getHolidayToEdit().id
      );
      return;
    }
    props.deletedHoliday();
  }

  const getHeaderText = () => {
    let headerText = "Add group";
    if (editMode) {
      headerText = "Edit group";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="perecentageTextField"
          label="Percentage"
          type="number"
          style={{margin: "8px"}}
          onChange={(event) => setPercentageField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={perecentageField}
        />
        <br />
        <TextField
                id="dateTextField"
                type="date"
                label="Active from"
                style={{ margin: "8px" }}
                onChange={(event) => setDateField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={dateField}
              />
              <br />
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >                  
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default HolidayEditView;

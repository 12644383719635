import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import ServiceConfigView from "./ServiceConfigView";
import Hidden from "@mui/material/Hidden";
import { PrimaryButton } from "./PrimaryButton";

function ServicesView() {
  const [allServices, setAllServices] = useState([]);
  const [servicesForUser, setServicesForUser] = useState([]);
  const [serviceSelectionsForUser, setServiceSelectionsForUser] = useState([]);
  const [scopeSelectionsForUser, setscopeSelectionsForUser] = useState([]);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    loadUserDataServices();
  }, []);

  async function loadUserDataServices() {
    let servicesAll = await ApplicationState.getAllUserDataServices();
    setAllServices(servicesAll);

    let servicesUser = await ApplicationState.getUserDataServicesForUser(
      ApplicationState.getState().user.email
    );
    setServicesForUser(servicesUser);

    let serviceSelectionsUser =
      await ApplicationState.getUserDataServiceSelectionsForUser(
        ApplicationState.getState().user.email
      );
    setServiceSelectionsForUser(serviceSelectionsUser);

    let scopeSelections =
      await ApplicationState.getUserDataServiceScopeSelectionsForUser(
        ApplicationState.getState().user.email
      );
    setscopeSelectionsForUser(scopeSelections);
  }

  function editService(serviceToEdit) {
    ApplicationState.setUserDataServiceSelectionToEdit(serviceToEdit);
    ApplicationState.setUserDataServiceToAdd(undefined);
    setOpenAddEditDialog(true);
  }

  function newService(serviceToAdd) {
    ApplicationState.setUserDataServiceToAdd(serviceToAdd);
    ApplicationState.setUserDataServiceSelectionToEdit(undefined);
    setOpenAddEditDialog(true);
  }

  function addServiceForEdit(service) {
    let imgSrc = "";
    let imgHeight = "33px";
    let imgWidth = "33px";
    let serviceText = service.userDataService.name;
    let accountText = service.accountIdentifier;
    let scopesText =
      "[Scopes shall go here and Rdagast är det längsta namnet i mannminne]";

    if (service.userDataService.name === "Google Workspace") {
      imgSrc =
        "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg";
      imgHeight = "33px";
      imgWidth = "33px";
    }
    if (service.userDataService.name === "Microsoft 365") {
      imgSrc =
        "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg";
      imgHeight = "33px";
      imgWidth = "33px";
    }
    if (service.userDataService.name === "Azure DevOps") {
      imgSrc =
        "https://www.forecast.app/hubfs/New%20Website%20/integrations-logos/Azure%20DevOps.png";
      imgHeight = "33px";
      imgWidth = "33px";
      accountText = service.accountIdentifier + " - " + service.username;
    }

    let serviceSelectionId = service.id;
    let scopesToShow = scopeSelectionsForUser.filter(
      (x) => x.serviceSelection.id === serviceSelectionId
    );
    scopesToShow = scopesToShow.sort((a, b) =>
      a.scope.name > b.scope.name ? 1 : -1
    );

    scopesText = "";
    scopesToShow.forEach((x) => (scopesText += x.scope.name + ", "));
    scopesText = scopesText.slice(0, -2);

    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        button
        onClick={() => editService(service)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ justifyContent: "space-between" }}
        >
          <Box p={0}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box sx={{display: {xs: "none", sm: "block"}}}>
              <ListItemAvatar>
                <img src={imgSrc} width={imgHeight} height={imgWidth}></img>
              </ListItemAvatar>
              </Box>
              <Box p={1} sx={{wordBreak: "break-word", maxWidth: {sm: "70%", md: "80%", xl: "90%"}}}>
                <Typography
                  style={{
                    color: "#93264A",
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                >
                  {serviceText}
                </Typography>
                <Typography style={{ fontWeight: "500" }}>
                  {accountText}
                </Typography>
                <Typography>{scopesText}</Typography>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </ListItem>
    );
  }

  function addServiceForNew(service) {
    let imgSrc = "";
    let imgHeight = "33px";
    let imgWidth = "33px";
    let primaryText = service.name;

    if (service.name === "Google Workspace") {
      imgSrc =
        "https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg";
      imgHeight = "33px";
      imgWidth = "33px";
    }
    if (service.name === "Microsoft 365") {
      imgSrc =
        "https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg";
      imgHeight = "33px";
      imgWidth = "33px";
    }
    if (service.name === "Azure DevOps") {
      imgSrc =
        "https://www.forecast.app/hubfs/New%20Website%20/integrations-logos/Azure%20DevOps.png";
      imgHeight = "33px";
      imgWidth = "33px";
    }

    return (
      <Grid container justifyContent="flex-start" spacing={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => newService(service)}
          size="large"
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            marginLeft: "32px",
            
            backgroundColor: "#fff",
            color: "#111",
            fontWeight: "600",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
          sx={{width: {md: "385px"}}}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box style={{ paddingLeft: "8px", paddingRight: "16px", textAlign: "left" }}>
              <span style={{ fontWeight: "400" }}>Add </span>
              {primaryText}
              <Hidden smDown>
              <span style={{ fontWeight: "400" }}> account</span>
              </Hidden>
            </Box>
            <Hidden smDown>
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box style={{ paddingRight: "16px" }}>
                  <Divider
                    orientation="vertical"
                    flexitem
                    style={{ height: "42px", backgroundColor: "#d8d8d8" }}
                  ></Divider>
                </Box>
                <img
                  src={imgSrc}
                  width="29px"
                  height="29px"
                  style={{margin: "0px"}}
                ></img>
              </Grid>
            </Box>
            </Hidden>
          </Grid>
        </Button>
      </Grid>
    );
  }

  function addAddServicesHeader() {
    return (
      <Box style={{ paddingBottom: "12px", paddingTop: "12px" }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "0px",
            }}
          >
            <Typography variant="h5">
              <b>Add services</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
    );
  }

  function addConnectedServices() {
    return serviceSelectionsForUser
      .sort((a, b) =>
        a.userDataService.name > b.userDataService.name ? 1 : -1
      )
      .map((element) => {
        return addServiceForEdit(element);
      });
  }

  function addAvailableServices() {
    return allServices
      .sort((a, b) => a.name - b.name)
      .map((element) => {
        return addServiceForNew(element);
      });
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };
  const deletedService = async () => {
    await loadServices();
    setOpenAddEditDialog(false);
  };
  const editedService  = async () => {
    await loadServices();
    setOpenAddEditDialog(false);
  };
  const addedService = async () => {
    await loadServices();
    setOpenAddEditDialog(false);
  };

  const loadServices = async () => {
    await loadUserDataServices();
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Connected services</Typography>
          <Typography>
            Connect your services to improve accuracy of time report generation. E.g. add your calendar so all your meetings are included in time reports.
          </Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1} style={{visibility: "hidden"}}>
        <PrimaryButton
                icon="add"
                text="Add project"
              >
              </PrimaryButton>
      </Box>
    );
  }

  return (
    <Grid>
            <Box>
              {addHeader()}
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              style={{
                backgroundColor: "#93264A",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={12}
                s={12}
                lg={6}
                style={{backgroundColor: "#fff"}}
              >
                <List style={{paddingTop: "0px"}}>{addConnectedServices()}</List>
              </Grid>
              <Grid
                item
                xs={12}
                s={12}
                lg={6}
                p={1}
                style={{backgroundColor: "#d8d8d8"}}
              >
                {addAddServicesHeader()}
                <Divider
                  style={{
                    marginTop: "16px",
                    marginBottom: "32px",
                    width: "100%",
                  }}
                ></Divider>
                {addAvailableServices()}
                <Box p={2}></Box>
              </Grid>
            </Grid>
        <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth="true"
        >
          <ServiceConfigView
            cancelClick={handleCloseAddEditDialog}
            addedService={addedService}
            editedService={editedService}
            deletedService={deletedService}
          ></ServiceConfigView>
        </Dialog>
    </Grid>
  );
}

export default ServicesView;

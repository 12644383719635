import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Dialog, Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import AssignmentCrudView from "./AssignmentCrudView";
import { PrimaryButton } from "./PrimaryButton";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

const StyledDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#d8d8d8",
    lineHeight: "1.4",
  },
  "& .MuiDataGrid-cell": {
    borderRight: "2px solid #eee", // Add a black vertical line between cells
    borderBottomWidth: "0px",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(246, 246, 246, 1)" },
    },
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#111", // Changes the color of sort icons to blue
  },
  "& .MuiDataGrid-columnSeparator": {
    color: "#777", // Changes the color of sort icons to blue
  },
});

function AssignmentView() {
  let history = useHistory();
  var today = new Date();
  const [assignment, setAssignment] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [skillExperience, setSkillExperience] = useState([]);

  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);

  const { id } = useParams();
  //Kör vid uppstart
  useEffect(() => {
    
    const load1 = async () => {
      showAssignments();
      showSkillExperience();
    };
    load1();
  }, []);

  function addTableForSkillExperience() {

    return (
      <CustomGrid item component={Card} xs>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#d8d8d8" }}>
                  {getTableHeaderForReportData("Skill")}
                  {getTableHeaderForReportData("Overview")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForSkillExperienceOverview()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
    );
  }

  function getTableRowsForAssignments() {
    let counter = 1;
    return assignments.map((assignment) =>
      getTableRowForAssignment(assignment, counter++)
    );
  }

  function getTableRowForAssignment(assignment, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }

    return (
      <TableRow key={assignment.id} style={{ backgroundColor: bgColor }}>
        <CustomTableCell align="left">
          {assignment.user.firstName} {assignment.user.lastName}
        </CustomTableCell>
        <CustomTableCell align="left">{assignment.client}</CustomTableCell>
        <CustomTableCell align="left">{assignment.title}</CustomTableCell>
        <CustomTableCell align="left">{assignment.description}</CustomTableCell>
        <CustomTableCell align="left">
          {new Date(assignment.startDate).toLocaleDateString("sv-SE")}
          {" to "}
          {new Date(assignment.endDate).toLocaleDateString("sv-SE")}
        </CustomTableCell>
        <CustomTableCell align="left">{assignment.skills}</CustomTableCell>
        <CustomTableCell align="left">
          {new Date(assignment.created).toLocaleDateString("sv-SE")}
        </CustomTableCell>
      </TableRow>
    );
  }

  function getTableRowsForSkillExperienceOverview() {
    let counter = 1;
    return skillExperience.map((overview) =>
      getTableRowForSkillExperienceOverview(overview, counter++)
    );
  }

  function addExperiencesContent(experience) {
    if (experience === 0) {
      return;
    }
    let years = parseInt(experience) / parseInt("12");
    let months = parseInt(experience) % 12;
    return (
      <Box>
        {parseInt(years)}
        {" y "}
        {months}
        {" m"}
      </Box>
    );
  }

  function getTableRowForSkillExperienceOverview(overview, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }
    return (
      <TableRow key={overview.id} style={{ backgroundColor: bgColor }}>
        <CustomTableCell align="left">{overview.skill.name}</CustomTableCell>
        <CustomTableCell align="left">          
        </CustomTableCell>
      </TableRow>
    );
  }

  function getTableHeaderForReportData(name) {
    return (
      <TableCell
        style={{ padding: "8px", borderBottomWidth: "0px", fontSize: "16px" }}
        align="left"
      >
        <Box
          style={{
            backgroundColor: "#d8d8d8",
            margin: "8px",
            padding: "4px",
            borderRadius: "8px",
            lineHeight: "1.4",
          }}
        >
          {name}
        </Box>
      </TableCell>
    );
  }

  async function showAssignments() {

    let as = await ApplicationState.getAssignment(
      id
    );
    setAssignment(as);
  }

  async function showSkillExperience() {
    let skillExperience =
      await ApplicationState.getSkillExperienceForAssignment(
        id
      );
    setSkillExperience(skillExperience);
  }

  const getInitialFromDate = () => {
    let currentTime = new Date();
    currentTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    return currentTime.toLocaleDateString("sv-SE");
  };

  const getInitialToDate = () => {
    let currentTime = new Date();
    return currentTime.toLocaleDateString("sv-SE");
  };

  const addSkillExperience = () => {
    if (!skillExperience) {
      return;
    }
    return addTableForSkillExperience();
  };

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Assignment - {id}</Typography>
        </Box>
      </Typography>
    );
  }

  function addEditAssignmentButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="edit"
          text="Edit"
          buttonClick={() => editAssignmentClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function editAssignmentClick() {
    ApplicationState.setAssignmentToEdit(assignment);
    setOpenAssignmentDialog(true);
  }

  async function editedAssignment() {
    let ass = await ApplicationState.getAssignment(id);
    setAssignment(ass);
    setOpenAssignmentDialog(false);
  }
  
  async function addedAssignment() {
  }

  async function deletedAssignment() {
    history.push("/assignments");
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "5px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>
        <CustomGrid item component={Card} xs>
              <Box p={0}>
                <Box p={2}>
                <Typography variant="h4">
                    {assignment.title}
                  </Typography>
                  <Typography paragraph>
                    {assignment.client}
                  </Typography>
                  <Typography paragraph>
                    <b>{assignment.description}</b>
                  </Typography>
                  <Typography paragraph>{new Date(assignment.startDate).toLocaleDateString("sv-SE")}</Typography>
                  <Typography paragraph>{new Date(assignment.endDate).toLocaleDateString("sv-SE")}</Typography>
                </Box>
              </Box>
            </CustomGrid>

        <Grid container style={{ flexWrap: "nowrap" }}>
        </Grid>
        <Box>{addEditAssignmentButton()}</Box>
        <Box>{addSkillExperience()}</Box>
        <Box>
          {id}
        </Box>
        <Dialog
          open={openAssignmentDialog}
          onClose={() => setOpenAssignmentDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <AssignmentCrudView
            deletedAssignment={deletedAssignment}
            addedAssignment={addedAssignment}
            editedAssignment={editedAssignment}
            cancelClick={() => setOpenAssignmentDialog(false)}
          ></AssignmentCrudView>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default AssignmentView;

export default class EducationDto {
  constructor(id, user, name, school, description, startYear, endYear, created) {
    this.id = id;
    this.user = user;
    this.name = name;
    this.school = school;
    this.description = description;
    this.startYear = startYear;
    this.endYear = endYear;
    this.created = created;
  }
}

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "0px",
  marginBottom: "0px",
  marginTop: "0px",
});

function SkillsOverviewView() {
  const [assignments, setAssignments] = useState([]);
  const [skillExperienceOverview, setSkillExperienceOverview] = useState([]);
  const [toDate, setToDate] = useState(new Date());

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      showAssignments();
      showSkillExperienceOverview();
    };
    load1();
  }, []);

  function getTableHeaderForReportData(name) {
    return (
      <TableCell
        style={{ padding: "8px", borderBottomWidth: "0px", fontSize: "16px" }}
        align="left"
      >
        <Box
          style={{
            backgroundColor: "#d8d8d8",
            margin: "8px",
            padding: "4px",
            borderRadius: "8px",
            lineHeight: "1.4",
          }}
        >
          {name}
        </Box>
      </TableCell>
    );
  }

  function addTableForSkillExperienceOverview() {
    skillExperienceOverview
      .sort((a, b) => {
        if (a.skill.name > b.skill.name) {
          return 1;
        }
        if (a.skill.name < b.skill.name) {
          return -1;
        }
        return 0;
      })

    return (
      <CustomGrid item xs>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: "#d8d8d8"}}>
                  {getTableHeaderForReportData("Skill")}
                  {getTableHeaderForReportData("Overview")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForSkillExperienceOverview()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
    );
  }

  function getTableRowsForSkillExperienceOverview() {
    let counter = 1;
    return skillExperienceOverview.map((overview) =>
      getTableRowForSkillExperienceOverview(overview, counter++)
    );
  }

  function addExperiencesContent(experience) {
    if(experience === 0){
      return;
    }
    let years = parseInt(experience) / parseInt("12");
    let months = parseInt(experience) % 12;
    return <Box>{parseInt(years)}{" y "}{months}{" m"}</Box>
  }

  function getTableRowForSkillExperienceOverview(overview, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }
      return (
        <TableRow key={overview.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {overview.skill.name}
          </CustomTableCell>
          <CustomTableCell align="left">
            {
              overview.experiences.map((experience) => {
                return addExperiencesContent(experience);
              })
            }
          </CustomTableCell>
        </TableRow>
      );  
  }

  async function showAssignments() {
    let ass = await ApplicationState.getAssignmentsForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setAssignments(ass);
  }

  async function showSkillExperienceOverview() {
    let overview = await ApplicationState.getSkillExperienceOverviewForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setSkillExperienceOverview(overview);
  }

  const addSkillExperienceOverview = () => {
    if (!skillExperienceOverview) {
      return;
    }
    return addTableForSkillExperienceOverview();
  };

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Skills Overview for Organization</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box p={1}>
            <Box p={1} alignItems="center" justifyContent="flex-end">
              <Typography variant="h6">
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => showAssignments()}
                      style={{
                        maxHeight: "56px",
                        minHeight: "56px",
                        maxWidth: "56px",
                        minWidth: "56px",
                        marginLeft: "16px",
                      }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        style={{color: "#eee"}}
                      ></SearchIcon>
                    </Button>
                  </Grid>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }

  return (
    <Grid>
        <Box>{addHeader()}</Box>
        <Box>{addSkillExperienceOverview()}</Box>
    </Grid>
  );
}

export default SkillsOverviewView;

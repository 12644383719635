import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";

const CLIENT_ID_GOOGLE =
  "735586588965-en607klmp7sh4aeghmgg69o67cdjq254.apps.googleusercontent.com";

const CLIENT_ID_MICROSOFT = "430fcbb2-babd-46c5-961e-ed4c4f51aac9";

function ServiceConfigView(props) {
  const [allScopesForService, setAllScopesForService] = useState([]);
  const [service, setService] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [useGoogleDrive, setUseGoogleDrive] = useState(false);
  const [useOneDrive, setUseOneDrive] = useState(false);
  const [useGoogleCalendar, setUseGoogleCalendar] = useState(false);
  const [useOutlookCalendar, setUseOutlookCalendar] = useState(false);
  const [useOutlookEmail, setUseOutlookEmail] = useState(false);
  const [useGmail, setUseGmail] = useState(false);
  const [useGoogleTasks, setUseGoogleTasks] = useState(false);
  const [useToDoTasks, setUseToDoTasks] = useState(false);
  const [usePlannerTasks, setUsePlannerTasks] = useState(false);
  const [useAzureDevOpsCode, setUseAzureDevOpsCode] = useState(false);
  const [useAzureDevOpsWiki, setUseAzureDevOpsWiki] = useState(false);
  const [useAzureDevOpsWorkItems, setUseAzureDevOpsWorkItems] = useState(false);
  const [azureDevOpsPersonalAccessToken, setAzureDevOpsPersonalAccessToken] =
    useState(undefined);
  const [azureDevOpsOrganization, setAzureDevOpsOrganization] =
    useState(undefined);
    const [azureDevOpsUsername, setAzureDevOpsUsername] =
    useState(undefined);

  const [] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      if (ApplicationState.getUserDataServiceToAdd()) {
        ApplicationState.getUserDataServiceToAdd();
        setService(ApplicationState.getUserDataServiceToAdd());
        let scopesAll = await ApplicationState.getAllUserDataServiceScopes();
        let selectedScopes = scopesAll.filter(
          (x) =>
            x.userDataService.id ===
            ApplicationState.getUserDataServiceToAdd().id
        );
        setAllScopesForService(selectedScopes);
      }
      if (ApplicationState.getUserDataServiceSelectionToEdit()) {
        setEditMode(true);
        ApplicationState.getUserDataServiceSelectionToEdit();
        setService(
          ApplicationState.getUserDataServiceSelectionToEdit().userDataService
        );
        if (
          ApplicationState.getUserDataServiceSelectionToEdit().userDataService
            .name === "Azure DevOps"
        ) {
          setAzureDevOpsOrganization(
            ApplicationState.getUserDataServiceSelectionToEdit()
              .accountIdentifier
          );
          setAzureDevOpsUsername(
            ApplicationState.getUserDataServiceSelectionToEdit()
              .username
          );
          setAzureDevOpsPersonalAccessToken(
            ApplicationState.getUserDataServiceSelectionToEdit().accessToken
          );
        }
        let scopeSelectionsForUser =
          await ApplicationState.getUserDataServiceScopeSelectionsForUser(
            ApplicationState.getState().user.email
          );

        let selectedScopes = scopeSelectionsForUser.filter(
          (x) =>
            x.serviceSelection.id ===
            ApplicationState.getUserDataServiceSelectionToEdit().id
        );
        selectedScopes.map((element) => {
          if (element.scope.name === "Google Calendar") {
            setUseGoogleCalendar(true);
          }
          if (element.scope.name === "Gmail") {
            setUseGmail(true);
          }
          if (element.scope.name === "Google Drive") {
            setUseGoogleDrive(true);
          }
          if (element.scope.name === "Google Tasks") {
            setUseGoogleTasks(true);
          }
          if (element.scope.name === "OneDrive") {
            setUseOneDrive(true);
          }
          if (element.scope.name === "Outlook Calendar") {
            setUseOutlookCalendar(true);
          }
          if (element.scope.name === "Outlook Email") {
            setUseOutlookEmail(true);
          }
          if (element.scope.name === "To Do Tasks") {
            setUseToDoTasks(true);
          }
          if (element.scope.name === "Planner Tasks") {
            setUsePlannerTasks(true);
          }
          if (element.scope.id === "11a72c5c-24fa-41a2-8f40-fbfd02a03f6c") {
            setUseAzureDevOpsCode(true);
          }
          if (element.scope.id === "5f50d810-3ae0-45b5-8ce9-06b05e4aa662") {
            setUseAzureDevOpsWiki(true);
          }
          if (element.scope.id === "0058a077-70c6-457e-a5bb-0c07b6d2f265") {
            setUseAzureDevOpsWorkItems(true);
          }
        });
        let scopesAll = await ApplicationState.getAllUserDataServiceScopes();
        selectedScopes = scopesAll.filter(
          (x) =>
            x.userDataService.id ===
            ApplicationState.getUserDataServiceSelectionToEdit().userDataService
              .id
        );
        setAllScopesForService(selectedScopes);
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function disconnectService() {
    var result = await ApplicationState.deleteUserDataServiceSelection(
      ApplicationState.getUserDataServiceSelectionToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete user data service selection with id: " +
          ApplicationState.getUserDataServiceSelectionToEdit().id
      );
      return;
    }
    props.deletedService();
  }

  function showAzureDevOps() {
    if (
      ApplicationState.getUserDataServiceToAdd() &&
      ApplicationState.getUserDataServiceToAdd().name === "Azure DevOps"
    ) {
      return true;
    }
    if (
      ApplicationState.getUserDataServiceSelectionToEdit() &&
      ApplicationState.getUserDataServiceSelectionToEdit().userDataService
        .name === "Azure DevOps"
    ) {
      return true;
    }
    return false;
  }

  function showMicrosoft365() {
    if (
      ApplicationState.getUserDataServiceToAdd() &&
      ApplicationState.getUserDataServiceToAdd().name === "Microsoft 365"
    ) {
      return true;
    }
    if (
      ApplicationState.getUserDataServiceSelectionToEdit() &&
      ApplicationState.getUserDataServiceSelectionToEdit().userDataService
        .name === "Microsoft 365"
    ) {
      return true;
    }
    return false;
  }

  function showGoogleWorkspace() {
    if (
      ApplicationState.getUserDataServiceToAdd() &&
      ApplicationState.getUserDataServiceToAdd().name === "Google Workspace"
    ) {
      return true;
    }
    if (
      ApplicationState.getUserDataServiceSelectionToEdit() &&
      ApplicationState.getUserDataServiceSelectionToEdit().userDataService
        .name === "Google Workspace"
    ) {
      return true;
    }
    return false;
  }

  const saveMicrosoftServices = async () => {
    let redirectUri =
      window.location.protocol.replace(":", "%3A") +
      "%2F%2F" +
      window.location.host;
    const scopes = getMicrosoftScopesUriString();
    if (scopes.length === 0) {
      return;
    }
    let selectedScopes = [];
    if(useOneDrive){
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.name === "OneDrive"
        ).id
      );
    }
    if(useOutlookCalendar){
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.name === "Outlook Calendar"
        ).id
      );
    }
    if(useOutlookEmail){
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.name === "Outlook Email"
        ).id
      );
    }
    if(usePlannerTasks){
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.name === "Planner Tasks"
        ).id
      );
    }
    if(useToDoTasks){
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.name === "To Do Tasks"
        ).id
      );
    }
    ApplicationState.setUserDataServiceScopesToAdd(selectedScopes);
    const query = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${CLIENT_ID_MICROSOFT}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=offline_access%20${scopes}&state=${scopes}&prompt=consent`;
    window.location.href = query;
  };

  const connectAzureDevOps = async () => {
    let selectedScopes = [];
    if (useAzureDevOpsCode) {
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.id === "11a72c5c-24fa-41a2-8f40-fbfd02a03f6c"
        ).id
      );
    }
    if (useAzureDevOpsWiki) {
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.id === "5f50d810-3ae0-45b5-8ce9-06b05e4aa662"
        ).id
      );
    }
    if (useAzureDevOpsWorkItems) {
      selectedScopes.push(
        allScopesForService.find(
          (x) => x.id === "0058a077-70c6-457e-a5bb-0c07b6d2f265"
        ).id
      );
    }
    let previousAccountIdentifier = azureDevOpsOrganization;
    if (ApplicationState.getUserDataServiceSelectionToEdit()) {
      previousAccountIdentifier =
        ApplicationState.getUserDataServiceSelectionToEdit().accountIdentifier;
    }

    let previousUsername = azureDevOpsUsername;
    if (ApplicationState.getUserDataServiceSelectionToEdit()) {
      previousUsername =
        ApplicationState.getUserDataServiceSelectionToEdit().username;
    }
    
    await ApplicationState.connectToUserDataServiceWithPersonalAccessToken(
      service,
      selectedScopes,
      azureDevOpsPersonalAccessToken,
      ApplicationState.getState().user.email,
      azureDevOpsOrganization,
      previousAccountIdentifier,
      azureDevOpsUsername,
      previousUsername
    );
    props.addedService();
  };

  const getMicrosoftScopesUriString = () => {
    let scopes = "user.read";
    if (useOutlookCalendar) {
      if (scopes.length > 0) {
        scopes += "%20";
      }
      scopes += "calendars.read";
    }
    if (useOutlookEmail) {
      if (scopes.length > 0) {
        scopes += "%20";
      }
      scopes += "mail.read";
    }
    if (useOneDrive) {
      if (scopes.length > 0) {
        scopes += "%20";
      }
      scopes += "files.read";
    }
    if (useToDoTasks || usePlannerTasks) {
      if (scopes.length > 0) {
        scopes += "%20";
      }
      scopes += "tasks.read";
    }
    return scopes;
  };

  const setGoogleScopesString = (useCalendar, useEmail, useDrive, useTasks) => {
    let scopes = "";
    if (useCalendar) {
      if (scopes.length > 0) {
        scopes += " ";
      }
      scopes += "https://www.googleapis.com/auth/calendar.events.readonly";
    }
    if (useEmail) {
      if (scopes.length > 0) {
        scopes += " ";
      }
      scopes += "https://www.googleapis.com/auth/gmail.readonly";
    }
    if (useDrive) {
      if (scopes.length > 0) {
        scopes += " ";
      }
      scopes += "https://www.googleapis.com/auth/drive.metadata.readonly";
    }
    if (useTasks) {
      if (scopes.length > 0) {
        scopes += " ";
      }
      scopes += "https://www.googleapis.com/auth/tasks.readonly";
    }
    return scopes;
  };

  const saveGoogleServices = () => {
    let redirectUri =
      window.location.protocol.replace(":", "%3A") +
      "%2F%2F" +
      window.location.host;

    var scopes = "profile";
    
    if(useGmail){
      scopes += " email https://www.googleapis.com/auth/gmail.readonly";
    }
    if(useGoogleCalendar){
      scopes += " https://www.googleapis.com/auth/calendar.readonly";
    }
    if(useGoogleDrive){
      scopes += " https://www.googleapis.com/auth/drive.metadata.readonly";
    }
    if(useGoogleTasks){
      scopes += " https://www.googleapis.com/auth/tasks.readonly";
    }

    var url =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${CLIENT_ID_GOOGLE}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${scopes}` +
      `&access_type=offline` +
      `&response_type=code` +
      `&state=${scopes}` +
      `&include_granted_scopes=true` +
      `&prompt=consent`;

      let selectedScopes = [];

      if (useGoogleDrive) {
        selectedScopes.push(
          allScopesForService.find((x) => x.name === "Google Drive").id
        );
      }
      if (useGmail) {
        selectedScopes.push(
          allScopesForService.find((x) => x.name === "Gmail").id
        );
      }
      if (useGoogleCalendar) {
        selectedScopes.push(
          allScopesForService.find((x) => x.name === "Google Calendar").id
        );
      }
      if (useGoogleTasks) {
        selectedScopes.push(
          allScopesForService.find((x) => x.name === "Google Tasks").id
        );
      }
    ApplicationState.setUserDataServiceScopesToAdd(selectedScopes);
    window.location.href = url;
  };

  const getGoogleWorkspaceForm = () => {
    return (
      <Box>
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useGoogleCalendar}
                    onChange={(event) => {
                      setUseGoogleCalendar(event.target.checked);
                      setGoogleScopesString(
                        event.target.checked,
                        useGmail,
                        useGoogleDrive,
                        useGoogleTasks
                      );
                    }}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Google Calendar"
            />
          </FormGroup>
          <i>Analyze meetings from your Calendar</i>
        </Box>
        <br />

        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useGmail}
                    onChange={(event) => {
                      setUseGmail(event.target.checked);
                      setGoogleScopesString(
                        useGoogleCalendar,
                        event.target.checked,
                        useGoogleDrive,
                        useGoogleTasks
                      );
                    }}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Gmail"
            />
          </FormGroup>
          <i>Analyze emails from your Gmail</i>
        </Box>
        <br />

        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useGoogleDrive}
                    onChange={(event) => {
                      setUseGoogleDrive(event.target.checked);
                      setGoogleScopesString(
                        useGoogleCalendar,
                        useGmail,
                        event.target.checked,
                        useGoogleTasks
                      );
                    }}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Google Drive"
            />
          </FormGroup>
          <i>Analyze files from your Google Drive</i>
        </Box>
        <br />

        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useGoogleTasks}
                    onChange={(event) => {
                      setUseGoogleTasks(event.target.checked);
                      setGoogleScopesString(
                        useGoogleCalendar,
                        useGmail,
                        useGoogleDrive,
                        event.target.checked
                      );
                    }}
                    name="checkedD"
                    color="primary"
                  />
                </div>
              }
              label="Google Tasks"
            />
          </FormGroup>
          <i>Analyze tasks from your Google-account</i>
        </Box>
        <br />
      </Box>
    );
  };
  const getAzureDevOpsForm = () => {
    return (
      <Box>
        <Box>
          <FormGroup row>
            <Box p={0.5}>
              <TextField
                id="azureDevOpsPersonalAccessTokenTextField"
                label="Personal Access Token"
                type="text"
                onChange={(event) =>
                  setAzureDevOpsPersonalAccessToken(event.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={azureDevOpsPersonalAccessToken}
              />
            </Box>
          </FormGroup>
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <Box p={0.5}>
              <TextField
                id="azureDevOpsOrganizationTextField"
                label="Organization"
                type="text"
                onChange={(event) =>
                  setAzureDevOpsOrganization(event.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={azureDevOpsOrganization}
              />
            </Box>
          </FormGroup>
          </Box>
          <br />
          <Box>
          <FormGroup row>
            <Box p={0.5}>
              <TextField
                id="azureDevOpsUsernameTextField"
                label="Username/Email"
                type="text"
                onChange={(event) =>
                  setAzureDevOpsUsername(event.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={azureDevOpsUsername}
              />
            </Box>
          </FormGroup>          
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useAzureDevOpsWorkItems}
                    onChange={(event) => {
                      setUseAzureDevOpsWorkItems(event.target.checked);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Work Items"
            />
          </FormGroup>
          <i>Analyze work item changes from Azure DevOps</i>
        </Box>
        <br />
      </Box>
    );
  };
  const getMicrosoft365Form = () => {
    return (
      <Box>
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useOutlookCalendar}
                    onChange={() => setUseOutlookCalendar(!useOutlookCalendar)}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Outlook Calendar"
            />
          </FormGroup>
          <i>Analyze your meetings from Outlook</i>
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useOutlookEmail}
                    onChange={() => setUseOutlookEmail(!useOutlookEmail)}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="Outlook Email"
            />
          </FormGroup>
          <i>Analyze your emails from Outlook</i>
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useOneDrive}
                    onChange={() => setUseOneDrive(!useOneDrive)}
                    name="checkedB"
                    color="primary"
                  />
                </div>
              }
              label="OneDrive"
            />
          </FormGroup>
          <i>Analyze your files from OneDrive</i>
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={useToDoTasks}
                    onChange={() => setUseToDoTasks(!useToDoTasks)}
                    name="checkedBToDo"
                    color="primary"
                  />
                </div>
              }
              label="To Do Tasks"
            />
          </FormGroup>
          <i>Analyze your tasks from To Do</i>
        </Box>
        <br />
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <div>
                  <Checkbox
                    checked={usePlannerTasks}
                    onChange={() => setUsePlannerTasks(!usePlannerTasks)}
                    name="checkedBPlanner"
                    color="primary"
                  />
                </div>
              }
              label="Planner Tasks"
            />
          </FormGroup>
          <i>Analyze your tasks from Planner</i>
        </Box>
        <br />
      </Box>
    );
  };

  const addForm = () => {
    if (showMicrosoft365()) {
      return getMicrosoft365Form();
    }
    if (showAzureDevOps()) {
      return getAzureDevOpsForm();
    }
    if (showGoogleWorkspace()) {
      return getGoogleWorkspaceForm();
    }
    return <Box>Uknown service</Box>;
  };

  const saveService = () => {
    if (showGoogleWorkspace()) {
      ApplicationState.setServiceNeedRedirectFromHack(true);
      saveGoogleServices();
    }
    if (showMicrosoft365()) {
      ApplicationState.setServiceNeedRedirectFromHack(true);
      saveMicrosoftServices();
    }
    if (showAzureDevOps()) {
      connectAzureDevOps();
    }
  };

  const getHeaderText = () => {
    let headerText = "Add ";
    if (editMode) {
      headerText = "Edit ";
    }
    if (showMicrosoft365()) {
      headerText += "Microsoft 365 account";
    }
    if (showAzureDevOps()) {
      headerText += "Azure DevOps account";
    }
    if (showGoogleWorkspace()) {
      headerText += "Google Workspace account";
    }
    return headerText;
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
            {ApplicationState.getUserDataServiceSelectionToEdit() && (
              <Typography>
                {
                  ApplicationState.getUserDataServiceSelectionToEdit()
                    .accountIdentifier
                }
              </Typography>
            )}
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveService()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={disconnectService}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ServiceConfigView;

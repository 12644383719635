import Box from "@mui/material/Box";
import { Dialog, FormControlLabel, Switch } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from '@mui/material/styles';
import ProjectEditView from "./ProjectEditView";
import { PrimaryButton } from "./PrimaryButton";
import { useHistory } from "react-router-dom";
import { StyledDataGrid } from "./StyledDataGrid";
import ProjectToDoDto from "../Dtos/ProjectToDoDto";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import ProjectExtensionCrudView from "./ProjectExtensionCrudView";
import EditIcon from "@mui/icons-material/Edit";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
});

const CustomTab = styled(Tab)({
  textTransform: "capitalize",
  color: "#ddd",
  minWidth: "50px",
});

const CustomTabPanel = styled(TabPanel)({
  padding: "0px",
});

function ProjectsView() {
  const [projects, setProjects] = useState([]);
  const [skillExperienceOverview, setSkillExperienceOverview] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [toDoList, setToDoList] = useState([]);
  const [gantProjects, setGantProjects] = useState([]);
  const [newProjects, setNewProjects] = useState([]);
  const [extensionsToActivate, setExtensionsToActivate] = useState([]);
  const [projectsThatEndsSoon, setProjectsThatEndsSoon] = useState([]);
  const [showOnlyActiveProjects, setShowOnlyActiveProjects] = useState(true);
  const [value, setValue] = React.useState("1");
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [openExtensionDialog, setOpenExtensionDialog] = useState(false);

  // Get current month and the following 11 months
  const months = Array.from({length: 12}, (_, i) => {
    const month = new Date();
    month.setMonth(new Date().getMonth() + i);
    return month.toLocaleString('sv-SE', { month: 'long' }) + " " + month.getFullYear();
  });

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'externalId',
      headerName: 'ID',
      maxWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      maxWidth: 135,
      flex: 1,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'activeFrom',
      headerName: 'From',
      type: 'date',
      width: 110,
      editable: false,
      valueGetter: (params) =>{
          if(!params.row.activeFrom) {
            return "";
          }
          return new Date(params.row.activeFrom);
         }
    },
    {
      field: 'activeTo',
      headerName: 'To',
      type: 'date',
      width: 110,
      editable: false,
      valueGetter: (params) =>{
        if(!params.row.activeTo) {
            return "";
          }
          return new Date(params.row.activeTo);
         }
    },
    {
      field: 'hourlyRateToInvoice',
      headerName: 'To Invoice',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 100,
      valueGetter: (params) =>{
        if(!params.row.hourlyRateToInvoice) {
          return "";
        }
        return params.row.hourlyRateToInvoice + " kr";
      } 
    },
    {
      field: 'hourlyRateToSubcontractor',
      headerName: 'To Friend',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 100,
      valueGetter: (params) =>{
        if(!params.row.hourlyRateToSubcontractor) {
          return "";
        }
        return params.row.hourlyRateToSubcontractor + " kr";
      } 
    },
    {
      field: 'hourlyRate',
      headerName: 'Margin',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 100,
      valueGetter: (params) =>{
        if(!params.row.hourlyRateToInvoice || !params.row.hourlyRateToSubcontractor) {
          return "";
        }
        return ((params.row.hourlyRateToInvoice - params.row.hourlyRateToSubcontractor) / params.row.hourlyRateToInvoice * 100).toFixed(1) + "%";
      } 
    },
    {
      field: 'projectManager',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 95,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            editProjectClick(params);
            }}
        >
          <EditIcon></EditIcon>
          </IconButton>
        {params.row.linkToFolder && (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            window.location.href = params.row.linkToFolder;
            }}
        >
          <FolderOpenIcon></FolderOpenIcon>
          </IconButton>
          )}
          </Grid>
          );
    
      }
    }
  ];

  const extensionColumns = [
    {
      field: 'project.name',
      headerName: 'Project',
      maxWidth: 250,
      flex: 1,
      editable: false,
      valueGetter: (params) =>{
        if(!params.row.project) {
          return "";
        }
        return params.row.project.name + " - Option " + params.row.extensionNumber;
       }
    },
    {
      field: 'project.contanctperson',
      headerName: 'Contact',
      maxWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) =>{
        if(!params.row.project.clientContactPerson) {
          return "";
        }
        return params.row.project.clientContactPerson;
       }
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'startDate',
      headerName: 'Start',
      type: 'date',
      width: 110,
      editable: false,
      valueGetter: (params) =>{
          if(!params.row.startDate) {
            return "";
          }
          return new Date(params.row.startDate);
         }
    },
    {
      field: 'endDate',
      headerName: 'End',
      type: 'date',
      width: 110,
      editable: false,
      valueGetter: (params) =>{
        if(!params.row.endDate) {
            return "";
          }
          return new Date(params.row.endDate);
         }
    },
    {
      field: 'activated',
      headerName: 'Status',
      maxWidth: 135,
      flex: 1,
      editable: false,
    },
  ];

  const toDoListColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'externalId',
      headerName: 'Id',
      maxWidth: 75,
      flex: 1,
      editable: false,
    },
    {
      field: 'type',
      headerName: 'Type',
      maxWidth: 75,
      flex: 1,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      maxWidth: 135,
      flex: 1,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Deadline',
      type: 'date',
      width: 100,
      editable: false,
      valueGetter: (params) =>{
          if(params.row.date && (params.row.status === "Klar" || params.row.status === "Väntar aktivering")) {
            return new Date(params.row.date);
          }
          return "";
          }
    },
    {
      field: 'startDateendDate',
      headerName: 'Period',
      minWidth: 200,
      editable: false,
      valueGetter: (params) =>{
          if(!params.row.startDate && !params.row.endDate) {
            return "";
          }
          if(!params.row.startDate && params.row.endDate) {
            return new Date(params.row.endDate).toLocaleDateString("sv-SE");
          }
          if(params.row.startDate && !params.row.endDate) {
            return new Date(params.row.startDate).toLocaleDateString("sv-SE");
          }
          return new Date(params.row.startDate).toLocaleDateString("sv-SE") + " to " + new Date(params.row.endDate).toLocaleDateString("sv-SE");
          }
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'actions',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 95,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            if(params.row.type === "Avtal") {
              ApplicationState.setProjectToEdit(params.row.object);
              setOpenProjectDialog(true);
            } else{
              ApplicationState.setProjectExtensionToEdit(params.row.object);
              ApplicationState.setProjectToAddProjectExtensionFor(undefined);
              setOpenExtensionDialog(true);
            }}
          }
        >
          <EditIcon></EditIcon>
          </IconButton>
        {params.row.linkToFolder && (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            window.location.href = params.row.linkToFolder;
            }}
        >
          <FolderOpenIcon></FolderOpenIcon>
          </IconButton>
          )}
          </Grid>
          );
    
      }
    }   
  ];
  
  const history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      showProjects();
      showSkillExperienceOverview();
    };
    load1();
  }, []);

  useEffect(() => {
    const load1 = async () => {
      showProjects();
    };
    load1();
  }, [showOnlyActiveProjects]);

  const handleToDoRowClick = (params) => {
    if(params.row.type === "Avtal") {
      history.push("/project/" + params.row.object.id);
    } else{
      history.push("/project/" + params.row.object.project.id);
    }
    
  };

  const editProjectClick = (params) => {
    ApplicationState.setProjectToEdit(params.row);
    setOpenProjectDialog(true);
  };

  const viewProjectClick = (params) => {
    history.push("/project/" + params.row.id);
  };

  async function showProjects() {
    let proj = await ApplicationState.getProjectsForOrganization(
      ApplicationState.getUser().organization.organizationId
    );
    proj = proj.sort((a, b) => (a.name > b.name ? 1 : -1));
    if(showOnlyActiveProjects) {
      let activeProjects = [];
      proj.forEach(element => {
        if(element.activeFrom && element.activeTo) {
          if(new Date(element.activeFrom) <= new Date() && new Date(element.activeTo) >= new Date()) {
            activeProjects.push(element);
          }
        } 
        if(element.activeFrom && !element.activeTo) {
          if(new Date(element.activeFrom) <= new Date()) {
            activeProjects.push(element);
          }
        }
        if(!element.activeFrom && element.activeTo) {
          if(new Date(element.activeTo) >= new Date()) {
            activeProjects.push(element);
          }
        }
        if(!element.activeFrom && !element.activeTo) {
          activeProjects.push(element);
        }
      });
      setProjects(activeProjects);
    } else{
      setProjects(proj);
    }
    

    let newProj = [];
    newProj = proj.filter(x => x.status != "Klar");
    setNewProjects(newProj);
  
    let ext = await ApplicationState.getProjectExtensionsForOrganization(
      ApplicationState.getUser().organization.organizationId
    )
    ext = ext.sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
    setExtensions(ext);

    let toDos = [];
    let gantProj = [];
    let projEndsSoon = [];
    let extToActivate = [];
    let now = new Date();
    let sixMonthsFromNow = new Date();
    sixMonthsFromNow.setMonth(now.getMonth() + 6);
    let twelveMonthsFromNow = new Date();
    twelveMonthsFromNow.setMonth(now.getMonth() + 12);

    proj.forEach(element => {
      var projDto = new ProjectToDoDto(
        element.id,
        element.activeTo,
        "Avtal",
        element,
        element.name,
        element.description,
        element.activeFrom,
        element.activeTo,
        element.status,
        element.externalId,
        element.linkToFolder
      );
      if(element.activeFrom && element.activeTo) {
        if(new Date(element.activeTo) <= new Date(sixMonthsFromNow) && new Date(element.activeTo) >= new Date()){
          toDos.push(projDto); 
          projEndsSoon.push(element);
        }
        if(new Date(element.activeFrom) <= new Date() && new Date(element.activeTo) >= new Date()) {
          gantProj.push(element);
        }
      }
      if(!element.activeFrom && element.activeTo) {
        if(new Date(element.activeTo) <= new Date(sixMonthsFromNow) && new Date(element.activeTo) >= new Date()){
          toDos.push(projDto); 
          projEndsSoon.push(element);
        }
      }

    });

    
    newProj.forEach(element => {
      var projDto = new ProjectToDoDto(
        element.id,
        element.activeTo,
        "Avtal",
        element,
        element.name,
        element.description,
        element.activeFrom,
        element.activeTo,
        element.status,
        element.externalId,
        element.linkToFolder
      );
      if(toDos.find(x => x.type === "Avtal" && x.object.id === element.id)) {
        //Do nothing, already in list
      } else{
        toDos.push(projDto); 
      }
    });

    gantProj = gantProj.sort((a, b) => (a.name > b.name ? 1 : -1));
    setGantProjects(gantProj);
    projEndsSoon = projEndsSoon.sort((a, b) => (a.activeTo > b.activeTo ? 1 : -1));
    setProjectsThatEndsSoon(projEndsSoon);

    ext.forEach(element => {
      let dtoStatus = "Aktiverad";
      if(!element.activated) {
        dtoStatus = "Väntar aktivering";
      }
      
      let extensionsForProject = ext.filter(e => e.project.id === element.project.id);

      let maxExtensionNumber;
      if (extensionsForProject.length > 0) {
          maxExtensionNumber = Math.max(...extensionsForProject.map(e => e.extensionNumber));
      } else {
          maxExtensionNumber = undefined;
      }

      var extDto = new ProjectToDoDto(
        element.id,
        element.startDate,
        "Option",
        element,
        element.project.name + " - Option " + element.extensionNumber + " av " + maxExtensionNumber,
        element.description,
        element.startDate,
        element.endDate,
        dtoStatus,
        element.project.externalId,
        element.project.linkToFolder
      );
      if(new Date(element.startDate) <= new Date(sixMonthsFromNow) && !element.activated){
        toDos.push(extDto);
        extToActivate.push(element);
      }
    }
    );

    setExtensionsToActivate(extToActivate);
    
    toDos = toDos.sort((a, b) => (a.date > b.date ? 1 : -1));
    let toDosTop = toDos.filter(x => x.type === "Avtal" && x.status != "Klar").sort((a, b) => a.name.localeCompare(b.name));
    let toDosNotInTop = toDos.filter(x => !toDosTop.includes(x));
    
    toDosNotInTop.sort((a, b) => {
      if (a.date && b.date) {
          return new Date(a.date) - new Date(b.date) || a.name.localeCompare(b.name);
      } else if (!a.date && b.date) {
          return 1; // a is larger (comes after) if it's missing the date
      } else if (a.date && !b.date) {
          return -1; // b is larger (comes after) if it's missing the date
      } else {
          return a.name.localeCompare(b.name); // if both dates are missing, compare by Name
      }
    });
    let toDosSorted = toDosTop.concat(toDosNotInTop);
    setToDoList(toDosSorted);
  }

  async function showSkillExperienceOverview() {
    let overview = await ApplicationState.getSkillExperienceOverviewForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setSkillExperienceOverview(overview);
  }

  function addToDoList() {
    if (!toDoList) {
      return;
    }
    return addToDoListGrid();
  }

  const addToDoListGrid = () => {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("ToDo - " + toDoList.length + " tasks", addAddToDoButton)}</React.Fragment>
        <StyledDataGrid columns={toDoListColumns} rows={toDoList} onRowClick={handleToDoRowClick}></StyledDataGrid>
      </CustomGrid>
      
    );
  }

  const addProjectsThatEndsSoon = () => {
    if (!projects) {
      return;
    }
    return addProjectsThatEndsSoonGrid();
  }

  const addProjectsThatEndsSoonGrid = () => {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("ToDo - Projects that ends soon", addAddHiddenButton)}</React.Fragment>
        <StyledDataGrid columns={columns} rows={projectsThatEndsSoon} onRowClick={viewProjectClick}></StyledDataGrid>
      </CustomGrid>
    );
  }

  const addBoxHeader = (text, addButtonFunction) => {
    return(
    <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              {text}
            </Typography>
            <Box>{addButtonFunction()}</Box>
          </Grid>
        </Box>
        );
    }

    function addAddHiddenButton() {
      return (
        <Box p={1} style={{visibility: "hidden"}}>
          <PrimaryButton
            icon="add"
            text="Add skill"
          ></PrimaryButton>
        </Box>
      );
    }

  const addProjects = () => {
    if (!projects) {
      return;
    }
    return addProjectsGrid();
  };

  function addProjectsGrid() {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("Projects", addAddProjectButton)}</React.Fragment>
        <StyledDataGrid columns={columns} rows={projects} onRowClick={viewProjectClick}></StyledDataGrid>
      </CustomGrid>
    );
  }

  const addNewProjects = () => {
    if (!newProjects) {
      return;
    }
    return addNewProjectsGrid();
  };

  function addNewProjectsGrid() {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("ToDo - New Projects", addAddHiddenButton)}</React.Fragment>
        <StyledDataGrid columns={columns} rows={newProjects} onRowClick={viewProjectClick}></StyledDataGrid>
      </CustomGrid>
    );
  }

  const addExtensionsToActivate = () => {
    if (!extensionsToActivate) {
      return;
    }
    return addExtensionsToActivateGrid();
  };

  function addExtensionsToActivateGrid() {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("ToDo - Extensions to activate", addAddHiddenButton)}</React.Fragment>
        <StyledDataGrid columns={extensionColumns} rows={extensionsToActivate} onRowClick={editExtensionClick}></StyledDataGrid>
      </CustomGrid>
    );
  }

  function addAddProjectButton() {
    return (
      <Grid container justifyContent="center" justifyItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyActiveProjects}
                  onChange={(event) => {
                    setShowOnlyActiveProjects(event.target.checked);
                  }}
                  name="onlyShowActiveProjects"
                  color="secondary"
                />
              }
              label="Only active"
              style={{color: "#fff"}}
            />
        <PrimaryButton
                icon="add"
                text="Add project"
                buttonClick={() => newProjectClick()}
              >
        </PrimaryButton>
      </Grid>
    );
  }

  function addAddToDoButton() {
    return (
      <Grid container justifyContent="center" justifyItems="center">
        <PrimaryButton
                icon="add"
                text="Add project"
                buttonClick={() => newProjectClick()}
              >
        </PrimaryButton>
      </Grid>
    );
  }

  function newProjectClick() {
    ApplicationState.setProjectToEdit(undefined);
    setOpenProjectDialog(true);
  }

  async function addedProject() {
    showProjects();
    setOpenProjectDialog(false);
  }

  function addGant() {
    // Skapa en ny Date objekt från strängen, ställ in dagen till den första i månaden
    let maxDate = new Date(months[months.length - 1]);
    const monthMap = {
        "januari": "January",
        "februari": "February",
        "mars": "March",
        "april": "April",
        "maj": "May",
        "juni": "June",
        "juli": "July",
        "augusti": "August",
        "september": "September",
        "oktober": "October",
        "november": "November",
        "december": "December"
    };
    
    let swedishMonth = months[months.length - 1]; // for example, "januari 2024"
    let parts = swedishMonth.split(" "); // ["januari", "2024"]
    let englishMonth = monthMap[parts[0].toLowerCase()]; // "January"
    let year = parts[1]; // "2024"
    
    maxDate = new Date(`${englishMonth} ${year}`);

    // Flytta fram datumet till nästa månad
    maxDate.setMonth(maxDate.getMonth() + 1);

    // Ställ tillbaka dagen till den första i månaden
    maxDate.setDate(1);

    // Dra bort en dag, vilket kommer att sätta datumet till den sista dagen i föregående månad
    maxDate.setDate(maxDate.getDate() - 1);
    

    return  (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("Overview", addAddHiddenButton)}</React.Fragment>
    <Table style={{backgroundColor: "white"}}>
      <TableHead>
      <TableRow>
          <TableCell>Projects</TableCell>
          <TableCell></TableCell>
          {months.map((month, index) => (
            <TableCell key={index}>{month}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    <TableBody>
      {gantProjects.map((project, index) => {
        let extensionsForProject = extensions.filter(x => x.project.id === project.id && new Date(x.endDate) >= new Date() && new Date(x.startDate) <= new Date(maxDate)).sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
        let endDate = new Date(project.activeTo);
        if(extensionsForProject.length > 0) {
          endDate = new Date(extensionsForProject[0].startDate);
          endDate.setMonth(endDate.getMonth() - 1);
        }
        let projectLength = getEndMonthIndex(endDate) - getStartMonthIndex(project.activeFrom) + 1;

        let month1 = new Date(endDate).toLocaleString('sv-SE', { month: 'long' }) + " " + new Date(endDate).getFullYear();
        let month2 = new Date(project.activeFrom).toLocaleString('sv-SE', { month: 'long' }) + " " + new Date(project.activeFrom).getFullYear();
        if(!months.includes(month1) && !months.includes(month2)) {
          projectLength = -1;
        }

        return (
          <TableRow key={index}>
            <TableCell>{project.name}</TableCell>
            <TableCell colSpan={0 + getStartMonthIndex(project.startDate)}></TableCell>
            {projectLength >= 0 && (
              <TableCell colSpan={projectLength} style={{ backgroundColor: "#93264A", color: "#fff" }}>{new Date(project.activeFrom).toLocaleDateString("sv-SE")}{" to "}{new Date(endDate).toLocaleDateString("sv-SE")}</TableCell>
            )}

            {extensionsForProject.map((extension, i) => {
              const activeFrom = new Date(extension.startDate);
              const activeTo = new Date(extension.endDate);
              let color = "#CC9933";
              if(extension.activated) {
                color = "#339966";
              }
              return (
                <React.Fragment key={i}>
                  <TableCell colSpan={getEndMonthIndex(activeTo) - getStartMonthIndex(activeFrom) + 1} style={{ backgroundColor: color, color: "#fff" }}>
                    {activeFrom.toLocaleDateString("sv-SE")}{" to "}{activeTo.toLocaleDateString("sv-SE")}
                  </TableCell>
                </React.Fragment>
              );
            })}

            <TableCell colSpan={12}></TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
  
  </CustomGrid>);
  }

  function getStartMonthIndex(startDate) {
    let month = new Date(startDate).toLocaleString('sv-SE', { month: 'long' }) + " " + new Date(startDate).getFullYear();
    if(months.includes(month)) {
      return months.indexOf(month) + 1;
    }
    return 0 + 1;
  }

  function getEndMonthIndex(endDate) {
    let month = new Date(endDate).toLocaleString('sv-SE', { month: 'long' }) + " " + new Date(endDate).getFullYear();
    if(months.includes(month)) {
      return months.indexOf(month) + 1;
    }
    return 11 + 2;
  }

  function editExtensionClick(params) {
    ApplicationState.setProjectExtensionToEdit(params.row);
    ApplicationState.setProjectToAddProjectExtensionFor(undefined);
    setOpenExtensionDialog(true);
  }

  const addTabs = (text) => {
    let text1 = "";
    let text2 = "";
    let text3 = "";
    let text4 = "";
    let text5 = "";
    let text6 = "";
    if(text) {
      text1 = "ToDo";
      text2 = "Projects";
      text3 = "Gant";
      text4 = "Keywords";
      text5 = "Autoflex";
      text6 = "Ignores";
    }


    return (<TabList
      onChange={handleChange}
      aria-label="simple tabs example"
      style={{backgroundColor: "#781938"}}
      variant="fullWidth"
      textColor="secondary"
      indicatorColor="secondary"
    >
      <CustomTab label={text1} value="1" icon={<ListAltIcon />} 
      />
      <CustomTab label={text2} value="2" icon={<AccountTreeIcon />}
      />
      <CustomTab label={text3} value="3" icon={<BarChartIcon />}
      />
    </TabList>);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        
      <TabContext value={value}>
                <AppBar position="static">
                    {useMediaQuery(useTheme().breakpoints.down("md")) && (
                    <React.Fragment>
                    {addTabs()}
                    </React.Fragment>
                    )}
                    {useMediaQuery(useTheme().breakpoints.up("md")) && (
                    <React.Fragment>
                    {addTabs(true)}
                    </React.Fragment>
                    )}
                </AppBar>
                
                <CustomTabPanel value="1">
                  <Box>{addToDoList()}</Box>
                  <Box>{addNewProjects()}</Box>
                  <Box>{addProjectsThatEndsSoon()}</Box>
                  <Box>{addExtensionsToActivate()}</Box>
                </CustomTabPanel>
                <CustomTabPanel value="2"><Box>{addProjects()}</Box></CustomTabPanel>
                <CustomTabPanel value="3"><Box>{addGant()}</Box></CustomTabPanel>
              </TabContext>

        <br />
        <Dialog
            open={openProjectDialog}
            onClose={() => setOpenProjectDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <ProjectEditView
              addedProject={addedProject}
              editedProject={addedProject}
              cancelClick={() => setOpenProjectDialog(false)}
            ></ProjectEditView>
          </Dialog>
          <Dialog
            open={openExtensionDialog}
            onClose={() => setOpenExtensionDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <ProjectExtensionCrudView
              deletedProjectExtension={() => {setOpenExtensionDialog(); showProjects();}}
              addedProjectExtension={() => {setOpenExtensionDialog(); showProjects();}}
              editedProjectExtension={() => {setOpenExtensionDialog(); showProjects();}}
              cancelClick={() => setOpenExtensionDialog(false)}
            ></ProjectExtensionCrudView>
          </Dialog>
      </Grid>
    </Grid>
  );
}

export default ProjectsView;

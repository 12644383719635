import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from "./PrimaryButton";
import { DeleteButton } from "./DeleteButton";
import AssignmentDto from "../Dtos/AssignmentDto";
import {
  Autocomplete,
  Chip,
  FormControlLabel,
  Select,
  Switch,
} from "@mui/material";
import SkillExperienceInAssignmentDto from "../Dtos/SkillExperienceInAssignmentDto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DateTimeHelper from "../Utils/DateTimeHelper";

function AssignmentCrudView(props) {
  const [titleField, setTitleField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [clientField, setClientField] = useState(undefined);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [years, setYears] = useState(getYears());
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(new Date().getMonth());
  const [endMonth, setEndMonth] = useState(new Date().getMonth());
  const [ongoing, setOngoing] = useState(false);

  useEffect(() => {
    const load1 = async () => {
      let orgSkills = await ApplicationState.getSkillsForOrganization(
        ApplicationState.getUser().organization.organizationId
      );
      setSkills(orgSkills);
      if (ApplicationState.getAssignmentToEdit()) {
        let existingSkills = [];
        let se = await ApplicationState.getSkillExperienceForAssignment(
          ApplicationState.getAssignmentToEdit().id
        );
        se.forEach((element) => {
          let s = orgSkills.find((x) => x.id === element.skill.id);
          existingSkills.push(s);
        });
        setSelectedSkills(existingSkills);
      }
    };
    load1();

    if (ApplicationState.getAssignmentToEdit()) {
      setEditMode(true);
      setTitleField(ApplicationState.getAssignmentToEdit().title);
      setClientField(ApplicationState.getAssignmentToEdit().client);
      setDescriptionField(ApplicationState.getAssignmentToEdit().description);
      setStartYear(
        new Date(ApplicationState.getAssignmentToEdit().startDate).getFullYear()
      );
      setStartMonth(
        new Date(ApplicationState.getAssignmentToEdit().startDate).getMonth()
      );
      if (ApplicationState.getAssignmentToEdit().endDate) {
        setEndYear(
          new Date(ApplicationState.getAssignmentToEdit().endDate).getFullYear()
        );
        setEndMonth(
          new Date(ApplicationState.getAssignmentToEdit().endDate).getMonth()
        );
      } else {
        setOngoing(true);
      }
    }
  }, []);

  function getYears() {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let year = 1970; year <= currentYear + 10; year++) {
      years.push(year);
    }
    return years;
  }

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let startDate = new Date(Date.UTC(startYear, startMonth, 1));
    let endDate = undefined;
    if (!ongoing && endYear && endMonth) {
      endDate = new Date(Date.UTC(endYear, endMonth, 28));
    }
    let dto = new AssignmentDto(
      ApplicationState.generateGuid(),
      ApplicationState.getUserToAddAssignmentFor(),
      titleField,
      descriptionField,
      clientField,
      new Date(startDate),
      endDate,
      false,
      new Date(Date.now())
    );
    if (ApplicationState.getAssignmentToEdit()) {
      dto.id = ApplicationState.getAssignmentToEdit().id;
      dto.user = ApplicationState.getAssignmentToEdit().user;
      dto.created = ApplicationState.getAssignmentToEdit().created;
      await ApplicationState.saveUpdatedAssignment(dto);
      await ApplicationState.deleteSkillExperienceForAssignment(dto.id);
      selectedSkills.forEach(async (skill) => {
        let skillExperienceDto = new SkillExperienceInAssignmentDto(
          ApplicationState.generateGuid(),
          skill,
          dto,
          new Date(Date.now())
        );
        await ApplicationState.saveNewSkillExperienceInAssignment(
          skillExperienceDto
        );
      });

      props.editedAssignment();
    } else {
      await ApplicationState.saveNewAssignment(dto);
      selectedSkills.forEach(async (skill) => {
        let skillExperienceDto = new SkillExperienceInAssignmentDto(
          ApplicationState.generateGuid(),
          skill,
          dto,
          new Date(Date.now())
        );
        await ApplicationState.saveNewSkillExperienceInAssignment(
          skillExperienceDto
        );
      });
      props.addedAssignment();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteAssignment(
      ApplicationState.getAssignmentToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete assignment with id: " +
          ApplicationState.getAssignmentToEdit().id
      );
      return;
    }
    props.deletedAssignment();
  }

  const getHeaderText = () => {
    let headerText = "Add assignment";
    if (editMode) {
      headerText = "Edit assignment";
    }
    return headerText;
  };

  const renderYearOptions = (year) => {
    return <option key={year} value={year} label={year}></option>;
  };

  const renderMonthOptions = () => {
    return (
      <React.Fragment>
        <option key={0} value={0} label={DateTimeHelper.getMonthName(0)} />
        <option key={1} value={1} label={DateTimeHelper.getMonthName(1)} />
        <option key={2} value={2} label={DateTimeHelper.getMonthName(2)} />
        <option key={3} value={3} label={DateTimeHelper.getMonthName(3)} />
        <option key={4} value={4} label={DateTimeHelper.getMonthName(4)} />
        <option key={5} value={5} label={DateTimeHelper.getMonthName(5)} />
        <option key={6} value={6} label={DateTimeHelper.getMonthName(6)} />
        <option key={7} value={7} label={DateTimeHelper.getMonthName(7)} />
        <option key={8} value={8} label={DateTimeHelper.getMonthName(8)} />
        <option key={9} value={9} label={DateTimeHelper.getMonthName(9)} />
        <option key={10} value={10} label={DateTimeHelper.getMonthName(10)} />
        <option key={11} value={11} label={DateTimeHelper.getMonthName(11)} />
      </React.Fragment>
    );
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="titleTextField"
          label="Title"
          type="text"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setTitleField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={titleField}
        />
        <br />
        <TextField
          id="clientTextField"
          label="Client"
          type="text"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setClientField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={clientField}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          multiline
          rows={3}
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
        {}
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box>
            <Select
              native
              id="startMonthSelect"
              variant="outlined"
              value={startMonth}
              onChange={(event) => setStartMonth(event.target.value)}
              style={{ margin: "8px" }}
            >
              {renderMonthOptions()}
            </Select>
            <Select
              native
              id="startYearSelect"
              variant="outlined"
              value={startYear}
              onChange={(event) => setStartYear(event.target.value)}
              style={{ margin: "8px" }}
            >
              {years.map((year) => {
                return renderYearOptions(year);
              })}
            </Select>
          </Box>
          <Box style={{ paddingLeft: "8px" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={ongoing}
                  onChange={(event) => {
                    setOngoing(event.target.checked);
                  }}
                  name="onging"
                  color="primary"
                />
              }
              label="Ongoing"
            />
          </Box>
        </Grid>
        {!ongoing && (
          <Grid container justifyContent="space-between" alignItems="center">
            <Box>
              <Select
                native
                id="endMonthSelect"
                variant="outlined"
                value={endMonth}
                onChange={(event) => setEndMonth(event.target.value)}
                style={{ margin: "8px" }}
              >
                <option key={-1} value={-1} label={" "} />
                {renderMonthOptions()}
              </Select>
              <Select
                native
                id="endYearSelect"
                variant="outlined"
                value={endYear}
                onChange={(event) => setEndYear(event.target.value)}
                style={{ margin: "8px" }}
              >
                <option key={-1} value={-1} label={" "} />
                {years.map((year) => {
                  return renderYearOptions(year);
                })}
              </Select>
            </Box>
          </Grid>
        )}
        <Autocomplete
          multiple
          id="skillSelector"
          sx={{
            width: 400,
            margin: "8px",
            marginTop: "16px",
            marginBottom: "24px",
          }}
          options={skills}
          value={selectedSkills}
          autoHighlight
          onChange={(event, newValue) => {
            setSelectedSkills(newValue);
          }}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box>
                    <b>{option.name}</b>
                  </Box>
                  <Typography>
                    <i>{option.synonyms}</i>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Skills"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
          filterOptions={(options, { inputValue }) => {
            const lowerCasedInputValue = inputValue.toLowerCase();
            return options.filter((option) => {
              if (option.name.toLowerCase().includes(lowerCasedInputValue)) {
                return true;
              }

              if (
                option.synonyms &&
                option.synonyms.toLowerCase().includes(lowerCasedInputValue)
              ) {
                return true;
              }
              return false;
            }, []);
          }}
          renderTags={(value, getTagProps) =>
            value
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: "#93264A",
                    color: "#fff",
                    margin: "8px",
                    fontSize: "larger",
                    padding: "4px",
                    height: "42px",
                    "&:hover": {
                      backgroundColor: "#93264A",
                      color: "#fff",
                    },
                    "&:focus": {
                      backgroundColor: "#93264A",
                      color: "#fff",
                    },
                  }}
                  deleteIcon={
                    <DeleteForeverIcon
                      style={{ color: "#fff" }}
                    ></DeleteForeverIcon>
                  }
                />
              ))
          }
        />

      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              ></PrimaryButton>
              {editMode && <DeleteButton onClick={deleteClick}></DeleteButton>}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default AssignmentCrudView;

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ApplicationState from "../State/ApplicationState";
import TimeEntryDto from "../Dtos/TimeEntryDto";
import { useState } from "react";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EventIcon from "@mui/icons-material/Event";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CardHeader from "@mui/material/CardHeader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { PrimaryButton } from "../Components/PrimaryButton";
import { styled } from "@mui/material/styles";
import DateTimeHelper from "../Utils/DateTimeHelper";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { DeleteButton } from "../Components/DeleteButton";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "60px",
});

export default function TimeEntryView(props) {
  const [dateField, setDateTextField] = useState(new Date());
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [hoursField, setHoursField] = useState(0);
  const [minutesField, setMinutesField] = useState(0);
  const [durationField, setDurationField] = useState("0:00");
  const [projectField, setProjectField] = useState(undefined);
  const [activityField, setActivityField] = useState(undefined);
  const [activitiesForProject, setActivitiesForProject] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedAdvanced, setExpandedAdvanced] = React.useState(false);
  const [userDataItem, setUserDataItem] = React.useState(undefined);
  const [userDataTitle, setUserDataTitle] = React.useState("");
  const [userDataType, setUserDataType] = React.useState(undefined);
  const [loadingUserDataItem, setLoadingUserDataItem] = React.useState(false);
  const [durationOvertime1, setDurationOvertime1] = React.useState("0:00");
  const [durationOvertime2, setDurationOvertime2] = React.useState("0:00");
  const [durationErrorMessage, setDurationErrorMessage] = useState("");
  const [showDurationErrorMessage, setShowDurationErrorMessage] = useState(false);
  const [durationOvertime1ErrorMessage, setDurationOvertime1ErrorMessage] = useState("");
  const [showDurationOvertime1ErrorMessage, setShowDurationOvertime1ErrorMessage] = useState(false);
  const [durationOvertime2ErrorMessage, setDurationOvertime2ErrorMessage] = useState("");
  const [showDurationOvertime2ErrorMessage, setShowDurationOvertime2ErrorMessage] = useState(false);
  const [projectErrorMessage, setProjectErrorMessage] = useState("Invalid projecty");
  const [showProjectErrorMessage, setShowProjectErrorMessage] = useState(false);


  useEffect(() => {
    setDateTextField(new Date().toLocaleDateString("sv-SE"));
    let projects = ApplicationState.getState().projects;
    if (projects !== undefined || projects.length !== 0) {
      let defaultProjectId =
        ApplicationState.getDefaultProjectManualTimeEntry();
      if (defaultProjectId && defaultProjectId.length > 1 && projects.some(x => x.id === defaultProjectId)) {
        setProjectField(defaultProjectId);
        fillActivitiesForProject(defaultProjectId);
      } else {
        setProjectField(projects[0].id);
        fillActivitiesForProject(projects[0].id);
      }
    }
    if (ApplicationState.getDateToAddTimeEntryFor()) {
      setDateTextField(
        new Date(
          ApplicationState.getDateToAddTimeEntryFor()
        ).toLocaleDateString("sv-SE")
      );
    }
    if (ApplicationState.getProjectToAddTimeEntryFor()) {
      setProjectField(ApplicationState.getProjectToAddTimeEntryFor().id);
      fillActivitiesForProject(
        ApplicationState.getProjectToAddTimeEntryFor().id
      );
    }
    if (ApplicationState.getUserDataItemToAddTimeEntryFor()) {
      let dto = ApplicationState.getUserDataItemToAddTimeEntryFor();
      setDescriptionField(dto.title);
      setDateTextField(new Date(dto.dateTime).toLocaleDateString("sv-SE"));
      setHoursField(parseInt(dto.duration / 60));
      setMinutesField(dto.duration % 60);
      setDurationField(
        DateTimeHelper.convertMinutesToTimeInputString(dto.duration)
      );
    }

    if (ApplicationState.getTimeEntryToEdit()) {
      setEditMode(true);
      setHoursField(
        parseInt(ApplicationState.getTimeEntryToEdit().durationMinutes / 60)
      );
      setMinutesField(
        ApplicationState.getTimeEntryToEdit().durationMinutes % 60
      );
      setDurationField(
        DateTimeHelper.convertMinutesToTimeInputString(
          ApplicationState.getTimeEntryToEdit().durationMinutes
        )
      );
      if (ApplicationState.getDurationForTimeEntriesNotInSummaryEntry()) {
        let durationToAdd =
          ApplicationState.getDurationForTimeEntriesNotInSummaryEntry();
        setDurationField(
          DateTimeHelper.convertMinutesToTimeInputString(
            ApplicationState.getTimeEntryToEdit().durationMinutes +
              durationToAdd
          )
        );
      }
      setDateTextField(
        new Date(ApplicationState.getTimeEntryToEdit().date).toLocaleDateString(
          "sv-SE"
        )
      );
      setDescriptionField(ApplicationState.getTimeEntryToEdit().description);

      setProjectField(
        ApplicationState.getTimeEntryToEdit().activity.project.id
      );
      fillActivitiesForProject(
        ApplicationState.getTimeEntryToEdit().activity.project.id
      );
      setActivityField(ApplicationState.getTimeEntryToEdit().activity.id);

      setDurationOvertime1(DateTimeHelper.convertMinutesToTimeInputString(ApplicationState.getTimeEntryToEdit().durationOvertime1));
      setDurationOvertime2(DateTimeHelper.convertMinutesToTimeInputString(ApplicationState.getTimeEntryToEdit().durationOvertime2));
    }

    const loadUserData = async () => {
      setLoadingUserDataItem(true);
      let userData = undefined;
      if (
        ApplicationState.getUserDataItemToAddTimeEntryFor() &&
        ApplicationState.getUserDataItemToAddTimeEntryFor().object
      ) {
        userData = ApplicationState.getUserDataItemToAddTimeEntryFor().object;
      }
      if (
        ApplicationState.getTimeEntryToEdit() &&
        ApplicationState.getTimeEntryToEdit().userData
      ) {
        userData = ApplicationState.getTimeEntryToEdit().userData;
      }

      if (userData) {
        cacheSuggestedKeywordsForNextDialog(userData);
        let userDataForTimeEntry = userData;
        if (
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Gmail" ||
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Outlook Email"
        ) {
          var email = await ApplicationState.getEmailForUser(
            ApplicationState.getState().user.email,
            userDataForTimeEntry.id
          );
          setUserDataItem(email);
          setUserDataTitle(email.subject);
          setUserDataType("email");
        }
        if (
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Google Calendar" ||
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Outlook Calendar"
        ) {
          var meeting = await ApplicationState.getMeetingForUser(
            ApplicationState.getState().user.email,
            userDataForTimeEntry.id
          );
          setUserDataItem(meeting);
          setUserDataTitle(meeting.title);
          setUserDataType("meeting");
        }
        if (
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Google Drive" ||
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "OneDrive"
        ) {
          var file = await ApplicationState.getFileForUser(
            ApplicationState.getState().user.email,
            userDataForTimeEntry.id
          );
          setUserDataItem(file);
          setUserDataTitle(file.name);
          setUserDataType("file");
        }
        if (
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Work Items"
        ) {
          var ticket = await ApplicationState.getTicketForUser(
            ApplicationState.getState().user.email,
            userDataForTimeEntry.id
          );
          setUserDataItem(ticket);
          setUserDataTitle(ticket.title);
          setUserDataType("ticket");
        }
        if (
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Google Tasks" ||
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "To Do Tasks" ||
          userDataForTimeEntry.userDataServiceScopeSelection
            .userDataServiceScope.name === "Planner Tasks"
        ) {
          var task = await ApplicationState.getTaskForUser(
            ApplicationState.getState().user.email,
            userDataForTimeEntry.id
          );
          setUserDataItem(task);
          setUserDataTitle(task.title);
          setUserDataType("task");
        }
      } else {
        setUserDataType("manual");
        setUserDataTitle("Manually created by you");
        if (
          ApplicationState.getTimeEntryToEdit() &&
          ApplicationState.getTimeEntryToEdit().createdFromStandardTime
        ) {
          setUserDataType("standard");
          setUserDataTitle("Automatically created from your standard week");
        }
      }
      setLoadingUserDataItem(false);
    };
    loadUserData();
  }, []);

  async function cacheSuggestedKeywordsForNextDialog(userData) {
    ApplicationState.setSuggestedKeywordsForUserData(
      await ApplicationState.getKeywordSuggestionsForUserData(userData.id)
    );
  }

  function fillActivitiesForProject(projectId) {
    let activities = ApplicationState.getState().activities;
    if (activities !== undefined || activities.length !== 0) {
    }
    let activitiesForProject = activities.filter(
      (x) => x.project.id === projectId
    );
    let activitiesForProjectOptions = [];
    activitiesForProject
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((val) => {
        activitiesForProjectOptions.push(
          <option key={val.id} value={val.id}>
            {val.name}
          </option>
        );
      });
    setActivitiesForProject(activitiesForProjectOptions);
    setActivityField(activitiesForProject[0].id);
  }

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  function cancelClick() {
    props.cancelClick();
  }

  const projectIsActive = (project, date) => {
		  let dateUTC = new Date(date);
      dateUTC.setHours(0);
      dateUTC.setMinutes(0);
      dateUTC.setSeconds(0);
      dateUTC.setMilliseconds(0);
      
      let activeFrom = undefined;
      if(project.activeFrom) {
        activeFrom = new Date(project.activeFrom);
        activeFrom.setHours(0);
        activeFrom.setMinutes(0);
        activeFrom.setSeconds(0);
        activeFrom.setMilliseconds(0);
      }

      let activeTo = undefined;
      if(project.activeTo) {
        activeTo = new Date(project.activeTo);
        activeTo.setHours(0);
        activeTo.setMinutes(0);
        activeTo.setSeconds(0);
        activeTo.setMilliseconds(0);
      }

      if (!project.activeFrom && !project.activeTo) {
				return true;
			}
			if (project.activeFrom && !project.activeTo && dateUTC >= activeFrom) {
        return true;
			}
			if (!project.activeFrom && project.activeTo && dateUTC <= activeTo) {
        return true;
			}
			if (project.activeFrom && dateUTC >= activeFrom && project.activeFrom && dateUTC <= activeTo) {
        return true;
			}
      return false;
  }

  const validateDto = (dto) => {
    if(!projectIsActive(dto.activity.project, dto.date)) {
      setShowProjectErrorMessage(true);
      setProjectErrorMessage("Project is not active for the date selected!");
      return false;
    } else{
      setShowProjectErrorMessage(false);
    }
    return true;
  }

   const saveNewTimeEntry = async event => {
    event.target.disabled = true;
    let date = new Date(dateField);
    let dateTime = new Date(new Date(date).toUTCString());
    let durationMinutes = parseInt(minutesField) + parseInt(hoursField) * 60;
    durationMinutes =
      DateTimeHelper.getMinutesFromTimeInputString(durationField);

    let durationOvertime1Converted = DateTimeHelper.getMinutesFromTimeInputString(durationOvertime1);
    let durationOvertime2Converted = DateTimeHelper.getMinutesFromTimeInputString(durationOvertime2);

    let activity = ApplicationState.getState().activities.filter(
      (a) => a.id === activityField
    )[0];

    let dto = new TimeEntryDto(
      ApplicationState.generateGuid(),
      activity,
      descriptionField,
      date,
      durationMinutes,
      new Date(Date.now()),
      ApplicationState.getState().user,
      undefined,
      dateTime,
      undefined,
      false,
      false,
      false,
      false,
      durationMinutes,
      false,
      durationOvertime1Converted,
      durationOvertime2Converted
    );
    if (ApplicationState.getUserDataItemToAddTimeEntryFor()) {
      if (ApplicationState.getUserDataItemToAddTimeEntryFor().object) {
        dto.userData =
          ApplicationState.getUserDataItemToAddTimeEntryFor().object;
      }
      dto.dateTime = new Date(
        new Date(
          ApplicationState.getUserDataItemToAddTimeEntryFor().dateTime
        ).toUTCString()
      );
    }

    if(!validateDto(dto)) {
      event.target.disabled = false;
      return;
    }

    if (ApplicationState.getTimeEntryToEdit()) {
      dto.id = ApplicationState.getTimeEntryToEdit().id;
      dto.userData = ApplicationState.getTimeEntryToEdit().userData;
      dto.summaryEntry = ApplicationState.getTimeEntryToEdit().summaryEntry;
      dto.dateTime = new Date(
        new Date(ApplicationState.getTimeEntryToEdit().dateTime).toUTCString()
      );
      dto.updatedActivity =
        ApplicationState.getTimeEntryToEdit().updatedActivity;
      dto.updatedDuration =
        ApplicationState.getTimeEntryToEdit().updatedDuration;
      if (ApplicationState.getDurationForTimeEntriesNotInSummaryEntry()) {
        let durationToRemove = parseInt(
          ApplicationState.getDurationForTimeEntriesNotInSummaryEntry()
        );
        dto.durationMinutes = dto.durationMinutes - durationToRemove;
      }
      ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(undefined);
      ApplicationState.setInSummaryModeTimeEntry(false);
      await ApplicationState.saveUpdatedTimeEntry(dto);
      let previousActivityId =
        ApplicationState.getTimeEntryToEdit().activity.id;
      ApplicationState.setTimeEntryToEdit(dto);
      if (dto.activity.id != previousActivityId && dto.userData) {
        ApplicationState.setTimeEntryToAddKeywordsFor(dto);
        props.editedTimeEntryShowKeywordSuggestions();
      } else {
        props.editedTimeEntry();
      }
    } else {
      await ApplicationState.saveNewTimeEntry(dto);
      ApplicationState.setTimeEntryToEdit(dto);
      if (
        ApplicationState.getUserDataItemToAddTimeEntryFor() &&
        ApplicationState.getUserDataItemToAddTimeEntryFor().object
      ) {
        ApplicationState.setTimeEntryToAddKeywordsFor(dto);
        props.editedTimeEntryShowKeywordSuggestions();
      } else {
        props.addedTimeEntry();
      }
    }
    event.target.disabled = false;
  }

  async function deleteTimeEntry() {
    var result = await ApplicationState.deleteTimeEntry(
      ApplicationState.getTimeEntryToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete time entry with id: " +
          ApplicationState.getTimeEntryToEdit().id
      );
      return;
    }
    props.deletedTimeEntry();
  }

  async function deleteTimeEntriesForDayAndActivity() {
    props.deletedTimeEntriesForDateAndActivity();
  }

  async function ignoreUserData() {
    let result = await ApplicationState.ignoreUserDataOnce(
      ApplicationState.getUserDataItemToAddTimeEntryFor().object.id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to ignore user data with id: " +
          ApplicationState.getUserDataItemToAddTimeEntryFor().object.id
      );
      return;
    }
    props.ignoredUserData();
  }

  const getTodaysDate = () => {
    let currentTime = new Date();
    return currentTime.toLocaleDateString("sv-SE");
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandAdvancedClick = () => {
    setExpandedAdvanced(!expandedAdvanced);
  };

  function getTimeStringForMeeting() {
    let time = "";
    let start = new Date(userDataItem.startTime);
    let end = new Date(userDataItem.endTime);
    if(start.toLocaleDateString("sv-SE") === end.toLocaleDateString("sv-SE")) {
      time = DateTimeHelper.getTimeStringFromDate(
        new Date(userDataItem.startTime)
      ) +
        " to " +
        DateTimeHelper.getTimeStringFromDate(
          new Date(userDataItem.endTime)
        );
    } else {
      time = new Date(userDataItem.startTime).toLocaleDateString("sv-SE") + " " +
      DateTimeHelper.getTimeStringFromDate(
        new Date(userDataItem.startTime)
      ) +
        " to " +
        new Date(userDataItem.endTime).toLocaleDateString("sv-SE") + " " +
        DateTimeHelper.getTimeStringFromDate(
          new Date(userDataItem.endTime)
        );
    }
    return time;
  }

  const addInfoboxForUserData = () => {
      return (
        <Grid container justifyContent="center" spacing={0}>
          <Box p={0} style={{ maxWidth: "650px", width: "650px" }}>
            <Box style={{ padding: "20px", paddingBottom: "0px" }}>
              <Box
                style={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: "0.25",
                  margin: "4px",
                }}
              >
                <CardHeader
                  avatar={
                    <React.Fragment>
                      {!loadingUserDataItem && (
                        <Box>
                          {userDataType === "email" && (
                            <EmailOutlinedIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "file" && (
                            <InsertDriveFileOutlinedIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "meeting" && (
                            <EventIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "ticket" && (
                            <StickyNote2OutlinedIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "task" && (
                            <AssignmentTurnedInOutlinedIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "manual" && (
                            <PersonIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                          {userDataType === "standard" && (
                            <ScheduleIcon
                              style={{
                                color: "#93264A",
                                width: "36px",
                                height: "36px",
                              }}
                            />
                          )}
                        </Box>
                      )}
                      {loadingUserDataItem && (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ padding: "8px" }}
                        >
                          <CircularProgress
                            size={25}
                            color="primary"
                            style={{ paddingLeft: "4px" }}
                          />
                          <Typography style={{ paddingLeft: "8px" }}>
                            <i>Loading...</i>
                          </Typography>
                        </Grid>
                      )}
                    </React.Fragment>
                  }
                  action={
                    <React.Fragment>
                      {userDataType != "standard" && userDataType != "manual" && (
                        <IconButton
                          sx={{
                            transform: "rotate(0deg)",
                            marginLeft: "auto",
                          }}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                          size="large"
                        >
                          {expanded && <ExpandLessIcon />}
                          {!expanded && <ExpandMoreIcon />}
                        </IconButton>
                      )}
                    </React.Fragment>
                  }
                  title={
                    <Typography
                      variant="h6"
                      style={{ fontSize: "medium", wordBreak: "break-word" }}
                    >
                      {userDataTitle}
                    </Typography>
                  }
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent
                    style={{ paddingTop: "0px", wordBreak: "break-word" }}
                  >
                    <Divider style={{ marginBottom: "16px" }} />
                    {userDataType === "email" && (
                      <Box>
                        {addUserDataItemProperty(
                          "Time",
                          DateTimeHelper.getTimeStringFromDate(
                            new Date(userDataItem.lastModifiedDateTime)
                          )
                        )}
                        {addUserDataItemProperty(
                          "Date",
                          new Date(
                            userDataItem.lastModifiedDateTime
                          ).toLocaleDateString("sv-SE")
                        )}
                        {addUserDataItemProperty("From", userDataItem.from)}
                        {addUserDataItemProperty(
                          "To",
                          addTextForArray(userDataItem.to)
                        )}
                        {addUserDataItemProperty("Body", userDataItem.body)}
                      </Box>
                    )}
                    {userDataType === "file" && (
                      <Box>
                        {addUserDataItemProperty(
                          "Time",
                          DateTimeHelper.getTimeStringFromDate(
                            new Date(userDataItem.lastModifiedDateTime)
                          )
                        )}
                        {addUserDataItemProperty(
                          "Date",
                          new Date(
                            userDataItem.lastModifiedDateTime
                          ).toLocaleDateString("sv-SE")
                        )}
                        {addUserDataItemProperty("Folder", userDataItem.path)}
                      </Box>
                    )}
                    {userDataType === "meeting" && (
                      <Box>
                        {addUserDataItemProperty(
                          "Time",
                          getTimeStringForMeeting()
                        )}
                        {addUserDataItemProperty(
                          "Date",
                          new Date(userDataItem.startTime).toLocaleDateString(
                            "sv-SE"
                          )
                        )}
                        {addUserDataItemProperty("From", userDataItem.from)}
                        {addUserDataItemProperty(
                          "To",
                          addTextForArray(userDataItem.to)
                        )}
                        {addUserDataItemProperty(
                          "Description",
                          userDataItem.description
                        )}
                      </Box>
                    )}
                    {userDataType === "ticket" && (
                      <Box>
                        {addUserDataItemProperty(
                          "Time",
                          DateTimeHelper.getTimeStringFromDate(
                            new Date(userDataItem.changeDateTime)
                          )
                        )}
                        {addUserDataItemProperty(
                          "Date",
                          new Date(
                            userDataItem.changeDateTime
                          ).toLocaleDateString("sv-SE")
                        )}
                        {addUserDataItemProperty("Id", userDataItem.id)}
                        {addUserDataItemProperty(
                          "Project",
                          userDataItem.project
                        )}
                        {addUserDataItemProperty("Type", userDataItem.type)}
                        {addUserDataItemProperty(
                          "Event",
                          userDataItem.userData.eventType
                        )}
                        {addUserDataItemProperty(
                          "Description",
                          userDataItem.description
                        )}
                      </Box>
                    )}
                    {userDataType === "task" && (
                      <Box>
                        {addUserDataItemProperty(
                          "Time",
                          DateTimeHelper.getTimeStringFromDate(
                            new Date(userDataItem.completedDateTime)
                          )
                        )}
                        {addUserDataItemProperty(
                          "Date",
                          new Date(
                            userDataItem.completedDateTime
                          ).toLocaleDateString("sv-SE")
                        )}
                        {addUserDataItemProperty(
                          "Tasklist",
                          userDataItem.taskListName
                        )}
                        {addUserDataItemProperty(
                          "Description",
                          userDataItem.description
                        )}
                      </Box>
                    )}
                  </CardContent>
                </Collapse>
              </Box>
            </Box>
          </Box>
        </Grid>
      );
  };

  function addUserDataItemProperty(title, property) {
    if (!property) {
      return (
        <Typography paragraph>
          <b>{title}</b>
          <br />
          <i>No data exists</i>
        </Typography>
      );
    } else {
      return (
        <Typography paragraph>
          <b>{title}</b>
          <br />
          {property}
        </Typography>
      );
    }
  }

  function addTextForArray(arr) {
    return arr.map((element) => {
      return <Box>{element}</Box>;
    });
  }

  const addTimeToMaxTime = () => {
    let currentMinutes =
      DateTimeHelper.getMinutesFromTimeInputString(durationField);
    if (currentMinutes % 15 === 0) {
      currentMinutes += 15;
    } else {
      currentMinutes += 15 - (currentMinutes % 15);
    }
    setDurationField(
      DateTimeHelper.convertMinutesToTimeInputString(currentMinutes)
    );
  };

  const removeTimeFromMaxTime = () => {
    let currentMinutes =
      DateTimeHelper.getMinutesFromTimeInputString(durationField);
    if (currentMinutes % 15 === 0) {
      currentMinutes -= 15;
    } else {
      currentMinutes -= currentMinutes % 15;
    }
    setDurationField(
      DateTimeHelper.convertMinutesToTimeInputString(currentMinutes)
    );
  };

  function addHeaderText() {
    if (editMode) {
      if (
        ApplicationState.getShowDateWhenAddingTimeEntry() &&
        ApplicationState.getInSummaryModeTimeEntry()
      ) {
        return (
          <Typography variant="h5">
            <Box style={{ marginRight: "28px" }}>
              <b>
                {"Edit time for "}
                {new Date(dateField).toLocaleDateString("sv-SE")}
              </b>
            </Box>
          </Typography>
        );
      }
      if (
        ApplicationState.getShowDateWhenAddingTimeEntry() &&
        !ApplicationState.getInSummaryModeTimeEntry()
      ) {
        return <b>Edit time entry</b>;
      }
      if (!ApplicationState.getShowDateWhenAddingTimeEntry()) {
        return (
          <Typography variant="h5">
            <Box style={{ marginRight: "28px" }}>
              <b>
                {"Edit time for "}
                {DateTimeHelper.getMonthName(new Date(dateField).getMonth()) +
                  " " +
                  new Date(dateField).getFullYear()}
              </b>
            </Box>
          </Typography>
        );
      }
    }

    if (!editMode) {
      if (ApplicationState.getShowDateWhenAddingTimeEntry()) {
        return <b>Add time entry</b>;
      }
      if (!ApplicationState.getShowDateWhenAddingTimeEntry()) {
        return (
          <Typography variant="h5">
            <Box style={{ marginRight: "28px" }}>
              <b>
                {"Add time for "}
                {DateTimeHelper.getMonthName(new Date(dateField).getMonth()) +
                  " " +
                  new Date(dateField).getFullYear()}
              </b>
            </Box>
          </Typography>
        );
      }
    }
  }

  function getDurationForNormalTime(){
    let o1 = DateTimeHelper.getMinutesFromTimeInputString(durationOvertime1);
    let o2 = DateTimeHelper.getMinutesFromTimeInputString(durationOvertime2);
    let totaltTime = DateTimeHelper.getMinutesFromTimeInputString(durationField);
    let normalTime = totaltTime - o1 - o2;
    if(isNaN(totaltTime) || isNaN(o1) || isNaN(o2)){
      return "";
    }
    return DateTimeHelper.convertMinutesToTimeInputString(normalTime);
  }

  function addDeleteAndIgnoreButtons(){
    if(editMode) {
      return(
        <React.Fragment>
          {addDeleteButton()}
        </React.Fragment>
      );
    }
    //Adding new entry
    if(!editMode) {
      if(ApplicationState.getUserDataItemToAddTimeEntryFor()) {
        return(
          <React.Fragment>
            {addIgnoreButton()}
          </React.Fragment>
        );  
      } else {
        return(
          <React.Fragment>
          </React.Fragment>
        );  
      }
    }
  }

  function addDeleteButton(){
    if(ApplicationState.getInSummaryModeTimeEntry()){
      return(<DeleteButton
        onClick={deleteTimeEntriesForDayAndActivity}
      >
      </DeleteButton>)
    }
    return(
      <DeleteButton
        onClick={deleteTimeEntry}
      >
      </DeleteButton>
    )
  }

  function addIgnoreButton(){
    return(
      <DeleteButton
        onClick={ignoreUserData}
      >
      </DeleteButton>
    )
  }

  return (
    <Box>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box sx={{ float: { sm: "none", md: "left" } }}>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              style={{ padding: "0px", paddingTop: "4px", paddingRight: "4px" }}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box style={{ backgroundColor: "#f6f6f6" }} paddingBottom="16px">
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Box p={1} paddingLeft={3}>
                <Typography variant="h5">
                  <Box p={2} paddingLeft={0}>
                    {addHeaderText()}
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Box>

          <Box
            m={0}
            sx={{ float: "left", paddingLeft: { xs: "24px", sm: "0px" } }}
          >
            {ApplicationState.getShowDateWhenAddingTimeEntry() === true &&
              !ApplicationState.getInSummaryModeTimeEntry() && (
                <Box style={{ marginRight: "28px", float: "left" }}>
                  <TextField
                    id="dateTextField"
                    type="date"
                    defaultValue={getTodaysDate()}
                    style={{ width: "176px", margin: "8px" }}
                    variant="outlined"
                    onChange={(event) => setDateTextField(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: {
                        fontWeight: "900",
                        fontSize: "large",
                        backgroundColor: "#fff",
                      },
                      disableUnderline: true,
                    }}
                    value={dateField}
                    disabled={ApplicationState.getInSummaryModeTimeEntry()}
                  />
                </Box>
              )}
          </Box>
        </Grid>
      </Box>
      {(userDataItem) && (
        <React.Fragment>{addInfoboxForUserData()}</React.Fragment>
      )}
      
        {loadingUserDataItem && (
          <Grid container justifyContent="center" spacing={0}>
          <Box p={0} style={{ maxWidth: "650px", width: "650px" }}>
            <Box style={{ padding: "20px", paddingBottom: "0px" }}>
              <Box
                style={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: "0.25",
                  margin: "4px",
                  padding: "16px"
                }}
              >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ padding: "8px" }}
          >
            <CircularProgress
              size={25}
              color="primary"
              style={{ paddingLeft: "4px" }}
            />
            <Typography style={{ paddingLeft: "8px" }}>
              <i>Loading...</i>
            </Typography>
          </Grid>
          </Box>
          </Box>
          </Box>
          </Grid>
        )}
      
      <Grid container justifyContent="center" spacing={0}>
        <Box p={0} style={{ width: "100%" }}>
          <Box p={2.5}>
            <Box p={0}>
            <FormControl error={showProjectErrorMessage}>
              <Box sx={{ float: { xs: "none", sm: "left" } }}>
                <InputLabelSelect
                  id="projectSelectLabel"
                  htmlFor="projectSelect"
                  variant="outlined"
                  shrink={true}
                >
                  Project
                </InputLabelSelect>
                <Select
                  native
                  onChange={(event) => {
                    setProjectField(event.target.value);
                    fillActivitiesForProject(event.target.value);
                  }}
                  id="projectSelect"
                  variant="outlined"
                  sx={{
                    margin: "8px",
                    fontWeight: "500",
                    width: { xs: "95%", sm: "300px" },
                  }}
                  value={projectField}
                  labelId="projectSelectLabel"
                  disabled={ApplicationState.getInSummaryModeTimeEntry()}
                >
                  {ApplicationState.getState()
                    .projects.sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((project) => {
                      return addProject(project);
                    })}
                </Select>
                {showProjectErrorMessage && (
                  <FormHelperText>{projectErrorMessage}</FormHelperText>
                )}
              </Box>
              </FormControl>
              <FormControl error={showProjectErrorMessage}>
              <Box sx={{ float: { xs: "none", sm: "left" } }}>
                <InputLabelSelect
                  id="activitySelectLabel"
                  htmlFor="activitySelect"
                  variant="outlined"
                  shrink={true}
                >
                  Activity
                </InputLabelSelect>
                <Select
                  native
                  onChange={(event) => {
                    setActivityField(event.target.value);
                  }}
                  id="activitySelect"
                  variant="outlined"
                  sx={{
                    margin: "8px",
                    fontWeight: "500",
                    width: { xs: "95%", sm: "300px" },
                  }}
                  value={activityField}
                  labelId="activitySelectLabel"
                  disabled={ApplicationState.getInSummaryModeTimeEntry()}
                >
                  {activitiesForProject}
                </Select>
              </Box>
              </FormControl>
            </Box>

            <br />

            {(!ApplicationState.getInSummaryModeTimeEntry() && editMode) && (
              <Box>
              <Box sx={{ float: { xs: "none", sm: "left" } }}>
                <TextField
                  id="descriptionTextField"
                  label="Description"
                  type="text"
                  multiline
                  rows={3}
                  sx={{
                    margin: "8px",
                    fontWeight: "500",
                    width: { xs: "95%", sm: "616px" },
                  }}
                  onChange={(event) => setDescriptionField(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      fontWeight: "500",
                    },
                  }}
                  variant="outlined"
                  value={descriptionField}
                />
              </Box>
            </Box>
            )}
            {(ApplicationState.getInSummaryModeTimeEntry() || !editMode) && (
            <Box>
              <Box sx={{ float: { xs: "none", sm: "left" } }}>
                <TextField
                  id="descriptionTextField"
                  label="Description"
                  type="text"
                  multiline
                  rows={3}
                  sx={{
                    margin: "8px",
                    fontWeight: "500",
                    width: { xs: "95%", sm: "300px" },
                  }}
                  onChange={(event) => setDescriptionField(event.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      fontWeight: "500",
                    },
                  }}
                  variant="outlined"
                  value={descriptionField}
                />
              </Box>
              <Box sx={{ float: { xs: "none", sm: "left" } }}>
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    fontWeight: "500",
                    width: { xs: "100%", sm: "300px" },
                    justifyContent: { xs: "flex-start", sm: "center" },
                  }}
                >
                  <IconButton
                    style={{ marginTop: "8px" }}
                    onClick={() => {
                      removeTimeFromMaxTime();
                    }}
                  >
                    <RemoveCircleOutlineIcon
                      sx={{
                        fontSize: { xs: "34px", sm: "40px" },
                      }}
                    ></RemoveCircleOutlineIcon>
                  </IconButton>
                  <TextField
                    id="durationTextField"
                    label="Total duration"
                    sx={{
                      width: { xs: "138px", sm: "160px" },
                      margin: "8px",
                      marginTop: "16px",
                    }}
                    error={showDurationErrorMessage}
                    helperText={durationErrorMessage}
                    onBlur={(event) => {
                      let minutes =
                        DateTimeHelper.convertTimeInputStringToMinutes(
                          event.target.value
                        );
                      if (minutes != "ERROR") {
                        setShowDurationErrorMessage(false);
                        setDurationErrorMessage("");
                        setDurationField(
                          DateTimeHelper.convertMinutesToTimeInputString(
                            minutes
                          )
                        );
                      } else {
                        setShowDurationErrorMessage(true);
                        setDurationErrorMessage("Invalid duration");
                      }
                    }}
                    onChange={(event) => setDurationField(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: {
                        fontWeight: "900",
                        fontSize: { xs: "32px", sm: "40px" },
                      },
                    }}
                    placeholder="hh:mm"
                    variant="outlined"
                    value={durationField}
                  />
                  <IconButton
                    style={{ marginTop: "8px" }}
                    onClick={() => {
                      addTimeToMaxTime();
                    }}
                  >
                    <AddCircleOutlineIcon
                      sx={{
                        fontSize: { xs: "34px", sm: "40px" },
                      }}
                    ></AddCircleOutlineIcon>
                  </IconButton>
                </Grid>
              </Box>
            </Box>
            )}
            <Box style={{ clear: "both" }}></Box>
          </Box>
          {(ApplicationState.getInSummaryModeTimeEntry() || !editMode) && (
            <Box
              style={{
                padding: "20px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  p={1}
                  style={{ fontWeight: "400", opacity: "0.7" }}
                >
                  Overtime
                </Typography>
                <Box>
                  <IconButton
                    sx={{
                      transform: "rotate(0deg)",
                      marginLeft: "auto",
                    }}
                    onClick={handleExpandAdvancedClick}
                    aria-expanded={expandedAdvanced}
                    aria-label="show more"
                    size="large"
                  >
                    {expandedAdvanced && <ExpandLessIcon />}
                    {!expandedAdvanced && <ExpandMoreIcon />}
                  </IconButton>
                </Box>
              </Grid>
            </Box>
          )}

          <Collapse
            in={expandedAdvanced}
            timeout="auto"
            unmountOnExit
            style={{ marginTop: "-8px" }}
          >
            <Box
              style={{
                padding: "20px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            >
              <Box
                sx={{ float: { xs: "none", sm: "left" }, marginBottom: "20px" }}
              >
                <TextField
                    id="durationOvertime1TextField"
                    label="Overtime 1"
                    sx={{
                      width: { xs: "138px", sm: "160px" },
                      margin: "8px",
                      marginTop: "16px",
                    }}
                    error={showDurationOvertime1ErrorMessage}
                    helperText={durationOvertime1ErrorMessage}
                    onBlur={(event) => {
                      let minutes =
                        DateTimeHelper.convertTimeInputStringToMinutes(
                          event.target.value
                        );
                      if (minutes != "ERROR") {
                        setShowDurationOvertime1ErrorMessage(false);
                        setDurationOvertime1ErrorMessage("");
                        setDurationOvertime1(
                          DateTimeHelper.convertMinutesToTimeInputString(
                            minutes
                          )
                        );
                      } else {
                        setShowDurationOvertime1ErrorMessage(true);
                        setDurationOvertime1ErrorMessage("Invalid duration");
                      }
                    }}
                    onChange={(event) => setDurationOvertime1(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: {
                        fontWeight: "900",
                        fontSize: { xs: "32px", sm: "40px" },
                      },
                    }}
                    placeholder="hh:mm"
                    variant="outlined"
                    value={durationOvertime1}
                  />
                  <TextField
                    id="durationOvertime2TextField"
                    label="Overtime 2"
                    sx={{
                      width: { xs: "138px", sm: "160px" },
                      margin: "8px",
                      marginTop: "16px",
                    }}
                    error={showDurationOvertime2ErrorMessage}
                    helperText={durationOvertime2ErrorMessage}
                    onBlur={(event) => {
                      let minutes =
                        DateTimeHelper.convertTimeInputStringToMinutes(
                          event.target.value
                        );
                      if (minutes != "ERROR") {
                        setShowDurationOvertime2ErrorMessage(false);
                        setDurationOvertime2ErrorMessage("");
                        setDurationOvertime2(
                          DateTimeHelper.convertMinutesToTimeInputString(
                            minutes
                          )
                        );
                      } else {
                        setShowDurationOvertime2ErrorMessage(true);
                        setDurationOvertime2ErrorMessage("Invalid duration");
                      }
                    }}
                    onChange={(event) => setDurationOvertime2(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: {
                        fontWeight: "900",
                        fontSize: { xs: "32px", sm: "40px" },
                      },
                    }}
                    placeholder="hh:mm"
                    variant="outlined"
                    value={durationOvertime2}
                  />
                  <TextField
                    id="durationOvertime2TextField"
                    label="Normal time"
                    sx={{
                      width: { xs: "138px", sm: "160px" },
                      margin: "8px",
                      marginTop: "16px",
                    }}
                    error={showDurationOvertime2ErrorMessage}
                    helperText={durationOvertime2ErrorMessage}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      sx: {
                        fontWeight: "900",
                        fontSize: { xs: "32px", sm: "40px" },
                      },
                    }}
                    variant="outlined"
                    disabled={true}
                    value={ getDurationForNormalTime() }
                  />
              </Box>
            </Box>
          </Collapse>
          <Box p={2} style={{ backgroundColor: "#93264a" }}>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Box
                p={1}
                style={{
                  visibility: "hidden",
                }}
              ></Box>

              <Box p={1}>
                <PrimaryButton
                  icon="save"
                  text="Save"
                  buttonClick={saveNewTimeEntry}
                ></PrimaryButton>
                
                  <React.Fragment>
                    {addDeleteAndIgnoreButtons()}
                  </React.Fragment>
                
              </Box>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import { PrimaryButton } from "./PrimaryButton";
import IgnoreKeywordEditView from "./IgnoreKeywordEditView";

function IgnoreKeywordsView() {
  const [keywords, setKeywords] = useState([]);
  const [keywordToAdd, setKeywordToAdd] = useState(undefined);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      await loadKeywords();
    }
    load();
  }, []);

  function addNewKeyword() {
    ApplicationState.setIgnoreKeywordToEdit(undefined);
    ApplicationState.setUserToAddIgnoreKeywordFor(ApplicationState.getState().user);
    setOpenAddEditDialog(true);
  }

  function editKeyword(keywordToEdit) {
    ApplicationState.setIgnoreKeywordToEdit(keywordToEdit);
    ApplicationState.setUserToAddIgnoreKeywordFor(undefined);
    setOpenAddEditDialog(true);
  }

  async function deleteKeyword(keywordToDelete) {
    await ApplicationState.deleteIgnoreKeyword(keywordToDelete.id);
    let keywords2 = keywords.slice();
    const index = keywords2.indexOf(keywordToDelete);
    if (index > -1) {
      keywords2.splice(index, 1);
    }
    setKeywords(keywords2);
  }

  function addKeywords(scope) {
    return keywords
      .filter(x => x.scope === scope)
      .sort((a, b) => (a.keyword > b.keyword ? 1 : -1))
      .map((element) => {
        return addExistingKeyword(element);
      });
  }

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Ignore Keywords</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <PrimaryButton
            icon="add"
            text="Add keyword"
            buttonClick={() =>
              addNewKeyword()
            }
          ></PrimaryButton>
        </Grid>
      </Box>
    );
  }

  function addExistingKeyword(k) {
    return (
      <Chip
        clickable={true}
        style={{
          backgroundColor: "#93264A",
          color: "#fff",
          margin: "8px",
          fontSize: "larger",
          padding: "4px",
          height: "42px",
          "&:hover": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
          "&:focus": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
        }}
        onClick={() => editKeyword(k)}
        onDelete={() => deleteKeyword(k)}
        label={<b style={{ fontWeight: "400" }}>{k.keyword}</b>}
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };

  const deletedKeyword = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };
  const editedKeyword = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };
  const addedKeyword = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };

  const loadKeywords = async () => {
    let keywordsForUser = await ApplicationState.getIgnoreKeywordsForUser(
      ApplicationState.getState().user.email
    );
    setKeywords(keywordsForUser);
  };

  return (
    
      <Grid>
        
          
            <Box p={0}>
              <Box>
                <Grid
                  container
                  alignItems="stretch"
                  justifyContent="space-between"
                  direction="row"
                ></Grid>
                {addHeader()}
                <ListItem
                  key={ApplicationState.generateGuid()}
                  style={{backgroundColor: "#f6f6f6"}}
                >
                  <ListItemText primary={<React.Fragment><Typography variant="h6" style={{margin: "8px", marginBottom: "0px", paddingLeft: "4px"}}>Word</Typography><Typography variant="subtitle1" style={{margin: "8px", marginBottom: "0px", marginTop: "0px", paddingLeft: "4px"}}>Include the event in the time report generation but ignore the keyword in the text</Typography></React.Fragment>} secondary={addKeywords("word")} />
                </ListItem>
                <ListItem
                  key={ApplicationState.generateGuid()}
                  style={{backgroundColor: "#fff"}}
                >
                  <ListItemText primary={<React.Fragment><Typography variant="h6" style={{margin: "8px", marginBottom: "0px", paddingLeft: "4px"}}>Event</Typography><Typography variant="subtitle1" style={{margin: "8px", marginBottom: "0px", marginTop: "0px", paddingLeft: "4px"}}>Ignore the entire event if the title contains the keyword</Typography></React.Fragment>} secondary={addKeywords("event")} />
                </ListItem>
              </Box>
        
          
        </Box>
        <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <IgnoreKeywordEditView
            cancelClick={handleCloseAddEditDialog}
            addedKeyword={addedKeyword}
            editedKeyword={editedKeyword}
            deletedKeyword={deletedKeyword}
            ></IgnoreKeywordEditView>
        </Dialog>
      </Grid>
    
  );
}

export default IgnoreKeywordsView;

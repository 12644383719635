import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Dialog, IconButton, Paper, Select } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { PrimaryButton } from "./PrimaryButton";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { StyledDataGrid } from "./StyledDataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UserEditView from "./UserEditView";
import { Folder } from "@mui/icons-material";
import UserProjectSelectionDto from "../Dtos/UserProjectSelectionDto";
import UserGroupSelectionDto from "../Dtos/UserInGroupDto";

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function UserView() {
  let history = useHistory();
  const [user, setUser] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsForOrganization, setProjectsForOrganization] = useState([]);
  const [projectsForSelection, setProjectsForSelection] = useState([]);
  const [projectToAdd, setProjectToAdd] = useState(undefined);
  const [targetWorkingTime, setTargetWorkingTime] = useState(undefined);
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [groupsForOrganization, setGroupsForOrganization] = useState([]);
  const [groupsForUser, setGroupsForUser] = useState([]);
  const [groupsForSelection, setGroupsForSelection] = useState([]);
  const [groupToAdd, setGroupToAdd] = useState(undefined);
  const [timeReports, setTimeReports] = useState([]);
  const { id } = useParams();

  const projectColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "client",
      headerName: "Client",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "foo",
      headerName: "",
      maxWidth: 30,
      editable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            editProjectClick(params);
            }}
        >
          <DeleteForeverIcon />
          </IconButton>
          </Grid>
          );
    
      }
    },
  ];

  const groupColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "foo",
      headerName: "",
      maxWidth: 30,
      editable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            editGroupClick(params);
            }}
        >
          <DeleteForeverIcon />
          </IconButton>
          </Grid>
          );
    
      }
    },
  ];
    

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      loadUserInfo();
    };
    load1();
  }, []);

  async function loadUserInfo() {
    let u = await ApplicationState.getUserById(id);
    setUser(u);

    let targetTime = await ApplicationState.getUserTargetWorkingTime(u.email);
    setTargetWorkingTime(targetTime);

    let allProjects = await ApplicationState.getProjectsForOrganization(u.organization.organizationId);
    setProjectsForOrganization(allProjects);

    let projectsForUser = await ApplicationState.getProjectsForUser(id);
    projectsForUser.sort((a, b) => a.name.localeCompare(b.name));
    setProjects(projectsForUser);

    let projectsNotInUserSelection = allProjects.filter(
      (orgProject) => !projectsForUser.some((userProject) => userProject.id === orgProject.id)
    );
    projectsNotInUserSelection.sort((a, b) => a.name.localeCompare(b.name));
    setProjectsForSelection(projectsNotInUserSelection);

    let allGroups = await ApplicationState.getGroupsForOrganization(u.organization.organizationId);
    setGroupsForOrganization(allGroups);

    let groupsForUser = await ApplicationState.getGroupsForUser(id);
    groupsForUser.sort((a, b) => a.name.localeCompare(b.name));
    setGroupsForUser(groupsForUser);

    let groupsNotInUserSelection = allGroups.filter(
      (orgGroup) => !groupsForUser.some((userGroup) => userGroup.id === orgGroup.id)
    );
    groupsNotInUserSelection.sort((a, b) => a.name.localeCompare(b.name));
    setGroupsForSelection(groupsNotInUserSelection);

    let sortedTimeReports = await ApplicationState.getTimeReportsForUser(u);
    sortedTimeReports = sortedTimeReports.sort((a, b) => new Date(b.from) - new Date(a.from));
    setTimeReports(sortedTimeReports);
  }

const addGroups = () => {
    if (!groupsForUser) {
      return;
    }
    return addGroupsBox();
  };

  function addGroupsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Groups

            </Typography>

            <Box>
            <Grid container justifyContent="center" alignItems="center">
              <Select
              native
              value={groupToAdd}
              onChange={(event) => {
                setGroupToAdd(groupsForOrganization.filter((group) => group.id === event.target.value)[0].id);
              }}
              inputProps={{
                name: "group",
                id: "group-native-simple",
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <option aria-label="None" value="" label="--Select group to add--" />
              {groupsForSelection.map((group) => (
                <option value={group.id}>{group.name}</option>
              ))}
            </Select>
            {addAddGroupButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid

          columns={groupColumns}
          rows={groupsForUser}
          onRowClick={groupRowClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  async function groupRowClick(params) {
    //TODO: Fix this site
    //history.push("/group2/" + params.row.id);
    //window.scrollTo(0, 0);
  }

  async function editGroupClick(params) {
    let selections = await ApplicationState.getUserInGroupForUser(id);
    let selection = selections.find((selection) => selection.group.id === params.row.id);
    await ApplicationState.deleteUserInGroup(selection.id);
    loadUserInfo();
  }

  function addAddGroupButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add group"
          buttonClick={() => addGroupClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  async function addGroupClick() {
    let group2 = groupsForSelection.find(
      (group) => group.id === groupToAdd
    );
    let selection = new UserGroupSelectionDto(
      ApplicationState.generateGuid(),
      group2,
      user,
      new Date()
    );
    await ApplicationState.saveNewUserInGroup(selection);
    loadUserInfo();
  }

  const addTimeReports = () => {
    if (!timeReports) {
      return;
    }
    return addTimeReportsBox();
  };

  function addTimeReportsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Time reports
            </Typography>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={timeReportColumns}
          rows={timeReports}
          onRowClick={timeReportRowClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  async function timeReportRowClick(params) {
    ApplicationState.setTimeReportToView(params.row);
    history.push("/timereportuser");
    window.scrollTo(0, 0);
  }

  const timeReportColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        let weeks = DateTimeHelper.getWeeksForPeriod(new Date(params.row.from), new Date(params.row.to));
        if(weeks.length > 1) {
          return "" + DateTimeHelper.getMonthName(new Date(params.row.from).getMonth()) + " " + new Date(params.row.from).getFullYear(); 
        } else{
           return "Report " + params.row.from.toLocaleDateString("sv-SE") + " to " + params.row.to.toLocaleDateString("sv-SE");
        }
      },
    },
    {
      field: "from",
      headerName: "From",
      maxWidth: 100,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return params.row.from.toLocaleDateString("sv-SE");
      },
    },
    {
      field: "to",
      headerName: "To",
      maxWidth: 100,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return params.row.to.toLocaleDateString("sv-SE");
      },
    },
    {
      field: "sent",
      headerName: "Sent",
      maxWidth: 100,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        if(params.row.sent && params.row.sent !== null){
          return new Date(params.row.sent).toLocaleDateString("sv-SE");
        } else {
          return "";
        }
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      maxWidth: 100,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        if(params.row.approved && params.row.approved !== null){
          return new Date(params.row.approved).toLocaleDateString("sv-SE");
        } else {
          return "";
        }
      },
    }
  ];



  const addProjects = () => {
    if (!projects) {
      return;
    }
    return addProjectsBox();
  };

  function addProjectsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Projects
            </Typography>
            
            <Box>
            <Grid container justifyContent="center" alignItems="center">
              <Select
              native
              value={projectToAdd}
              onChange={(event) => {
                setProjectToAdd(projectsForOrganization.filter((project) => project.id === event.target.value)[0].id);
              }}
              inputProps={{
                name: "project",
                id: "project-native-simple",
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <option aria-label="None" value="" label="--Select project to add--" />
              {projectsForSelection.map((project) => (
                <option value={project.id}>{project.name}</option>
              ))}
            </Select>
            {addAddProjectButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={projectColumns}
          rows={projects}
          onRowClick={projectRowClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  async function projectRowClick(params) {
    history.push("/project/" + params.row.id);
    window.scrollTo(0, 0);
  }

  async function editProjectClick(params) {
    let selections = await ApplicationState.getUserProjectSelectionsForUser(id);
    let selection = selections.find((selection) => selection.project.id === params.row.id);
    await ApplicationState.deleteUserProjectSelection(selection.id);
    loadUserInfo();
  }

  function addAddProjectButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add project"
          buttonClick={() => addProjectClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  async function addProjectClick() {
    let project2 = projectsForSelection.find(
      (project) => project.id === projectToAdd
    );
    let selection = new UserProjectSelectionDto(
      ApplicationState.generateGuid(),
      user,
      project2,
      new Date()
    );
    await ApplicationState.saveNewUserProjectSelection(selection);
    loadUserInfo();
  }

  

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>{addEditUserButton(user)}</Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <React.Fragment>
        <Typography variant="h4">
          <Box paddingRight={2}>
            <Typography variant="h4">User</Typography>
          </Box>
        </Typography>
      </React.Fragment>
    );
  }

  function addEditUserButton(user) {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="edit"
          text="Edit"
          buttonClick={() => editUserClick(user)}
        ></PrimaryButton>
      
        <PrimaryButton
          icon="cv"
          text={"CV"}
          buttonClick={() => {history.push("/usercv/" + id)}}
        >
        </PrimaryButton>
                  
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{
            maxHeight: "58px",
            minHeight: "58px",
            maxWidth: "58px",
            minWidth: "58px",
            marginRight: "8px",
            marginLeft: "8px",
          }}
          onClick={(event) => {
            window.open(user.linkToFolder, '_blank');
            }}
        >
          <Folder />
          </Button>
      </Box>
    );
  }

  function editUserClick(userToEdit) {
    ApplicationState.setUserToEdit(userToEdit);
    setOpenUserDialog(true);
  }

  async function editedUser() {
    loadUserInfo();
    setOpenUserDialog(false);
  }

  async function deletedUser() {
    setOpenUserDialog(false);
    history.push("/users");
  }

  function addUserInfo() {
    return (
      <Box p={2}>
              <Typography variant="h4">{user.firstName + " " + user.lastName}</Typography>
      <Typography variant="body1">{user.description}</Typography>

      <List>
        <ListItem>
          <ListItemText primary="E-mail" secondary={user.email} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Secondary e-mail" secondary={user.secondaryEmail} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Phone number" secondary={user.phoneNumber} />
        </ListItem>
        <ListItem>
        {user.firstWorkingDay && (
          <ListItemText primary="First working day" secondary={
            new Date(user.firstWorkingDay).toLocaleDateString("sv-SE")
          } />
        )}
        {!user.firstWorkingDay && (
          <ListItemText primary="First working day" secondary={
            "Not set"
          } />
        )}
        </ListItem>
      </List>

      <Typography variant="h6">Detailed Information</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Employment number" secondary={user.employmentNumber} />
        </ListItem>
        <ListItem>
          <ListItemText primary="City" secondary={user.city} />
        </ListItem>
        
      </List>

      <Typography variant="h6">Roles</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Is Admin" secondary={user.isAdmin} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Is Time Report Approver" secondary={user.isTimeReportApprover} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Is Sub contractor" secondary={user.isSubContractor} />
        </ListItem>
      </List>

      <Typography variant="h6">Time management</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Use flex time" secondary={user.useFlexTime} />
        </ListItem>
        {targetWorkingTime && !user.isSubContractor && ( 
          <React.Fragment>
        <ListItem>
          <ListItemText primary="Monday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.mondayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tuesday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.tuesdayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Wednesday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.wednesdayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Thursday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.thursdayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Friday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.fridayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Saturday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.saturdayMinutesToWork * 60)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Sunday" secondary={DateTimeHelper.getHourMinuteStringFromHours(targetWorkingTime.sundayMinutesToWork * 60)} />
        </ListItem>
        </React.Fragment>
        )}
        {user.timeReportApprover && (
        <ListItem>
          <ListItemText primary="Time report approver" secondary={user.timeReportApprover.firstName + " " + user.timeReportApprover.lastName} />
        </ListItem>
        )}
        </List>
                  {user.isSubContractor && (
                    <React.Fragment>
        <Typography variant="h6">Subcontractor info</Typography>
        <List>
        <ListItem>
          <ListItemText primary="Company" secondary={user.subContractorCompanyName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Solo" secondary={user.subContractorIsSolo ? "Yes" : "No"} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Manager name" secondary={user.subContractorManagerName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Manager E-mail" secondary={user.subContractorManagerEmail} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Manager Phone Number" secondary={user.subContractorManagerPhoneNumber} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Invoice E-mail" secondary={user.subContractorInvoiceEmail} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Giro number" secondary={user.subContractorGiroNumber} />
        </ListItem>
        </List>
        </React.Fragment>
        )}
            </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "0px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
            <Box style={{backgroundColor: "#fff"}}>{addUserInfo()}</Box>
          </Paper>
        </Box>

        <Box>{addProjects()}</Box>
        <Box>{addGroups()}</Box>
        <Box>{addTimeReports()}</Box>

        <Dialog
          open={openUserDialog}
          onClose={() => setOpenUserDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <UserEditView
            cancelClick={() => setOpenUserDialog(false)}
            editedUser={editedUser}
            deletedUser={deletedUser}
          ></UserEditView>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default UserView;

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function StatisticsView() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [autoPercentage, setAutoPercentage] = useState(0);
  const [autoTime, setAutoTime] = useState(0);
  const [autoUpdatedPercentage, setAutoUpdatedPercentage] = useState(0);
  const [autoUpdatedTime, setAutoUpdatedTime] = useState(0);
  const [autoUpdatedActivityPercentage, setAutoUpdatedActivityPercentage] =
    useState(0);
  const [autoUpdatedActivityTime, setAutoUpdatedActivityTime] = useState(0);
  const [autoUpdatedDurationPercentage, setAutoUpdatedDurationPercentage] =
    useState(0);
  const [autoUpdatedDurationTime, setAutoUpdatedDurationTime] = useState(0);
  const [manualPercentage, setManualPercentage] = useState(0);
  const [manualTime, setManualTime] = useState(0);
  const [name, setName] = useState("");

  //Kör vid uppstart
  useEffect(() => {
    setFromDate(getInitialFromDate());
    setToDate(getInitialToDate());
    const load1 = async () => {};
    load1();
  }, []);

  function addTableForTimeEntries() {
    let projectsForTimeEntries = [];
    timeEntries
      .sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((timeEntry) => {
        if (
          !projectsForTimeEntries.some(
            (foo) => foo.name === timeEntry.activity.project.name
          )
        ) {
          projectsForTimeEntries.push(timeEntry.activity.project);
        }
      });

    return (
      <CustomGrid item component={Card} xs>
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: "#d8d8d8"}}>
                  {getTableHeaderForReportData("Project")}
                  {getTableHeaderForReportData("Activity")}
                  {getTableHeaderForReportData("Date")}
                  {getTableHeaderForReportData("User")}
                  {getTableHeaderForReportData("Minutes")}
                  {getTableHeaderForReportData("Time")}
                </TableRow>
              </TableHead>
              <TableBody>{getTableRowsForTimeEntries()}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CustomGrid>
    );
  }

  function addTimeEntriesStatistics() {
    return (
      <CustomGrid
        item
        component={Card}
        xs={12}
        md={6}
      >
        <Box p={2}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Typography variant="h6">
              <Box>Automation - Time entries</Box>
            </Typography>
          </Grid>
        </Box>

        <Divider />
        <Box p={2}>
          <Typography>
            <b>Auto</b>
            {" - "}
            {autoPercentage}%{"    "}
            <i>({autoTime})</i>
          </Typography>
          <LinearProgress
            style={{ height: "24px" }}
            variant="determinate"
            value={autoPercentage}
          />
        </Box>
        <Box p={2}>
          <Typography>
            <b>Auto and updated</b>
            {" - "}
            {autoUpdatedPercentage}%{"    "}
            <i>({autoUpdatedTime})</i>
          </Typography>
          <LinearProgress
            style={{ height: "24px" }}
            variant="determinate"
            value={autoUpdatedPercentage}
          />
        </Box>
        <Box p={2}>
          <Typography>
            <b>Auto and updated activity</b>
            {" - "}
            {autoUpdatedActivityPercentage}%{"    "}
            <i>({autoUpdatedActivityTime})</i>
          </Typography>
          <LinearProgress
            style={{ height: "24px" }}
            variant="determinate"
            value={autoUpdatedActivityPercentage}
          />
        </Box>
        <Box p={2}>
          <Typography>
            <b>Auto and updated time</b>
            {" - "}
            {autoUpdatedDurationPercentage}%{"    "}
            <i>({autoUpdatedDurationTime})</i>
          </Typography>
          <LinearProgress
            style={{ height: "24px" }}
            variant="determinate"
            value={autoUpdatedDurationPercentage}
          />
        </Box>
        <Box p={2}>
          <Typography>
            <b>Manual</b>
            {" - "}
            {manualPercentage}%{"    "}
            <i>({manualTime})</i>
          </Typography>
          <LinearProgress
            style={{ height: "24px" }}
            variant="determinate"
            value={manualPercentage}
          />
        </Box>
      </CustomGrid>
    );
  }

  function getTableRowsForTimeEntries() {
    let counter = 1;
    return timeEntries.map((timeEntry) =>
      getTableRowForTimeEntry(timeEntry, counter++)
    );
  }

  function getTableRowForTimeEntry(timeEntry, rowNr) {
    if (rowNr % 2 === 0) {
      return (
        <TableRow key={timeEntry.id} style={{backgroundColor: "#f6f6f6"}}>
          <CustomTableCell align="left">
            {timeEntry.activity.project.name}
          </CustomTableCell>
          <CustomTableCell align="left">
            {timeEntry.activity.name}
          </CustomTableCell>
          <CustomTableCell align="left">
            {new Date(timeEntry.date).toLocaleDateString("sv-SE")}
          </CustomTableCell>
          <CustomTableCell align="left">
            {timeEntry.user.firstName + " " + timeEntry.user.lastName}
          </CustomTableCell>
          <CustomTableCell align="left">
            {timeEntry.durationMinutes}
          </CustomTableCell>
          <CustomTableCell align="left">
            {DateTimeHelper.getHourMinuteStringFromHours(
              ApplicationState.getDurationForTimeEntry(timeEntry)
            )}
          </CustomTableCell>
        </TableRow>
      );
    }
    return (
      <TableRow key={timeEntry.id}>
        <CustomTableCell align="left">
          {timeEntry.activity.project.name}
        </CustomTableCell>
        <CustomTableCell align="left">
          {timeEntry.activity.name}
        </CustomTableCell>
        <CustomTableCell align="left">
          {new Date(timeEntry.date).toLocaleDateString("sv-SE")}
        </CustomTableCell>
        <CustomTableCell align="left">
          {timeEntry.user.firstName + " " + timeEntry.user.lastName}
        </CustomTableCell>
        <CustomTableCell align="left">
          {timeEntry.durationMinutes}
        </CustomTableCell>
        <CustomTableCell align="left">
          {DateTimeHelper.getHourMinuteStringFromHours(
            ApplicationState.getDurationForTimeEntry(timeEntry)
          )}
        </CustomTableCell>
      </TableRow>
    );
  }
  function getTableHeaderForReportData(name) {
    return (
      <TableCell style={{padding: "8px", borderBottomWidth: "0px", fontSize: "16px"}} align="left">
        <Box style={{
          backgroundColor: "#d8d8d8",
          margin: "8px",
          padding: "4px",
          borderRadius: "8px",
          lineHeight: "1.4"}}>
          {name}
        </Box>
      </TableCell>
    );
  }

  async function showTimeEntries() {
    let te = await ApplicationState.getTimeEntriesForOrganization(
      ApplicationState.getState().user.organization.organizationId,
      new Date(fromDate).toLocaleDateString("sv-SE"),
      new Date(toDate).toLocaleDateString("sv-SE")
    );
    if (name && name.length > 0) {
      let nameFilter = name.toLowerCase();
      te = te.filter((x) =>
        (
          x.user.firstName.toLowerCase() +
          " " +
          x.user.lastName.toLowerCase()
        ).includes(nameFilter)
      );
    }
    setTimeEntries(te);

    let autoTimeEntries = te.filter(
      (x) => x.createdByDynamicTime === true && !x.updated
    );
    let autoUpdatedTimeEntries = te.filter(
      (x) => x.createdByDynamicTime === true && x.updated
    );
    let autoUpdatedActivityTimeEntries = te.filter(
      (x) => x.createdByDynamicTime === true && x.updatedActivity
    );
    let autoUpdatedDurationTimeEntries = te.filter(
      (x) => x.createdByDynamicTime === true && x.updatedDuration
    );
    let manualTimeEntries = te.filter((x) => x.createdByDynamicTime === false);

    let autoDuration = 0;
    autoTimeEntries.forEach((element) => {
      autoDuration += element.durationMinutes;
    });

    let autoUpdateDuration = 0;
    autoUpdatedTimeEntries.forEach((element) => {
      autoUpdateDuration += element.durationMinutes;
    });

    let autoUpdateActivityDuration = 0;
    autoUpdatedActivityTimeEntries.forEach((element) => {
      autoUpdateActivityDuration += element.durationMinutes;
    });

    let autoUpdateDurationDuration = 0;
    autoUpdatedDurationTimeEntries.forEach((element) => {
      autoUpdateDurationDuration += element.durationMinutes;
    });

    let manualDuration = 0;
    manualTimeEntries.forEach((element) => {
      manualDuration += element.durationMinutes;
    });

    let totalDuration = autoDuration + autoUpdateDuration + manualDuration;

    let auto = (autoDuration / totalDuration) * 100;
    auto = auto.toFixed(2);
    let autoUpdated = (autoUpdateDuration / totalDuration) * 100;
    autoUpdated = autoUpdated.toFixed(2);
    let autoUpdatedActivity =
      (autoUpdateActivityDuration / totalDuration) * 100;
    autoUpdatedActivity = autoUpdatedActivity.toFixed(2);
    let autoUpdatedDuration =
      (autoUpdateDurationDuration / totalDuration) * 100;
    autoUpdatedDuration = autoUpdatedDuration.toFixed(2);
    let manual = (manualDuration / totalDuration) * 100;
    manual = manual.toFixed(2);

    let max = auto;
    if (autoUpdated > max) {
      max = autoUpdated;
    }
    if (manual > max) {
      max = manual;
    }

    setAutoTime(DateTimeHelper.getHourMinuteStringFromHours(autoDuration * 60));
    setAutoPercentage(auto);
    setAutoUpdatedTime(
      DateTimeHelper.getHourMinuteStringFromHours(autoUpdateDuration * 60)
    );
    setAutoUpdatedPercentage(autoUpdated);

    setAutoUpdatedActivityTime(
      DateTimeHelper.getHourMinuteStringFromHours(
        autoUpdateActivityDuration * 60
      )
    );
    setAutoUpdatedActivityPercentage(autoUpdatedActivity);
    setAutoUpdatedDurationTime(
      DateTimeHelper.getHourMinuteStringFromHours(
        autoUpdateDurationDuration * 60
      )
    );
    setAutoUpdatedDurationPercentage(autoUpdatedDuration);

    setManualTime(
      DateTimeHelper.getHourMinuteStringFromHours(manualDuration * 60)
    );
    setManualPercentage(manual);
  }

  const getInitialFromDate = () => {
    let currentTime = new Date();
    currentTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    return currentTime.toLocaleDateString("sv-SE");
  };

  const getInitialToDate = () => {
    let currentTime = new Date();
    return currentTime.toLocaleDateString("sv-SE");
  };

  const addTimeEntriesForTimePeriod = () => {
    if (!timeEntries) {
      return;
    }
    return addTableForTimeEntries();
  };

  function addOverviewBox() {
    return (
      <CustomGrid
        item
        component={Card}
        xs={12}
        md={6}
      >
        <Box p={2}>
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Typography variant="h6">
              <Box>Projects</Box>
            </Typography>
          </Grid>
        </Box>

        <Divider />

        <List style={{ paddinBottom: "0px" }}>
          {ApplicationState.getTimeItemsViewFromTimeEntries(timeEntries).map(
            (element) => {
              return (
                <ListItem key={element.id} button>
                  <ListItemText key={element.id + "a"}>
                    <strong>{element.name}</strong>
                  </ListItemText>

                  <strong>
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      element.duration
                    )}
                  </strong>
                </ListItem>
              );
            }
          )}
        </List>

        <Box p={1} style={{backgroundColor: "#111", color: "#fff"}} bottom>
          <Grid container justifyContent="space-between">
            <Box p={1}>
              <Typography variant="h6">Total</Typography>
            </Box>
            <Box p={1}>
              <Typography variant="h6">
                {DateTimeHelper.getHourMinuteStringFromHours(
                  ApplicationState.getDurationForTimeEntries(timeEntries)
                )}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </CustomGrid>
    );
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Statistics</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box p={1}>
            <Box p={1} alignItems="center" justifyContent="flex-end">
              <Typography variant="h6">
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="nameTextField"
                      placeholder="Name for user"
                      type="text"
                      variant="outlined"
                      onChange={(event) => setName(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={name}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />
                    <TextField
                      id="fromTextField"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setFromDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={fromDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />
                    <TextField
                      id="toTextField"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setToDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={toDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => showTimeEntries()}
                      style={{
                        maxHeight: "56px",
                        minHeight: "56px",
                        maxWidth: "56px",
                        minWidth: "56px",
                        marginLeft: "16px",
                      }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        style={{color: "#eee"}}
                      ></SearchIcon>
                    </Button>
                  </Grid>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7} style={{paddingTop: "15px", padding: "5px", backgroundColor: "#93264A"}}>
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>
        <Grid container style={{ flexWrap: "nowrap" }}>
          {addOverviewBox()}
          {addTimeEntriesStatistics()}
        </Grid>
        <Box>{addTimeEntriesForTimePeriod()}</Box>
      </Grid>
    </Grid>
  );
}

export default StatisticsView;

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { CircularProgress, Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from '@mui/material/styles';

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function RevenueForecastView() {
  const [fromDate, setFromDate] = useState(new Date().toLocaleDateString("sv-SE"));
  const [toDate, setToDate] = useState(new Date().toLocaleDateString("sv-SE"));
  const [revenue, setRevenue] = useState(0);
  const [loading, setLoading] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      setFromDate(getInitialFromDate());
      setToDate(getInitialToDate());
    };
    load1();
  }, []);

  function addCardForRevenue() {
    return (
      <CustomGrid item component={Card} xs>
        <Box p={2}>
          {loading &&(
            <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
          >
            <CircularProgress
              size={60}
              color="primary"
              style={{ paddingLeft: "4px" }}
            ></CircularProgress>
            <Typography style={{ marginTop: "12px"}}>
              <center>
                Generating revenue forecast... This can take a few minutes
              </center>
            </Typography>
          </Grid>
          )}
          {!loading &&(
            <Grid container justifyContent="flex-end" alignItems="center"> 
            <Typography variant="h4">{revenue.toLocaleString('fr-FR')}{" SEK"}</Typography>
          </Grid>
          )}
        </Box>
      </CustomGrid>
    );
  }

  async function loadRevenueForecast() {
    setLoading(true);
    let forecast = await ApplicationState.getRevenueForecastForOrganization(
      ApplicationState.getState().user.organization.organizationId, fromDate, toDate
    );
    setRevenue(forecast);
    setLoading(false);
  }

  const getInitialFromDate = () => {
    let currentTime = new Date();
    currentTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    return currentTime.toLocaleDateString("sv-SE");
  };

  const getInitialToDate = () => {
    let currentTime = new Date();
    currentTime = DateTimeHelper.getLastDayInMonth(currentTime.getFullYear(), currentTime.getMonth());
    return currentTime.toLocaleDateString("sv-SE");
  };

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addSearchControls()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Revenue forecast</Typography>
        </Box>
      </Typography>
    );
  }
  function addSearchControls() {
    return (
      <Box p={1}>
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Box p={1}>
            <Box p={1} alignItems="center" justifyContent="flex-end">
              <Typography variant="h6">
                <Box>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <TextField
                      id="fromTextField"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setFromDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={fromDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />
                    <TextField
                      id="toTextField"
                      type="date"
                      variant="outlined"
                      onChange={(event) => setToDate(event.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={toDate}
                      style={{ backgroundColor: "white", width: "176px", margin: "8px", borderRadius: "4px" }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => loadRevenueForecast()}
                      style={{
                        maxHeight: "56px",
                        minHeight: "56px",
                        maxWidth: "56px",
                        minWidth: "56px",
                        marginLeft: "16px",
                      }}
                    >
                      <SearchIcon
                        fontSize="medium"
                        style={{color: "#eee"}}
                      ></SearchIcon>
                    </Button>
                  </Grid>
                </Box>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7} style={{paddingTop: "15px", padding: "5px", backgroundColor: "#93264A"}}>
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>
        <Box>{addCardForRevenue()}</Box>
      </Grid>
    </Grid>
  );
}

export default RevenueForecastView;

export default class MeetingDto {
  constructor(id, startTime, endTime, title, description, location, from, to, categories, userData) {
    this.id = id;
    this.startTime = startTime;
    this.endTime = endTime;
    this.title = title;
    this.description = description;
    this.location = location;
    this.from = from;
    this.to = to;
    this.categories = categories;
    this.userData = userData;
  }
}

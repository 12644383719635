import Box from "@mui/material/Box";
import { Dialog, FormControlLabel, Switch } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import UserEditView from "./UserEditView";
import { PrimaryButton } from "./PrimaryButton";
import { useHistory } from "react-router-dom";
import { StyledDataGrid } from "./StyledDataGrid";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import IconButton from '@mui/material/IconButton';
import EditIcon from "@mui/icons-material/Edit";

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
});

function UsersView() {
  const [users, setUsers] = useState([]);
  const [showOnlyActiveUsers, setShowOnlyActiveUsers] = useState(true);
  const [openUserDialog, setOpenUserDialog] = useState(false);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return params.row.firstName + " " + params.row.lastName;
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'timeReportApprover',
      headerName: 'Approver',
      maxWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        if(params.row.timeReportApprover) {
          return params.row.timeReportApprover.firstName + " " + params.row.timeReportApprover.lastName;
        }
        return "";
      }
    },
    {
      field: 'subContractorCompanyName',
      headerName: 'Company',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      minWidth: 150,
      valueGetter: (params) =>{
        if(!params.row.isSubContractor) {
          return params.row.organization.name;
        }
        return params.row.subContractorCompanyName;
      } 
    },
    {
      field: 'subContractorIsSolo',
      headerName: 'Solo',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      maxWidth: 50,
      valueGetter: (params) =>{
        if(params.row.subContractorIsSolo) {
          return "Yes";
        }
        return "No";
      }
    },
    {
      field: 'city',
      headerName: 'City',
      maxWidth: 125,
      flex: 1,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      maxWidth: 100,
      flex: 1,
      editable: false,
      valueGetter: (params) =>{
        if(params.row.isActive) {
          return "Active";
        }
        return "Inactive";
      }
    },  
    {
      field: 'userManager',
      headerName: ' ',
      description: 'This column has a value getter and is not sortable.',
      sortable: true,
      width: 95,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            editUserClick(params);
            }}
        >
          <EditIcon></EditIcon>
          </IconButton>
        {params.row.linkToFolder && (
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            window.location.href = params.row.linkToFolder;
            }}
        >
          <FolderOpenIcon></FolderOpenIcon>
          </IconButton>
          )}
          </Grid>
          );
    
      }
    }
  ];
  
  const history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      showUsers();
    };
    load1();
  }, []);

  useEffect(() => {
    const load1 = async () => {
      showUsers();
    };
    load1();
  }, [showOnlyActiveUsers]);

  const editUserClick = (params) => {
    ApplicationState.setUserToEdit(params.row);
    setOpenUserDialog(true);
  };

  const viewUserClick = (params) => {
    history.push("/user/" + params.row.userId);
  };

  async function showUsers() {
    let proj = await ApplicationState.getUsersForOrganization(
      ApplicationState.getUser().organization.organizationId,
      !showOnlyActiveUsers
    );
    proj = proj.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
    setUsers(proj);

  }

  const addBoxHeader = (text, addButtonFunction) => {
    return(
    <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              {text}
            </Typography>
            <Box>{addButtonFunction()}</Box>
          </Grid>
        </Box>
        );
    }

  const addUsers = () => {
    if (!users) {
      return;
    }
    return addUsersGrid();
  };

  function addUsersGrid() {
    return (
      <CustomGrid item component={Card} xs>
        <React.Fragment>{addBoxHeader("Users", addAddUserButton)}</React.Fragment>
        <StyledDataGrid columns={columns} rows={users} onRowClick={viewUserClick} useUserIdAsRowId={true}></StyledDataGrid>
      </CustomGrid>
    );
  }

  function addAddUserButton() {
    return (
      <Grid container justifyContent="center" justifyItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={showOnlyActiveUsers}
                  onChange={(event) => {
                    setShowOnlyActiveUsers(event.target.checked);
                  }}
                  name="onlyShowActiveUsers"
                  color="secondary"
                />
              }
              label="Only active"
              style={{color: "#fff"}}
            />
        <PrimaryButton
                icon="add"
                text="Add user"
                buttonClick={() => newUserClick()}
              >
        </PrimaryButton>
      </Grid>
    );
  }

  function newUserClick() {
    ApplicationState.setUserToEdit(undefined);
    ApplicationState.setOrganizationForUserToAdd(ApplicationState.getUser().organization);
    setOpenUserDialog(true);
  }

  async function addedUser() {
    showUsers();
    setOpenUserDialog(false);
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                  <Box>{addUsers()}</Box>
        <br />
        <Dialog
            open={openUserDialog}
            onClose={() => setOpenUserDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <UserEditView
              addedUser={addedUser}
              editedUser={addedUser}
              cancelClick={() => setOpenUserDialog(false)}
              deletedUser={addedUser}
            ></UserEditView>
          </Dialog>
      </Grid>
    </Grid>
  );
}

export default UsersView;

import Box from "@mui/material/Box";
import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SkillsView from "./SkillsView";
import SkillsOverviewView from "./SkillsOverviewView";
import Diversity2Icon from '@mui/icons-material/Diversity2';
import SchoolIcon from '@mui/icons-material/School';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';



function SkillsPanelView() {
  const [value, setValue] = React.useState("2");

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#ddd",
  });

  const CustomTabPanel = styled(TabPanel)({
    padding: "0px",
  });


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7}>
            <Box p={0}>
              <TabContext value={value}>
                <AppBar position="static">
                  <TabList
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    style={{backgroundColor: "#781938"}}
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <CustomTab color="" label="Overview" value="1" icon={<SchoolIcon />} /> 
                    <CustomTab label="Skills" value="2" icon={<Diversity2Icon />} />
                    <CustomTab label="CVs" value="3" icon={<WorkHistoryOutlinedIcon />} />
                  </TabList>
                </AppBar>
                <CustomTabPanel value="1">
                  <SkillsOverviewView />
                </CustomTabPanel>
                <CustomTabPanel value="2">
                  <SkillsView />
                </CustomTabPanel>
                <CustomTabPanel value="3">
                  <Box padding={2}>
                    <Typography variant="h5">Comming soon!</Typography>
                  </Box>
                </CustomTabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SkillsPanelView;

import Box from "@mui/material/Box";
import { Dialog, Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import AssignmentCrudView from "./AssignmentCrudView";
import { PrimaryButton } from "./PrimaryButton";
import { useParams } from "react-router-dom";
import { StyledDataGrid } from "./StyledDataGrid";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EducationCrudView from "./EducationCrudView";
import CourseCrudView from "./CourseCrudView";
import CertificateCrudView from "./CertificateCrudView";

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function UserCvView() {
  const [user, setUser] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [skillExperience, setSkillExperience] = useState([]);
  const [skillOverview, setSkillOverview] = useState([]);
  const [educations, setEducations] = useState([]);
  const [courses, setCourses] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [openAssignmentDialog, setOpenAssignmentDialog] = useState(false);
  const [openEducationDialog, setOpenEducationDialog] = useState(false);
  const [openCourseDialog, setOpenCourseDialog] = useState(false);
  const [openCertificateDialog, setOpenCertificateDialog] = useState(false);

  const { id } = useParams();

  const columns = [
    {
      field: "skillName",
      headerName: "Skill",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.skill.name,
    },
    {
      field: "skillCategory",
      headerName: "Category",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.skill.category,
    },
    {
      field: "years",
      headerName: "Years",
      width: 150,
      editable: false,
      valueGetter: (params) => params.row.years,
    },
    {
      field: "months",
      headerName: "Months",
      width: 150,
      editable: false,
      valueGetter: (params) => params.row.months,
    },
  ];

  const educationColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "school",
      headerName: "School",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "startYear",
      headerName: "Start",
      width: 150,
      editable: false,
    },
    {
      field: "endYear",
      headerName: "End",
      width: 150,
      editable: false,
    },
  ];

  const courseColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "school",
      headerName: "School",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "startYear",
      headerName: "Start",
      width: 150,
      editable: false,
    },
  ];

  const certificateColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "issuer",
      headerName: "Issuer",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "startYear",
      headerName: "Start",
      width: 150,
      editable: false,
    },
    {
      field: "endYear",
      headerName: "End",
      width: 150,
      editable: false,
    },
    {
      field: "referenceLink",
      headerName: "Link",
      width: 150,
      editable: false,
    },
  ];

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      showAssignments();
      showSkillExperience();
    };
    load1();
  }, []);

  function addAssignmentsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Assignments
            </Typography>
            <Box>{addAddAssignmentButton()}</Box>
          </Grid>
        </Box>
        <Box p={0}>{getTableRowsForAssignments()}</Box>
      </CustomGrid>
    );
  }

  function getTableRowsForAssignments() {
    let counter = 1;
    return assignments.map((assignment) =>
      getTableRowForAssignment(assignment, counter++)
    );
  }

  function getTableRowForAssignment(assignment, rowNr) {
    let bgColor = "#fff";
    if (rowNr % 2 === 0) {
      bgColor = "#f6f6f6";
    }

    return (
      <Box
        key={assignment.id}
        style={{ backgroundColor: bgColor, padding: "16px" }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5">{assignment.title}</Typography>
            <Typography variant="h6">{assignment.client}</Typography>
            <Typography variant="h6" style={{ fontWeight: "400" }}>
              {DateTimeHelper.getMonthName(
                new Date(assignment.startDate).getMonth()
              )}{" "}
              {new Date(assignment.startDate).getFullYear()}
              {assignment.endDate && (
                <React.Fragment>
                  {" - "}
                  {DateTimeHelper.getMonthName(
                    new Date(assignment.endDate).getMonth()
                  )}{" "}
                  {new Date(assignment.endDate).getFullYear()}
                </React.Fragment>
              )}
              {!assignment.endDate && (
                <React.Fragment>{" - Ongoing"}</React.Fragment>
              )}
            </Typography>
            <br />
            <Typography variant="p">{assignment.description}</Typography>
            <br />
            <br />
            {addSkillsForAssignment(assignment.id)}
          </Box>
          <Box>{addEditAssignmentButton(assignment)}</Box>
        </Grid>
      </Box>
    );
  }

  function addSkillsForAssignment(id) {
    return skillExperience
      .filter((x) => x.assignment.id === id)
      .sort((a, b) => (a.skill.name > b.skill.name ? 1 : -1))
      .map((element) => {
        return addSkillExperienceChip(element);
      });
  }

  async function showAssignments() {
    let users = await ApplicationState.getUsersForOrganization(
      ApplicationState.getUser().organization.organizationId
    );
    let u = users.find((x) => x.userId === id);
    setUser(u);

    let ass = await ApplicationState.getAssignmentsForUser(id);
    ass = ass.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
    setAssignments(ass);

    let skillExperience = await ApplicationState.getSkillExperienceForUser(id);
    setSkillExperience(skillExperience);

    let skillOverview =
      await ApplicationState.getSkillExperienceOverviewForUser(id);
    setSkillOverview(skillOverview);

    let edu = await ApplicationState.getEducationsForUser(id);
    edu = edu.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));
    setEducations(edu);

    let cr = await ApplicationState.getCoursesForUser(id);
    cr = cr.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));
    setCourses(cr);

    let crt = await ApplicationState.getCertificatesForUser(id);
    crt = crt.sort((a, b) => (a.startYear < b.startYear ? 1 : -1));
    setCertificates(crt);
  }

  async function showSkillExperience() {
    // TODO: FIx for user
  }

  const addSkillExperience = () => {
    if (!skillExperience) {
      return;
    }
    return addSkillExperienceBox();
  };

  function addSkillExperienceBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Skills
            </Typography>
            <Box>{addAddSkillButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid columns={columns} rows={skillOverview}></StyledDataGrid>
      </CustomGrid>
    );
  }

  const addCertificates = () => {
    if (!certificates) {
      return;
    }
    return addCertificatesBox();
  };

  function addCertificatesBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Certificates
            </Typography>
            <Box>{addAddCertificateButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={certificateColumns}
          rows={certificates}
          onRowClick={editCertificateClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editCertificateClick(params) {
    ApplicationState.setCertificateToEdit(params.row);
    ApplicationState.setUserToAddCertificateFor(undefined);
    setOpenCertificateDialog(true);
  }

  const addCourses = () => {
    if (!courses) {
      return;
    }
    return addCoursesBox();
  };

  function addCoursesBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Courses
            </Typography>
            <Box>{addAddCourseButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={courseColumns}
          rows={courses}
          onRowClick={editCourseClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editCourseClick(params) {
    ApplicationState.setCourseToEdit(params.row);
    ApplicationState.setUserToAddCourseFor(undefined);
    setOpenCourseDialog(true);
  }

  const addEducations = () => {
    if (!educations) {
      return;
    }
    return addEducationsBox();
  };

  function addEducationsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Educations
            </Typography>
            <Box>{addAddEducationButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={educationColumns}
          rows={educations}
          onRowClick={editEducationClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editEducationClick(params) {
    ApplicationState.setEducationToEdit(params.row);
    ApplicationState.setUserToAddEducationFor(undefined);
    setOpenEducationDialog(true);
  }

  const addAssignments = () => {
    if (!assignments) {
      return;
    }
    return addAssignmentsBox();
  };

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <React.Fragment>
      <Typography variant="h4">
        <Box paddingRight={2}>
          <Typography variant="h4">CV - {user.firstName}{" "}{user.lastName}</Typography>
        </Box>
      </Typography>
      </React.Fragment>
    );
  }

  function addAddCourseButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add course"
          buttonClick={() => addCourseClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addAddSkillButton() {
    return (
      <Box p={1} style={{visibility: "hidden"}}>
        <PrimaryButton
          icon="add"
          text="Add skill"
        ></PrimaryButton>
      </Box>
    );
  }

  function addCourseClick() {
    ApplicationState.setCourseToEdit(undefined);
    ApplicationState.setUserToAddCourseFor(user);
    setOpenCourseDialog(true);
  }

  function addAddCertificateButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add certificate"
          buttonClick={() => addCertificateClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addCertificateClick() {
    ApplicationState.setCertificateToEdit(undefined);
    ApplicationState.setUserToAddCertificateFor(user);
    setOpenCertificateDialog(true);
  }

  function addAddEducationButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add education"
          buttonClick={() => addEducationClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addEducationClick() {
    ApplicationState.setEducationToEdit(undefined);
    ApplicationState.setUserToAddEducationFor(user);
    setOpenEducationDialog(true);
  }

  function addAddAssignmentButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add assignment"
          buttonClick={() => addAssignmentClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addAssignmentClick() {
    ApplicationState.setAssignmentToEdit(undefined);
    ApplicationState.setUserToAddAssignmentFor(user);
    setOpenAssignmentDialog(true);
  }

  function addEditAssignmentButton(assignment) {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="edit"
          text="Edit"
          buttonClick={() => editAssignmentClick(assignment)}
        ></PrimaryButton>
      </Box>
    );
  }

  function editAssignmentClick(assignment) {
    ApplicationState.setAssignmentToEdit(assignment);
    ApplicationState.setUserToAddAssignmentFor(undefined);
    setOpenAssignmentDialog(true);
  }

  async function editedAssignment() {
    showAssignments();
    setOpenAssignmentDialog(false);
  }

  async function addedAssignment() {
    showAssignments();
    setOpenAssignmentDialog(false);
  }

  async function deletedAssignment() {
    showAssignments();
    setOpenAssignmentDialog(false);
  }

  async function addedEducation() {
    showAssignments();
    setOpenEducationDialog(false);
  }

  async function editedEducation() {
    showAssignments();
    setOpenEducationDialog(false);
  }

  async function deletedEducation() {
    showAssignments();
    setOpenEducationDialog(false);
  }

  async function addedCourse() {
    showAssignments();
    setOpenCourseDialog(false);
  }

  async function editedCourse() {
    showAssignments();
    setOpenCourseDialog(false);
  }

  async function deletedCourse() {
    showAssignments();
    setOpenCourseDialog(false);
  }

  async function addedCertificate() {
    showAssignments();
    setOpenCertificateDialog(false);
  }

  async function editedCertificate() {
    showAssignments();
    setOpenCertificateDialog(false);
  }

  async function deletedCertificate() {
    showAssignments();
    setOpenCertificateDialog(false);
  }

  function addSkillExperienceChip(se) {
    return (
      <Chip
        clickable={true}
        style={{
          backgroundColor: "#93264A",
          color: "#fff",
          margin: "8px",
          fontSize: "larger",
          padding: "4px",
          height: "42px",
          "&:hover": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
          "&:focus": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
        }}
        onDelete={() => deleteKeyword(se)}
        label={<b style={{ fontWeight: "400" }}>{se.skill.name}</b>}
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  async function deleteKeyword(skillExperienceToDelete) {
    await ApplicationState.deleteSkillExperienceInAssignment(
      skillExperienceToDelete.id
    );
    let keywords2 = skillExperience.slice();
    const index = keywords2.indexOf(skillExperienceToDelete);
    if (index > -1) {
      keywords2.splice(index, 1);
    }
    setSkillExperience(keywords2);
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      {/* //   <Paper elevation={8}> */}
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "5px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>
        
        <Box>{addAssignments()}</Box>
        <Box>{addEducations()}</Box>
        <Box>{addCourses()}</Box>
        <Box>{addCertificates()}</Box>
        <Box>{addSkillExperience()}</Box>

        <Dialog
          open={openAssignmentDialog}
          onClose={() => setOpenAssignmentDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <AssignmentCrudView
            deletedAssignment={deletedAssignment}
            addedAssignment={addedAssignment}
            editedAssignment={editedAssignment}
            cancelClick={() => setOpenAssignmentDialog(false)}
          ></AssignmentCrudView>
        </Dialog>
        <Dialog
          open={openEducationDialog}
          onClose={() => setOpenEducationDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <EducationCrudView
            deletedEducation={deletedEducation}
            addedEducation={addedEducation}
            editedEducation={editedEducation}
            cancelClick={() => setOpenEducationDialog(false)}
          ></EducationCrudView>
        </Dialog>

        <Dialog
          open={openCourseDialog}
          onClose={() => setOpenCourseDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <CourseCrudView
            deletedCourse={deletedCourse}
            addedCourse={addedCourse}
            editedCourse={editedCourse}
            cancelClick={() => setOpenCourseDialog(false)}
          ></CourseCrudView>
        </Dialog>

        <Dialog
          open={openCertificateDialog}
          onClose={() => setOpenCertificateDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <CertificateCrudView
            deletedCertificate={deletedCertificate}
            addedCertificate={addedCertificate}
            editedCertificate={editedCertificate}
            cancelClick={() => setOpenCertificateDialog(false)}
          ></CertificateCrudView>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default UserCvView;

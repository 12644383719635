import MicrosoftLogin from "react-microsoft-login";
import ApplicationState from "../State/ApplicationState";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

const CLIENT_ID = "430fcbb2-babd-46c5-961e-ed4c4f51aac9";

export default function MicrosoftLoginButton(props) {
  let history = useHistory();
  const authHandler = (err, data, msal) => {
    if (err) {
      alert("Failed to log in!" + err);
    } else {
      login(data);
    }
  };

  async function login(data) {
    let userInfoDecoded = jwt_decode(data.idToken.rawIdToken);
    if (userInfoDecoded && data.userPrincipalName) {
      await ApplicationState.loadConfig();
      let result = await ApplicationState.loadUser(
        data.userPrincipalName,
        "Microsoft",
        data.idToken.rawIdToken
      );
      if (typeof result === "string") {
        alert(result);
        return;
      }
      let user = await ApplicationState.getState().user;
      if (user === "No user found!") {
        alert("No user found!");
        return;
      }
      await ApplicationState.loadDataForUser();
      props.doLoginFunction();
      if(user.onboardingCompleted) {
        history.push("/timereport");
      }
      if(!user.onboardingCompleted) {
        history.push("/onboarding");
      }
    }
  }

  return (
    <MicrosoftLogin
      clientId={CLIENT_ID}
      authCallback={authHandler}
      buttonTheme="light"
      withUserData="true"
      prompt="select_account"
    >
 
    </MicrosoftLogin>
  );
}

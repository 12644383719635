export default class ProjectToDoTaskDto {
  constructor(id, project, title, description, completed, created) {
    this.id = id;
    this.project = project;
    this.title = title;
    this.description = description;
    this.completed = completed;
    this.created = created;
  }
}

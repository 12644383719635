import TimeItemViewDto from "../Dtos/TimeItemViewDto";
import TimeReportWeek from "../Dtos/TimeReportWeek";
import TimeReportDay from "../Dtos/TimeReportDay";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import DateTimeHelper from "../Utils/DateTimeHelper";
import TimeReportFilterDto from "../Dtos/TimeReportFilterDto";
import ExporterDto from "../Dtos/ExporterDto";
import TimeReportsFilterDto from "../Dtos/TimeReportsFilterDto";
import jwt_decode from 'jwt-decode';

export const StateKey = "State";

class ApplicationState {
  static storeState(state) {
    sessionStorage.setItem(StateKey, JSON.stringify(state));
  }

  static hasState() {
    let state = sessionStorage.getItem(StateKey);
    return state !== undefined && state !== null;
  }

  static getState() {
    let value = sessionStorage.getItem(StateKey);
    if (value === undefined || value === null) {
      throw Error("Failed to load state from session storage");
    }
    try {
      var object = JSON.parse(value);
      return object;
    } catch (e) {
      throw Error("Failed to parse stored state: " + e);
    }
  }

  static async loadConfig() {
    // load config from config.json in public folder.
    console.log("Loaded config");
    let result = await fetch(process.env.PUBLIC_URL + "/config.json");
    let text = await result.text();
    if (!text) {
      throw Error("Failed to load config");
    }
    let config = JSON.parse(text);
    let state = ApplicationState.getState();
    state.config = config;
    ApplicationState.storeState(state);
  }

  static isAdmin() {
    let state = ApplicationState.getState();

    let token = this.getToken();
    let decodedToken = jwt_decode(token);

    if (state.user && state.user.isAdmin && decodedToken.role && decodedToken.role.includes("admin")) {
      return true;
    }
    return false;
  }

  static isTimeReportApprover() {
    let state = ApplicationState.getState();

    let token = this.getToken();
    let decodedToken = jwt_decode(token);

    if (state.user && state.user.isTimeReportApprover && decodedToken.role && decodedToken.role.includes("timeReportApprover")) {
      return true;
    }
    return false;
  }

  static isSubContractor() {
    let state = ApplicationState.getState();

    let token = this.getToken();
    let decodedToken = jwt_decode(token);

    if (state.user && state.user.isSubContractor && decodedToken.role && decodedToken.role.includes("subContractor")) {
      return true;
    }
    return false;
  }

  static viewMenuItem() {
    let state = ApplicationState.getState();
    if (!state.user) {
      return false;
    }
    if (!state.user.organization) {
      return false;
    }
    return true;
  }

  static async loadDataForUser() {
    let state = ApplicationState.getState();
    if (!state.user) {
      throw Error("Failed to load user from stored state");
    }
    state.projects = await ApplicationState.dataService.getProjectsForUser(
      state.user.userId
    );
    state.activeProjects = await ApplicationState.dataService.getActiveProjectsForUser(
      state.user.userId
    );
    state.activities = await this.dataService.getActivitiesForUser(
      state.user.userId
    );
    var holidays = await this.getHolidaysForOrganization(
      state.user.organization.organizationId,
      new Date().getFullYear()
    );
    state.holidays = holidays;
    state.organizationSettings = await this.dataService.getOrganizationSettingsForOrganization(
      state.user.organization.organizationId
    );
    state.userSettings = await this.dataService.getUserSettingsForUser(
      state.user.email
    );

    let types = [];
    let scopes = await this.getUserDataServiceScopeSelectionsForUser(
      state.user.email
    );
    
    if(scopes.some((x) => x.scope.name === "Gmail" || x.scope.name === "Outlook Email")){
      types.push("email");
    }
    if(scopes.some((x) => x.scope.name === "Google Calendar" || x.scope.name === "Outlook Calendar")){
      types.push("meeting");
    }
    if(scopes.some((x) => x.scope.name === "Google Drive" || x.scope.name === "OneDrive" )){
      types.push("file");
    }
    if(scopes.some((x) => x.scope.name === "Google Tasks" || x.scope.name === "To Do Tasks" || x.scope.name === "Planner Tasks")){
      types.push("task");
    }
    if(scopes.some((x) => x.scope.name === "Work Items" )){
      types.push("ticket");
    }
    state.userDataItemTypes = types;

    ApplicationState.storeState(state);

    await ApplicationState.loadTimeReportsForUser();

    let today = new Date();
    var weeksForYear = DateTimeHelper.printAllDatesAndWeeks(
      today.getFullYear()
    );
    let filter = new TimeReportFilterDto(
      today.getFullYear(),
      today.getMonth(),
      DateTimeHelper.getWeekNumber(today),
      weeksForYear
    );
    var maxTimeReport = await ApplicationState.getTimeReportToShowAfterLogin();
    if(maxTimeReport){
      filter.year = new Date(maxTimeReport.from).getFullYear();
      filter.month = new Date(maxTimeReport.from).getMonth();
      filter.weekNr = DateTimeHelper.getWeekNumber(new Date(maxTimeReport.from));
      if(new Date(maxTimeReport.from).getFullYear() != today.getFullYear()){
        filter.weeksForYear = DateTimeHelper.printAllDatesAndWeeks(new Date(maxTimeReport.from).getFullYear());
      }
      if(new Date(maxTimeReport.from).getMonth() === today.getMonth() && ApplicationState.getReportingInterval() === "Month"){
        filter.weekNr = DateTimeHelper.getWeekNumber(new Date(today));
      }
      filter.timeReportId = maxTimeReport.id;
    }
    ApplicationState.setTimeReportFilter(filter);
    
    var filterForTimeReports = new TimeReportsFilterDto(  
      today.getFullYear(),
      today.getMonth(),
      DateTimeHelper.getWeekNumber(today),
      weeksForYear);
    if(!ApplicationState.getTimeReportsFilter()){
      ApplicationState.setTimeReportsFilter(filterForTimeReports);
    }
  }

  static async loadTimeEntriesForUser(from, to) {
    let state = ApplicationState.getState();
    let user = state.user;
    let timeEntries = await ApplicationState.dataService.getTimeEntriesForUser(
      user.email,
      from,
      to
    );
    state.timeEntries = timeEntries;
    ApplicationState.storeState(state);
    this.setTimeEntriesForTimeReport(timeEntries);
  }

  static async loadTimeReportsForUser() {
    let timeReport = await this.dataService.getTimeReportsForUser(
      this.getState().user.email
    );
    let state = this.getState();
    state.timeReports = timeReport;
    this.storeState(state);
  }

  static async getTimeReportToShowAfterLogin() {
    if(ApplicationState.getState().timeReports){
      let sentTimeReports = ApplicationState.getState().timeReports.filter(x => x.sent).sort((a, b) => (a.from < b.from ? 1 : -1));
      let unSentTimeReports = ApplicationState.getState().timeReports.filter(x => !x.sent).sort((a, b) => (a.from > b.from ? 1 : -1));
      if (unSentTimeReports.length > 0) {
        return unSentTimeReports[0];
      } else if (sentTimeReports.length > 0 ){
        return sentTimeReports[0];
      }
    }
    return undefined;
  }

  static getTimeEntryToEdit() {
    return this.getState().timeEntryToEdit;
  }

  static setTimeEntryToEdit(timeEntryToEdit) {
    let state = this.getState();
    state.timeEntryToEdit = timeEntryToEdit;
    this.storeState(state);
  }
  
  static getTimeEntryToAddKeywordsFor() {
    return this.getState().timeEntryToAddKeywordsFor;
  }

  static setTimeEntryToAddKeywordsFor(timeEntryToAddKeywordsFor) {
    let state = this.getState();
    state.timeEntryToAddKeywordsFor = timeEntryToAddKeywordsFor;
    this.storeState(state);
  }

  static getUserDataItemTypes() {
    return this.getState().userDataItemTypes;
  }

  static getUserDataItemToAddTimeEntryFor() {
    return this.getState().userDataItemDtoToAddTimeEntryFor;
  }

  static setUserDataItemToAddTimeEntryFor(userDataItemDtoToAddTimeEntryFor) {
    let state = this.getState();
    state.userDataItemDtoToAddTimeEntryFor = userDataItemDtoToAddTimeEntryFor;
    this.storeState(state);
  }

  static getStandardTimeToEdit() {
    return this.getState().standardTimeToEdit;
  }

  static setStandardTimeToEdit(standardTime) {
    let state = this.getState();
    state.standardTimeToEdit = standardTime;
    this.storeState(state);
  }

  static getProjectToView() {
    return this.getState().projectToView;
  }

  static setProjectToView(projectToView) {
    let state = this.getState();
    state.projectToView = projectToView;
    this.storeState(state);
  }

  static getProjectToEdit() {
    return this.getState().projectToEdit;
  }

  static setProjectToEdit(projectToEdit) {
    let state = this.getState();
    state.projectToEdit = projectToEdit;
    this.storeState(state);
  }

  
  static getGroupToView() {
    return this.getState().groupToView;
  }

  static setGroupToView(groupToView) {
    let state = this.getState();
    state.groupToView = groupToView;
    this.storeState(state);
  }

  static getGroupToEdit() {
    return this.getState().groupToEdit;
  }

  static setGroupToEdit(groupToEdit) {
    let state = this.getState();
    state.groupToEdit = groupToEdit;
    this.storeState(state);
  }

  static getHolidayToEdit() {
    return this.getState().holidayToEdit;
  }

  static setHolidayToEdit(holidayToEdit) {
    let state = this.getState();
    state.holidayToEdit = holidayToEdit;
    this.storeState(state);
  }

  static getActivityToEdit() {
    return this.getState().activityToEdit;
  }

  static setActivityToEdit(activityToEdit) {
    let state = this.getState();
    state.activityToEdit = activityToEdit;
    this.storeState(state);
  }

  static getDefaultActivityToEdit() {
    return this.getState().defaultActivityToEdit;
  }

  static setDefaultActivityToEdit(defaultActivityToEdit) {
    let state = this.getState();
    state.defaultActivityToEdit = defaultActivityToEdit;
    this.storeState(state);
  }

  static getActivityToView() {
    return this.getState().activityToView;
  }

  static setActivityToView(activityToView) {
    let state = this.getState();
    state.activityToView = activityToView;
    this.storeState(state);
  }

  static getProjectForActivityToAdd() {
    return this.getState().projectForActivityToAdd;
  }

  static setProjectForActivityToAdd(projectForActivityToAdd) {
    let state = this.getState();
    state.projectForActivityToAdd = projectForActivityToAdd;
    this.storeState(state);
  }

  static getHolidayToView() {
    return this.getState().holidayToView;
  }

  static setHolidayToView(holidayToView) {
    let state = this.getState();
    state.holidayToView = holidayToView;
    this.storeState(state);
  }

  static getUserToView() {
    return this.getState().userToView;
  }

  static setUserToView(userToView) {
    let state = this.getState();
    state.userToView = userToView;
    this.storeState(state);
  }

  static getUserToEdit() {
    return this.getState().userToEdit;
  }

  static setUserToEdit(userToEdit) {
    let state = this.getState();
    state.userToEdit = userToEdit;
    this.storeState(state);
  }

  static getOrganizationForUserToAdd() {
    return this.getState().organizationForUserToAdd;
  }

  static setOrganizationForUserToAdd(organizationForUserToAdd) {
    let state = this.getState();
    state.organizationForUserToAdd = organizationForUserToAdd;
    this.storeState(state);
  }

  static getUserToAddActivityKeywordFor() {
    return this.getState().userToAddActivityKeywordFor;
  }

  static setUserToAddActivityKeywordFor(userToAddActivityKeywordFor) {
    let state = this.getState();
    state.userToAddActivityKeywordFor = userToAddActivityKeywordFor;
    this.storeState(state);
  }

  static getKeywordToAddActivityKeywordFor() {
    return this.getState().keywordToAddActivityKeywordFor;
  }

  static setKeywordToAddActivityKeywordFor(keywordToAddActivityKeywordFor) {
    let state = this.getState();
    state.keywordToAddActivityKeywordFor = keywordToAddActivityKeywordFor;
    this.storeState(state);
  }

  static getActivityKeywordToEdit() {
    return this.getState().activityKeywordToEdit;
  }

  static setActivityKeywordToEdit(activityKeywordToEdit) {
    let state = this.getState();
    state.activityKeywordToEdit = activityKeywordToEdit;
    this.storeState(state);
  }

  static getUserToAddIgnoreKeywordFor() {
    return this.getState().userToAddIgnoreKeywordFor;
  }

  static setUserToAddIgnoreKeywordFor(userToAddIgnoreKeywordFor) {
    let state = this.getState();
    state.userToAddIgnoreKeywordFor = userToAddIgnoreKeywordFor;
    this.storeState(state);
  }

  static getIgnoreKeywordToEdit() {
    return this.getState().ignoreKeywordToEdit;
  }

  static setIgnoreKeywordToEdit(ignoreKeywordToEdit) {
    let state = this.getState();
    state.ignoreKeywordToEdit = ignoreKeywordToEdit;
    this.storeState(state);
  }

  static getProjectSettingToEdit() {
    return this.getState().projectSettingToEdit;
  }

  static setProjectSettingToEdit(projectSettingToEdit) {
    let state = this.getState();
    state.projectSettingToEdit = projectSettingToEdit;
    this.storeState(state);
  }

  static getProjectToAddSettingFor() {
    return this.getState().projectToAddSettingFor;
  }

  static setProjectToAddSettingFor(projectToAddSettingFor) {
    let state = this.getState();
    state.projectToAddSettingFor = projectToAddSettingFor;
    this.storeState(state);
  }

  static getUserForProjectSettingToAdd() {
    return this.getState().userForProjectSettingToAdd;
  }

  static setUserForProjectSettingToAdd(userForProjectSettingToAdd) {
    let state = this.getState();
    state.userForProjectSettingToAdd = userForProjectSettingToAdd;
    this.storeState(state);
  }

  static getUserDataServiceToAdd() {
    return this.getState().userDataServiceToAdd;
  }

  static setUserDataServiceToAdd(userDataServiceToAdd) {
    let state = this.getState();
    state.userDataServiceToAdd = userDataServiceToAdd;
    this.storeState(state);
  }

  static getUserDataServiceScopesToAdd() {
    return this.getState().userDataServiceScopesToAdd;
  }

  static setUserDataServiceScopesToAdd(userDataServiceScopesToAdd) {
    let state = this.getState();
    state.userDataServiceScopesToAdd = userDataServiceScopesToAdd;
    this.storeState(state);
  }

  static getUserDataServiceSelectionToEdit() {
    return this.getState().userDataServiceSelectionToEdit;
  }

  static setUserDataServiceSelectionToEdit(userDataServiceSelectionToEdit) {
    let state = this.getState();
    state.userDataServiceSelectionToEdit = userDataServiceSelectionToEdit;
    this.storeState(state);
  }

  static getStandardTime() {
    return this.getState().standardTime;
  }

  static setStandardTime(standardTime) {
    let state = this.getState();
    state.standardTime = standardTime;
    this.storeState(state);
  }

  static getDateToAddTimeEntryFor() {
    return this.getState().dateToAddTimeEntryFor;
  }

  static setDateToAddTimeEntryFor(dateToAddTimeEntryFor) {
    let state = this.getState();
    state.dateToAddTimeEntryFor = dateToAddTimeEntryFor;
    this.storeState(state);
  }

  static getProjectToAddTimeEntryFor() {
    return this.getState().projectToAddTimeEntryFor;
  }

  static setProjectToAddTimeEntryFor(projectToAddTimeEntryFor) {
    let state = this.getState();
    state.projectToAddTimeEntryFor = projectToAddTimeEntryFor;
    this.storeState(state);
  }

  static getDurationForTimeEntriesNotInSummaryEntry() {
    return this.getState().durationForTimeEntriesNotInSummaryEntry;
  }

  static setDurationForTimeEntriesNotInSummaryEntry(durationForTimeEntriesNotInSummaryEntry) {
    let state = this.getState();
    state.durationForTimeEntriesNotInSummaryEntry = durationForTimeEntriesNotInSummaryEntry;
    this.storeState(state);
  }

  static getInSummaryModeTimeEntry() {
    return this.getState().inSummaryModeTimeEntry;
  }

  static setInSummaryModeTimeEntry(inSummaryModeTimeEntry) {
    let state = this.getState();
    state.inSummaryModeTimeEntry = inSummaryModeTimeEntry;
    this.storeState(state);
  }

  static getHolidays() {
    if(!this.getState().holidays){
      return [];
    }
    return this.getState().holidays;
  }

  static setHolidays(holidays) {
    let state = this.getState();
    state.holidays = holidays;
    this.storeState(state);
  }

  static getTimeEntriesForTimeReport() {
    if(!this.getState().timeEntriesForTimeReport){
      return [];
    }
    return this.getState().timeEntriesForTimeReport;
  }

  static setTimeEntriesForTimeReport(timeEntriesForTimeReport) {
    let state = this.getState();
    state.timeEntriesForTimeReport = timeEntriesForTimeReport;
    this.storeState(state);
  }

  static getTimeReportFilter() {
    if(!this.getState().timeReportFilter){
      let today = new Date();
      return new TimeReportFilterDto(today.getFullYear(),today.getMonth(),1);
    }
    return this.getState().timeReportFilter;
  }

  static setTimeReportFilter(timeReportFilter) {
    let state = this.getState();
    state.timeReportFilter = timeReportFilter;
    this.storeState(state);
  }

  static getUserProfilePictureUrl() {
    return this.getState().userProfilePictureUrl;
  }

  static setUserProfilePictureUrl(userProfilePictureUrl) {
    let state = this.getState();
    state.userProfilePictureUrl = userProfilePictureUrl;
    this.storeState(state);
  }

  static async saveNewTimeEntry(timeEntry) {
    await this.dataService.saveNewTimeEntry(timeEntry);
  }

  static async saveUpdatedTimeEntry(timeEntry) {
    await this.dataService.saveUpdatedTimeEntry(timeEntry);
  }

  static async loadUser(email, loginMethod, loginToken) {
    let userResult = await ApplicationState.dataService.loginUser(email, loginMethod, loginToken);
    let state = ApplicationState.getState();
    state.user = userResult.user;
    state.loginMethod = loginMethod;
    state.token = userResult.token;
    ApplicationState.storeState(state);
  }

  static logout() {
    this.clearSessionStorageFromMicrosoftLoginInfo();
    ApplicationState.clearState();
  }

  static clearSessionStorageFromMicrosoftLoginInfo() {
    //This is done because logout function logs user out from the account, not just only Dynamic Time
    Object.keys(sessionStorage).forEach(key => {
      if(key.startsWith("msal.") || key.startsWith('{"authority":"https://login.microsoftonline.com')){
        sessionStorage.removeItem(key);
      }
    });
  }

  static clearState() {
    sessionStorage.setItem(StateKey, JSON.stringify({}));
  }

  static getTimeItemsViewFromTimeEntries(timeEntries) {
    let timeItems = [];
    if (!timeEntries || timeEntries.length < 1) {
      return timeItems;
    }

    let projects = [];
    let activities = [];

    timeEntries.forEach((timeEntry) => {
      if(!projects.some(x => x.id === timeEntry.activity.project.id)){
        projects.push(timeEntry.activity.project);
      }
      if(!activities.some(x => x.id === timeEntry.activity.id)){
        activities.push(timeEntry.activity);
      }
    })

    timeEntries.forEach((timeEntry) => {
      let existingTimeItems = timeItems.filter(
        (timeItem) => timeItem.activity.id === timeEntry.activity.id
      );
      let duration = this.getDurationForTimeEntry(timeEntry);
      if (existingTimeItems.length > 0) {
        existingTimeItems[0].duration += duration;
      } else {
        let showActivityText = false;
        if(activities.filter(x => x.project.id === timeEntry.activity.project.id).length > 1) {
          showActivityText = true;
        }
        showActivityText = true;
        timeItems.push(
          new TimeItemViewDto(timeEntry.activity.project.name + " - " + timeEntry.activity.name, duration, timeEntry.activity.project, timeEntry.activity, showActivityText)
        );
      }
    });

    return timeItems.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  static getWeeks(timeEntries, weekNr, weekMode, year, month, weeksForYear) {
    let weeks = [];
    if (weekMode && weekNr) {
      let startDate = new Date();
      if(weeksForYear.length > 0){
        startDate = new Date(weeksForYear.find(x => x.number === weekNr).startDate);
      } 
      weeks.push(new TimeReportWeek(parseInt(weekNr), 0, startDate));
    } else {
      let weeksForMonth = DateTimeHelper.getWeeksForMonth(year, month);
      weeksForMonth = weeksForYear.filter(x => 
        (new Date(x.startDate).getFullYear() === year && new Date(x.startDate).getMonth() === month)
        ||
        (new Date(x.endDate).getFullYear() === year && new Date(x.endDate).getMonth() === month)

      );
      weeksForMonth.forEach((x) => {
        weeks.push(new TimeReportWeek(parseInt(x.number), 0, new Date(x.startDate)));
      });
    }

    timeEntries.forEach((timeEntry) => {
      let weekNumber = this.getWeekNumber(new Date(timeEntry.date));
      let existingWeeks = weeks.filter((week) => week.number === weekNumber);
      let duration = this.getDurationForTimeEntry(timeEntry);
      if (existingWeeks.length > 0) {
        existingWeeks[0].duration += duration;
      } else {
        let startDate = new Date();
        if(weeksForYear.length > 0){
          startDate = new Date(weeksForYear.find(x => x.number === weekNumber).startDate);
        }
        weeks.push(new TimeReportWeek(parseInt(weekNumber), duration, startDate));
      }
    });
    return weeks.sort((a, b) => parseInt(a.number) - parseInt(b.number));
  }

  static getDaysFromTimeEntriesInWeek(
    timeEntries,
    weekNumber,
    fromDate,
    toDate
  ) {
    let toDateWithTime = new Date(toDate);
    toDateWithTime.setDate(new Date(toDate).getDate() + 1);
    let weekTimeEntries = timeEntries.filter(x => new Date(x.date) >= new Date(fromDate) && new Date(x.date) <= new Date(toDate));
    let days = [];
    let dateToLoop = new Date(fromDate);
    while (dateToLoop <= toDate) {
      let nameToUse =
        DateTimeHelper.getDayNameFromWeekday(dateToLoop.getDay()) +
        " " +
        dateToLoop.getDate() +
        " " +
        DateTimeHelper.getMonthName(dateToLoop.getMonth());
      days.push(new TimeReportDay(dateToLoop + "", nameToUse, 0));
      dateToLoop.setDate(dateToLoop.getDate() + 1);
    }

    weekTimeEntries.forEach((weekTimeEntry) => {
      let date = new Date(weekTimeEntry.date);
      let existingDays = days.filter(
        (day) =>
          new Date(day.date).getDay() === new Date(weekTimeEntry.date).getDay()
      );
      let duration = this.getDurationForTimeEntry(weekTimeEntry);
      if (existingDays.length > 0) {
        existingDays[0].duration += duration;
      } else {

      }
    });
    return days.sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  static getDurationForTimeEntries(timeEntries) {
    if (!timeEntries || timeEntries.length <= 0) {
      return "0";
    }
    return timeEntries.reduce((a, b) => a + this.getDurationForTimeEntry(b), 0);
  }

  static getDurationForTimeEntry(timeEntry) {
    return timeEntry.durationMinutes * 60;
  }

  static getTimeEntriesForDate(timeEntries, date) {
    return timeEntries.filter(
      (timeEntry) =>
        new Date(timeEntry.date).toLocaleDateString("sv-SE") ===
        new Date(date).toLocaleDateString("sv-SE")
    );
  }

  static getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

  static getMonthFromTimeEntries(timeEntries) {
    let date = timeEntries[0].date;
    let monthNr = new Date(date).getMonth();
    switch (monthNr) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        throw new Error(`Invalid month number ${monthNr}`);
    }
  }

  static getDayFromDay(date) {
    let day = date.getDay();
    switch (day) {
      case 0:
        return "Monday";
      case 1:
        return "Tuesday";
      case 2:
        return "Wednesday";
      case 3:
        return "Thursday";
      case 4:
        return "Friday";
      case 5:
        return "Saturday";
      case 6:
        return "Sunday";
      default:
        throw new Error(`Invalid day number ${day}`);
    }
  }

  static generateGuid() {
    return uuidv4();
  }

  static async deleteTimeEntry(id) {
    return await this.dataService.deleteTimeEntry(id);
  }

  static async deleteTimeEntriesForUserOnDate(email, date) {
    return await this.dataService.deleteTimeEntriesForUserOnDate(email, date);
  }

  static async deleteTimeEntriesForUserAndProjectOnDate(email, date, projectId) {
    return await this.dataService.deleteTimeEntriesForUserAndProjectOnDate(email, date, projectId);
  }

  static async deleteTimeEntriesForUserAndActivityOnDate(email, date, activityId) {
    return await this.dataService.deleteTimeEntriesForUserAndActivityOnDate(email, date, activityId);
  }

  static async sendReport(email, from, to, comment) {
    return await this.dataService.sendReport(
      email,
      from.toLocaleDateString("sv-SE"),
      to.toLocaleDateString("sv-SE"),
      comment
    );
  }

  static async approveReport(timeReport) {
    return await this.dataService.approveReport(
      timeReport
    );
  }

  static async saveNewTimeReport(timeReport) {
    await this.dataService.saveNewTimeReport(timeReport);
  }

  static async updateTimeReport(timeReport, from, to) {
    timeReport.from = from.toLocaleDateString("sv-SE");
    timeReport.to = to.toLocaleDateString("sv-SE");
    return await this.dataService.updateTimeReport(timeReport);
  }

  static getFunctionsAccessCodeForGetFileForTimeReport() {
    return `&code=${
      ApplicationState.getState().config
        .FUNCTION_ACCESSCODE_GET_FILE_FOR_TIMEREPORT
    }`;
  }

  static getFunctionsAccessCodeForUploadFileForTimeReport() {
    return `&code=${
      ApplicationState.getState().config
        .FUNCTION_ACCESSCODE_UPLOAD_FILE_FOR_TIMEREPORT
    }`;
  }

  static getFunctionsAccessCodeForDeleteFileForTimeReport() {
    return `&code=${
      ApplicationState.getState().config
        .FUNCTION_ACCESSCODE_DELETE_FILE_FOR_TIMEREPORT
    }`;
  }

  static getFunctionsApiUrl(path) {
    return `${ApplicationState.getState().config.FUNCTIONS_API_URL}/${path}`;
  }

  static async getFileForTimeReport(email, year, month, fileExtension, fileId) {
    let url =
      "http://localhost:7071/api/GetReport?user=" +
      email +
      "&year=" +
      year +
      "&month=" +
      month +
      "&fileExtension=" +
      fileExtension +
      "&fileId=" +
      fileId +
      "&code=OvmuC3SZsHa5CosTv73gVLR9tyKxzixtUhLTQb4QL9TyefVu/pY4Sw==";
    url =
      this.getFunctionsApiUrl("GetReport") +
      "?user=" +
      email +
      "&year=" +
      year +
      "&month=" +
      month +
      "&fileExtension=" +
      fileExtension +
      "&fileId=" +
      fileId +
      this.getFunctionsAccessCodeForGetFileForTimeReport();
      saveAs(url);
  }

  static async uploadFileForTimeReport(file, email, year, month, fileExtension, fileId) {
    let url =
      "http://localhost:7071/api/UpdateReport?user=" +
      email +
      "&year=" +
      year +
      "&month=" +
      month +
      "&fileExtension=" +
      fileExtension +
      "&fileId=" +
      fileId +
      "&code=OvmuC3SZsHa5CosTv73gVLR9tyKxzixtUhLTQb4QL9TyefVu/pY4Sw==";
    url =
      this.getFunctionsApiUrl("UpdateReport") +
      "?user=" +
      email +
      "&year=" +
      year +
      "&month=" +
      month +
      "&fileExtension=" +
      fileExtension +
      "&fileId=" +
      fileId +
      this.getFunctionsAccessCodeForUploadFileForTimeReport();
    const response = await fetch(
      url,
      {
        method: "POST",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      }
    );
  }

  static async deleteFileForTimeReport(email, year, month, fileExtension, fileId) {
    let url =
      this.getFunctionsApiUrl("DeleteReport") +
      "?user=" +
      email +
      "&year=" +
      year +
      "&month=" +
      month +
      "&fileExtension=" +
      fileExtension +
      "&fileId=" +
      fileId +
      this.getFunctionsAccessCodeForDeleteFileForTimeReport();
    const response = await fetch(url, {
      method: "GET",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  static async getAllUserDataServices() {
    return await this.dataService.getAllUserDataServices();
  }

  static async getAllUserDataServiceScopes() {
    return await this.dataService.getAllUserDataServiceScopes();
  }
  
  static async getProjectById(email) {
    return await this.dataService.getProjectById(email);
  }

  static async getProjectsForUser(userId) {
    return await this.dataService.getProjectsForUser(userId);
  }

  static async getProjectsForGroup(id) {
    return await this.dataService.getProjectsForGroup(id);
  }

  static async getProjectsForFileExporter(id) {
    return await this.dataService.getProjectsForFileExporter(id);
  }

  static async getProjectsForEmailExporter(id) {
    return await this.dataService.getProjectsForEmailExporter(id);
  }

  static async getProjectsForOrganization(id) {
    return await this.dataService.getProjectsForOrganization(id);
  }

  static async getGroupsForOrganization(id) {
    return await this.dataService.getGroupsForOrganization(id);
  }

  static async getGroupsForProject(id) {
    return await this.dataService.getGroupsForProject(id);
  }

  static async getUsersForGroup(id) {
    return await this.dataService.getUsersForGroup(id);
  }

  static async getUsersForProject(id) {
    return await this.dataService.getUsersForProject(id);
  }

  static async getActivitiesForProject(id) {
    return await this.dataService.getActivitiesForProject(id);
  }

  static async getUserDataServicesForUser(email) {
    return await this.dataService.getUserDataServicesForUser(email);
  }

  static async getUserDataServiceSelectionsForUser(email) {
    return await this.dataService.getUserDataServiceSelectionsForUser(email);
  }

  static async getUserDataServiceScopesForUser(email) {
    return await this.dataService.getUserDataServiceScopesForUser(email);
  }

  static async saveUserDataServiceSelection(selection) {
    await this.dataService.saveUserDataServiceSelection(selection);
  }

  static async saveNewUser(user) {
    await this.dataService.saveNewUser(user);
  }

  static async saveUpdatedUser(user) {
    await this.dataService.saveUpdatedUser(user);
  }

  static getTimeReportsForOrganization(id) {
    return this.dataService.getTimeReportsForOrganization(id);
  }

  static saveNewActivityKeyword(keyword) {
    return this.dataService.saveNewActivityKeyword(keyword);
  }

  static saveUpdatedActivityKeyword(keyword) {
    return this.dataService.saveUpdatedActivityKeyword(keyword);
  }

  static async deleteActivityKeyword(id) {
    return await this.dataService.deleteActivityKeyword(id);
  }

  static getKeywordsForActivity(id) {
    return this.dataService.getKeywordsForActivity(id);
  }

  static async saveNewStandardTime(standardTime) {
    await this.dataService.saveNewStandardTime(standardTime);
  }

  static async saveUpdatedStandardTime(standardTime) {
    await this.dataService.saveUpdatedStandardTime(standardTime);
  }

  static async deleteStandardTime(standardTime) {
    return await this.dataService.deleteStandardTime(standardTime);
  }

  static getUsersForOrganization(id, includeInactiveUsers = false) {
    return this.dataService.getUsersForOrganization(id, includeInactiveUsers);
  }

  static async getProjectSettingsForUser(email) {
    return await this.dataService.getProjectSettingsForUser(email);
  }

  static async getProjectSettingsForProject(id) {
    return await this.dataService.getProjectSettingsForProject(id);
  }

  static async getActivityKeywordsForUser(email) {
    return await this.dataService.getActivityKeywordsForUser(email);
  }

  static async getGroupsForUser(email) {
    return await this.dataService.getGroupsForUser(email);
  }

  static async deleteUser(id) {
    return await this.dataService.deleteUser(id);
  }

  static async connectToUserDataService(
    service,
    selectedScopesForService,
    accessCode,
    userEmail
  ) {
    return this.dataService.connectToUserDataService(
      service,
      selectedScopesForService,
      accessCode,
      userEmail
    );
  }

  static async connectToUserDataServiceWithPersonalAccessToken(
    service,
    selectedScopesForService,
    personalAccessCode,
    userEmail,
    accountIdentifier,
    previousAccountIdentifier,
    username,
    previousUsername
  ) {
    return this.dataService.connectToUserDataServiceWithPersonalAccessToken(
      service,
      selectedScopesForService,
      personalAccessCode,
      userEmail,
      accountIdentifier,
      previousAccountIdentifier,
      username,
      previousUsername
    );
  }

  static async saveNewProjectSetting(setting) {
    await this.dataService.saveNewProjectSetting(setting);
  }

  static async saveUpdatedProjectSetting(setting) {
    await this.dataService.saveUpdatedProjectSetting(setting);
  }

  static async deleteProjectSetting(id) {
    return await this.dataService.deleteProjectSetting(id);
  }

  static async saveNewProject(project) {
    await this.dataService.saveNewProject(project);
  }

  static async saveUpdatedProject(project) {
    await this.dataService.saveUpdatedProject(project);
  }

  static async deleteProject(id) {
    return await this.dataService.deleteProject(id);
  }

  static async saveNewGroup(project) {
    await this.dataService.saveNewGroup(project);
  }

  static async saveUpdatedGroup(project) {
    await this.dataService.saveUpdatedGroup(project);
  }

  static async deleteGroup(id) {
    return await this.dataService.deleteGroup(id);
  }

  static async saveNewActivity(activity) {
    await this.dataService.saveNewActivity(activity);
  }

  static async saveUpdatedActivity(activity) {
    await this.dataService.saveUpdatedActivity(activity);
  }

  static async deleteActivity(id) {
    return await this.dataService.deleteActivity(id);
  }

  static async saveNewUserProjectSelection(dto) {
    await this.dataService.saveNewUserProjectSelection(dto);
  }

  static async deleteUserProjectSelection(id) {
    return await this.dataService.deleteUserProjectSelection(id);
  }

  static async getUserProjectSelectionsForUser(email) {
    return await this.dataService.getUserProjectSelectionsForUser(email);
  }

  static async getUserProjectSelectionsForProject(id) {
    return await this.dataService.getUserProjectSelectionsForProject(id);
  }

  static async saveNewGroupProjectSelection(dto) {
    await this.dataService.saveNewGroupProjectSelection(dto);
  }

  static async deleteGroupProjectSelection(id) {
    return await this.dataService.deleteGroupProjectSelection(id);
  }

  static async getGroupProjectSelectionsForGroup(email) {
    return await this.dataService.getGroupProjectSelectionsForGroup(email);
  }

  static async getGroupProjectSelectionsForProject(id) {
    return await this.dataService.getGroupProjectSelectionsForProject(id);
  }
  
  static async saveNewUserInGroup(dto) {
    await this.dataService.saveNewUserInGroup(dto);
  }

  static async deleteUserInGroup(id) {
    return await this.dataService.deleteUserInGroup(id);
  }

  static async getUserInGroupForUser(email) {
    return await this.dataService.getUserInGroupForUser(email);
  }

  static async getUserInGroupForGroup(id) {
    return await this.dataService.getUserInGroupForGroup(id);
  }

  static async getStandardTimeForUser(email) {
    return await this.dataService.getStandardTimeForUser(email);
  }

  static async deleteUserDataServiceSelection(id) {
    return await this.dataService.deleteUserDataServiceSelection(id);
  }

  static async rejectTimeReport(dto) {
    return await this.dataService.rejectTimeReport(dto);
  }

  static async unlockApprovedTimeReport(dto) {
    return await this.dataService.unlockApprovedTimeReport(dto);
  }

  static async getUserDataServiceScopeSelectionsForUser(email) {
    return await this.dataService.getUserDataServiceScopeSelectionsForUser(
      email
    );
  }

  static async getHolidaysForOrganization(id, year) {
    return await this.dataService.getHolidaysForOrganization(id, year);
  }

  static async getKeywordSuggestionsForUserData(id) {
    return await this.dataService.getKeywordSuggestionsForUserData(id);
  }

  static async getEmailsForUser(email, from, to) {
    return await this.dataService.getEmailsForUser(email, from, to);
  }

  static async getEmailForUser(userEmail, userDataId) {
    return await this.dataService.getEmailForUser(userEmail, userDataId);
  }

  static async getMeetingsForUser(email, from, to) {
    return await this.dataService.getMeetingsForUser(email, from, to);
  }

  static async getMeetingForUser(userEmail, userDataId) {
    return await this.dataService.getMeetingForUser(userEmail, userDataId);
  }

  static async getFilesForUser(email, from, to) {
    return await this.dataService.getFilesForUser(email, from, to);
  }

  static async getFileForUser(userEmail, userDataId) {
    return await this.dataService.getFileForUser(userEmail, userDataId);
  }

  static async getTicketForUser(userEmail, userDataId) {
    return await this.dataService.getTicketForUser(userEmail, userDataId);
  }

  static async getTicketsForUser(userEmail, from, to) {
    return await this.dataService.getTicketsForUser(userEmail, from, to);
  }

  static async getTasksForUser(email, from, to) {
    return await this.dataService.getTasksForUser(email, from, to);
  }

  static async getTaskForUser(userEmail, userDataId) {
    return await this.dataService.getTaskForUser(userEmail, userDataId);
  }

  static async getTimeEntriesForOrganization(id, from, to) {
    return await this.dataService.getTimeEntriesForOrganization(id, from, to);
  }

  static async getTimeEntriesForUser(email, from, to) {
    return await ApplicationState.dataService.getTimeEntriesForUser(email, from, to);
  }

  static async causeExceptionInApi() {
    return await ApplicationState.dataService.causeExceptionInApi();
  }

  static getDefaultTimeReportViewMode() {
    let value = this.getState().userSettings.defaultTimeReportView;
    return value;
  }

  static getUserSettings() {
    return this.getState().userSettings;
  }

  static getOrganizationSettings() {
    return this.getState().organizationSettings;
  }

  static async saveUpdatedOrganizationSetting(setting) {
    await this.dataService.saveUpdatedOrganizationSetting(setting);
  }

  static getReportingInterval() {
    let value = this.getState().organizationSettings.reportingInterval
    return value;
  }

  static getAbsenceProject() {
    let value = this.getState().organizationSettings.absenceProject;
    return value;
  }

  static getFlexWithdrawalActivity() {
    let value = this.getState().organizationSettings.flexWithdrawalActivity;
    return value;
  }

  static getUseFlexTime() {
    let value = this.getState().organizationSettings.useFlexTime;
    return value;
  }

  static getDefaultProjectManualTimeEntry() {
    let value = this.getState().userSettings.defaultProjectManualTimeEntry;
    return value;
  }

  static async saveUpdatedUserSetting(setting) {
    await this.dataService.saveUpdatedUserSetting(setting);
  }

  static async getCurrentFlexTime(email, date) {
    return await this.dataService.getCurrentFlexTime(email, date);
  }

  static async getFlexTimeDifferenceForUser(email, date) {
    return await this.dataService.getFlexTimeDifferenceForUser(email, date);
  }

  static async getExportersForOrganization(id) {
    let exporters = [];
    let fExporters = await this.dataService.getFileExportersForOrganization(id);
    fExporters.forEach((element) => {
      let dto = new ExporterDto(element.id, element.name, "FileExporter", element);
      exporters.push(dto);
    });

    let eExporter = await this.dataService.getEmailExportersForOrganization(id);
    eExporter.forEach((element) => {
      let dto = new ExporterDto(element.id, element.name, "EmailExporter", element);
      exporters.push(dto);
    });

    let aExporters = await this.dataService.getApiExportersForOrganization(id);
    aExporters.forEach((element) => {
      let dto = new ExporterDto(element.id, element.name, "ApiExporter", element);
      exporters.push(dto);
    });
    return exporters;
  }

  static async getExportersForProject(id) {
    let exporters = [];
    let fExporters = await this.dataService.getFileExportersForProject(id);
    
    
    fExporters.forEach((element) => {
      let dto = new ExporterDto(element.id, element.name, "FileExporter", element);
      exporters.push(dto);
    });
    
    let eExporter = await this.dataService.getEmailExportersForProject(id);
    eExporter.forEach((element) => {
      let dto = new ExporterDto(element.id, element.name, "EmailExporter", element);
      exporters.push(dto);
    });
    return exporters;
  }

  static getFileExporterToEdit() {
    return this.getState().fileExporterToEdit;
  }

  static setFileExporterToEdit(fileExporterToEdit) {
    let state = this.getState();
    state.fileExporterToEdit = fileExporterToEdit;
    this.storeState(state);
  }

  static getFileExporterToView() {
    return this.getState().fileExporterToView;
  }

  static setFileExporterToView(fileExporterToView) {
    let state = this.getState();
    state.fileExporterToView = fileExporterToView;
    this.storeState(state);
  }

  static async saveNewFileExporter(dto) {
    await this.dataService.saveNewFileExporter(dto);
  }

  static async saveUpdatedFileExporter(dto) {
    await this.dataService.saveUpdatedFileExporter(dto);
  }

  static async getPropertiesForFileExporter(id) {
    return await this.dataService.getPropertiesForFileExporter(id);
  }

  static async deleteFileExporterProperty(id) {
    return await this.dataService.deleteFileExporterProperty(id);
  }

  static getFileExporterPropertyToEdit() {
    return this.getState().fileExporterPropertyToEdit;
  }

  static setFileExporterPropertyToEdit(fileExporterPropertyToEdit) {
    let state = this.getState();
    state.fileExporterPropertyToEdit = fileExporterPropertyToEdit;
    this.storeState(state);
  }

  static getFileExporterToAddPropertyFor() {
    return this.getState().fileExporterToAddPropertyFor;
  }

  static setFileExporterToAddPropertyFor(fileExporterToAddPropertyFor) {
    let state = this.getState();
    state.fileExporterToAddPropertyFor = fileExporterToAddPropertyFor;
    this.storeState(state);
  }

  static async saveNewFileExporterProperty(dto) {
    await this.dataService.saveNewFileExporterProperty(dto);
  }

  static async saveUpdatedFileExporterProperty(dto) {
    await this.dataService.saveUpdatedFileExporterProperty(dto);
  }

  static async saveNewFileExporterProjectSelection(dto) {
    await this.dataService.saveNewFileExporterProjectSelection(dto);
  }

  static async deleteFileExporterProjectSelection(id) {
    return await this.dataService.deleteFileExporterProjectSelection(id);
  }

  static async getFileExporterProjectSelectionsForFileExporter(id) {
    return await this.dataService.getFileExporterProjectSelectionsForFileExporter(id);
  }

  static async getFileExporterPropertiesForProject(id) {
    return await this.dataService.getFileExporterPropertiesForProject(id);
  }

  static async getFileExporterPropertiesForActivity(id) {
    return await this.dataService.getFileExporterPropertiesForActivity(id);
  }

  static async deleteFileExporter(id) {
    return await this.dataService.deleteFileExporter(id);
  }

  static async getActivitySettingsForActivity(id) {
    return await this.dataService.getActivitySettingsForActivity(id);
  }

  static async saveNewActivitySetting(setting) {
    await this.dataService.saveNewActivitySetting(setting);
  }

  static async saveUpdatedActivitySetting(setting) {
    await this.dataService.saveUpdatedActivitySetting(setting);
  }

  static async deleteActivitySetting(id) {
    return await this.dataService.deleteActivitySetting(id);
  }

  static getEmailExporterToEdit() {
    return this.getState().emailExporterToEdit;
  }

  static setEmailExporterToEdit(emailExporterToEdit) {
    let state = this.getState();
    state.emailExporterToEdit = emailExporterToEdit;
    this.storeState(state);
  }

  static getEmailExporterToView() {
    return this.getState().emailExporterToView;
  }

  static setEmailExporterToView(emailExporterToView) {
    let state = this.getState();
    state.emailExporterToView = emailExporterToView;
    this.storeState(state);
  }

  static async saveNewEmailExporter(dto) {
    await this.dataService.saveNewEmailExporter(dto);
  }

  static async saveUpdatedEmailExporter(dto) {
    await this.dataService.saveUpdatedEmailExporter(dto);
  }

  static async getPropertiesForEmailExporter(id) {
    return await this.dataService.getPropertiesForEmailExporter(id);
  }

  static async deleteEmailExporterProperty(id) {
    return await this.dataService.deleteEmailExporterProperty(id);
  }

  static getEmailExporterPropertyToEdit() {
    return this.getState().emailExporterPropertyToEdit;
  }

  static setEmailExporterPropertyToEdit(emailExporterPropertyToEdit) {
    let state = this.getState();
    state.emailExporterPropertyToEdit = emailExporterPropertyToEdit;
    this.storeState(state);
  }

  static getEmailExporterToAddPropertyFor() {
    return this.getState().emailExporterToAddPropertyFor;
  }

  static setEmailExporterToAddPropertyFor(emailExporterToAddPropertyFor) {
    let state = this.getState();
    state.emailExporterToAddPropertyFor = emailExporterToAddPropertyFor;
    this.storeState(state);
  }

  static async saveNewEmailExporterProperty(dto) {
    await this.dataService.saveNewEmailExporterProperty(dto);
  }

  static async saveUpdatedEmailExporterProperty(dto) {
    await this.dataService.saveUpdatedEmailExporterProperty(dto);
  }

  static async saveNewEmailExporterProjectSelection(dto) {
    await this.dataService.saveNewEmailExporterProjectSelection(dto);
  }

  static async deleteEmailExporterProjectSelection(id) {
    return await this.dataService.deleteEmailExporterProjectSelection(id);
  }

  static async getEmailExporterProjectSelectionsForEmailExporter(id) {
    return await this.dataService.getEmailExporterProjectSelectionsForEmailExporter(id);
  }

  static async getEmailExporterPropertiesForProject(id) {
    return await this.dataService.getEmailExporterPropertiesForProject(id);
  }

  static async getEmailExporterPropertiesForActivity(id) {
    return await this.dataService.getEmailExporterPropertiesForActivity(id);
  }

  static async deleteEmailExporter(id) {
    return await this.dataService.deleteEmailExporter(id);
  }

  static getTimeReportToView() {
    return this.getState().timeReportToView;
  }

  static setTimeReportToView(timeReportToView) {
    let state = this.getState();
    state.timeReportToView = timeReportToView;
    this.storeState(state);
  }

  static async getReduceWorkingTimeKeywordsForUser(email) {
    return await this.dataService.getReduceWorkingTimeKeywordsForUser(email);
  }

  static async saveNewReduceWorkingTimeKeyword(dto) {
    return await this.dataService.saveNewReduceWorkingTimeKeyword(dto);
  }

  static async saveUpdatedReduceWorkingTimeKeyword(dto) {
    return await this.dataService.saveUpdatedReduceWorkingTimeKeyword(dto);
  }

  static async deleteReduceWorkingTimeKeyword(id) {
    return await this.dataService.deleteReduceWorkingTimeKeyword(id);
  }

  static async getNormalWorkingTimesForUser(email) {
    return await this.dataService.getNormalWorkingTimesForUser(email);
  }

  static async saveNewNormalWorkingTime(dto) {
    return await this.dataService.saveNewNormalWorkingTime(dto);
  }

  static async saveUpdatedNormalWorkingTime(dto) {
    return await this.dataService.saveUpdatedNormalWorkingTime(dto);
  }

  static async deleteNormalWorkingTime(id) {
    return await this.dataService.deleteNormalWorkingTime(id);
  }

  static getNormalWorkingTimeToEdit() {
    return this.getState().normalWorkingTimeToEdit;
  }

  static setNormalWorkingTimeToEdit(normalWorkingTimeToEdit) {
    let state = this.getState();
    state.normalWorkingTimeToEdit = normalWorkingTimeToEdit;
    this.storeState(state);
  }

  static getUserToAddNormalWorkingTimeFor() {
    return this.getState().userToAddNormalWorkingTimeFor;
  }

  static setUserToAddNormalWorkingTimeFor(userToAddNormalWorkingTimeFor) {
    let state = this.getState();
    state.userToAddNormalWorkingTimeFor = userToAddNormalWorkingTimeFor;
    this.storeState(state);
  }

  static getWeekdayToAddNormalWorkingTimeFor() {
    return this.getState().weekdayToAddNormalWorkingTimeFor;
  }

  static setWeekdayToAddNormalWorkingTimeFor(weekdayToAddNormalWorkingTimeFor) {
    let state = this.getState();
    state.weekdayToAddNormalWorkingTimeFor = weekdayToAddNormalWorkingTimeFor;
    this.storeState(state);
  }

  static async getMinutesToWorkForTimeReport(timeReportId) {
    return await this.dataService.getMinutesToWorkForTimeReport(timeReportId);
  }

  static async getUserDataForUser(email, from, to) {
    return await this.dataService.getUserDataForUser(email, from, to);
  }

  static getSuggestedKeywordsForUserData() {
    return this.getState().suggestedKeywordsForUserData;
  }

  static setSuggestedKeywordsForUserData(suggestedKeywordsForUserData) {
    let state = this.getState();
    state.suggestedKeywordsForUserData = suggestedKeywordsForUserData;
    this.storeState(state);
  }

  static getShowDateWhenAddingTimeEntry() {
    return this.getState().showDateWhenAddingTimeEntry;
  }

  static setShowDateWhenAddingTimeEntry(value) {
    let state = this.getState();
    state.showDateWhenAddingTimeEntry = value;
    this.storeState(state);
  }

  static async getExperimentsSelected(type, email) {
    return await this.dataService.getExperimentsSelected(type, email);
  }

  static async getExperimentsRated(type, email, opinion) {
    return await this.dataService.getExperimentsRated(type, email, opinion);
  }

  static getLastSentTimeReportFromDate() {
    return this.getState().lastSentTimeReportFromDate;
  }

  static setLastSentTimeReportFromDate(value) {
    let state = this.getState();
    state.lastSentTimeReportFromDate = value;
    this.storeState(state);
  }

  static async getHolidaysForOrganization(id) {
    return await this.dataService.getHolidaysForOrganization(id);
  }

  static async saveNewHoliday(setting) {
    await this.dataService.saveNewHoliday(setting);
  }

  static async saveUpdatedHoliday(setting) {
    await this.dataService.saveUpdatedHoliday(setting);
  }

  static async deleteHoliday(id) {
    return await this.dataService.deleteHoliday(id);
  }

  static async getUserTargetWorkingTime(email) {
    return await this.dataService.getUserTargetWorkingTime(email);
  }

  static async saveUpdatedUserTargetWorkingTime(targetTime) {
    await this.dataService.saveUpdatedUserTargetWorkingTime(targetTime);
  }

  static async getIgnoreKeywordsForUser(email) {
    return await this.dataService.getIgnoreKeywordsForUser(email);
  }

  static async saveNewIgnoreKeyword(dto) {
    return await this.dataService.saveNewIgnoreKeyword(dto);
  }

  static async saveUpdatedIgnoreKeyword(dto) {
    return await this.dataService.saveUpdatedIgnoreKeyword(dto);
  }

  static async deleteIgnoreKeyword(id) {
    return await this.dataService.deleteIgnoreKeyword(id);
  }

  static async getTimeReportFilesForTimeReport(id) {
    return await this.dataService.getTimeReportFilesForTimeReport(id);
  }

  static async saveNewTimeReportFile(dto) {
    return await this.dataService.saveNewTimeReportFile(dto);
  }

  static async saveUpdatedTimeReportFile(dto) {
    return await this.dataService.saveUpdatedTimeReportFile(dto);
  }

  static async deleteTimeReportFile(id) {
    return await this.dataService.deleteTimeReportFile(id);
  }

  static async ignoreUserDataAlways(id, email, title) {
    return await this.dataService.ignoreUserDataAlways(
      id, email, title
    );
  }

  static async ignoreUserDataOnce(id) {
    return await this.dataService.ignoreUserDataOnce(
      id
    );
  }

  static getServiceNeedRedirectFromHack() {
    return this.getState().serviceNeedRedirectFromHack;
  }

  static setServiceNeedRedirectFromHack(value) {
    let state = this.getState();
    state.serviceNeedRedirectFromHack = value;
    this.storeState(state);
  }

  static getUser() {
    return this.getState().user;
  }

  static getTimeReportsFilter() {
    return this.getState().timeReportsFilter;
  }

  static setTimeReportsFilter(timeReportsFilter) {
    let state = this.getState();
    state.timeReportsFilter = timeReportsFilter;
    this.storeState(state);
  }

  static async getLogEvents() {
    return await this.dataService.getLogEvents();
  }

  static async getLoginLogEvents() {
    return await this.dataService.getLoginLogEvents();
  }

  static async getTimeReportGenerationLog() {
    return await this.dataService.getTimeReportGenerationLog();
  }
  static async reGenerateTimeReport(id) {
    return await this.dataService.reGenerateTimeReport(id);
  }
  static async reGenerateCurrentTimeReportForUser(userId) {
    return await this.dataService.reGenerateCurrentTimeReportForUser(userId);
  }
  static async clearTimeReport(id) {
    return await this.dataService.clearTimeReport(id);
  }
  static async getKeywordSuggestions(id) {
    return await this.dataService.getKeywordSuggestions(id);
  }
  static async getAssignmentsForUser(id) {
    return await this.dataService.getAssignmentsForUser(id);
  }
  static async getAssignmentsForOrganization(id) {
    return await this.dataService.getAssignmentsForOrganization(id);
  }
  static async getSkillExperienceOverviewForOrganization(id) {
    return await this.dataService.getSkillExperienceOverviewForOrganization(id);
  }
  static async saveNewAssignment(dto) {
    return await this.dataService.saveNewAssignment(dto);
  }
  static async saveNewSkillExperienceInAssignment(dto) {
    return await this.dataService.saveNewSkillExperienceInAssignment(dto);
  }
  static async getSkillsForOrganization(id) {
    return await this.dataService.getSkillsForOrganization(id);
  }
  static async getAssignment(id) {
    return await this.dataService.getAssignment(id);
  }

  static getAssignmentToEdit() {
    return this.getState().assignmentToEdit;
  }

  static setAssignmentToEdit(assignmentToEdit) {
    let state = this.getState();
    state.assignmentToEdit = assignmentToEdit;
    this.storeState(state);
  }

  static async saveUpdatedAssignment(assignment) {
    await this.dataService.saveUpdatedAssignment(assignment);
  }

  static async deleteAssignment(id) {
    return await this.dataService.deleteAssignment(id);
  }

  static async getSkillExperienceForAssignment(id) {
    return await this.dataService.getSkillExperienceForAssignment(id);
  }

  static getSkillToEdit() {
    return this.getState().skillToEdit;
  }

  static setSkillToEdit(skillToEdit) {
    let state = this.getState();
    state.skillToEdit = skillToEdit;
    this.storeState(state);
  }
  
  static async saveNewSkill(dto) {
    return await this.dataService.saveNewSkill(dto);
  }

  static async saveUpdatedSkill(dto) {
    await this.dataService.saveUpdatedSkill(dto);
  }

  static async deleteSkill(id) {
    return await this.dataService.deleteSkill(id);
  }

  static getUserToAddAssignmentFor() {
    return this.getState().userToAddAssignmentFor;
  }

  static setUserToAddAssignmentFor(userToAddAssignmentFor) {
    let state = this.getState();
    state.userToAddAssignmentFor = userToAddAssignmentFor;
    this.storeState(state);
  }

  static async deleteSkillExperienceForAssignment(id) {
    return await this.dataService.deleteSkillExperienceForAssignment(id);
  }

  static async deleteSkillExperienceInAssignment(id) {
    return await this.dataService.deleteSkillExperienceInAssignment(id);
  }

  static async getSkillExperienceForUser(id) {
    return await this.dataService.getSkillExperienceForUser(id);
  }

  static async getSkillExperienceOverviewForUser(id) {
    return await this.dataService.getSkillExperienceOverviewForUser(id);
  }

  static getUserToAddEducationFor() {
    return this.getState().userToAddEducationFor;
  }

  static setUserToAddEducationFor(userToAddEducationFor) {
    let state = this.getState();
    state.userToAddEducationFor = userToAddEducationFor;
    this.storeState(state);
  }

  static getEducationToEdit() {
    return this.getState().educationToEdit;
  }

  static setEducationToEdit(educationToEdit) {
    let state = this.getState();
    state.educationToEdit = educationToEdit;
    this.storeState(state);
  }

  static getUserToAddCourseFor() {
    return this.getState().userToAddCourseFor;
  }
  
  static setUserToAddCourseFor(userToAddCourseFor) {
    let state = this.getState();
    state.userToAddCourseFor = userToAddCourseFor;
    this.storeState(state);
  }
  
  static getCourseToEdit() {
    return this.getState().courseToEdit;
  }
  
  static setCourseToEdit(courseToEdit) {
    let state = this.getState();
    state.courseToEdit = courseToEdit;
    this.storeState(state);
  }
  
  static getUserToAddCertificateFor() {
    return this.getState().userToAddCertificateFor;
  }
  
  static setUserToAddCertificateFor(userToAddCertificateFor) {
    let state = this.getState();
    state.userToAddCertificateFor = userToAddCertificateFor;
    this.storeState(state);
  }
  
  static getCertificateToEdit() {
    return this.getState().certificateToEdit;
  }
  
  static setCertificateToEdit(certificateToEdit) {
    let state = this.getState();
    state.certificateToEdit = certificateToEdit;
    this.storeState(state);
  }

  static async saveNewEducation(dto) {
    return await this.dataService.saveNewEducation(dto);
  }
  
  static async saveUpdatedEducation(education) {
    await this.dataService.saveUpdatedEducation(education);
  }
  
  static async deleteEducation(id) {
    return await this.dataService.deleteEducation(id);
  }
  
  static async getEducationsForUser(id) {
    return await this.dataService.getEducationsForUser(id);
  }
  
  static async saveNewCourse(dto) {
    return await this.dataService.saveNewCourse(dto);
  }
  
  static async saveUpdatedCourse(course) {
    await this.dataService.saveUpdatedCourse(course);
  }
  
  static async deleteCourse(id) {
    return await this.dataService.deleteCourse(id);
  }
  
  static async getCoursesForUser(id) {
    return await this.dataService.getCoursesForUser(id);
  }
  
  static async saveNewCertificate(dto) {
    return await this.dataService.saveNewCertificate(dto);
  }
  
  static async saveUpdatedCertificate(certificate) {
    await this.dataService.saveUpdatedCertificate(certificate);
  }
  
  static async deleteCertificate(id) {
    return await this.dataService.deleteCertificate(id);
  }
  
  static async getCertificatesForUser(id) {
    return await this.dataService.getCertificatesForUser(id);
  }

  static async getProjectExtensionsForProject(id) {
    return await this.dataService.getProjectExtensionsForProject(id);
  }

  static async getProjectExtensionsForOrganization(id) {
    return await this.dataService.getProjectExtensionsForOrganization(id);
  }

  static async saveNewProjectExtension(dto) {
    return await this.dataService.saveNewProjectExtension(dto);
  }

  static async saveUpdatedProjectExtension(dto) {
    await this.dataService.saveUpdatedProjectExtension(dto);
  }

  static async deleteProjectExtension(id) {
    return await this.dataService.deleteProjectExtension(id);
  }

  static getProjectExtensionToEdit() {
    return this.getState().projectExtensionToEdit;
  }

  static setProjectExtensionToEdit(projectExtensionToEdit) {
    let state = this.getState();
    state.projectExtensionToEdit = projectExtensionToEdit;
    this.storeState(state);
  }

  static getProjectToAddProjectExtensionFor() {
    return this.getState().projectToAddProjectExtensionFor;
  }

  static setProjectToAddProjectExtensionFor(projectToAddProjectExtensionFor) {
    let state = this.getState();
    state.projectToAddProjectExtensionFor = projectToAddProjectExtensionFor;
    this.storeState(state);
  }

  static async getUserById(id) {
    return await this.dataService.getUserById(id);
  }

  static async getRevenueForecastForOrganization(id, fromDate, toDate) {
    return await this.dataService.getRevenueForecastForOrganization(id, fromDate, toDate);
  }

  static getUserToEditTimeReportFor() {
    return this.getState().userToEditTimeReportFor;
  }

  static setUserToEditTimeReportFor(userToEditTimeReportFor) {
    let state = this.getState();
    state.userToEditTimeReportFor = userToEditTimeReportFor;
    this.storeState(state);
  }

  static getProjectToDoTaskToEdit() {
    return this.getState().projectToDoTaskToEdit;
  }

  static setProjectToDoTaskToEdit(projectToDoTaskToEdit) {
    let state = this.getState();
    state.projectToDoTaskToEdit = projectToDoTaskToEdit;
    this.storeState(state);
  }

  static getProjectToAddProjectToDoTaskFor() {
    return this.getState().projectToAddProjectToDoTaskFor;
  }

  static setProjectToAddProjectToDoTaskFor(projectToAddProjectToDoTaskFor) {
    let state = this.getState();
    state.projectToAddProjectToDoTaskFor = projectToAddProjectToDoTaskFor;
    this.storeState(state);
  }

  static async saveNewProjectToDoTask(dto) {
    return await this.dataService.saveNewProjectToDoTask(dto);
  }

  static async saveUpdatedProjectToDoTask(dto) {
    await this.dataService.saveUpdatedProjectToDoTask(dto);
  }

  static async deleteProjectToDoTask(id) {
    return await this.dataService.deleteProjectToDoTask(id);
  }

  static async getProjectToDoTasksForProject(id) {
    return await this.dataService.getProjectToDoTasksForProject(id);
  }

  static async getApiExportersForProject(id) {
    return await this.dataService.getApiExportersForProject(id);
  }

  static async getApiExportersForOrganization(id) {
    return await this.dataService.getApiExportersForOrganization(id);
  }

  static async saveNewApiExporter(dto) {
    return await this.dataService.saveNewApiExporter(dto);
  }

  static async saveUpdatedApiExporter(dto) {
    await this.dataService.saveUpdatedApiExporter(dto);
  }

  static async deleteApiExporter(id) {
    return await this.dataService.deleteApiExporter(id);
  }

  static async saveNewApiExporterProjectSelection(dto) {
    await this.dataService.saveNewApiExporterProjectSelection(dto);
  }

  static async deleteApiExporterProjectSelection(id) {
    return await this.dataService.deleteApiExporterProjectSelection(id);
  }

  static async getApiExporterProjectSelectionsForApiExporter(id) {
    return await this.dataService.getApiExporterProjectSelectionsForApiExporter(id);
  }

  static getApiExporterToEdit() {
    return this.getState().apiExporterToEdit;
  }

  static setApiExporterToEdit(apiExporterToEdit) {
    let state = this.getState();
    state.apiExporterToEdit = apiExporterToEdit;
    this.storeState(state);
  }

  static getApiExporterToView() {
    return this.getState().apiExporterToView;
  }

  static setApiExporterToView(apiExporterToView) {
    let state = this.getState();
    state.apiExporterToView = apiExporterToView;
    this.storeState(state);
  }

  static async getProjectsForApiExporter(id) {
    return await this.dataService.getProjectsForApiExporter(id);
  }

  static async getTimeReportsForUser(user) {
    return await this.dataService.getTimeReportsForUser(
      user.email
    );
  }

  static async getDefaultActivitiesForOrganization(organizationId) {
    return await this.dataService.getDefaultActivitiesForOrganization(organizationId);
  }

  static async saveNewDefaultActivity(dto) {
    return await this.dataService.saveNewDefaultActivity(dto);
  }

  static async saveUpdatedDefaultActivity(dto) {
    await this.dataService.saveUpdatedDefaultActivity(dto);
  }

  static async deleteDefaultActivity(id) {
    return await this.dataService.deleteDefaultActivity(id);
  }

  static async getClientsForOrganization(organizationId) {
    return await this.dataService.getClientsForOrganization(organizationId);
  }

  static async saveNewClient(dto) {
    return await this.dataService.saveNewClient(dto);
  }

  static async saveUpdatedClient(dto) {
    await this.dataService.saveUpdatedClient(dto);
  }

  static async deleteClient(id) {
    return await this.dataService.deleteClient(id);
  }

  static getClientToEdit() {
    return this.getState().clientToEdit;
  }

  static setClientToEdit(clientToEdit) {
    let state = this.getState();
    state.clientToEdit = clientToEdit;
    this.storeState(state);
  }

  static getClientToView() {
    return this.getState().clientToView;
  }

  static setClientToView(clientToView) {
    let state = this.getState();
    state.clientToView = clientToView;
    this.storeState(state);
  }

  static getToken(){
    return this.getState().token;
  }

  static setToken(token){
    let state = this.getState();
    state.token = token;
    this.storeState(state);
  }
}

export default ApplicationState;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from './PrimaryButton';
import { styled } from '@mui/material/styles';
import NormalWorkingTimeDto from "../Dtos/NormalWorkingTimeDto";
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "60px",
});

function NormalWorkingTimeEditView(props) {
  const [weekdayField, setWeekdayField] = useState(1);
  const [startTimeField, setStartTimeField] = useState("08:00");
  const [endTimeField, setEndTimeField] = useState("17:00");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    
    let maxWeekday = 0;
    if(ApplicationState.getWeekdayToAddNormalWorkingTimeFor()){
      maxWeekday = ApplicationState.getWeekdayToAddNormalWorkingTimeFor();
    }
    setWeekdayField(maxWeekday);

    if (ApplicationState.getNormalWorkingTimeToEdit()) {
      setEditMode(true);
      setStartTimeField(
        ApplicationState.getNormalWorkingTimeToEdit().startTime
      );
      setEndTimeField(
        ApplicationState.getNormalWorkingTimeToEdit().endTime
      );
      setWeekdayField(ApplicationState.getNormalWorkingTimeToEdit().weekday);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveNewNormalWorkingTime() {
    
    let dto = new NormalWorkingTimeDto(
      ApplicationState.generateGuid(),
      ApplicationState.getState().user,
      weekdayField,
      startTimeField,
      endTimeField,
      new Date(Date.now())
    );
    if (ApplicationState.getNormalWorkingTimeToEdit()) {
      dto.id = ApplicationState.getNormalWorkingTimeToEdit().id;
      await ApplicationState.saveUpdatedNormalWorkingTime(dto);
      props.editedWorkingTime();
    } else {
      await ApplicationState.saveNewNormalWorkingTime(dto);
      props.addedWorkingTime();
    }
  }

  async function deleteNormalWorkingTime() {
    var result = await ApplicationState.deleteNormalWorkingTime(
      ApplicationState.getNormalWorkingTimeToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete normal working time with id: " +
          ApplicationState.getNormalWorkingTimeToEdit().id
      );
      return;
    }
    props.deletedWorkingTime();
  }

  const getHeaderText = () => {
    let headerText = "Add normal working time";
    if (editMode) {
      headerText = "Edit normal working time";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
            <InputLabelSelect
              id="weekdaySelectLabel"
              htmlFor="weekdaySelect"
              variant="outlined"
              style={{ width: "74px" }}
              shrink={true}
            >
              Weekday
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setWeekdayField(event.target.value);
              }}
              id="weekdaySelect"
              variant="outlined"
              sx={{
                width: "292px",
                margin: "8px",
                fontWeight: "500",
                width: {xs: "95%", sm: "256px"},
              }}
              value={weekdayField}
            >
              <option key={1} label={"Monday"} value={1}></option>
              <option key={2} label={"Tuesday"} value={2}></option>
              <option key={3} label={"Wednesday"} value={3}></option>
              <option key={4} label={"Thursday"} value={4}></option>
              <option key={5} label={"Friday"} value={5}></option>
              <option key={6} label={"Saturday"} value={6}></option>
              <option key={0} label={"Sunday"} value={0}></option>
            </Select>
          </Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
          </Box>
        </Box>

        <Box>
          <TextField
                id="hoursTextField"
                label="Start time"
                type="time"
                style={{width: "120px", margin: "8px", marginTop: "32px"}}
                onChange={(event) => setStartTimeField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: { 
                    min: "00:00",
                    max: "23:59",
                    step: "300"
                  }
                }}
                variant="outlined"
                value={startTimeField}
              />

              <TextField
                id="minutesTextField"
                label="End time"
                type="time"
                step="5"
                style={{width: "120px", margin: "8px", marginTop: "32px", fontWeight: "900"}}
                onChange={(event) => setEndTimeField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                  inputProps: {
                    min: "00:00",
                    max: "23:59",
                    step: "300"
                  }
                }}
                variant="outlined"
                value={endTimeField}
              />
          </Box>
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveNewNormalWorkingTime()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteNormalWorkingTime}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default NormalWorkingTimeEditView;

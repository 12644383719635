import DateTimeHelper from "../Utils/DateTimeHelper";

export default class TimeReportFileDto {
    constructor(
      id,
      timeReport,
      name,
      comment,
      type,
      created,
      amountExcludingVAT,
      amountIncludingVAT
    ) {
      this.id = id;
      this.timeReport = timeReport;
      this.name = name;
      this.comment = comment;
      this.type = type;
      this.created = created;
      this.amountExcludingVAT = amountExcludingVAT;
      this.amountIncludingVAT = amountIncludingVAT;
    }
  }
  
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ApplicationState from "../State/ApplicationState";
import IconButton from "@mui/material/IconButton";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

function TimeReportSentView() {
  const [showMeme, setShowMeme] = React.useState(false);
  const [showMovie, setShowMovie] = React.useState(false);
  const [rated, setRated] = React.useState(false);

  function addMeme() {
    let date = new Date();
    if (ApplicationState.getLastSentTimeReportFromDate()) {
      date = new Date(ApplicationState.getLastSentTimeReportFromDate());
    }
    let month = date.getMonth();

    switch (month) {
      case 5:
        return (
          <iframe
            src="https://assets.pinterest.com/ext/embed.html?id=703898616773494063"
            height="666"
            width="450"
            frameborder="0"
            scrolling="no"
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 6:
        return (
          <iframe
            src="https://assets.pinterest.com/ext/embed.html?id=161355599139520636"
            height="781"
            width="450"
            frameborder="0"
            scrolling="no"
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 7:
        return (
          <iframe src="https://assets.pinterest.com/ext/embed.html?id=589549407485533522" height="407" width="345" frameborder="0" scrolling="no" style={{ maxWidth: "100%" }}></iframe>
        );
      case 8:
        return (
          <iframe src="https://assets.pinterest.com/ext/embed.html?id=790452172112568729" height="664" width="450" frameborder="0" scrolling="no" style={{ maxWidth: "100%" }}></iframe>
        );
      case 9:
        return (
          <iframe src="https://assets.pinterest.com/ext/embed.html?id=425097652329117255" height="550" width="450" frameborder="0" scrolling="no" style={{ maxWidth: "100%" }}></iframe>
        );
      case 10:
        return (
          <iframe src="https://assets.pinterest.com/ext/embed.html?id=661466264008179488" height="535" width="450" frameborder="0" scrolling="no" style={{ maxWidth: "100%" }}></iframe>
        );
      case 11:
        return (
          <iframe src="https://assets.pinterest.com/ext/embed.html?id=382665299581697379" height="430" width="450" frameborder="0" scrolling="no" style={{ maxWidth: "100%" }}></iframe>
        );
    }
    return <Box>?</Box>;
  }

  function addVideoOrMeme() {
    let date = new Date();
    if (ApplicationState.getLastSentTimeReportFromDate()) {
      date = new Date(ApplicationState.getLastSentTimeReportFromDate());
    }
    let month = date.getMonth();
    switch (month) {
      case 0:
        //Januari
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/74pmmkBCpQo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 1:
        //Februari
        return (
          <iframe width="647" height="1151" src="https://www.youtube.com/embed/MJpH1QVbgqI" title="Demonskrivaren på HDAB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ maxWidth: "100%" }}></iframe>
        );
      case 2:
        //Mars
        return (
          <video controls>
            <source src="https://drive.google.com/uc?export=download&id=11IyvaqF9sTGRQzZPwGFmcdqqzgl-J6Mw" type="video/mp4" />
            Easter-edition Defenestrering
          </video>
        );
        case 3:
          //April
          return (
            <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/Ufj5bMkSOfs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
          );
        case 4:
          //Maj
          return (
            <iframe width="647" height="1151" src="https://www.youtube.com/embed/TYnQf7fxRCU" title="Star Wars UNO" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ maxWidth: "100%" }}></iframe>
          );
      case 5:
        //Juni
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/dovpZhsaRHQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 6:
        //Juli
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/ZkH1Wv66OlM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 7:
        //Augusti
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/nLXg7JjjJFo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 8:
        //September
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/2FWjxIVBmmI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 9:
        //Oktober
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/9svRAXGtN0s"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>
        );
      case 10:
        //November
        return (
          <iframe
            width="647"
            height="1151"
            src="https://www.youtube.com/embed/WNEE-9uRA4Y"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            style={{ maxWidth: "100%" }}
          ></iframe>

        );
      case 11:
        //December
        return (
          <iframe width="647" height="1151" src="https://www.youtube.com/embed/C8oUQoSqWU4" title="En julsaga om tidrapportering" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{ maxWidth: "100%" }}></iframe>
        );
    }
    return <Box>?</Box>;
  }

  function addRateBox(type) {
    return (
      <Box
        style={{
          padding: "24px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {!rated && (
            <Typography variant="h5">Did you like this?</Typography>
          )}
          {rated && (
            <Typography variant="h5">
              Thank you for your feedback <SentimentVerySatisfiedIcon />
            </Typography>
          )}
        </Grid>
        {!rated && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              variant="contained"
              color="primary"
              onClick={async () => {
                let user = await ApplicationState.getState().user;
                ApplicationState.getExperimentsRated(type, user.email, "Liked");
                setRated(true);
              }}
              size="large">
              <ThumbUpOutlinedIcon fontSize="large"
                style={{color: "#93264A"}} />
            </IconButton>


            <IconButton
              variant="contained"
              color="primary"
              onClick={async () => {
                let user = await ApplicationState.getState().user;
                ApplicationState.getExperimentsRated(type, user.email, "Didn't like");
                setRated(true);
              }}
              size="large">
              <ThumbDownOutlinedIcon fontSize="large"
                style={{color: "#93264A"}} />
            </IconButton>
          </Grid>
        )}
      </Box>
    );
  }

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
                {addHeaderText()}
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h4">Finally it's over!</Typography>
        </Box>
      </Typography>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box style={{ margin: "8px" }}>
          <Paper elevation={7}>
            <Box
            >
              
                  {addHeader()}
              
              {!showMovie && !showMeme && (
                <Box p={3}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      style={{ maxWidth: "80%", textAlign: "center" }}
                    >
                      We're sorry you had to go through that but now you are
                      done and here is your reward <SentimentVerySatisfiedIcon /> <br />
                    </Typography>
                  </Grid>
                </Box>
              )}
              {showMeme && (
                <Box p={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingBottom: "24px", paddingTop: "24px" }}
                  >
                    {addMeme()}
                  </Grid>
                  {addRateBox("meme")}
                </Box>
              )}

                <Box p={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingBottom: "24px", paddingTop: "24px" }}
                  >
                    {addVideoOrMeme()}
                  </Grid>
                  {addRateBox("movie")}
                </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default TimeReportSentView;

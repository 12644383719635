import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import { PrimaryButton } from "../Components/PrimaryButton";
import { styled } from "@mui/material/styles";
import { StyledDataGrid } from "./StyledDataGrid";
import Card from "@mui/material/Card";
import DefaultActivityEditView from "./DefaultActivityCrudView";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "60px",
});

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function OrganizationSettingsView() {
  const [reportingInterval, setReportingInterval] = useState(
    ApplicationState.getReportingInterval()
  );
  const [absenceProject, setAbsenceProject] = useState(
    ApplicationState.getAbsenceProject()
  );
  const [useFlexTime, setUseFlexTime] = useState(
    ApplicationState.getUseFlexTime()
  );
  const [flexWithdrawalActivity, setFlexWithdrawalActivity] = useState(
    ApplicationState.getFlexWithdrawalActivity()
  );

  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);
  const [history] = useState(useHistory());
  const [defaultActivities, setDefaultActivities] = useState([]);
  const [openActivityDialog, setOpenActivityDialog] = useState(false);
  const [] = useState([]);

  const activityColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "code",
      headerName: "Code",
      maxWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
  ];

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let p = await ApplicationState.getProjectsForOrganization(
        ApplicationState.getState().user.organization.organizationId
      );
      setProjects(p);
      loadActivities();
    }
    load();
  }, []);

  useEffect(() => {
    async function load() {
      if(!absenceProject){
        return;
      }
      let a = await ApplicationState.getActivitiesForProject(absenceProject);
      setActivities(a);
    }
    load();
  }, [absenceProject]);

  const loadActivities = async () => {
    let defaultActivities = await ApplicationState.getDefaultActivitiesForOrganization(
      ApplicationState.getUser().organization.organizationId
    );
    setDefaultActivities(defaultActivities);
  };

  function cancelClick() {
    history.push("/settings");
  }

  const handleChangeReportinInterval = (event) => {
    setReportingInterval(event.target.value);
  };

  const addForm = () => {
    return (
      <Box>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">Reporting interval</FormLabel>
            <RadioGroup
              aria-label="reporting interval"
              name="ReportingInterval"
              value={reportingInterval}
              onChange={handleChangeReportinInterval}
            >
              <FormControlLabel
                value="Month"
                control={<Radio color="primary" />}
                label="Month"
              />
              <FormControlLabel
                value="Week"
                control={<Radio color="primary" />}
                label="Week"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <br />
        <Divider ></Divider>
        <br />
        <Box>
          <FormLabel component="legend">Absence project</FormLabel>
          <FormControl component="fieldset">
            <Box style={{ float: "left" }}>
              <InputLabelSelect
                id="projectSelectLabel"
                htmlFor="projectSelect"
                variant="outlined"
                shrink={true}
              >
                Project
              </InputLabelSelect>
              <Select
                native
                onChange={(event) => {
                  setAbsenceProject(event.target.value);
                }}
                id="projectSelect"
                variant="outlined"
                style={{ width: "300px", margin: "8px", fontWeight: "500" }}
                value={absenceProject}
                labelId="projectSelectLabel"
              >
                {projects
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((project) => {
                    return addProject(project);
                  })}
              </Select>
            </Box>
          </FormControl>
        </Box>
        <br />
        <Divider ></Divider>
        <br />
        <Box>
          <FormLabel component="legend">Flex time</FormLabel>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  checked={useFlexTime}
                  onChange={(event) => {
                    setUseFlexTime(event.target.checked);
                  }}
                  name="useFlexTime"
                  color="primary"
                />
              }
              label="Use flex time"
            />
          </FormControl>
        </Box>
        {useFlexTime && (
          <React.Fragment>
            <br />
            <Box>
              <FormLabel component="legend">Flex withdrawal activity</FormLabel>
              <FormControl component="fieldset">
                <Box style={{ float: "left" }}>
                  <InputLabelSelect
                    id="activitySelectLabel"
                    htmlFor="activitySelect"
                    variant="outlined"
                    shrink={true}
                  >
                    Activity
                  </InputLabelSelect>
                  <Select
                    native
                    onChange={(event) => {
                      setFlexWithdrawalActivity(event.target.value);
                    }}
                    id="activitySelect"
                    variant="outlined"
                    style={{ width: "300px", margin: "8px", fontWeight: "500" }}
                    value={flexWithdrawalActivity}
                    labelId="activitySelectLabel"
                  >
                    {absenceProject && absenceProject.length > 0 && (
                      <React.Fragment>
                        {activities
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((activity) => {
                            return addActivity(activity);
                          })}
                      </React.Fragment>
                    )}
                    {!absenceProject ||
                      (absenceProject.length <= 0 && (
                        <option aria-label="None" value="" />
                      ))}
                  </Select>
                </Box>
              </FormControl>
            </Box>
            <br />
          </React.Fragment>
        )}
      </Box>
    );
  };

  const addDefaultActivities = () => {
    if (!defaultActivities) {
      return;
    }
    return addDefaultActivitiesBox();
  };

  function addDefaultActivitiesBox() {
    return (
      <CustomGrid item component={Card} xs style={{margin: "0px"}}>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Default Activities
            </Typography>
            <Box>{addAddActivityButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={activityColumns}
          rows={defaultActivities}
          onRowClick={editActivityClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editActivityClick(params) {
     ApplicationState.setDefaultActivityToEdit(params.row);
     setOpenActivityDialog(true);
   }

  function addAddActivityButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add activity"
          buttonClick={() => addActivityClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addActivityClick() {
    ApplicationState.setActivityToEdit(undefined);
    setOpenActivityDialog(true);
  }

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  function addActivity(activity) {
    return (
      <option
        key={activity.id}
        label={activity.name}
        value={activity.id}
      ></option>
    );
  }

  const saveSettings = async () => {
    let orgSettings = ApplicationState.getOrganizationSettings();
    orgSettings.reportingInterval = reportingInterval;
    orgSettings.absenceProject = absenceProject;
    orgSettings.useFlexTime = useFlexTime;
    orgSettings.flexWithdrawalActivity = flexWithdrawalActivity;

    await ApplicationState.saveUpdatedOrganizationSetting(orgSettings);
    await ApplicationState.loadDataForUser();
  };

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">General settings</Typography>
        </Box>
      </Typography>
    );
  }

  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveSettings()}
              >
              </PrimaryButton>
      </Box>
    );
  }

  const addedActivity = async () => {
    await loadActivities();
    setOpenActivityDialog(false);
  };

  const editedActivity = async () => {
    await loadActivities();
    setOpenActivityDialog(false);
  };

  const deletedActivity = async () => {
    await loadActivities();
    setOpenActivityDialog(false);
  };

  return (
    <Box p={0}>
      <Box>
        {addHeader()}
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box>{addDefaultActivities()}</Box>
      <Dialog
          open={openActivityDialog}
          onClose={() => setOpenActivityDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DefaultActivityEditView
            deletedActivity={deletedActivity}
            addedActivity={addedActivity}
            editedActivity={editedActivity}
            cancelClick={() => setOpenActivityDialog(false)}
          />
        </Dialog>
    </Box>
  );
}

export default OrganizationSettingsView;

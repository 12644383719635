import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ProjectSettingDto from "../Dtos/ProjectSettingDto";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";

function ProjectSettingEditView(props) {
  const [valueField, setValueField] = useState(undefined);
  const [nameField, setNameField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [projectField, setProjectField] = useState(undefined);
  const [projects, setProjects] = useState([]);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let projects = await ApplicationState.getProjectsForOrganization(ApplicationState.getState().user.organization.organizationId);
      if(ApplicationState.getUserForProjectSettingToAdd()){
        projects = await ApplicationState.getProjectsForUser(ApplicationState.getUserForProjectSettingToAdd().userId);
      }
      if (projects !== undefined || projects.length !== 0) {
        setProjectField(projects[0].id);
        setProjects(projects.sort((a, b) => (a.name > b.name ? 1 : -1)));
      }
      if(ApplicationState.getProjectToAddSettingFor()){
        setProjectField(ApplicationState.getProjectToAddSettingFor().id);
      }
      if (ApplicationState.getProjectSettingToEdit()) {
        setEditMode(true);
        setValueField(ApplicationState.getProjectSettingToEdit().value);
        setNameField(ApplicationState.getProjectSettingToEdit().name);
        setProjectField(ApplicationState.getProjectSettingToEdit().project.id);
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let project = projects.filter(
      (a) => a.id === projectField
    )[0];

    let dto = new ProjectSettingDto(
      ApplicationState.generateGuid(),
      project,
      ApplicationState.getUserForProjectSettingToAdd(),
      nameField,
      valueField,
      new Date()
    );
    if (ApplicationState.getProjectSettingToEdit()) {
      dto.id = ApplicationState.getProjectSettingToEdit().id;
      await ApplicationState.saveUpdatedProjectSetting(dto);
    } else {
      await ApplicationState.saveNewProjectSetting(dto);
    }

    if(ApplicationState.getUserForProjectSettingToAdd()){
      ApplicationState.setUserForProjectSettingToAdd(undefined);
      ApplicationState.setProjectSettingToEdit(undefined);
      props.editedProjectSetting();
    }
    if(!ApplicationState.getUserForProjectSettingToAdd()){
      ApplicationState.setUserForProjectSettingToAdd(undefined);
      ApplicationState.setProjectSettingToEdit(undefined);
      props.addedProjectSetting();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteProjectSetting(
      ApplicationState.getProjectSettingToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete project with id: " +
          ApplicationState.getProjectSettingToEdit().id
      );
      return;
    }
    if(ApplicationState.getUserForProjectSettingToAdd()){
      ApplicationState.setUserForProjectSettingToAdd(undefined);
      ApplicationState.setProjectSettingToEdit(undefined);
      props.deletedProjectSetting();
    }
    if(!ApplicationState.getUserForProjectSettingToAdd()){
      ApplicationState.setUserForProjectSettingToAdd(undefined);
      ApplicationState.setProjectSettingToEdit(undefined);
      props.deletedProjectSetting();
    }
  }

  const getHeaderText = () => {
    let headerText = "Add project setting";
    if (editMode) {
      headerText = "Edit project setting";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <Select
                native
                onChange={(event) => {
                  setProjectField(event.target.value);
                }}
                id="projectSelect"
                variant="outlined"
                style={{margin: "8px"}}
                value={projectField}
              >
                {projects.map((project) => {
                  return addProject(project);
                })}
              </Select>
              <br />
              <TextField
                id="nameTextField"
                label="Name"
                type="text"
                style={{margin: "8px"}}
                onChange={(event) => setNameField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={nameField}
              />
              <br />
              <TextField
                id="valueTextField"
                label="Value"
                type="text"
                style={{margin: "8px"}}
                onChange={(event) => setValueField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={valueField}
              />
              <br />
      </Box>
    );
  };
  
  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
<Grid container justifyContent="flex-end" direction="row">
  <Box>
    <IconButton
      variant="contained"
      color="primary"
      onClick={cancelClick}
      size="large">
      <CloseIcon
        fontSize="medium"
        style={{color: "#93264A"}}
      ></CloseIcon>
    </IconButton>
  </Box>
</Grid>
</Box>
<Box
style={{
  backgroundColor: "#f6f6f6",
  paddingBottom: "12px",
  paddingTop: "0px",
}}
>
<Grid container justifyContent="flex-start" alignItems="center">
  <Box
    style={{
      paddingLeft: "24px",
      paddingTop: "0px",
      paddingBottom: "16px",
    }}
  >
    <Typography variant="h5">
      <b>{getHeaderText()}</b>
    </Typography>
  </Box>
</Grid>
</Box>
<Box p={2}>{addForm()}</Box>
<Box
style={{
  backgroundColor: "#93264A",
  paddingBottom: "12px",
  paddingTop: "12px",
}}
>
<Grid container justifyContent="flex-end" alignItems="center">
  <Box
    style={{
      paddingLeft: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
    }}
  >
    <Box p={1}>
      <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
      </PrimaryButton>
      {editMode && (
        <DeleteButton
          onClick={deleteClick}
        >
        </DeleteButton>
      )}
    </Box>
  </Box>
</Grid>
</Box>
      
      
    </Box>
  );
}

export default ProjectSettingEditView;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "60px",
});

function UserSettingsView() {
  const [defaultTimeReportView, setDefaultTimeReportView] = useState(
    ApplicationState.getDefaultTimeReportViewMode()
  );
  const [projects, setProjects] = useState([]);
  const [defaultProjectManualTimeEntry, setDefaultProjectManualTimeEntry] = useState(ApplicationState.getDefaultProjectManualTimeEntry());

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let p = await ApplicationState.getProjectsForUser(ApplicationState.getState().user.userId);
      p = ApplicationState.getState().activeProjects.sort((a, b) => (a.name > b.name ? 1 : -1));
      setProjects(p);
    }
    load();
  }, []);

  const handleChangeDefaultTimeReportView = (event) => {
    setDefaultTimeReportView(event.target.value);
  };

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  const addForm = () => {
    return (
      <Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">Default view for time report</FormLabel>
          <RadioGroup
            aria-label="default time report view"
            name="DefaultTimeReportVIew"
            value={defaultTimeReportView}
            onChange={handleChangeDefaultTimeReportView}
          >
            <FormControlLabel
              value="Simple"
              control={<Radio color="primary" />}
              label="Simple"
            />
            <FormControlLabel
              value="Week"
              control={<Radio color="primary" />}
              label="Normal"
            />
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <br />
        <Box>
        <FormLabel component="legend">Default project for manual time entry</FormLabel>
        <FormControl component="fieldset">
        <Box style={{float: "left"}}>
                <InputLabelSelect
                  id="projectSelectLabel"
                  htmlFor="projectSelect"
                  variant="outlined"
                  shrink={true}
                >
                  Project
                </InputLabelSelect>
                <Select
                  native
                  onChange={(event) => {
                    setDefaultProjectManualTimeEntry(event.target.value);
                  }}
                  id="projectSelect"
                  variant="outlined"
                  style={{width: "300px", margin: "8px", fontWeight: "500"}}
                  value={defaultProjectManualTimeEntry}
                  labelId="projectSelectLabel"
                >
                  {projects.sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((project) => {
                      return addProject(project);
                    })}
                </Select>
              </Box>
        </FormControl>
        </Box>
      </Box>
    );
  };

  const saveSettings = async () => {
    let userSettings = ApplicationState.getUserSettings();
    userSettings.defaultTimeReportView = defaultTimeReportView;
    userSettings.defaultProjectManualTimeEntry = defaultProjectManualTimeEntry;
    await ApplicationState.saveUpdatedUserSetting(userSettings);
  };

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addSaveButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">General settings</Typography>
        </Box>
      </Typography>
    );
  }
  function addSaveButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveSettings()}
              >
              </PrimaryButton>
      </Box>
    );
  }

  return (
    <Grid>
            <Box p={0}>
              {addHeader()}
              </Box>
              <Box p={2}>{addForm()}</Box>
    </Grid>
  );
}

export default UserSettingsView;

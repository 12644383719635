import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TooltipMUI from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export const Tooltip = (props) => {
  return (
    <TooltipMUI
      title={<Typography variant="subtitle1">{props.text}</Typography>}
      aria-label="flex tooltip"
    >
      <InfoOutlinedIcon
        style={{ verticalAlign: "super", fontSize: "large" }}
      ></InfoOutlinedIcon>
    </TooltipMUI>
  );
};

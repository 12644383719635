import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const SDataGrid = styled(DataGrid)({
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#d8d8d8",
    lineHeight: "1.4",
  },
  "& .MuiDataGrid-cell": {
    borderRight: "2px solid #eee", // Add a black vertical line between cells
    borderBottomWidth: "0px",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    "& .MuiDataGrid-row": {
      "&:nth-child(2n)": { backgroundColor: "rgba(246, 246, 246, 1)" },
    },
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#111", // Changes the color of sort icons to blue
  },
  "& .MuiDataGrid-columnSeparator": {
    color: "#777", // Changes the color of sort icons to blue
  },
});

export const StyledDataGrid = (props) => {

  if(props.useUserIdAsRowId) {
    return (
      <SDataGrid
        rows={props.rows}
        columns={props.columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[100]}
        style={{ backgroundColor: "white" }}
        onRowClick={props.onRowClick}
        getRowId={(row) => row.userId}
      />
    );
  }

  return (
    <SDataGrid
      rows={props.rows}
      columns={props.columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      pageSizeOptions={[100]}
      style={{ backgroundColor: "white" }}
      onRowClick={props.onRowClick}
    />
  );
};

export default class ApiExporterDto {
  constructor(id, name, description, organization, created, url, format, token, tenantId) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.organization = organization;
    this.created = created;
    this.url = url;
    this.format = format;
    this.token = token;
    this.tenantId = tenantId;
  }
}

import GoogleLoginButton from "./GoogleLoginButton";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import Box from "@mui/material/Box";
import logo from "./../logo_white_text.svg";
import { Grid, Typography, Hidden } from "@mui/material";
import { withRouter, useHistory } from "react-router-dom";
import ApplicationState from "../State/ApplicationState";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

const CLIENT_ID =
  "735586588965-en607klmp7sh4aeghmgg69o67cdjq254.apps.googleusercontent.com";

function Login(props) {
  let history = useHistory();
  useEffect(() => {
    if(ApplicationState.getToken() && ApplicationState.getToken().length > 0) {
      history.push("/timereport");
    }
  }, []);

  // Check if the "code" parameter is in the query string.
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid container justifyContent="center" spacing={2}>
        <img src={logo} style={{height: 112, margin: "10px 5px 10px 5px", alignContent: ""}} width="85%" />
      </Grid>
    
      <Divider style={{width: "168px", marginBottom: "28px", marginTop: "12px",  backgroundColor: "white"}}></Divider>
      <Box p="8px" m="8px">
          <GoogleLoginButton
            style={{padding: "4px"}}
            doLoginFunction={props.doLoginFunction}
          />
        </Box>
        <Box
          p="8px"
          m="8px"
        >
          <MicrosoftLoginButton
            style={{padding: "4px"}}
            doLoginFunction={props.doLoginFunction}
          />
        </Box>
        <Link
            onClick={() => {
              history.push("/faq");
            }}
            style={{ color: "White", padding: "8px", paddingBottom: "16px"}}
          >
            <i>Do you need help?</i>
          </Link>

    </Grid>
  );
}

export default withRouter(Login);

export default class AssignmentDto {
  constructor(id, user, title, description, client, startDate, endDate, ongoing, created) {
    this.id = id;
    this.user = user;
    this.title = title;
    this.description = description;
    this.client = client;
    this.startDate = startDate;
    this.endDate = endDate;
    this.ongoing = ongoing;
    this.created = created;
  }
}

export default class EmailDto {
  constructor(id, lastModifiedDateTime, subject, body, from, to, categories, userData) {
    this.id = id;
    this.lastModifiedDateTime = lastModifiedDateTime;
    this.subject = subject;
    this.body = body;
    this.from = from;
    this.to = to;
    this.categories = categories;
    this.userData = userData;
  }
}

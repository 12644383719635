import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import DateTimeHelper from "../Utils/DateTimeHelper";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function TimeReportViews() {
  var today = new Date();
  const [weekNrSelected, setWeekNrSelected] = useState(ApplicationState.getTimeReportsFilter().weekNr);
  const [monthSelected, setMonthSelected] = useState(ApplicationState.getTimeReportsFilter().month);
  const [yearSelected, setYearSelected] = useState(ApplicationState.getTimeReportsFilter().year);
  const [weeksForYear, setWeeksForYear] = useState(ApplicationState.getTimeReportsFilter().weeksForYear);
  const [timeReports, setTimeReports] = useState([]);
  const [timeReportsReady, setTimeReportsReady] = useState([]);
  const [timeReportsApproved, setTimeReportsApproved] = useState([]);
  const [usersInOrganization, setUsersInOrganization] = useState([]);
  const [usersWithNoReportSentOrApproved, setUsersWithNoReportSentOrApproved] =
    useState(false);

  let history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    let from = new Date(yearSelected, monthSelected, 1);
    let to = DateTimeHelper.getLastDayInMonth(yearSelected, monthSelected);
    const load = async () => {
      var weeksForYear = DateTimeHelper.printAllDatesAndWeeks(
        today.getFullYear()
      );
      setWeeksForYear(weeksForYear);
      setWeekNrSelected(DateTimeHelper.getWeekNumber(new Date()));
      showTimeReports();
      let usersForOrganization = await ApplicationState.getUsersForOrganization(
        ApplicationState.getState().user.organization.organizationId
      );
      usersForOrganization = usersForOrganization.filter(x => x.firstWorkingDay && new Date(x.firstWorkingDay) <= new Date(to));
      setUsersInOrganization(usersForOrganization);
    };
    load();
  }, []);
  //Kör när monthSelected elller yearSelected ändras
  useEffect(() => {
    showTimeReports();
  }, [weekNrSelected, monthSelected, yearSelected]);

  function showTimeReports() {
    let from = new Date(yearSelected, monthSelected, 1);
    let to = DateTimeHelper.getLastDayInMonth(yearSelected, monthSelected);
    const load = async () => {
      let allReports = await ApplicationState.getTimeReportsForOrganization(
        ApplicationState.getState().user.organization.organizationId
      );
      let reportsToShow = allReports.filter(
        (x) => x.month === monthSelected && x.year === yearSelected
      );
      if (ApplicationState.getReportingInterval() === "Week") {
        reportsToShow = allReports.filter((x) => x.week === weekNrSelected);
      }
      setTimeReports(reportsToShow);
      let readyReports = reportsToShow.filter((x) => x.sent && !x.approved);
      let approvedReports = reportsToShow.filter((x) => x.sent && x.approved);
      setTimeReportsApproved(approvedReports);
      setTimeReportsReady(readyReports);

      let usersNoReport = 0;
      let usersForOrganization = await ApplicationState.getUsersForOrganization(
        ApplicationState.getState().user.organization.organizationId
      );
      usersForOrganization = usersForOrganization.filter(x => x.firstWorkingDay && new Date(x.firstWorkingDay) <= new Date(to));
      setUsersInOrganization(usersForOrganization);
      usersForOrganization.forEach((element) => {
        if (
          !approvedReports.some((x) => x.user.userId === element.userId) &&
          !readyReports.some((x) => x.user.userId === element.userId)
        ) {
          usersNoReport++;
        }
      });
      setUsersWithNoReportSentOrApproved(usersNoReport);
      let filter = ApplicationState.getTimeReportsFilter();
      filter.year = yearSelected;
      filter.month = monthSelected;
      filter.weekNr = weekNrSelected;
      filter.weeksForYear = DateTimeHelper.printAllDatesAndWeeks(yearSelected);
      ApplicationState.setTimeReportsFilter(filter);
    };
    load();
  }

  const handleChangedWeek = (event) => {
    setWeekNrSelected(event.target.value);
  };
  const handleChangedMonth = (event) => {
    setMonthSelected(parseInt(event.target.value));
  };
  const handleChangedYear = (event) => {
    setYearSelected(parseInt(event.target.value));
  };

  function addWeekOption(week) {
    return (
      <option
        key={week.number}
        label={DateTimeHelper.getWeekName(week)}
        value={week.number}
        style={{ color: "#111" }}
      ></option>
    );
  }

  function addTimeReports(state) {
    return usersInOrganization
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .map((element) => {
        return addTimeReport(element, state);
      });
  }

  function addTimeReport(user, state) {
    let timeReport = timeReports.find((x) => x.user.userId === user.userId);
    let primaryText = user.firstName + " " + user.lastName;
    let secondaryText = "";
    if(user.isSubContractor) {
      secondaryText = user.subContractorCompanyName;
    }
    
    if (state === "ready") {
      timeReport = timeReportsReady.find((x) => x.user.userId === user.userId);
      if (!timeReport) {
        return <React.Fragment></React.Fragment>;
      }
    }
    if (state === "approved") {
      timeReport = timeReportsApproved.find(
        (x) => x.user.userId === user.userId
      );
      if (!timeReport) {
        return <React.Fragment></React.Fragment>;
      }
    }
    if (state === "notsent") {
      timeReport = timeReports.find((x) => x.user.userId === user.userId);
      if (timeReport && (timeReport.sent || timeReport.approved)) {
        return <React.Fragment></React.Fragment>;
      }
    }

    if (
      !ApplicationState.isAdmin() &&
      ApplicationState.isTimeReportApprover()
    ) {
      if (
        user.timeReportApprover &&
        user.timeReportApprover.userId !=
          ApplicationState.getState().user.userId
      ) {
        return <React.Fragment></React.Fragment>;
      }
      if (!user.timeReportApprover) {
        return <React.Fragment></React.Fragment>;
      }
    }

    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        button
        onClick={() => goToShowTimeReport(timeReport)}
      >
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
        />
        {timeReport && timeReport.sent && !timeReport.approved && (
          <Typography>
            <b>Ready for approval</b>
          </Typography>
        )}
        {timeReport && timeReport.sent && timeReport.approved && (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ padding: "0px", maxWidth: "175px" }}
          >
            <Typography
              style={{
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingRight: "4px",
                color: "green",
              }}
            >
              Approved
            </Typography>
            <LockOutlinedIcon
              style={{
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "4px",
                color: "green",
              }}
            ></LockOutlinedIcon>
          </Grid>
        )}
        {timeReport && !timeReport.sent && (
          <Typography style={{ color: "red" }}>
            <i>No report sent</i>
          </Typography>
        )}
        {!timeReport && (
          <Typography style={{ color: "red" }}>
            <i>No report sent</i>
          </Typography>
        )}
      </ListItem>
    );
  }

  function goToShowTimeReport(timeReport) {
    if (timeReport) {
      ApplicationState.setTimeReportToView(timeReport);
      history.push("/timereportuser");
    }
  }

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Time reports</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box style={{ margin: "8px" }}>
        {ApplicationState.getReportingInterval() === "Week" && (
          <FormControl variant="outlined">
            <Select
              native
              value={weekNrSelected}
              onChange={handleChangedWeek}
              style={{ margin: "4px", backgroundColor: "white" }}
            >
              {weeksForYear.map((week) => {
                return addWeekOption(week);
              })}
            </Select>
          </FormControl>
        )}
        {ApplicationState.getReportingInterval() === "Month" && (
          <FormControl variant="outlined">
            <Select
              native
              id="monthSelect"
              style={{ margin: "4px", backgroundColor: "white" }}
              value={monthSelected}
              onChange={handleChangedMonth}
              variant="outlined"
            >
              <option value={-1}>All</option>
              <option value={0}>January</option>
              <option value={1}>February</option>
              <option value={2}>March</option>
              <option value={3}>April</option>
              <option value={4}>May</option>
              <option value={5}>June</option>
              <option value={6}>July</option>
              <option value={7}>August</option>
              <option value={8}>September</option>
              <option value={9}>October</option>
              <option value={10}>November</option>
              <option value={11}>December</option>
            </Select>
          </FormControl>
        )}

        <FormControl variant="outlined">
          <Select
            native
            id="yearSelect"
            style={{ margin: "4px", backgroundColor: "white" }}
            value={yearSelected}
            onChange={handleChangedYear}
            variant="outlined"
          >
            <option value={2021}>2021</option>
            <option value={2022}>2022</option>
            <option value={2023}>2023</option>
            <option value={2024}>2024</option>
          </Select>
        </FormControl>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7}>
            <Box>
              <Box>{addHeader()}</Box>
              <Box>
                <Box style={{ padding: "16px", backgroundColor: "#f6f6f6" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">Ready for approval</Typography>
                    <Typography variant="h6">
                      {timeReportsReady.length} reports
                    </Typography>
                  </Grid>
                </Box>
                <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  {addTimeReports("ready")}
                </List>

                <Box style={{ padding: "16px", backgroundColor: "#f6f6f6" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">No report sent</Typography>
                    <Typography variant="h6">
                      {usersWithNoReportSentOrApproved} reports
                    </Typography>
                  </Grid>
                </Box>
                <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  {addTimeReports("notsent")}
                </List>

                <Box style={{ padding: "16px", backgroundColor: "#f6f6f6" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">Approved reports</Typography>
                    <Typography variant="h6">
                      {timeReportsApproved.length} reports
                    </Typography>
                  </Grid>
                </Box>
                <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  {addTimeReports("approved")}
                </List>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default TimeReportViews;

import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import Snackbar from "@mui/material/Snackbar";
import Alert from '@mui/material/Alert';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

export default function Main() {
  const [error, setError] = useState(undefined);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      setError(message);
      setAlertOpen(true);
    };
    window.onunhandledrejection = function (event) {
      setError(event.reason.message);
      setAlertOpen(true);
    };
  }, []);

  function handleCloseAlert(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#93264A",
      },
      secondary: {
        main: "#fff",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Snackbar
          open={alertOpen}
          onClose={handleCloseAlert}
          autoHideDuration={5000}
        >
          <Alert severity="error" onClose={handleCloseAlert}>
            An occured: {error}
          </Alert>
        </Snackbar>
          <TopBar></TopBar>
          <main style={{flexGrow: 1}}>
            <div />
          </main>
        </ThemeProvider>
    </StyledEngineProvider>
  );
}



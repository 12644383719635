import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import { PrimaryButton } from "./PrimaryButton";
import NormalWorkingTimeEditView from "./NormalWorkingTimeEditView";
import DateTimeHelper from "../Utils/DateTimeHelper";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip } from "../Components/Tooltip";

function NormalWorkingTimeView() {
  const [workingTimes, setWorkingTimes] = useState([]);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      loadWorkingTimes();
    }
    load();
  }, []);

  function addNewNormalWorkingTime(weekday) {
    ApplicationState.setNormalWorkingTimeToEdit(undefined);
    ApplicationState.setUserToAddNormalWorkingTimeFor(
      ApplicationState.getState().user
    );
    ApplicationState.setWeekdayToAddNormalWorkingTimeFor(weekday);
    setOpenAddEditDialog(true);
  }

  function editWorkingTime(workingTimeToEdit) {
    ApplicationState.setNormalWorkingTimeToEdit(workingTimeToEdit);
    ApplicationState.setUserToAddNormalWorkingTimeFor(undefined);
    ApplicationState.setWeekdayToAddNormalWorkingTimeFor(undefined);
    setOpenAddEditDialog(true);
  }

  async function deleteWorkingTime(workingTimeToDelete) {
    await ApplicationState.deleteNormalWorkingTime(workingTimeToDelete.id);
    let keywords2 = workingTimes.slice();
    const index = keywords2.indexOf(workingTimeToDelete);
    if (index > -1) {
      keywords2.splice(index, 1);
    }
    setWorkingTimes(keywords2);
  }

  function addWeekday(weekday, rowNr) {
    let color = "#f6f6f6"
    if (rowNr % 2 === 0) {
      color = "#fff";
    }
      return (
        <React.Fragment>
        <ListItem
          key={ApplicationState.generateGuid()}
          style={{ backgroundColor: color }}
        >
          <ListItemText
            primary={
              <Typography
                variant="h6"
                style={{
                  margin: "8px",
                  marginBottom: "0px",
                  paddingLeft: "4px",
                }}
              >
                {DateTimeHelper.getDayNameFromWeekday(weekday)}
                <IconButton
                  aria-label="addNewKeyword"
                  onClick={() => addNewNormalWorkingTime(weekday)}
                  sx={{
                    color: "#93264A",
                    margin: "0px",
                    fontSize: "medium",
                    "&:hover": {
                      color: "#93264A",
                    },
                    "&:focus": {
                      color: "#93264A",
                    },
                  }}
                  size="large"
                >
                  <AddCircleIcon />
                </IconButton>
              </Typography>
            }
            secondary={addWorkingTimeForWeekday(weekday)}
          />
        </ListItem>
        </React.Fragment>
      );
  }

  function addWorkingTimeForWeekday(weekday) {
    return workingTimes
      .filter((x) => x.weekday === weekday)
      .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
      .map((element) => {
        return addExistingWorkingTime(element);
      });
  }

  function addWorkingTimes() {
    let rowNr = 0;
    var weekdays = [1, 2, 3, 4, 5, 6, 0];
    return weekdays.map((element) => {
      return addWeekday(element, rowNr++);
    });
  }

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#d8d8d8" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Normal working hours{" "}<Tooltip text="All items found outside these hours will increase your flex time." /></Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add working time"
          buttonClick={() => addNewNormalWorkingTime()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addExistingWorkingTime(workingTime) {
    return (
      <Chip
        clickable={true}
        style={{
          backgroundColor: "#93264A",
          color: "#fff",
          margin: "8px",
          fontSize: "larger",
          padding: "4px",
          height: "42px",
          "&:hover": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
          "&:focus": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
        }}
        onDelete={() => deleteWorkingTime(workingTime)}
        onClick={() => editWorkingTime(workingTime)}
        label={
          <b style={{ fontWeight: "400" }}>
            {workingTime.startTime.substring(0, 5) +
              " - " +
              workingTime.endTime.substring(0, 5)}
          </b>
        }
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };

  const deletedWorkingTime = async () => {
    await loadWorkingTimes();
    setOpenAddEditDialog(false);
  };
  const editedWorkingTime = async () => {
    await loadWorkingTimes();
    setOpenAddEditDialog(false);
  };
  const addedWorkingTime = async () => {
    await loadWorkingTimes();
    setOpenAddEditDialog(false);
  };

  const loadWorkingTimes = async () => {
    let workingTimeForUser =
      await ApplicationState.getNormalWorkingTimesForUser(
        ApplicationState.getState().user.email
      );
    setWorkingTimes(workingTimeForUser);
  };

  function getMaxWeekday() {
    let maxWeekday = 0;
    let containSunday = false;
    workingTimes.forEach((element) => {
      if (element.weekday > maxWeekday) {
        maxWeekday = element.weekday;
      }
      if (element.weekday === 0) {
        containSunday = true;
      }
    });
    if (containSunday) {
      return 1;
    }
    if (maxWeekday === 5) {
      return 5;
    }
    if (maxWeekday === 6) {
      return 5;
    } else {
      return maxWeekday + 1;
    }
  }

  return (
    <Grid>
            <Box p={0}>
              <Box>
                <Grid
                  container
                  alignItems="stretch"
                  justifyContent="space-between"
                  direction="row"
                ></Grid>
                {addHeader()}
                <List style={{ padding: "0px" }}>{addWorkingTimes()}</List>
              </Box>
            </Box>
        <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <NormalWorkingTimeEditView
            cancelClick={handleCloseAddEditDialog}
            addedWorkingTime={addedWorkingTime}
            editedWorkingTime={editedWorkingTime}
            deletedWorkingTime={deletedWorkingTime}
            maxWeekday={getMaxWeekday}
          ></NormalWorkingTimeEditView>
        </Dialog>
    </Grid>
  );
}

export default NormalWorkingTimeView;

import React from "react";
import ApplicationState from "../State/ApplicationState";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { PrimaryButton } from '../Components/PrimaryButton';

export default function EmailLoginButton(props) {
  let history = useHistory();

  async function login() {
      await ApplicationState.loadConfig();
      let result = await ApplicationState.loadUser(
        props.email,
        "Email",
        props.code
      );
      if (typeof result === "string") {
        alert(result);
        return;
      }
      let user = await ApplicationState.getState().user; 
      if (user === "No user found!") {
        alert("No user found!");
        return;
      }
      
      await ApplicationState.loadDataForUser();
      props.doLoginFunction();
      if(user.onboardingCompleted) {
        history.push("/timereport");
      }
      if(!user.onboardingCompleted) {
        history.push("/onboarding");
      }
  }

  function handleLoginFailure(response) {
    console.log(response);
    alert("Failed to log in");
  }

  return (
    <Box p={1}>
      <PrimaryButton
        icon="login"
        text="Login via Email"
        buttonClick={() => login()}
      >
      </PrimaryButton>
      </Box>
  );
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import HolidayView from "./HolidayView";
import HolidayEditView from "./HolidayEditView";
import { PrimaryButton } from '../Components/PrimaryButton';

function HolidaysView() {
  const [holidays, setHolidays] = useState([]);
  const [holidayToView, setHolidayToView] = useState(undefined);
  const [openUserDialog, setOpenHolidayDialog] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let orgId = ApplicationState.getState().user.organization.organizationId;
      let holidays = await ApplicationState.getHolidaysForOrganization(orgId);
      setHolidays(holidays);
      setHolidayToView(holidays[0]);
    }
    load();
  }, []);

  async function editedHoliday() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let holidays = await ApplicationState.getHolidaysForOrganization(orgId);
    setHolidays(holidays);
    setHolidayToView(holidays.find((x) => x.id === holidayToView.id));
    setOpenHolidayDialog(false);
  }

  async function addedHoliday() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let holidays = await ApplicationState.getHolidaysForOrganization(orgId);
    setHolidays(holidays);
    setHolidayToView(
      holidays.find((x) => x.id === ApplicationState.getHolidayToView().id)
    );
    setOpenHolidayDialog(false);
  }

  async function deletedHoliday() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let holidays = await ApplicationState.getHolidaysForOrganization(orgId);
    setHolidays(holidays);
    setHolidayToView(holidays[0]);
    setOpenHolidayDialog(false);
  }

  function viewHoliday(holidayToView2) {
    setHolidayToView(holidayToView2);
  }

  function newHolidayClick() {
    ApplicationState.setHolidayToEdit(undefined);
    setOpenHolidayDialog(true);
  }

  function addHoliday(holiday) {
    let primaryText = new Date(holiday.date).toLocaleDateString("sv-SE");
    if(holiday.name && holiday.name.length > 0){
      primaryText = primaryText + ", " + holiday.name;
    }

    return (
      <Box>
        {holidayToView && holidayToView.id === holiday.id && (
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => viewHoliday(holiday)}
            style={{
              backgroundColor: "#781938",
              color: "white",
              padding: "16px",
            }}
          >
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={primaryText} />
          </ListItem>
        )}
        {!holidayToView ||
          (holidayToView.id != holiday.id && (
            <ListItem
              key={ApplicationState.generateGuid()}
              button
              onClick={() => viewHoliday(holiday)}
            >
              <ListItemAvatar></ListItemAvatar>
              <ListItemText primary={primaryText} />
            </ListItem>
          ))}
      </Box>
    );
  }

  function addHolidays() {
    return holidays
      .sort((a, b) => (a.date < b.date ? 1 : -1))
      .map((element) => {
        return addHoliday(element);
      });
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Holidays</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add holiday"
                buttonClick={() => newHolidayClick()}
              >
        </PrimaryButton>
      </Box>
    );
  }

  return (
    <Box p={0}>
      <Box>{addHeader()}</Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={4} style={{ backgroundColor: "#f6f6f6" }}>
          <List style={{padding: "0px"}}>{addHolidays()}</List>
        </Grid>
        <Grid item xs={8}>
          {holidayToView && (
            <HolidayView
              selectedHoliday={holidayToView}
              editedHoliday={editedHoliday}
              deletedHoliday={deletedHoliday}
            ></HolidayView>
          )}
          {!holidayToView && <Box>No holiday selected</Box>}
          <Dialog
            open={openUserDialog}
            onClose={() => setOpenHolidayDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <HolidayEditView
              selectedHoliday={holidayToView}
              deletedHoliday={deletedHoliday}
              addedHoliday={addedHoliday}
              editedHoliday={editedHoliday}
              cancelClick={() => setOpenHolidayDialog(false)}
            ></HolidayEditView>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HolidaysView;

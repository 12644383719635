import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ScheduleIcon from "@mui/icons-material/Schedule";
import LabelIcon from "@mui/icons-material/Label";
import AppsIcon from "@mui/icons-material/Apps";
import UserSettingsView from "./UserSettingsView";
import ServicesView from "./ServicesView";
import StandardTimeView from "./StandardTimeView";
import UserKeywordsView from "./UserKeywordsView";
import AutoFlexView from "./AutoFlexView";
import IgnoreKeywordsView from "./IgnoreKeywordsView";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ApplicationState from "../State/ApplicationState";

function SettingsView() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [value, setValue] = React.useState("1");

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#ddd",
    minWidth: "50px",
  });

  const CustomTabPanel = styled(TabPanel)({
    padding: "0px",
  });


  //Kör vid uppstart
  useEffect(() => {
    if(ApplicationState.getServiceNeedRedirectFromHack()){
      setValue("2");
      ApplicationState.setServiceNeedRedirectFromHack(false);
    }
  }, [fromDate, toDate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addTabs = (text) => {
    let text1 = "";
    let text2 = "";
    let text3 = "";
    let text4 = "";
    let text5 = "";
    let text6 = "";
    if(text) {
      text1 = "General";
      text2 = "Services";
      text3 = "Standard Week";
      text4 = "Keywords";
      text5 = "Autoflex";
      text6 = "Ignores";
    }
    return (<TabList
      onChange={handleChange}
      aria-label="simple tabs example"
      style={{backgroundColor: "#781938"}}
      variant="fullWidth"
      textColor="secondary"
      indicatorColor="secondary"
    >
      <CustomTab color="" label={text1} value="1" icon={<SettingsIcon />} 
      />
      <CustomTab label={text2} value="2" icon={<AppsIcon />}
      />
      <CustomTab label={text3} value="3" icon={<ScheduleIcon />}
      />
      <CustomTab label={text4} value="4" icon={<LabelIcon />} 
      />
      <CustomTab label={text5} value="5" icon={<HourglassBottomIcon />} 
      />
      <CustomTab label={text6} value="6" icon={<VisibilityOffIcon />} 
      />
    </TabList>);
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7}>
            <Box p={0}>
              <TabContext value={value}>
                <AppBar position="static">
                    {useMediaQuery(useTheme().breakpoints.down("md")) && (
                    <React.Fragment>
                    {addTabs()}
                    </React.Fragment>
                    )}
                    {useMediaQuery(useTheme().breakpoints.up("md")) && (
                    <React.Fragment>
                    {addTabs(true)}
                    </React.Fragment>
                    )}
                </AppBar>
                <CustomTabPanel value="1">
                  <UserSettingsView />
                </CustomTabPanel>
                <CustomTabPanel value="2"><ServicesView /></CustomTabPanel>
                <CustomTabPanel value="3">
                  <StandardTimeView />
                </CustomTabPanel>
                <CustomTabPanel value="4"><UserKeywordsView /></CustomTabPanel>
                <CustomTabPanel value="5"><AutoFlexView /></CustomTabPanel>
                <CustomTabPanel value="6"><IgnoreKeywordsView /></CustomTabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SettingsView;

export default class ActivitySettingDto {
  constructor(id, activity, user, name, value, created) {
    this.id = id;
    this.activity = activity;
    this.user = user;
    this.name = name;
    this.value = value;
    this.created = created;
  }
}

export default class UserDataDto {
  constructor(id, title, dataObjectId, dateTime, created, userDataServiceScopeSelection, duration, type, eventType, ignore) {
    this.id = id;
    this.title = title;
    this.dataObjectId = dataObjectId;
    this.dateTime = dateTime;
    this.created = created;
    this.userDataServiceScopeSelection = userDataServiceScopeSelection;
    this.duration = duration;
    this.type = type;
    this.eventType = eventType;
    this.ignore = ignore;
  }
}

export default class TimeEntryDTO {
  constructor(
    id,
    activity,
    description,
    date,
    durationMinutes,
    created,
    user,
    userData,
    dateTime,
    updated,
    createdByDynamicTime,
    updatedActivity,
    updatedDuration,
    createdFromStandardTime,
    originalDuration,
    summaryEntry,
    durationOvertime1,
    durationOvertime2,
  ) {
    this.id = id;
    this.activity = activity;
    this.description = description;
    this.date = date;
    this.durationMinutes = parseInt(durationMinutes);
    this.created = created;
    this.user = user;
    this.userData = userData;
    this.dateTime = dateTime;
    this.updated = updated;
    this.createdByDynamicTime = createdByDynamicTime;
    this.updatedActivity = updatedActivity;
    this.updatedDuration = updatedDuration;
    this.createdFromStandardTime = createdFromStandardTime;
    this.originalDuration = originalDuration;
    this.summaryEntry = summaryEntry;
    this.durationOvertime1 = durationOvertime1;
    this.durationOvertime2 = durationOvertime2;
  }
}

import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import ApplicationState from "../State/ApplicationState";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

const CLIENT_ID =
  "735586588965-en607klmp7sh4aeghmgg69o67cdjq254.apps.googleusercontent.com";

export default function GoogleLoginButton(props) {
  let history = useHistory();

  async function login(response) {
    let userInfoDecoded = jwt_decode(response.credential);
    if (userInfoDecoded && userInfoDecoded.email && userInfoDecoded.email_verified) {
      await ApplicationState.loadConfig();
      let result = await ApplicationState.loadUser(
        userInfoDecoded.email,
        "Google",
        response.credential
      );
      if (typeof result === "string") {
        alert(result);
        return;
      }
      let user = await ApplicationState.getState().user; 
      if (user === "No user found!") {
        alert("No user found!");
        return;
      }
      if(userInfoDecoded.picture && userInfoDecoded.picture.length > 0){
        ApplicationState.setUserProfilePictureUrl(userInfoDecoded.picture);
      }
      await ApplicationState.loadDataForUser();
      props.doLoginFunction();
      if(user.onboardingCompleted) {
        history.push("/timereport");
      }
      if(!user.onboardingCompleted) {
        history.push("/onboarding");
      }
    }
  }

  function handleLoginFailure(response) {
    console.log(response);
    alert("Failed to log in");
  }

  return (
    <GoogleOAuthProvider
        clientId={CLIENT_ID}
      >
    <GoogleLogin 
      clientId={CLIENT_ID}
      onSuccess={login}
      onError={handleLoginFailure}
      size="large"
      text="signin_with"
      theme="outline"
      logo_alignment="left"
      shape="square"
      width="212px"
      ux_mode="popup"
    >
  
    </GoogleLogin>
    
    </GoogleOAuthProvider>
  );
}

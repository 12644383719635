import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AddCircle } from "@mui/icons-material";
import { CircularProgress, Dialog, Paper } from "@mui/material";
import ActivityKeywordEditView from "./ActivityKeywordEditView";

export default function KeywordSuggestionsView(props) {
  const [keywordSuggestions, setKeywordSuggestions] = useState([]);
  const [suggestedKeyword1, setSuggestedKeyword1] = useState(undefined);
  const [suggestedKeyword2, setSuggestedKeyword2] = useState(undefined);
  const [suggestedKeyword3, setSuggestedKeyword3] = useState(undefined);
  const [suggestedKeyword4, setSuggestedKeyword4] = useState(undefined);
  const [suggestedKeyword5, setSuggestedKeyword5] = useState(undefined);
  const [suggestedKeyword6, setSuggestedKeyword6] = useState(undefined);
  const [suggestedKeyword7, setSuggestedKeyword7] = useState(undefined);
  const [suggestedKeyword8, setSuggestedKeyword8] = useState(undefined);
  const [suggestedKeyword9, setSuggestedKeyword9] = useState(undefined);
  const [suggestedKeyword10, setSuggestedKeyword10] = useState(undefined);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      let suggestions = await ApplicationState.getKeywordSuggestions(
        ApplicationState.getState().user.userId
      );
    
      setKeywordSuggestions(suggestions);
      let counter = 1;
      suggestions.forEach((suggestion) => {
        switch(counter){
          case 1: 
            setSuggestedKeyword1(suggestion.keyword);
            break;
          case 2: 
            setSuggestedKeyword2(suggestion.keyword);
            break;
          case 3: 
            setSuggestedKeyword3(suggestion.keyword);
            break;
          case 4: 
            setSuggestedKeyword4(suggestion.keyword);
            break;
          case 5: 
            setSuggestedKeyword5(suggestion.keyword);
            break;
          case 6: 
            setSuggestedKeyword6(suggestion.keyword);
            break;
          case 7: 
            setSuggestedKeyword7(suggestion.keyword);
            break;
          case 8: 
            setSuggestedKeyword8(suggestion.keyword);
            break;
          case 9: 
            setSuggestedKeyword9(suggestion.keyword);
            break;
          case 10: 
            setSuggestedKeyword10(suggestion.keyword);
            break;
        }
        counter++;
      });
      setLoading(false);
    };
    load();
  }, []);

  function addNewActivityKeyword(keyword) {
    ApplicationState.setActivityKeywordToEdit(undefined);
    ApplicationState.setUserToAddActivityKeywordFor(ApplicationState.getState().user);
    ApplicationState.setKeywordToAddActivityKeywordFor(keyword);
    setOpenAddEditDialog(true);
  }

  function addSuggestedKeywordsWithCheckboxes() {
    if (!keywordSuggestions || keywordSuggestions.length < 1) {
      return (
        <Typography variant="subtitle1" style={{margin: "8px"}}>
          <i>No suggested keywords found</i>
        </Typography>
      );
    }

    let counter = 0;
    return keywordSuggestions.map((suggestion) => {
      return addSuggestedKeywordWithCheckbox(suggestion, counter);  
      counter++;
        
      })
  }

  function addSuggestedKeywordWithCheckbox(k, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0){
      bgColor = "#f6f6f6";
    }
    return (
      <ListItem
          style={{backgroundColor: bgColor}}
        >
          <ListItemIcon>
            <IconButton color="primary" onClick={() => addNewActivityKeyword(k.keyword)}>
                <AddCircle></AddCircle>
                </IconButton>
          </ListItemIcon>
          <ListItemText primary={k.keyword} />
        </ListItem>
    );
  }

  function addKewyordsWithCheckboxes(){
    return(
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ padding: "0px" }}
      >
        {addSuggestedKeywordsWithCheckboxes()}
      </Grid>
    );
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };

  const deletedKeyword = async () => {
    setOpenAddEditDialog(false);
  };
  const editedKeyword  = async () => {
    if(ApplicationState.getKeywordToAddActivityKeywordFor()){
      let suggestions = [];
      keywordSuggestions.forEach((suggestion) => {
        if(suggestion.keyword && suggestion.keyword != ApplicationState.getKeywordToAddActivityKeywordFor()) {
          suggestions.push(suggestion);
        }
      })
      ApplicationState.setKeywordToAddActivityKeywordFor(undefined);
      setKeywordSuggestions(suggestions);  
    }
    setOpenAddEditDialog(false);
  };
  const addedKeyword = async () => {
    editedKeyword();
  };

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Keyword suggestions</Typography>
        </Box>
      </Typography>
    );
  }

  function addAddTimeButton() {
    return (
      <Box style={{ margin: "8px" }}>
       
      </Box>
    );
  }

  return (
    <React.Fragment>
      {!loading && (

<Grid container justifyContent="center" spacing={2}>
<Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
  <Box sx={{margin: { xs: "0px", md: "8px"}}}>
    <Paper elevation={7}>
      <Box>
      
      <Box>{addHeader()}</Box>
      <Typography variant="h6" style={{padding: "16px", paddingLeft: "28px", fontWeight: "400"}}>
        Adding these keywords would improve your future time reports
      </Typography>
      <Divider />
      {addKewyordsWithCheckboxes()}
      <Divider />
      
      <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ActivityKeywordEditView
            cancelClick={handleCloseAddEditDialog}
            addedKeyword={addedKeyword}
            editedKeyword={editedKeyword}
            deletedKeyword={deletedKeyword}
          ></ActivityKeywordEditView>
        </Dialog>
    </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
    )}


    {loading && (
      <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="row"
        style={{ marginBottom: "24px", marginTop: "24px" }}
        >
          <Box style={{height: "240px", width: "240px"}}>
          <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="row"
        style={{height: "240px", width: "240px"}}
        >
            
            <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column">
            <CircularProgress size={60}
                              color="secondary"
                              style={{ paddingLeft: "4px" }}>
                                
            </CircularProgress>
            
            <Typography style={{marginTop: "12px", color: "white"}}>
             <center>Loading keyword suggestions, this can take a few minutes</center>
            </Typography>
            
            </Grid>
            </Grid>
          </Box>
      </Grid>
      </Box>
    )}
    </React.Fragment>
  );
}

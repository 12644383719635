import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CardActionArea from "@mui/material/CardActionArea";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import StandardTimeEditView from "./StandardTimeEditView";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box)({
  backgroundColor: "#781938",
  color: "#fff",
  margin: "8px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRadius: "4px",
  lineHeight: "1.4",
  whiteSpace: "nowrap", 
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
    borderRightWidth: "2px",
    borderRightStyle: "solid",
    verticalAlign: "top",
    borderBottomWidth: "0px",
    fontSize: "16px",
});

function StandardTimeView() {
  const [totalTime, setTotalTime] = useState(0);
  const [standardTime, setStandardTime] = useState([]);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let totalSeconds = 0;
      let st = await ApplicationState.getStandardTimeForUser(
        ApplicationState.getState().user.email
      );
      ApplicationState.setStandardTime(st);
      setStandardTime(st);
      st.map((element) => {
        let hSeconds = element.maxHours * 60 * 60;
        let mSeconds = element.maxMinutes * 60;
        totalSeconds = totalSeconds + mSeconds + hSeconds;
      });
      setTotalTime(totalSeconds);
    }
    load();
  }, []);

  function navigateToAddStandardTime() {
    ApplicationState.setStandardTimeToEdit(undefined);
    setOpenAddEditDialog(true);
  }

  function navigateToEditStandardTime(standardTime) {
    ApplicationState.setStandardTimeToEdit(standardTime);
    setOpenAddEditDialog(true);
  }

  function addStandardWeek() {
    var weekdays = [1, 2, 3, 4, 5, 6, 0];
    return weekdays.map((element) => {
      return addTableForDay(element);
    });
  }
  function addTableForDay(day) {
    let totalSeconds = 0;

    standardTime
      .filter((x) => x.weekday === day)
      .map((element) => {
        let hSeconds = element.maxHours * 60 * 60;
        let mSeconds = element.maxMinutes * 60;
        totalSeconds = totalSeconds + mSeconds + hSeconds;
      });
    return (
      <Box key={day + "d"}>
        
          <Box p={0}>
            <Box
              style={{
                backgroundColor: "#781938",
                padding: "16px",
                color: "#fff",
              }}
            >
              <Typography variant="h6">
                {DateTimeHelper.getDayNameFromWeekday(day)}{" "}
              </Typography>
              <Typography>
                {DateTimeHelper.getHourMinuteStringFromHours(totalSeconds)}
              </Typography>
            </Box>
            <List style={{ padding: "0px" }}>{addStandardTimeRows(day)}</List>
          </Box>        
      </Box>
    );
  }

  function addStandardTimeRows(weekday) {
    let standardTimeForDay = standardTime.filter((x) => x.weekday === weekday);

    let rowNr = 0;
    return standardTimeForDay
      .sort((a, b) =>
        a.activity.project.name > b.activity.project.name ? 1 : -1
      )
      .map((element) => {
        return getMobileListItem(element, rowNr++);
      });
  }

  function getMobileListItem(element, rowNr) {
    if (rowNr % 2 === 0) {
      return (
        <ListItem
          key={ApplicationState.generateGuid()}
          button
          style={{backgroundColor: "#f6f6f6"}}
          onClick={() => navigateToEditStandardTime(element)}
        >
          <ListItemText>
            <strong>{element.activity.project.name}</strong>
            <br />
            {element.activity.name}
          </ListItemText>
          <strong>
            {DateTimeHelper.getHourMinuteStringFromHours(
              element.maxHours * 60 * 60 + element.maxMinutes * 60
            )}
          </strong>
        </ListItem>
      );
    }
    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        button
        onClick={() => navigateToEditStandardTime(element)}
      >
        <ListItemText>
          <strong>{element.activity.project.name}</strong>
          <br />
          {element.activity.name}
        </ListItemText>
        <strong>
          {DateTimeHelper.getHourMinuteStringFromHours(
            element.maxHours * 60 * 60 + element.maxMinutes * 60
          )}
        </strong>
      </ListItem>
    );
  }

  function getWidthForTableCell() {
    let width = "100px";
    let w = window.innerWidth;
    width = w / 14 + "px";
    return width;
  }

  function getTableHeaderForWeekday(weekday, weekdayNr) {
    let isHoliday = false;
    if (weekday.includes("Saturday") || weekday.includes("Sunday")) {
      isHoliday = true;
    }
    let width = getWidthForTableCell();
    let standardTimesForWeekday = standardTime.filter(
      (x) => x.weekday === weekdayNr
    );
    let totalDuration = 0;
    standardTimesForWeekday.forEach((element) => {
      totalDuration += element.maxHours * 3600;
      totalDuration += element.maxMinutes * 60;
    });
    if (isHoliday) {
      return (
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            padding: "8px",
            borderBottomWidth: "0px",
            fontSize: "16px",
            color: "#888",
          }}
        >
          <CustomBox>
            {weekday}
            <Box style={{fontWeight: "300", color: "#fff"}}>
              {DateTimeHelper.getHourMinuteStringFromHours(totalDuration)}
            </Box>
          </CustomBox>
        </TableCell>
      );
    }
    return (
      <TableCell
        align="left"
        style={{ 
          maxWidth: width, 
          minWidth: width, 
          overflowWrap: "break-word", 
          padding: "8px",
          borderBottomWidth: "0px",
          fontSize: "16px" 
        }}
      >
        <CustomBox>
          {weekday}
          <Box style={{fontWeight: "300", color: "#fff"}}>
            {DateTimeHelper.getHourMinuteStringFromHours(totalDuration)}
          </Box>
        </CustomBox>
      </TableCell>
    );
  }

  function getTableRowsForProjects(projectsForStandardTime) {
    let counter = 1;
    let projectsWithStandardTime = [];
    projectsForStandardTime.map((project) => {
      if (standardTime.some((x) => x.activity.project.id === project.id)) {
        projectsWithStandardTime.push(project);
      }
    });
    return projectsWithStandardTime.map((project) =>
      getTableRowForProject(project, counter++)
    );
  }

  function getProjectDuration(project) {
    let duration = 0;
    standardTime.map((x) => {
      if (x.activity.project.id === project.id) {
        duration += x.maxHours * 3600 + x.maxMinutes * 60;
      }
    });
    return DateTimeHelper.getHourMinuteStringFromHours(duration);
  }

  function getTableRowForProject(project, rowNr) {
    if (rowNr % 2 === 0) {
      return (
        <TableRow key={project.name} style={{backgroundColor: "#f6f6f6"}}>
          <CustomTableCell
            component="th"
            scope="row"
          >
            <Box>
              <b>{project.name}</b>
            </Box>
            {getProjectDuration(project)}
          </CustomTableCell>
          {getTableCellsForProject(project)}
        </TableRow>
      );
    }
    return (
      <TableRow key={project.name}>
        <CustomTableCell
          component="th"
          scope="row"
        >
          <Box>
            <b>{project.name}</b>
          </Box>
          {getProjectDuration(project)}
        </CustomTableCell>
        {getTableCellsForProject(project)}
      </TableRow>
    );
  }

  function getTableCellsForProject(project) {
    let days = [1, 2, 3, 4, 5, 6, 0];
    return days.map((day) => {
      return getTableCellForProjectAndDate(project, day);
    });
  }

  function getTableCellForProjectAndDate(project, weekdayNr) {
    let standardTimesForDay = standardTime.filter(
      (x) => x.activity.project.id === project.id && x.weekday === weekdayNr
    );
    let totalDuration = 0;
    standardTimesForDay.forEach((element) => {
      totalDuration += element.maxHours * 3600;
      totalDuration += element.maxMinutes * 60;
    });

    let width = getWidthForTableCell();

    if (weekdayNr === 0) {
      return (
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            borderBottomWidth: "0px", 
            verticalAlign: "top"
          }}
        >
          {getSummaryProjectPerDayBox(totalDuration)}
          {getCardActionAreaForTimeEntries(standardTimesForDay)}
        </TableCell>
      );
    }
    return (
      <CustomTableCell
        align="left"
        style={{ maxWidth: width, minWidth: width, overflowWrap: "break-word" }}
      >
        {getSummaryProjectPerDayBox(totalDuration)}
        {getCardActionAreaForTimeEntries(standardTimesForDay)}
      </CustomTableCell>
    );
  }

  function getCardActionAreaForTimeEntries(standardTimesForDay) {
    let counter = 0;
    return standardTimesForDay.map((element) => {
      counter++;
      return (
        <Box>
          <Box paddingTop={1.5} paddingBottom={1.5}>
            <CardActionArea
              style={{
                paddingLeft: "4px",
                paddingRight: "4px",
                paddingTop: "4px",
                paddingBottom: "4px",
                fontFamily: "Roboto",
                fontSize: "15px",
              }}
              onClick={() => navigateToEditStandardTime(element)}
            >
              {<b>{element.activity.name}</b>}
              <br />
              {DateTimeHelper.getHourMinuteStringFromHours(
                element.maxHours * 3600 + element.maxMinutes * 60
              )}
            </CardActionArea>
          </Box>
          {counter < standardTimesForDay.length && <Divider />}
        </Box>
      );
    });
  }

  function getSummaryProjectPerDayBox(totalDuration) {
    if (totalDuration < 1) {
      return <Box></Box>;
    }
    return (
      <Box>
        <Box
          paddingTop={1}
          paddingBottom={1}
          paddingLeft={0.5}
          paddingRight={0.5}
          style={{
            color: "#93264A",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          <Grid container justifyContent="space-between">
            <Box>
              {DateTimeHelper.getHourMinuteStringFromHours(totalDuration)}
            </Box>
          </Grid>
        </Box>
        <Divider></Divider>
      </Box>
    );
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Standard week</Typography>
          <Typography>
            Let Dynamic Time fill the gaps in your time reports when data can't be found in your connected services.
          </Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add time"
                buttonClick={() => navigateToAddStandardTime()}
              >
              </PrimaryButton>
      </Box>
    );
  }

  function addTableForStandardWeek() {
    let projectsForStandardTime = [];
    standardTime
      .sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((standardTime) => {
        if (
          !projectsForStandardTime.some(
            (foo) => foo.name === standardTime.activity.project.name
          )
        ) {
          projectsForStandardTime.push(standardTime.activity.project);
        }
      });

    let totalDuration = 0;
    standardTime.forEach((element) => {
      totalDuration += element.maxHours * 3600;
      totalDuration += element.maxMinutes * 60;
    });

    return (
      <Grid item xs>
        {addHeader()}
        <Box p={0}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{backgroundColor: "#53001B"}}>
                  <TableCell
                    align="left"
                    style={{ 
                      width: "25%", 
                      padding: "8px",
                      borderBottomWidth: "0px",
                      fontSize: "16px",
                    }}
                  >
                    <Box style={{
                      color: "#fff",
                      border: "2px solid #781938",
                      margin: "8px",
                      paddingLeft: "16px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      borderRadius: "8px",
                      lineHeight: "1.4"
                    }}>
                      <Box style={{fontWeight: "500", color: "#fff", fontSize: "x-large"}}>
                        {DateTimeHelper.getHourMinuteStringFromHours(
                          totalDuration
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  {getTableHeaderForWeekday("Monday", 1)}
                  {getTableHeaderForWeekday("Tuesday", 2)}
                  {getTableHeaderForWeekday("Wednesday", 3)}
                  {getTableHeaderForWeekday("Thursday", 4)}
                  {getTableHeaderForWeekday("Friday", 5)}
                  {getTableHeaderForWeekday("Saturday", 6)}
                  {getTableHeaderForWeekday("Sunday", 0)}
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRowsForProjects(projectsForStandardTime)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    );
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };
  const deletedStandardTime = async () => {
    await loadStandardTime();
    setOpenAddEditDialog(false);
  };
  const editedStandardTime  = async () => {
    await loadStandardTime();
    setOpenAddEditDialog(false);
  };
  const addedStandardTime = async () => {
    await loadStandardTime();
    setOpenAddEditDialog(false);
  };

  const loadStandardTime = async () => {
    let totalSeconds = 0;
      let st = await ApplicationState.getStandardTimeForUser(
        ApplicationState.getState().user.email
      );
      st = st.sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      });
      ApplicationState.setStandardTime(st);
      setStandardTime(st);
      st.map((element) => {
        let hSeconds = element.maxHours * 60 * 60;
        let mSeconds = element.maxMinutes * 60;
        totalSeconds = totalSeconds + mSeconds + hSeconds;
      });
      setTotalTime(totalSeconds);
  }

  function getMaxWeekday(){
    let maxWeekday = 0;
    let containSunday = false;
    standardTime.forEach((element) => {
      if(element.weekday > maxWeekday){
        maxWeekday = element.weekday;
      }
      if(element.weekday === 0){
        containSunday = true;
      }
    });
    if(containSunday){
      return 1;
    }
    if(maxWeekday === 5){
      return 5;
    }
    if(maxWeekday === 6){
      return 5;
    } else{
      return maxWeekday + 1    
    }
  }

  return (
    <Grid>
            <Box p={0}>
              {useMediaQuery(useTheme().breakpoints.down('lg')) && (
                <Box>
                  <Box>
                    <Box>
                      {addHeader()}
                      {addStandardWeek()}
                    </Box>
                  </Box>
                </Box>
              )}
              {useMediaQuery(useTheme().breakpoints.up("lg")) && (
                <Box>
                  <Grid
                    container
                    alignItems="stretch"
                    justifyContent="space-between"
                    direction="row"
                  ></Grid>
                  {addTableForStandardWeek()}
                </Box>
              )}
            </Box>
        <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <StandardTimeEditView
            cancelClick={handleCloseAddEditDialog}
            addedStandardTime={addedStandardTime}
            editedStandardTime={editedStandardTime}
            deletedStandardTime={deletedStandardTime}
            maxWeekday={getMaxWeekday}
          ></StandardTimeEditView>
        </Dialog>
    </Grid>
  );
}

export default StandardTimeView;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import GroupView from "./GroupView";
import GroupEditView from "./GroupEditView";
import { PrimaryButton } from '../Components/PrimaryButton';

function GroupsView() {
  const [groups, setGroups] = useState([]);
  const [groupToView, setGroupToView] = useState(undefined);
  const [openUserDialog, setOpenGroupDialog] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let orgId = ApplicationState.getState().user.organization.organizationId;
      let groups = await ApplicationState.getGroupsForOrganization(orgId);
      setGroups(groups);
      setGroupToView(groups[0]);
    }
    load();
  }, []);

  async function editedGroup() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let groups = await ApplicationState.getGroupsForOrganization(orgId);
    setGroups(groups);
    setGroupToView(groups.find((x) => x.id == groupToView.id));
    setOpenGroupDialog(false);
  }

  async function addedGroup() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let groups = await ApplicationState.getGroupsForOrganization(orgId);
    setGroups(groups);
    setGroupToView(
      groups.find((x) => x.id === ApplicationState.getGroupToView().id)
    );
    setOpenGroupDialog(false);
  }

  async function deletedGroup() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let groups = await ApplicationState.getGroupsForOrganization(orgId);
    setGroups(groups);
    setGroupToView(groups[0]);
    setOpenGroupDialog(false);
  }

  function viewGroup(groupToView2) {
    setGroupToView(groupToView2);
  }

  function newGroupClick() {
    ApplicationState.setGroupToEdit(undefined);
    setOpenGroupDialog(true);
  }

  function addUser(group) {
    let primaryText = group.name;
    return (
      <Box>
        {groupToView && groupToView.id === group.id && (
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => viewGroup(group)}
            style={{
              backgroundColor: "#781938",
              color: "white",
              padding: "16px",
            }}
          >
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={primaryText} />
          </ListItem>
        )}
        {!groupToView ||
          (groupToView.id != group.id && (
            <ListItem
              key={ApplicationState.generateGuid()}
              button
              onClick={() => viewGroup(group)}
            >
              <ListItemAvatar></ListItemAvatar>
              <ListItemText primary={primaryText} />
            </ListItem>
          ))}
      </Box>
    );
  }

  function addGroups() {
    return groups
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((element) => {
        return addUser(element);
      });
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Groups</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add group"
                buttonClick={() => newGroupClick()}
              >
        </PrimaryButton>
      </Box>
    );
  }

  return (
    <Box p={0}>
      <Box>{addHeader()}</Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={4} style={{ backgroundColor: "#f6f6f6" }}>
          <List style={{padding: "0px"}}>{addGroups()}</List>
        </Grid>
        <Grid item xs={8}>
          {groupToView && (
            <GroupView
              selectedGroup={groupToView}
              editedGroup={editedGroup}
              deletedGroup={deletedGroup}
            ></GroupView>
          )}
          {!groupToView && <Box>No group selected</Box>}
          <Dialog
            open={openUserDialog}
            onClose={() => setOpenGroupDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <GroupEditView
              deletedGroup={deletedGroup}
              addedGroup={addedGroup}
              editedGroup={editedGroup}
              cancelClick={() => setOpenGroupDialog(false)}
            ></GroupEditView>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GroupsView;

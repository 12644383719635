import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import logo from "./../logo_black_text.svg";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import TimeReportView from "./TimeReportView";
import TimeReportsView from "./TimeReportsView";
import TimeReportUserView from "./TimeReportUserView";
import SettingsView from "./SettingsView";
import AdminSettingsView from "./AdminSettingsView";
import UserSettingsView from "./UserSettingsView";
import OrganizationSettingsView from "./OrganizationSettingsView";
import LoginView from "./LoginView";
import ActivityView from "./ActivityView";
import ActivityEditView from "./ActivityEditView";
import ProjectEditView from "./ProjectEditView";
import ServicesView from "./ServicesView";
import ServiceConfigView from "./ServiceConfigView";
import StandardTimeView from "./StandardTimeView";
import StandardTimeEditView from "./StandardTimeEditView";
import GroupsView from "./GroupsView";
import GroupView from "./GroupView";
import UserView from "./UserView";
import UsersView from "./UsersView";
import UserEditView from "./UserEditView";
import UserKeywordsView from "./UserKeywordsView";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import TimeEntryView from "./TimeEntryView";
import TimeEntryKeywordView from "./TimeEntryKeywordView";
import ApplicationState from "../State/ApplicationState";
import ProjectSettingEditView from "./ProjectSettingEditView";
import MyDayView from "./MyDayView";
import StatisticsView from "./StatisticsView";
import ActivityKeywordEditView from "./ActivityKeywordEditView";
import Grid from "@mui/material/Grid";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { BarChart } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import AutoFlexView from "./AutoFlexView";
import TimeReportSentView from "./TimeReportSentView";
import IgnoreKeywordsView from "./IgnoreKeywordsView";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AdminLogView from "./AdminLogView";
import KeywordSuggestionsView from "./KeywordSuggestionsView";
import OnboardingView from "./OnboardingView";
import AssignmentsView from "./AssignmentsView";
import AssignmentView from "./AssignmentView";
import UserCvView from "./UserCvView";
import ProjectsView from "./ProjectsView";
import SchoolIcon from '@mui/icons-material/School';
import ProjectView from "./ProjectView";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import RevenueForecastView from "./RevenueForecastView";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AbsenceView from "./AbsenceView";
import NotAuthorizedView from "./NotAuthorizedView";
import FaqView from "./FaqView";
import HelpIcon from '@mui/icons-material/Help';
import SkillsPanelView from "./SkillsPanelView";
import LoginEmailView from "./LoginEmailView";

const MenuList = styled(List)({
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'red',
    backgroundColor: "#34242c !important",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: "linear-gradient(135deg, #282428 0%, #93264A 100%) ",
    color: "#fff",
  },
});

const drawerWidth = 240;

export default function TopBar(props) {
  const [, setHasLoggedIn] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("Home");
  const { window2 } = props;
  const theme = useTheme();
  const [showMenuText, setShowMenuText] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const container = window2 !== undefined ? () => window().document.body : undefined;

  function doLogin() {
    setHasLoggedIn(true);
  }  

  function logout() {
    ApplicationState.logout();
    window.location.href = "/";
    setHasLoggedIn(false);
    handleCloseUserMenu();
  }

  function handleCloseUserMenu() {
    setAnchorEl(null);
  }

  const drawer = (anchor) => (
    <Grid container direction="column" justifyContent="space-between"
    alignItems="center" style={{backgroundColor: "#fff", color: "#000"}}> 
      <Grid item>
      <Box>
      <MenuList style={{ padding: "0px" }}>
      {ApplicationState.viewMenuItem() && (
          <Box>
            <ListItem
              button
              component={NavLink}
              to="/timereport"
              onClick={() => {
                setMobileOpen(false);
                setSelectedMenuItem("Home");
              }}
              selected={selectedMenuItem === "Home"}
              style={{paddingBottom: "20px", paddingTop: "20px"}}
            >
              <ListItemIcon style={{minWidth: "42px"}}>
                <HomeIcon style={{color: "#B2355E"}} />
              </ListItemIcon>
              {showMenuText && <ListItemText primary="Home" />}
            </ListItem>
          </Box>
        )}
        {ApplicationState.isTimeReportApprover() && !ApplicationState.isAdmin() && (
          <Box>
            {addTimeReportsMenuItem()}        
          </Box>
        )}
        {ApplicationState.isAdmin() && (
          <Box>
          {addTimeReportsMenuItem()}        
        <ListItem
          button
          component={NavLink}
          to="/statistics"
          onClick={() => {
            setMobileOpen(false);
            setSelectedMenuItem("Statistics");
          }}
          selected={selectedMenuItem === "Statistics"}
          style={{paddingBottom: "20px", paddingTop: "20px"}}
        >
          <ListItemIcon style={{minWidth: "42px"}}>
            <BarChart style={{color: "#B2355E"}} />
          </ListItemIcon>
          {showMenuText && <ListItemText primary="Statistics" />}
        </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/skills"
            onClick={() => {
              setMobileOpen(false);
              setSelectedMenuItem("Skills");
            }}
            selected={selectedMenuItem === "Skills"}
            style={{paddingBottom: "20px", paddingTop: "20px"}}
          >
            <ListItemIcon style={{minWidth: "42px"}}>
              <SchoolIcon style={{color: "#B2355E"}} />
            </ListItemIcon>
            {showMenuText && <ListItemText primary="Skills" />}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/projects"
            onClick={() => {
              setMobileOpen(false);
              setSelectedMenuItem("Projects");
            }}
            selected={selectedMenuItem === "Projects"}
            style={{paddingBottom: "20px", paddingTop: "20px"}}
          >
            <ListItemIcon style={{minWidth: "42px"}}>
              <AccountTreeIcon style={{color: "#B2355E"}} />
            </ListItemIcon>
            {showMenuText && <ListItemText primary="Projects" />}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/users"
            onClick={() => {
              setMobileOpen(false);
              setSelectedMenuItem("Users");
            }}
            selected={selectedMenuItem === "Users"}
            style={{paddingBottom: "20px", paddingTop: "20px"}}
          >
            <ListItemIcon style={{minWidth: "42px"}}>
              <PeopleIcon style={{color: "#B2355E"}} />
            </ListItemIcon>
            {showMenuText && <ListItemText primary="Users" />}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/revenueforecast"
            onClick={() => {
              setMobileOpen(false);
              setSelectedMenuItem("RevenueForecast");
            }}
            selected={selectedMenuItem === "RevenueForecast"}
            style={{paddingBottom: "20px", paddingTop: "20px"}}
          >
            <ListItemIcon style={{minWidth: "42px"}}>
              <TrendingUpIcon style={{color: "#B2355E"}} />
            </ListItemIcon>
            {showMenuText && <ListItemText primary="Revenue Forecast" />}
          </ListItem>
          <ListItem
            button
            component={NavLink}
            to="/adminsettings"
            onClick={() => {
              setMobileOpen(false);
              setSelectedMenuItem("AdminSettings");
            }}
            selected={selectedMenuItem === "AdminSettings"}
            style={{paddingBottom: "20px", paddingTop: "20px"}}
          >
            <ListItemIcon style={{minWidth: "42px"}}>
              <AdminPanelSettingsIcon style={{color: "#B2355E"}} />
            </ListItemIcon>
            {showMenuText && <ListItemText primary="Admin settings" />}
          </ListItem>
          </Box>
        )}
        <ListItem
              button
              component={NavLink}
              to="/settings"
              onClick={() => {
                setMobileOpen(false);
                setSelectedMenuItem("UserSettings");
              }}
              selected={selectedMenuItem === "UserSettings"}
              style={{paddingBottom: "20px", paddingTop: "20px"}}
            >
              <ListItemIcon style={{minWidth: "42px"}}>
                <SettingsIcon style={{color: "#B2355E"}} />
              </ListItemIcon>
              {showMenuText && <ListItemText primary="User settings" />}
            </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/faq"
          onClick={() => {
            setMobileOpen(false);
            setSelectedMenuItem("FAQ");
          }}
          selected={selectedMenuItem === "FAQ"}
          style={{paddingBottom: "20px", paddingTop: "20px"}}
        >
          <ListItemIcon style={{minWidth: "42px"}}>
            <HelpIcon style={{color: "#B2355E"}} />
          </ListItemIcon>
          {showMenuText && <ListItemText primary="Help & FAQ" />}
        </ListItem>
      </MenuList>
      </Box>
      <Divider style={{borderColor: "#f6f6f6"}} />
      <Box p={5}></Box>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "16px",
          }}
        >
          {ApplicationState.getUserProfilePictureUrl() && ApplicationState.getUserProfilePictureUrl().length > 0 &&(
            <img style={{
              width: "85px",
              height: "85px",
              borderRadius: "50%",
            }} src={ApplicationState.getUserProfilePictureUrl()}></img>
          )}
          {!ApplicationState.getUserProfilePictureUrl() && (
            <AccountCircle
            style={{
              width: "85px",
              height: "85px",
              fontSize: "xx-large",
              color: "#d8d8d8",
            }}
          ></AccountCircle>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "12px",
          }}
        >
          <Typography
            variant="body2"
            style={{ color: "#781938", fontWeight: "600", padding: "8px", paddingTop: "0px", paddingBottom: "0px", wordBreak: "break-word", textAlign: "center" }}
          >
            {ApplicationState.getState().user.firstName + " " + ApplicationState.getState().user.lastName}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            justifyContent: "center",
            paddingTop: "0px",
          }}
        >
          <Link
            component="button"
            onClick={logout}
            style={{ color: "#000", textDecoration: "underline" }}
            underline="hover">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Log out</Typography>
            </Grid>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );

  if (!ApplicationState.hasState() || !ApplicationState.getState().user || (ApplicationState.getState().user && ApplicationState.getState().user.onboardingDone === "t")) {
    return (
      <>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginView doLoginFunction={doLogin} />
            </Route>
            <Route path="/faq">
              <FaqView />
            </Route>
            <Route path="/loginEmail">
                  <LoginEmailView doLoginFunction={doLogin} />
                </Route>
            <Route path="/">
              <LoginView doLoginFunction={doLogin} />
            </Route>
          </Switch>
        </Router>
      </>
    );
  }

  function addTimeReportsMenuItem(){
    return(
      <ListItem
          button
          component={NavLink}
          to="/timereports"
          onClick={() => {
            setMobileOpen(false);
            setSelectedMenuItem("TimeReports");
          }}
          selected={selectedMenuItem === "TimeReports"}
          style={{paddingBottom: "20px", paddingTop: "20px"}}
        >
          <ListItemIcon style={{minWidth: "42px"}}>
            <ListAltIcon style={{color: "#B2355E"}} />
          </ListItemIcon>
          {showMenuText && <ListItemText primary="Time Reports" />}
        </ListItem>
    );
  }

  return <>
    <Router>
      <div style={{display: "flex"}}>
        {["☰"].map((anchor) => (
          <React.Fragment key={anchor}>
              <AppBar position="fixed" 
              sx={{
                backgroundColor: "#fff",
                color: "#000",
              }}
              >
                <Toolbar>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  >
                  <Grid item>
                  <ListItem
                    button
                    onClick={handleDrawerToggle}
                    sx={{
                      paddingBottom: "20px", paddingTop: "20px"
                    }}
                    size="large"
                    variant="outlined">
                    <MenuIcon style={{color: "#B2355E"}} />
                  </ListItem>
                  </Grid>
                  <Grid item>
                    <NavLink to="/timereport" onClick={() => {
                      setMobileOpen(false);
                      setSelectedMenuItem("Home");
                    }}>
                    <img src={logo} style={{height: "50px",
                                        width: "155px",
                                        margin: "8px 8px 8px 4px",
                                        top: "57px",
                                        left: "58px",
                                        alignContent: ""}} />
                    </NavLink>
                  </Grid>
                  <Grid item>                  
                  <ListItem
                    button
                    component={NavLink}
                    to="/settings"
                    onClick={() => {
                      setMobileOpen(false);
                      setSelectedMenuItem("Settings");
                    }}
                    style={{paddingBottom: "20px", paddingTop: "20px"}}
                  >
                      <SettingsIcon style={{color: "#B2355E"}} />
                  </ListItem>                  
                  </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            <Box
            sx={{
              width: {xs: 0},
              flexShrink: 0,
              }} 
              aria-label="mailbox folders">
                <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  styles={{
                    width: drawerWidth,
                    backgroundColor: "#f6f6f6",
                    borderRightWidth: "0px",
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  style={{ borderRight: "0px solid white" }}
                  BackdropProps={{ invisible: true }}
                >
                  {drawer(anchor)}
                </Drawer>
            </Box>
            <Box sx={{flexGrow: 1, paddingTop: {xs: "80px"}}}>
                <div />
              <Switch>
                <Route path="/groups">
                  {ApplicationState.isAdmin() ? <GroupsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/group">
                  {ApplicationState.isAdmin() ? <GroupView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/myday">
                  <MyDayView />
                </Route>
                <Route path="/timereports">
                  {ApplicationState.isAdmin() ? <TimeReportsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/userkeywords">
                  <UserKeywordsView />
                </Route>
                <Route path="/users">
                  {ApplicationState.isAdmin() ? <UsersView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/useredit">
                  {ApplicationState.isAdmin() ? <UserEditView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/activity">
                  {ApplicationState.isAdmin() ? <ActivityView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/activityedit">
                  {ApplicationState.isAdmin() ? <ActivityEditView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/activitykeywordedit">
                  {ApplicationState.isAdmin() ? <ActivityKeywordEditView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/ignorekeywords">
                  <IgnoreKeywordsView />
                </Route>
                <Route path="/projects">
                  {ApplicationState.isAdmin() ? <ProjectsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/projectedit">
                  {ApplicationState.isAdmin() ? <ProjectEditView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/projectsettingedit">
                  {ApplicationState.isAdmin() ? <ProjectSettingEditView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/services">
                  <ServicesView />
                </Route>
                <Route path="/serviceconfig">
                  <ServiceConfigView />
                </Route>
                <Route path="/standardtime">
                  <StandardTimeView />
                </Route>
                <Route path="/standardtimeedit">
                  <StandardTimeEditView />
                </Route>
                <Route path="/timereport">
                  <TimeReportView />
                </Route>
                <Route path="/timeentry">
                  <TimeEntryView />
                </Route>
                <Route path="/timeentrykeyword">
                  <TimeEntryKeywordView />
                </Route>
                <Route path="/statistics">
                  {ApplicationState.isAdmin() ? <StatisticsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/settings">
                  <SettingsView />
                </Route>
                <Route path="/adminsettings">
                  {ApplicationState.isAdmin() ? <AdminSettingsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/usersettings">
                  <UserSettingsView />
                </Route>
                <Route path="/organizationsettings">
                  {ApplicationState.isAdmin() ? <OrganizationSettingsView/> : <NotAuthorizedView />}
                </Route>
                <Route path="/timereportuser">
                  {ApplicationState.isAdmin() ? <TimeReportUserView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/autoflex">
                  <AutoFlexView />
                </Route>
                <Route path="/timereportsent">
                  <TimeReportSentView />
                </Route>
                <Route path="/keywordsuggestions">
                  <KeywordSuggestionsView />
                </Route>
                <Route path="/logs">
                  {ApplicationState.getState().user.email === "daniel.hagman@hellmandynamic.se" && ApplicationState.isAdmin() ? <AdminLogView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/onboarding">
                  <OnboardingView />
                </Route>
                <Route path="/faq">
                  <FaqView />
                </Route>
                <Route path="/assignments">
                  {ApplicationState.isAdmin() ? <AssignmentsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/assignment/:id">
                  {ApplicationState.isAdmin() ? <AssignmentView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/skills">
                  {ApplicationState.isAdmin() ? <SkillsPanelView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/usercv/:id">
                  {ApplicationState.isAdmin() ? <UserCvView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/projects">
                  {ApplicationState.isAdmin() ? <ProjectsView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/project/:id">
                  {ApplicationState.isAdmin() ? <ProjectView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/users">
                  {ApplicationState.isAdmin() ? <UsersView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/user/:id">
                  {ApplicationState.isAdmin() ? <UserView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/revenueforecast">
                  {ApplicationState.isAdmin() ? <RevenueForecastView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/absence">
                  {ApplicationState.isAdmin() ? <AbsenceView /> : <NotAuthorizedView />}
                </Route>
                <Route path="/">
                  <TimeReportView />
                </Route>
              </Switch>
            </Box>
          </React.Fragment>
        ))}
      </div>
    </Router>
  </>;
}
import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReduceWorkingTimeKeywordDto from "../Dtos/ReduceWorkingTimeKeywordDto";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip } from "../Components/Tooltip";

function ReduceWorkingTimeKeywordsView() {
  const [keywords, setKeywords] = useState([]);
  const [keywordToAdd, setKeywordToAdd] = useState(undefined);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      loadKeywords();
    }
    load();
  }, []);

  async function saveNewKeyword() {
    let dto = new ReduceWorkingTimeKeywordDto(
      ApplicationState.generateGuid(),
      keywordToAdd,
      ApplicationState.getState().user,
      new Date(Date.now())
    );
    await ApplicationState.saveNewReduceWorkingTimeKeyword(dto);
    await loadKeywords();
    setKeywordToAdd("");
  }

  async function deleteKeyword(keywordToDelete) {
    await ApplicationState.deleteReduceWorkingTimeKeyword(keywordToDelete.id);
    let keywords2 = keywords.slice();
    const index = keywords2.indexOf(keywordToDelete);
    if (index > -1) {
      keywords2.splice(index, 1);
    }
    setKeywords(keywords2);
  }

  function addKeywords() {
    return keywords
      .sort((a, b) => (a.keyword > b.keyword ? 1 : -1))
      .map((element) => {
        return addExistingKeyword(element);
      });
  }

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#f6f6f6"}}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Keywords to reduce working time{" "}<Tooltip text="Items containing these keywords will decrease your flex time if found during your normal working hours. E.g. doctors appointments." /></Typography>
        </Box>
      </Typography>
    );
  }
 
  function addExistingKeyword(k) {
    return (
      <Chip
        clickable={true}
        style={{
          backgroundColor: "#93264A",
          color: "#fff",
          margin: "8px",
          fontSize: "larger",
          padding: "4px",
          height: "42px",
          "&:hover": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
          "&:focus": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
        }}
        onDelete={() => deleteKeyword(k)}
        label={<b style={{ fontWeight: "400" }}>{k.keyword}</b>}
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  const loadKeywords = async () => {
    let keywordsForUser =
      await ApplicationState.getReduceWorkingTimeKeywordsForUser(
        ApplicationState.getState().user.email
      );
    setKeywords(keywordsForUser);
  };

  return (
    <Grid>
            <Box p={0}>
              <Box>
                <Grid
                  container
                  alignItems="stretch"
                  justifyContent="space-between"
                  direction="row"
                ></Grid>
                {addHeader()}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ padding: "16px" }}
                >
                  <TextField
                    id="outlined-basic"
                    label="New keyword"
                    variant="outlined"
                    type="text"
                    style={{ margin: "8px" }}
                    onChange={(event) => setKeywordToAdd(event.target.value)}
                    value={keywordToAdd}
                  />
                  <IconButton
                    aria-label="addNewKeyword"
                    onClick={() => saveNewKeyword()}
                    sx={{
                      color: "#93264A",
                      margin: "0px",
                      fontSize: "medium",
                      "&:hover": {
                        color: "#93264A",
                      },
                      "&:focus": {
                        color: "#93264A",
                      },
                    }}
                    size="large"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Grid>
                <Box style={{ padding: "16px" }}>{addKeywords()}</Box>
              </Box>
            </Box>
    </Grid>
  );
}

export default ReduceWorkingTimeKeywordsView;

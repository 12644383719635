export default class ProjectToDoDto {
  constructor(id, date, type, object, name, description, startDate, endDate, status, externalId, linkToFolder) {
    this.id = id;
    this.date = date;
    this.startDate = startDate;
    this.endDate = endDate;
    this.type = type;
    this.object = object;
    this.name = name;
    this.description = description;
    this.status = status;
    this.externalId = externalId;
    this.linkToFolder = linkToFolder;
  }
}

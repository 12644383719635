export default class EmailExporterPropertyDto {
  constructor(id, name, emailExporter, requiredForActivities, requiredForProjects, created) {
    this.id = id;
    this.name = name;
    this.emailExporter = emailExporter;
    this.requiredForActivities = requiredForActivities;
    this.requiredForProjects = requiredForProjects;
    this.created = created;
  }
}

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from './PrimaryButton';
import TimeReportFileDto from "../Dtos/TimeReportFileDto";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "60px",
});

function TimeReportFileEditView(props) {
  
  const [commentField, setCommentField] = useState(undefined);
  const [amountExVATField, setAmountExVATField] = useState(undefined);
  const [amountIncVATField, setAmountIncVATField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [typeField, setTypeField] = useState("reportFromOtherSystem");
  const [fileId, setFileId] = useState(undefined);

  useEffect(() => {
    document.getElementById("select-file-button").click();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new TimeReportFileDto (
      fileId,
      props.timeReportToAddFileTo,
      selectedFile,
      commentField, 
      typeField,
      new Date(Date.now()),
      amountExVATField,
      amountIncVATField
    );
    await ApplicationState.saveNewTimeReportFile(dto);
    props.addedTimeReportFile();
  }

  async function handleFileSelected(e) {
    setUploadingFile(true);
    const files = Array.from(e.target.files);
    let fileId = ApplicationState.generateGuid();
    setFileId(fileId);
    await ApplicationState.uploadFileForTimeReport(
      files[0],
      ApplicationState.getState().user.email,
      new Date(props.timeReportToAddFileTo.from).getFullYear(),
      new Date(props.timeReportToAddFileTo.from).getMonth(),
      files[0].name.split(".").pop(),
      fileId
    );
    setUploadingFile(false);
    setSelectedFile(files[0].name);
  }

  const getHeaderText = () => {
    let headerText = "Add attachment";
    if (editMode) {
      headerText = "Edit attachment";
    }
    return headerText;
  };

  const addForm = () => {
    let buttonText = "Select file";
    if(selectedFile){
      buttonText = "Change file";
    }
    return (
      <Box>

        <div>
          <input
            id="contained-button-file"
            type="file"
            hidden={true}
            onChange={handleFileSelected}
          />
          <label htmlFor="contained-button-file">
            <Box p={0}>
              <Button
                variant="text"
                color="primary"
                component="span"
                type="submit"
                size="large"
                id="select-file-button"
              >{buttonText}</Button>
            </Box>
          </label>
        </div>

        {uploadingFile && (
          <Box p={1}>
            <CircularProgress color="primary" />
            <i>Uploading file...</i>
          </Box>
        )}
        <Typography variant="subtitle1" style={{paddingLeft: "8px", paddingBottom: "0px"}}>
        <i>{selectedFile}</i>
        </Typography>
        <Box />
        <InputLabelSelect
          id="projectSelectLabel"
          htmlFor="projectSelect"
          variant="outlined"
          shrink={true}
        >
          Type
        </InputLabelSelect>
        <Select
          native
          onChange={(event) => {
            setTypeField(event.target.value);
          }}
          id="projectSelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "95%", sm: "240px" },
          }}
          value={typeField}
          labelId="projectSelectLabel"
        >
          <option value="expenseToBeInvoiced" style={{ color: "#111" }}>
            Expense to be invoiced
          </option>
          <option value="reportFromOtherSystem" style={{ color: "#111" }}>
            Report from other system
          </option>
        </Select>
        <br />
        <TextField
          id="setCommentField_Text"
          label="Comment"
          type="text"
          multiline
          rows={3}
          style={{margin: "8px", width: "240px"}}
          onChange={(event) => setCommentField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={commentField}
        />
        <br />
        <TextField
          id="setAmountExVATField_Text"
          label="Amount Ex VAT"
          type="number"
          style={{margin: "8px", width: "120px"}}
          onChange={(event) => setAmountExVATField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={amountExVATField}
          disabled={typeField != "expenseToBeInvoiced"}
        />
        <br />
        <TextField
          id="setAmountIncVATField_Text"
          label="Amount Inc VAT"
          type="number"
          style={{margin: "8px", width: "120px" }}
          onChange={(event) => setAmountIncVATField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={amountIncVATField}
          disabled={typeField != "expenseToBeInvoiced"}
        />
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              { selectedFile && (
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default TimeReportFileEditView;

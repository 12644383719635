import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EventIcon from "@mui/icons-material/Event";
import CircularProgress from "@mui/material/CircularProgress";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import DateTimeHelper from "../Utils/DateTimeHelper";
import CardActionArea from "@mui/material/CardActionArea";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonIcon from "@mui/icons-material/Person";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDto from "../Dtos/TimelineDto";
import Dialog from "@mui/material/Dialog";
import TimeEntryView from "./TimeEntryView";
import TimeEntryKeywordView from "./TimeEntryKeywordView";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import BugReportIcon from "@mui/icons-material/BugReport";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import UserDataItemDto from "../Dtos/UserDataItemDto";
import { PrimaryButton } from "../Components/PrimaryButton";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";

function MyDayView(props) {
  const [loadingTimelineDtos, setLoadingTimelineDtos] = useState(false);
  const [timelineDtos, setTimelineDtos] = useState([]);
  const [allUserDataItems, setAllUserDataItems] = useState([]);
  const [openTimeEntryDialog, setOpenTimeEntryDialog] = React.useState(false);
  const [
    openTimeEntryKeywordSuggestionDialog,
    setOpenTimeEntryKeywordSuggestionDialog,
  ] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("events");

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#111",
  });

  const CustomTabPanel = styled(TabPanel)({
    padding: "0px",
  });

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      await loadTimelineDtos(new Date(props.parentDay));
    }
    load();
  }, props.parentDay);

  async function loadTimelineDtos(date) {
    setLoadingTimelineDtos(true);
    let timeEntriesForDate = await ApplicationState.getTimeEntriesForUser(
      ApplicationState.getState().user.email,
      new Date(date).toLocaleDateString("sv-SE"),
      new Date(date).toLocaleDateString("sv-SE")
    );

    let dtos = [];
    timeEntriesForDate.map((timeEntry) => {
      let dto = new TimelineDto(
        timeEntry.id,
        timeEntry.description,
        timeEntry.durationMinutes,
        new Date(timeEntry.dateTime),
        "timeEntry",
        timeEntry
      );
      if (!dto.title || dto.title.length < 1) {
        dto.title = timeEntry.activity.name;
      }
      dtos.push(dto);
    });

    let userDataDtos = await ApplicationState.getUserDataForUser(
      ApplicationState.getState().user.email,
      new Date(date).toLocaleDateString("sv-SE"),
      new Date(date).toLocaleDateString("sv-SE")
    );

    let userDataItemsDtos = [];
    userDataDtos.map((element) => {
      let dto = getUserDataItemDtoFromUserData(element);
      userDataItemsDtos.push(dto);
    });

    setAllUserDataItems(userDataItemsDtos);

    userDataItemsDtos.map((element) => {
      let dto = new TimelineDto(
        element.id,
        element.title,
        element.duration,
        element.dateTime,
        element.type,
        element.object
      );
      if (
        !timeEntriesForDate ||
        !timeEntriesForDate.some(
          (x) => x.userData && x.userData.id === element.userDataId
        )
      ) {
        dtos.push(dto);
      }
    });

    dtos = dtos.sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));

    setTimelineDtos(dtos);
    setLoadingTimelineDtos(false);
  }

  function getUserDataItemDtoFromUserData(data) {
    return new UserDataItemDto(
      data.dataObjectId,
      data.title,
      data.duration,
      new Date(data.dateTime),
      getTypeFromUserData(data),
      data,
      data.id,
      data.eventType
    );
  }

  function getTypeFromUserData(data) {
    let scope = data.userDataServiceScopeSelection.userDataServiceScope.name;
    if (scope === "Google Calendar" || scope === "Outlook Calendar") {
      return "meeting";
    } else if (scope === "Gmail" || scope === "Outlook Email") {
      return "email";
    } else if (scope === "Google Drive" || scope === "OneDrive") {
      return "file";
    } else if (scope === "Work Items") {
      return "ticket";
    } else if (
      scope === "Google Tasks" ||
      scope === "To Do Tasks" ||
      scope === "Planner Tasks"
    ) {
      return "task";
    } else {
      return "?";
    }
  }

  function addTimelineDtoMobile(timelineDto) {
    if (timelineDto.type != "timeEntry") {
      return (
        <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
          {getTimelineDtoTimelineBoxMobile(timelineDto)}
          <Divider style={{ backgroundColor: "#d8d8d8" }}></Divider>
        </List>
      );
    }
    return (
      <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        {getTimeEntryTimelineBoxMobile(timelineDto)}
        <Divider style={{ backgroundColor: "#d8d8d8" }}></Divider>
      </List>
    );
  }

  function addTimelineDto(timelineDto) {
    if (timelineDto.type != "timeEntry") {
      return (
        <Box p={0}>
          <TimelineItem>
            <TimelineContent>
              {getTimelineDtoTimelineBox(timelineDto)}
            </TimelineContent>

            <TimelineSeparator>
              <TimelineConnector style={{ backgroundColor: "#93264A" }} />
              <Avatar
                style={{
                  padding: "8px",
                  color: "white",
                  backgroundColor: "#666",
                  opacity: "0.9",
                }}
              >
                {getIconForTimelineDto(timelineDto, false)}
              </Avatar>
              <TimelineConnector style={{ backgroundColor: "#93264A" }} />
            </TimelineSeparator>
            <TimelineOppositeContent
              style={{
                backgroundColor: "#fff",
                alignSelf: "center",
                opacity: "0.45",
              }}
            >
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {DateTimeHelper.getTimeStringFromDate(timelineDto.dateTime)}
              </Grid>
            </TimelineOppositeContent>
          </TimelineItem>
        </Box>
      );
    }
    return (
      <Box p={0}>
        <TimelineItem>
          <TimelineContent
            style={{
              backgroundColor: "#fff",
              alignSelf: "center",
              opacity: "0.45",
            }}
          >
            {timelineDto.object && timelineDto.object.userData && (
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                {DateTimeHelper.getTimeStringFromDate(timelineDto.dateTime)}
              </Grid>
            )}
          </TimelineContent>
          <TimelineSeparator>
            <TimelineConnector style={{ backgroundColor: "#93264A" }} />
            <Avatar
              style={{
                padding: "8px",
                color: "white",
                backgroundColor: "#93264A",
                opacity: "0.9",
              }}
            >
              {getIconForTimelineDto(timelineDto, false)}
            </Avatar>
            <TimelineConnector style={{ backgroundColor: "#93264A" }} />
          </TimelineSeparator>
          <TimelineOppositeContent>
            {getTimeEntryTimelineBox(timelineDto)}
          </TimelineOppositeContent>
        </TimelineItem>
      </Box>
    );
  }

  function getTimelineDtoTimelineBoxMobile(timelineDto) {
    return (
      <ListItem style={{ padding: "0px", backgroundColor: "#fff" }}>

        <Box style={{ paddingBottom: "0px", paddingTop: "0px" }}>
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => showDialogForAddingTimeEntry(timelineDto)}
          >
            <ListItemIcon style={{ minWidth: "32px" }}>
              {getIconForTimelineDto(timelineDto, true)}
            </ListItemIcon>
            <ListItemText
              style={{ paddingLeft: "8px", wordBreak: "break-word" }}
            >
              <Grid item>
                <b>{timelineDto.title}</b>
                <br />
                <i>
                  {getEventTypeFromTimelineDto(timelineDto)}{" "}
                  {DateTimeHelper.getTimeStringFromDate(timelineDto.dateTime)}
                </i>
              </Grid>
            </ListItemText>
          </ListItem>
        </Box>
      </ListItem>
    );
  }

  function getTimeEntryTimelineBoxMobile(timelineDto) {
    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        button
        onClick={() => showDialogForEditTimeEntry(timelineDto.object)}
        style={{
          padding: "8px",
          paddingTop: "8px",
          paddingBottom: "8px",
          backgroundColor: "#fff",
        }}
      >
        <ListItemIcon style={{ minWidth: "32px" }}>
          {getIconForTimeEntry(timelineDto.object, true)}
        </ListItemIcon>
        <ListItemText
          style={{
            paddingLeft: "8px",
            paddingRight: "16px",
            wordBreak: "break-word",
          }}
        >
          <Grid item>
            <b>{timelineDto.title}</b>
            <br />
            <i>{addTimeEntryEventTypeText(timelineDto)}</i>
          </Grid>
        </ListItemText>
        {DateTimeHelper.getHourMinuteStringFromHours(timelineDto.duration * 60)}
      </ListItem>
    );
  }

  function addTimeEntryEventTypeText(timelineDto) {
    let text = getEventTypeFromTimelineDto(timelineDto);
    if (!text || text.length <= 0) {
      return "";
    }
    text =
      text + " " + DateTimeHelper.getTimeStringFromDate(timelineDto.dateTime);
    return text;
  }

  function getTimelineDtoTimelineBox(timelineDto) {
    return (
      <Grid container justifyContent="flex-end" alignItems="flex-end">
        <Grid item style={{ width: "333px" }}>
          <CardActionArea
            onClick={() => showDialogForAddingTimeEntry(timelineDto)}
          >
            <Grid
              container
              justifyContent="flex-end"
              style={{
                border: "2px solid #d8d8d8",
                borderRadius: "8px",
                backgroundColor: "#f6f6f6",
              }}
            >
              <Grid item style={{ alignSelf: "center" }}>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Box
                    style={{
                      padding: "8px",
                      textAlign: "right",
                      lineHeight: "1.4rem",
                      wordBreak: "break-word",
                      fontSize: "15px",
                      fontFamily: "Roboto",
                    }}
                  >
                    <Typography variant="subtitle1">
                      <b style={{ color: "#111", wordBreak: "break-all" }}>{timelineDto.title}</b>
                      <br />
                      {timelineDto.object &&
                        timelineDto.object.eventType &&
                        (timelineDto.object.type === "ticket" ||
                          timelineDto.object.type === "file") && (
                          <i>{getEventTypeFromTimelineDto(timelineDto)}</i>
                        )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Grid>
      </Grid>
    );
  }

  function getTimeEntryTimelineBox(timelineDto) {
    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <Grid item style={{ width: "333px" }}>
          <CardActionArea
            onClick={() => showDialogForEditTimeEntry(timelineDto.object)}
          >
            <Box
              style={{
                border: "2px solid rgb(147, 38, 74, 0.25)",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
            >
              <Grid item style={{ alignSelf: "center" }}>
                <ListItem>
                  <ListItemText>
                    <Typography
                      variant="subtitle1"
                      syle={{ wordBreak: "break-word" }}
                    >
                      <Box
                        style={{
                          padding: "8px",
                          textAlign: "left",
                          lineHeight: "1.4rem",
                        }}
                      >
                        <Box
                          style={{
                            color: "#93264A",
                            fontWeight: "500",
                            fontSize: "large",
                          }}
                        >
                          {timelineDto.object.activity.project.name}
                        </Box>
                        <span style={{ color: "#111", wordBreak: "break-all" }}>
                          <b>{timelineDto.title}</b>
                          <br />
                          {timelineDto.object &&
                            timelineDto.object.userData &&
                            (timelineDto.object.userData.type === "ticket" ||
                              timelineDto.object.userData.type === "file") && (
                              <i>{getEventTypeFromTimelineDto(timelineDto)}</i>
                            )}
                        </span>
                      </Box>
                    </Typography>
                  </ListItemText>

                  <Typography variant="subtitle1">
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      timelineDto.duration * 60
                    )}
                  </Typography>
                </ListItem>
              </Grid>
            </Box>
          </CardActionArea>
        </Grid>
      </Grid>
    );
  }

  const getEventTypeFromTimelineDto = (dto) => {
    if (dto.object && dto.object.userData) {
      if (dto.object.userData.eventType === "create") {
        return "Created";
      }
      if (dto.object.userData.eventType === "edit") {
        return "Edited";
      }
      if (dto.object.userData.eventType === "commit") {
        return "Commited";
      }
      if (dto.object.userData.eventType === "complete") {
        return "Completed";
      }
      if (dto.object.userData.eventType === "attend") {
        return "Attended";
      }
      if (dto.object.userData.eventType === "send") {
        return "Sent";
      }
      if (dto.object.userData.eventType === "comment") {
        return "Commented";
      }
    }

    if (dto.type != "timeentry") {
      if (dto.object.eventType === "create") {
        return "Created";
      }
      if (dto.object.eventType === "edit") {
        return "Edited";
      }
      if (dto.object.eventType === "commit") {
        return "Commited";
      }
      if (dto.object.eventType === "complete") {
        return "Completed";
      }
      if (dto.object.eventType === "attend") {
        return "Attended";
      }
      if (dto.object.eventType === "send") {
        return "Sent";
      }
      if (dto.object.eventType === "comment") {
        return "Commented";
      }
    }
    return "";
  };

  function addTimelineDtos() {
    if (!timelineDtos || timelineDtos.length < 1) {
      return (
        <Box p={2} style={{ alignSelf: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography>
              <i>No items or time entries found</i>
            </Typography>
            <Box style={{ paddingRight: "4px" }}></Box>
            <SentimentDissatisfiedIcon />
          </Grid>
        </Box>
      );
    }

    return timelineDtos.sort().map((element) => {
      return addTimelineDto(element);
    });
  }

  function addDayTotalMobile(type) {
    let dtos = [];
    if (type === "timeentries") {
      dtos = timelineDtos.filter((x) => x.type === "timeEntry");
    }

    if (!dtos || dtos.length < 1) {
      return (
        <React.Fragment></React.Fragment>
      );
    }

    let duration = 0;
    dtos.forEach((dto) => {
      duration += dto.object.durationMinutes;
    })
    return(
      <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
          style={{
            padding: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            backgroundColor: "#111",
            color: "#fff"
          }}
        >
          <ListItemText
            style={{
              paddingLeft: "8px",
              paddingRight: "16px",
              wordBreak: "break-word",
              
            }}
          >
            

              <span style={{fontSize: "larger"}}>
              {"Total"}
              </span>
            
          </ListItemText>
          <span style={{fontSize: "larger"}}>
          {DateTimeHelper.getHourMinuteStringFromHours(duration * 60)}
          </span>
        </ListItem>
        <Divider style={{ backgroundColor: "#d8d8d8" }}></Divider>
      </List>
    );
  }

  function addTimelineDtosMobile(type) {
    let dtos = [];
    if (type === "timeentries") {
      dtos = timelineDtos.filter((x) => x.type === "timeEntry");
    } else {
      dtos = timelineDtos.filter((x) => x.type != "timeEntry");
    }

    if (!dtos || dtos.length < 1) {
      return (
        <Box p={2} style={{ alignSelf: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography>
              <i>All events have been handled</i>
            </Typography>
            <Box style={{ paddingRight: "4px" }}></Box>
            <SentimentSatisfiedAltIcon />
          </Grid>
        </Box>
      );
    }

    if (type === "timeentries") {
      let activities = [];
      dtos.forEach((dto) => {
        if(!activities.some((x) => x.id === dto.object.activity.id)){
          activities.push(dto.object.activity);
        }
      })

      return activities.map((activity) => {
        return addActivitySummaryMobile(activity, dtos.filter(x => x.object.activity.id === activity.id));
      })
    } else{
      return dtos.sort().map((element) => {
        return addTimelineDtoMobile(element);
      });
    }
  }

  function addActivitySummaryMobile(activity, dtos){
    let duration = 0;
    dtos.forEach((dto) => {
      duration += dto.object.durationMinutes;
    })
    return(
      <React.Fragment>
      <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
          style={{
            padding: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            backgroundColor: "#f6f6f6",
          }}
        >
          <ListItemText
            style={{
              paddingLeft: "8px",
              paddingRight: "16px",
              wordBreak: "break-word",
            }}
          >
            <span style={{fontSize: "large"}}>
              <b>{activity.project.name}</b> <br /> <i>{activity.name}</i>
            </span>
          </ListItemText>
          <span style={{fontSize: "large"}}>
            {DateTimeHelper.getHourMinuteStringFromHours(duration * 60)}
          </span>
        </ListItem>
        <Divider style={{ backgroundColor: "#d8d8d8" }}></Divider>
      </List>
      {addFoo(dtos)}
      </React.Fragment>
    )
  }

  function addFoo(dtos){
    return dtos.sort().map((element) => {
      return addTimelineDtoMobile(element);
    });
  }

  function getIconForTimelineDto(timelineDto, mobile) {
    if (timelineDto.type === "timeEntry") {
      return getIconForTimeEntry(timelineDto.object, mobile);
    }

    let style = {
      color: "#fff",
      opacity: "0.99",
      padding: "8px",
      fontSize: "xx-large",
    };
    if (mobile) {
      style = { color: "#666", opacity: "0.75", paddingRight: "4px" };
    }
    if (timelineDto.type === "meeting") {
      return (
        <EventIcon
          style={style}
        ></EventIcon>
      );
    }
    if (timelineDto.type === "email") {
      return <EmailOutlinedIcon style={style}></EmailOutlinedIcon>;
    }
    if (timelineDto.type === "file") {
      return (
        <InsertDriveFileOutlinedIcon
          style={style}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (timelineDto.type === "ticket") {
      return <BugReportIcon style={style}></BugReportIcon>;
    }
    if (timelineDto.type === "task") {
      return (
        <AssignmentTurnedInOutlinedIcon
          style={style}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    return <Box></Box>;
  }

  function getIconForTimeEntry(entry, mobile) {
    let style = {
      color: "#fff",
      opacity: "0.99",
      padding: "8px",
      fontSize: "xx-large",
    };
    if (mobile) {
      style = { color: "#93264A", opacity: "0.75", paddingRight: "4px" };
    }
    if (!entry.userData && !entry.createdFromStandardTime) {
      return <PersonIcon style={style}></PersonIcon>;
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Outlook Calendar"
    ) {
      return <EventIcon style={style}></EventIcon>;
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Calendar"
    ) {
      return <EventIcon style={style}></EventIcon>;
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Drive"
    ) {
      return (
        <InsertDriveFileOutlinedIcon
          style={style}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "OneDrive"
    ) {
      return (
        <InsertDriveFileOutlinedIcon
          style={style}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Gmail"
    ) {
      return <EmailOutlinedIcon style={style}></EmailOutlinedIcon>;
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Outlook Email"
    ) {
      return <EmailOutlinedIcon style={style}></EmailOutlinedIcon>;
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          style={style}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "To Do Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          style={style}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Planner Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          style={style}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Work Items"
    ) {
      return <BugReportIcon style={style}></BugReportIcon>;
    }
    if (!entry.userData && entry.createdFromStandardTime) {
      return <ScheduleIcon style={style}></ScheduleIcon>;
    }
    return <Box></Box>;
  }

  function addOverviewBox() {
    return (
      <Grid item xs>
        <Box
          p={2}
          style={{
            backgroundColor: "#111",
            color: "#fff",
          }}
          bottom
        >
          <Grid container justifyContent="center">
            <Box p={1}>
              <Typography variant="h5">{getNameOfDayForOverview()}</Typography>
            </Box>
          </Grid>
        </Box>

        <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>{getListItemsForProjects()}</List>
      </Grid>
    );
  }

  function getNameOfDayForOverview() {
    let date = new Date(props.parentDay);
    let name =
      DateTimeHelper.getDayNameFromWeekday(date.getDay()) +
      " " +
      date.getDate() +
      " " +
      DateTimeHelper.getMonthName(date.getMonth()) +
      ", " +
      date.getFullYear();
    return name;
  }

  function getTimeEntriesFromTimeline() {
    return timelineDtos
      .filter((x) => x.type === "timeEntry")
      .map((x) => x.object);
  }

  function addTimelineBox() {

    let eventCount = 0;
    eventCount = timelineDtos.filter((x) => x.type != "timeEntry").length;


    return (
      <Grid
        item
        component={Card}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: "0px",
          borderRadius: "0px",
        }}
        xs
      >

        <Box>{addOverviewBox()}</Box>

        <Divider />
        <Box p={0}>
          <TimelineItem>
            <TimelineContent style={{paddingBottom: "24px"}}>
              <Grid container justifyContent="flex-end" alignItems="flex-end">
                <Grid item style={{ width: "333px", marginTop: "12px" }}>
                  <Grid
                    container
                    justifyContent="center"
                    style={{
                      border: "2px solid #666",
                      borderRadius: "8px",
                      backgroundColor: "#666"
                    }}
                  >
                  <Grid item style={{ alignSelf: "center" }}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Box
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          lineHeight: "1.4rem",
                          wordBreak: "break-word",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          paddingBottom: "16px",
                          paddingTop: "16px"
                        }}
                      >
                        <Typography variant="h6">
                          <b style={{ color: "#fff", wordBreak: "break-all" }}>{eventCount}{" "}events not in time report</b>
                          
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </TimelineContent>

            <TimelineSeparator style={{paddingLeft: "24px", paddingRight:"24px"}}>
              <TimelineConnector style={{ backgroundColor: "#93264A" }} />
            </TimelineSeparator>
            <TimelineOppositeContent
              style={{
                backgroundColor: "#fff",
                alignSelf: "center",
                paddingBottom: "24px"
                
              }}
            >
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                <Grid item style={{ width: "333px", marginTop: "12px" }}>
                  <Grid
                    container
                    justifyContent="center"
                    style={{
                      border: "2px solid #93264A",
                      borderRadius: "8px",
                      backgroundColor: "#93264A"
                    }}
                  >
                  <Grid item style={{ alignSelf: "center" }}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Box
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          lineHeight: "1.4rem",
                          wordBreak: "break-word",
                          fontSize: "15px",
                          fontFamily: "Roboto",
                          paddingBottom: "16px",
                          paddingTop: "16px"
                        }}
                      >
                        <Typography variant="h6">
                          <b style={{ color: "#fff", wordBreak: "break-all" }}>Time entries</b>
                          
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </TimelineOppositeContent>
          </TimelineItem>
        </Box>

        
        

        <Box p={0}>
          {loadingTimelineDtos && (
            <Box p={2}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ paddingBottom: "8px" }}
              >
                <CircularProgress></CircularProgress>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Typography variant="body1">Loading items...</Typography>
              </Grid>
            </Box>
          )}
          {!loadingTimelineDtos && <Timeline style={{margin: "0px", padding: "0px"}}>{addTimelineDtos()}</Timeline>}
        </Box>

<TimelineItem>
<TimelineContent style={{paddingBottom: "8px"}}></TimelineContent>
<TimelineSeparator style={{paddingLeft: "24px", paddingRight:"24px"}}>

              <TimelineConnector style={{ backgroundColor: "#93264A" }} />
            </TimelineSeparator>
            <TimelineOppositeContent
              style={{
                backgroundColor: "#fff",
                alignSelf: "center",
                paddingBottom: "8px"
                
              }}
            ></TimelineOppositeContent>
</TimelineItem>


        <Box p={2} style={{ backgroundColor: "#93264A", color: "#fff" }} bottom>
          <Grid container justifyContent="space-between">
            <Box p={1}>
              <Grid container alignItems="stretch" style={{ opacity: "0.5" }}>
              </Grid>
            </Box>
            <Box p={1}>
              <PrimaryButton
                icon="add"
                text="Add time"
                buttonClick={() =>
                  showDialogForAddingTimeEntry(
                    new TimelineDto(
                      ApplicationState.generateGuid(),
                      "",
                      0,
                      props.parentDay,
                      "timeEntry",
                      undefined
                    )
                  )
                }
              ></PrimaryButton>
            </Box>
          </Grid>
        </Box>
      </Grid>
    );
  }

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function addLoadingItemsBox() {
    return (
      <Box p={2}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingBottom: "8px" }}
        >
          <CircularProgress></CircularProgress>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="body1">Loading items...</Typography>
        </Grid>
      </Box>
    );
  }

  function addTimelineBoxMobile() {
    return (
      <Grid
        item
        component={Card}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: "0px",
          borderRadius: "0px",
        }}
        xs
      >
        <Box p={0}>
          <TabContext value={selectedTab}>
            <AppBar position="static">
              <TabList
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                style={{ backgroundColor: "#781938", color: "green" }}
                sx={{ color: "red" }}
                variant="fullWidth"
                indicatorColor="primary"
              >
                <CustomTab
                  label="EVENTS NOT IN TIME REPORT"
                  value="events"
                  style={{ backgroundColor: "#d8d8d8" }}
                />
                <CustomTab
                  label="TIME ENTRIES"
                  value="timeentries"
                  style={{ backgroundColor: "#d8d8d8" }}
                />
              </TabList>
            </AppBar>
            <CustomTabPanel value="events">
              <Box p={0}>
                {loadingTimelineDtos && (
                  <React.Fragment>{addLoadingItemsBox()}</React.Fragment>
                )}
                {!loadingTimelineDtos && (
                  <React.Fragment>
                    {addTimelineDtosMobile("events")}
                  </React.Fragment>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value="timeentries">
              <Box p={0}>
                {loadingTimelineDtos && (
                  <React.Fragment>{addLoadingItemsBox()}</React.Fragment>
                )}
                {!loadingTimelineDtos && (
                  <React.Fragment>
                    {addDayTotalMobile("timeentries")}
                    {addTimelineDtosMobile("timeentries")}
                  </React.Fragment>
                )}
              </Box>
            </CustomTabPanel>
          </TabContext>
        </Box>

        <Box p={2} style={{ backgroundColor: "#93264A", color: "#fff" }} bottom>
          <Grid container justifyContent="space-between">
            <Box p={1}>
              <Grid container alignItems="stretch" style={{ opacity: "0.5" }}>
              </Grid>
            </Box>
            <Box p={1}>
              <PrimaryButton
                icon="add"
                text="Add time"
                buttonClick={() =>
                  showDialogForAddingTimeEntry(
                    new TimelineDto(
                      ApplicationState.generateGuid(),
                      "",
                      0,
                      props.parentDay,
                      "timeEntry",
                      undefined
                    )
                  )
                }
              ></PrimaryButton>
            </Box>
          </Grid>
        </Box>
      </Grid>
    );
  }

  function getListItemsForProjects() {
    let counter = 1;
    return ApplicationState.getTimeItemsViewFromTimeEntries(
      getTimeEntriesFromTimeline()
    ).map((element) => {
      return getListItemForProject(element, counter++);
    });
  }

  function getListItemForProject(timeItemView, rowNr) {
    if (rowNr % 2 === 0) {
      return (
        <ListItem
          key={timeItemView.id}
          style={{ padding: "12px", paddingLeft: "24px", paddingRight: "24px", backgroundColor: "#f6f6f6" }}
        >
          <ListItemText key={timeItemView.id + "a"}>
            <strong>{timeItemView.project.name}</strong>
            {" "}
            <i>{timeItemView.activity.name}</i>
          </ListItemText>

          <strong>
            {DateTimeHelper.getHourMinuteStringFromHours(timeItemView.duration)}
          </strong>
        </ListItem>
      );
    }
    return (
      <ListItem
        key={timeItemView.id}
        style={{ padding: "12px", paddingLeft: "24px", paddingRight: "24px" }}
      >
        <ListItemText key={timeItemView.id + "a"}>
        <strong>{timeItemView.project.name}</strong>
            {" "}
            <i>{timeItemView.activity.name}</i>
        </ListItemText>

        <strong>
          {DateTimeHelper.getHourMinuteStringFromHours(timeItemView.duration)}
        </strong>
      </ListItem>
    );
  }

  function getCompletionText() {
    let completed = timelineDtos.filter(
      (x) => x.type === "timeEntry" && x.object.userData
    ).length;
    let total =
      timelineDtos.filter((x) => x.type != "timeEntry").length + completed;
    return completed + "/" + total + " items";
  }

  function showDialogForAddingTimeEntry(timelineDto) {
    ApplicationState.setTimeEntryToEdit(undefined);
    ApplicationState.setDateToAddTimeEntryFor(undefined);
    ApplicationState.setUserDataItemToAddTimeEntryFor(
      timelineDto
    );
    ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(undefined);
    ApplicationState.setInSummaryModeTimeEntry(false);
    setOpenTimeEntryDialog(true);
  }

  function showDialogForEditTimeEntry(timeEntry) {
    ApplicationState.setTimeEntryToEdit(undefined);
    ApplicationState.setDateToAddTimeEntryFor(undefined);
    ApplicationState.setUserDataItemToAddTimeEntryFor(undefined);
    ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(undefined);
    ApplicationState.setInSummaryModeTimeEntry(false);
    ApplicationState.setTimeEntryToEdit(timeEntry);
    setOpenTimeEntryDialog(true);
  }

  const handleCloseTimeEntryDialog = () => {
    setOpenTimeEntryDialog(false);
  };

  const handleCloseTimeEntryKeywordSuggestionDialog = () => {
    setOpenTimeEntryKeywordSuggestionDialog(false);
  };

  function deletedTimeEntry() {
    let newTimelineDtos = timelineDtos.slice();
    let timelineDtoEdited = timelineDtos.find(
      (x) =>
        x.type === "timeEntry" &&
        x.object.id === ApplicationState.getTimeEntryToEdit().id
    );
    const index = newTimelineDtos.indexOf(timelineDtoEdited);
    if (index > -1) {
      newTimelineDtos.splice(index, 1);
    }
    let removedTimelineDto = ApplicationState.getTimeEntryToEdit().userData;
    let dto = undefined;
    if (removedTimelineDto) {
      let td = allUserDataItems.find(
        (x) => x.id === removedTimelineDto.dataObjectId
      );
      dto = new TimelineDto(
        td.id,
        td.title,
        td.duration,
        td.dateTime,
        td.type,
        td.object
      );
    }
    if (dto) {
      newTimelineDtos.push(dto);
    }
    newTimelineDtos = newTimelineDtos.sort((a, b) =>
      new Date(a.dateTime).getTime() > new Date(b.dateTime).getTime() ? 1 : -1
    );
    setTimelineDtos(newTimelineDtos);
    setOpenTimeEntryDialog(false);
    props.changedTimeEntries();
  }

  function addedTimeEntry() {
    let newTimelineDtos = timelineDtos.slice();
    if (ApplicationState.getUserDataItemToAddTimeEntryFor().object) {
      let timelineDtoEdited = timelineDtos.find(
        (x) =>
          x.type != "timeEntry" &&
          x.object.id ===
            ApplicationState.getUserDataItemToAddTimeEntryFor().object.id
      );
      const index = newTimelineDtos.indexOf(timelineDtoEdited);
      if (index > -1) {
        newTimelineDtos.splice(index, 1);
      }
    }

    let editedTimeEntry = ApplicationState.getTimeEntryToEdit();
    let dto = new TimelineDto(
      editedTimeEntry.id,
      editedTimeEntry.description,
      editedTimeEntry.durationMinutes,
      editedTimeEntry.dateTime,
      "timeEntry",
      editedTimeEntry
    );
    newTimelineDtos.push(dto);
    newTimelineDtos = newTimelineDtos.sort((a, b) =>
      new Date(a.dateTime).getTime() > new Date(b.dateTime).getTime() ? 1 : -1
    );

    setTimelineDtos(newTimelineDtos);
    setOpenTimeEntryDialog(false);
    props.changedTimeEntries();
  }

  function editedTimeEntry() {
    let newTimelineDtos = timelineDtos.slice();
    let timelineDtoEdited = timelineDtos.find(
      (x) =>
        x.type === "timeEntry" &&
        x.object.id === ApplicationState.getTimeEntryToEdit().id
    );
    const index = newTimelineDtos.indexOf(timelineDtoEdited);
    if (index > -1) {
      newTimelineDtos.splice(index, 1);
    }
    let editedTimeEntry = ApplicationState.getTimeEntryToEdit();
    let dto = new TimelineDto(
      editedTimeEntry.id,
      editedTimeEntry.description,
      editedTimeEntry.durationMinutes,
      editedTimeEntry.dateTime,
      "timeEntry",
      editedTimeEntry
    );
    newTimelineDtos.push(dto);
    newTimelineDtos = newTimelineDtos.sort((a, b) =>
      new Date(a.dateTime).getTime() > new Date(b.dateTime).getTime() ? 1 : -1
    );
    setTimelineDtos(newTimelineDtos);
    setOpenTimeEntryDialog(false);
    props.changedTimeEntries();
  }

  const editedTimeEntryShowKeywordSuggestions = () => {
    if (ApplicationState.getUserDataItemToAddTimeEntryFor()) {
      addedTimeEntry();
    } else {
      editedTimeEntry();
    }
    setOpenTimeEntryKeywordSuggestionDialog(false);
    setOpenTimeEntryKeywordSuggestionDialog(true);
  };

  return (
    <Box>
      <Box>
        {useMediaQuery(useTheme().breakpoints.down("lg")) && (
          <React.Fragment>
            <Box>{addTimelineBoxMobile()}</Box>
          </React.Fragment>
        )}
        {useMediaQuery(useTheme().breakpoints.up("lg")) && (
          <Box>{addTimelineBox()}</Box>
        )}
      </Box>

      <Dialog
        open={openTimeEntryDialog}
        onClose={handleCloseTimeEntryDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <TimeEntryView
          deletedTimeEntry={deletedTimeEntry}
          addedTimeEntry={addedTimeEntry}
          editedTimeEntry={editedTimeEntry}
          cancelClick={handleCloseTimeEntryDialog}
          editedTimeEntryShowKeywordSuggestions={
            editedTimeEntryShowKeywordSuggestions
          }
        ></TimeEntryView>
      </Dialog>
      <Dialog
        open={openTimeEntryKeywordSuggestionDialog}
        onClose={handleCloseTimeEntryKeywordSuggestionDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <TimeEntryKeywordView
          cancelClick={handleCloseTimeEntryKeywordSuggestionDialog}
        ></TimeEntryKeywordView>
      </Dialog>
    </Box>
  );
}

export default MyDayView;

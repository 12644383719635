export default class ProjectDto {
  constructor(id, name, description, organization, created, activeFrom, activeTo, frameworkAgreement, externalId, clientId, clientContactPerson, role, extentMinimumPerMonth, extentMaximumPerMonth, hourlyRateToInvoice, hourlyRateToSubcontractor, linkToFolder, status, isTimeReportingAllowedForAll, exportId) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.organization = organization;
    this.created = created;
    this.activeFrom = activeFrom;
    this.activeTo = activeTo;
    this.frameworkAgreement = frameworkAgreement;
    this.externalId = externalId;
    this.clientId = clientId;
    this.clientContactPerson = clientContactPerson;
    this.role = role;
    this.extentMinimumPerMonth = extentMinimumPerMonth;
    this.extentMaximumPerMonth = extentMaximumPerMonth;
    this.hourlyRateToInvoice = hourlyRateToInvoice;
    this.hourlyRateToSubcontractor = hourlyRateToSubcontractor;
    this.linkToFolder = linkToFolder;
    this.status = status;
    this.isTimeReportingAllowedForAll = isTimeReportingAllowedForAll;
    this.exportId = exportId;
  }
}

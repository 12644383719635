import Box from "@mui/material/Box";
import {  Paper } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import DateTimeHelper from "../Utils/DateTimeHelper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function AbsenceView() {
  const [users, setUsers] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [absenceEntries, setAbsenceEntries] = useState([]);
  
  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      loadUserInfo();
    };
    load1();
  }, []);

  async function loadUserInfo() {
    let users = await ApplicationState.getUsersForOrganization(ApplicationState.getUser().organization.organizationId);
    let timeEntries = await ApplicationState.getTimeEntriesForOrganization(ApplicationState.getUser().organization.organizationId, new Date(2023, 6, 1).toLocaleDateString("sv-SE"), new Date(2023, 8, 31).toLocaleDateString("sv-SE"));  
    let orgSettings = await ApplicationState.getOrganizationSettings(ApplicationState.getUser().organization.organizationId);
    let absenceEntries = timeEntries.filter((timeEntry) => timeEntry.activity.project.id === orgSettings.absenceProject && timeEntry.activity.name === "Semester");
    setAbsenceEntries(absenceEntries);

    let usersWithAbsenceOnly = [];
    for(let i = 0; i < users.length; i++) {
      let user = users[i];
      let absenceEntriesForUser = absenceEntries.filter((absenceEntry) => absenceEntry.user.userId === user.userId);
      if(!user.isSubContractor) {
        usersWithAbsenceOnly.push(user);
      }
    }

    usersWithAbsenceOnly.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setUsers(usersWithAbsenceOnly);

    let weeks = DateTimeHelper.getWeeksForPeriod(new Date(2023, 5, 1), new Date(2023, 7, 31));
    setWeeks(weeks);
    
  }

  const addAbsence = () => {
    return addAbsenceBox();
  };

  function addAbsenceBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {weeks.map((week, index) => (
              <TableCell key={index}>{DateTimeHelper.getWeekName(week)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.name}>
              <TableCell component="th" scope="row">
                {user.firstName + ' ' + user.lastName}
              </TableCell>
              {weeks.map((week, index) => {
                const hasVacation = checkIfUserHasVacationInWeek(user, week);
                return (
                  <TableCell 
                    key={index} 
                    style={hasVacation ? {backgroundColor: 'lightgreen'} : {}}
                  >
                    {hasVacation ? 'Vacation' : '-'}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </CustomGrid>
    );
  }

  function checkIfUserHasVacationInWeek(user, week) {
    let absenceEntriesForUser = absenceEntries.filter((absenceEntry) => absenceEntry.user.userId === user.userId);
    let absenceEntriesForWeek = absenceEntriesForUser.filter((absenceEntry) => new Date(absenceEntry.date) >= new Date(week.startDate) && new Date(absenceEntry.date) <= new Date(week.endDate));

    if(absenceEntriesForWeek.length > 0) {
      return true;
    }
    return false;
  }
  

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box></Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <React.Fragment>
        <Typography variant="h4">
          <Box paddingRight={2}>
            <Typography variant="h4">Absence</Typography>
          </Box>
        </Typography>
      </React.Fragment>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "0px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
          </Paper>
        </Box>
        <Box>{addAbsence()}</Box>

      </Grid>
    </Grid>
  );
}

export default AbsenceView;

import TimeEntryDTO from "../Dtos/TimeEntryDto";
import TimeReportDTO from "../Dtos/TimeReportDto";
import ProjectDto from "../Dtos/ProjectDto";
import ActivityDto from "../Dtos/ActivityDto";
import ApplicationState from "../State/ApplicationState";
import UserDataServiceDto from "../Dtos/UserDataServiceDto";
import UserDataServiceScopeDto from "../Dtos/UserDataServiceScopeDto";
import StandardTimeDto from "../Dtos/StandardTimeDto";
import GroupDto from "../Dtos/GroupDto";
import ProjectSettingDto from "../Dtos/ProjectSettingDto";
import ActivityKeywordDto from "../Dtos/ActivityKeywordDto";
import UserDataServiceSelectionDto from "../Dtos/UserDataServiceSelectionDto";
import UserProjectSelectionDto from "../Dtos/UserProjectSelectionDto";
import UserDataServiceScopeSelectionDto from "../Dtos/UserDataServiceScopeSelectionDto";
import HolidayDto from "../Dtos/HolidayDto";
import EmailDto from "../Dtos/EmailDto";
import MeetingDto from "../Dtos/MeetingDto";
import FileDto from "../Dtos/FileDto";
import UserSettingDto from "../Dtos/UserSettingDto";
import OrganizationSettingDto from "../Dtos/OrganizationSettingDto";
import TicketDto from "../Dtos/TicketDto";
import GroupProjectSelectionDto from "../Dtos/GroupProjectSelectionDto";
import UserInGroupDto from "../Dtos/UserInGroupDto";
import TaskDto from "../Dtos/TaskDto";
import FileExporterDto from "../Dtos/FileExporterDto";
import FileExporterPropertyDto from "../Dtos/FileExporterPropertyDto";
import FileExporterProjectSelectionDto from "../Dtos/FileExporterProjectSelectionDto";
import ActivitySettingDto from "../Dtos/ActivitySettingDto";
import EmailExporterDto from "../Dtos/EmailExporterDto";
import EmailExporterPropertyDto from "../Dtos/EmailExporterPropertyDto";
import EmailExporterProjectSelectionDto from "../Dtos/EmailExporterProjectSelectionDto";
import ReduceWorkingTimeKeywordDto from "../Dtos/ReduceWorkingTimeKeywordDto";
import NormalWorkingTimeDto from "../Dtos/NormalWorkingTimeDto";
import UserDataDto from "../Dtos/UserDataDto";
import UserTargetWorkingTimeDto from "../Dtos/UserTargetWorkingTimeDto";
import IgnoreKeywordDto from "../Dtos/IgnoreKeywordDto";
import TimeReportFileDto from "../Dtos/TimeReportFileDto";

export default class DynamicTimeDataService {
  async getTimeEntriesForUser(email, from, to) {
    let result = await fetch(
      this.getApiUrl("TimeEntry/ForUser/" + email + "/" + from + "/" + to)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let timeEntries = [];
    json.forEach((element) => {
      timeEntries.push(
        new TimeEntryDTO(
          element.id,
          element.activity,
          element.description,
          element.date,
          element.durationMinutes,
          element.created,
          element.user,
          element.userData,
          new Date(element.dateTime).toString(),
          element.updated,
          element.createdByDynamicTime,
          element.updatedActivity,
          element.updatedDuration,
          element.createdFromStandardTime,
          element.originalDuration,
          element.summaryEntry,
          element.durationOvertime1,
          element.durationOvertime2
        )
      );
    });    
    return timeEntries;
  }

  async getTimeEntriesForOrganization(id, from, to) {
    let result = await fetch(
      this.getApiUrl("TimeEntry/ForOrganization/" + id + "/" + from + "/" + to)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let timeEntries = [];
    json.forEach((element) => {
      timeEntries.push(
        new TimeEntryDTO(
          element.id,
          element.activity,
          element.description,
          element.date,
          element.durationMinutes,
          element.created,
          element.user,
          element.userData,
          new Date(element.dateTime).toString(),
          element.updated,
          element.createdByDynamicTime,
          element.updatedActivity,
          element.updatedDuration,
          element.createdFromStandardTime,
          element.originalDuration,
          element.summaryEntry,
          element.durationOvertime1,
          element.durationOvertime2
        )
      );
    });
    return timeEntries;
  }

  async saveNewTimeEntry(timeEntry) {
    const response = await fetch(this.getApiUrl("TimeEntry/"), {
      method: "POST",
      body: JSON.stringify(timeEntry),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async saveUpdatedTimeEntry(timeEntry) {
    const response = await fetch(this.getApiUrl("TimeEntry/" + timeEntry.id), {
      method: "PUT",
      body: JSON.stringify(timeEntry),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async getActiveProjectsForUser(userId) {
    let result = await fetch(this.getApiUrl("Project/ActiveForUser/" + userId));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async getProjectsForUser(userId) {
    let result = await fetch(this.getApiUrl("Project/ForUser/" + userId));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async getActivitiesForUser(userId) {
    let result = await fetch(this.getApiUrl("Activity/ForUser/" + userId));
    let text = await result.text();
    let json = JSON.parse(text);
    let activities = [];
    json.forEach((element) => {
      var activity = new ActivityDto(
        element.id,
        element.project,
        element.name,
        element.description,
        element.created,
        element.code,
        element.exportId
      );
      activities.push(activity);
    });
    return activities;
  }

  async sendReport(email, from, to, comment) {
    let result;
    if(!comment || comment.length < 1) {
      result = await fetch(
        this.getApiUrl(
          "TimeReportSending/SendTimeReportForUser/" +
            email +
            "/" +
            from +
            "/" +
            to +
            "/"
        )
      );
    } else {
      result = await fetch(
        this.getApiUrl(
          "TimeReportSending/SendTimeReportForUser?email=" +
            email +
            "&from=" +
            from +
            "&to=" +
            to +
            "&comment=" + 
            comment
        )
      );
    }
    
    let text = await result.text();
    if (result.status === 200) {
      return "Ok";
    }
    return (
      result.status +
      " - " +
      result.statusText +
      " URL: " +
      result.url +
      " MESSAGE: " +
      text
    );
  }

  async approveReport(dto) {
    const response = await fetch(
      this.getApiUrl("TimeReportSending/ApproveTimeReportAndExportData?timeReportId=" + dto.id +"&comment=" + dto.comment + "&approverUserId=" + dto.approver.userId)
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async ignoreUserDataAlways(id, email, title) {
    const response = await fetch(
      this.getApiUrl("UserData/IgnoreAlways?userDataId=" + id +"&email=" + email + "&title=" + title)
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async ignoreUserDataOnce(id) {
    const response = await fetch(
      this.getApiUrl("UserData/IgnoreOnce/" + id)
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getUser(email) {
    let url = this.getApiUrl("User/Email/" + email);
    let result = await fetch(url);
    let text = await result.text();
    if (text) {
      let json = JSON.parse(text);
      return json;
    }
    return "No user found!";
  }

  async loginUser(email, loginMethod, loginToken) {
    let result = await fetch(this.getApiUrl("User/Login/" + email + "?loginMethod=" + loginMethod + "&loginToken=" + loginToken));
    await this.handleErrors(result);
    let text = await result.text();
    let json = JSON.parse(text);
    console.log("LOGIN: ", json);
    return json;
  }

  async handleErrors(response) {
    if (response.status >= 400 && response.status < 600) {
      var errorMessage = await response.text();
      errorMessage = "Login failed: " +  errorMessage + ", StatusCode: " + response.status;
      console.log(errorMessage);
      throw new Error(errorMessage);
    }
  }

  async organizationHaveValidLicense(organizationId) {
    let result = await fetch(this.getApiUrl("Organization/" + organizationId));
    let text = await result.text();
    if (text) {
      let json = JSON.parse(text);
      let expireDate = new Date(json.license.expireDate);
      expireDate.setHours(0, 0, 0);
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      if (expireDate < today) {
        return "InvalidLicense";
      }
    }
    return "Ok";
  }

  async deleteTimeEntry(id) {
    let result = await fetch(this.getApiUrl("TimeEntry/") + id, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteTimeEntriesForUserOnDate(email, date) {
    let result = await fetch(this.getApiUrl("TimeEntry/ForUser/") + email + "/" + date, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteTimeEntriesForUserAndProjectOnDate(email, date, projectId) {
    let result = await fetch(this.getApiUrl("TimeEntry/ForUserAndProject/") + email + "/" + date + "/" + projectId, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteTimeEntriesForUserAndActivityOnDate(email, date, activityId) {
    let result = await fetch(this.getApiUrl("TimeEntry/ForUserAndActivity/") + email + "/" + date + "/" + activityId, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async getTimeReportsForUser(email) {
    let result = await fetch(this.getApiUrl("TimeReport/ForUserAll/" + email));
    let text = await result.text();
    if (!text) {
      return undefined;
    }
    let json = JSON.parse(text);
    let timeReports = [];
    json.forEach((element) => {
      timeReports.push(
        new TimeReportDTO(
          element.id,
          element.user,
          element.from,
          element.to,
          element.created,
          element.sent,
          element.approved,
          element.approver,
          element.approverComment,
          element.userComment
        )
      );
    });
    return timeReports;
  }

  async updateTimeReport(timeReport) {
    const response = await fetch(
      this.getApiUrl("TimeReport/" + timeReport.id),
      {
        method: "PUT",
        body: JSON.stringify(timeReport),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewTimeReport(timeReport) {
    const response = await fetch(this.getApiUrl("TimeReport/"), {
      method: "POST",
      body: JSON.stringify(timeReport),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  getApiUrl(path) {
    return `${ApplicationState.getState().config.API_URL}/${path}`;
  }

  async getUserDataServicesForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserDataService/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let services = [];
    json.forEach((element) => {
      var service = new UserDataServiceDto(
        element.id,
        element.name,
        element.description
      );
      services.push(service);
    });
    return services;
  }

  async getUserDataServiceSelectionsForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserDataServiceSelection/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let services = [];
    json.forEach((element) => {
      var service = new UserDataServiceSelectionDto(
        element.id,
        element.accessToken,
        element.refreshToken,
        element.user,
        element.userDataService,
        element.accountIdentifier,
        element.dynamicTimeAccessCode,
        element.name,
        element.username
      );
      services.push(service);
    });
    return services;
  }

  async getUserDataServiceScopesForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserDataServiceScope/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let scopes = [];
    json.forEach((element) => {
      var scope = new UserDataServiceScopeDto(
        element.id,
        element.name,
        element.description,
        element.scope,
        element.userDataService
      );
      scopes.push(scope);
    });
    return scopes;
  }

  async getAllUserDataServices() {
    let result = await fetch(this.getApiUrl("UserDataService/"));
    let text = await result.text();
    let json = JSON.parse(text);
    let services = [];
    json.forEach((element) => {
      var service = new UserDataServiceDto(
        element.id,
        element.name,
        element.description
      );
      services.push(service);
    });
    return services;
  }

  async getAllUserDataServiceScopes() {
    let result = await fetch(this.getApiUrl("UserDataServiceScope/"));
    let text = await result.text();
    let json = JSON.parse(text);
    let services = [];
    json.forEach((element) => {
      var service = new UserDataServiceScopeDto(
        element.id,
        element.name,
        element.description,
        element.scope,
        element.userDataService
      );
      services.push(service);
    });
    return services;
  }

  async getStandardTimeForUser(email) {
    let result = await fetch(this.getApiUrl("StandardTime/ForUser/" + email));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new StandardTimeDto(
        element.id,
        element.activity,
        element.user,
        element.weekday,
        element.maxHours,
        element.maxMinutes,
        element.priority,
        element.created
      );
      projects.push(project);
    });
    return projects;
  }

  async getProjectsForOrganization(id) {
    let result = await fetch(this.getApiUrl("Project/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async getGroupsForOrganization(id) {
    let result = await fetch(this.getApiUrl("Group/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new GroupDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getGroupsForProject(id) {
    let result = await fetch(this.getApiUrl("Group/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new GroupDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getUsersForProject(id) {
    let result = await fetch(this.getApiUrl("User/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getActivitiesForProject(id) {
    let result = await fetch(this.getApiUrl("Activity/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let activities = [];
    json.forEach((element) => {
      var activity = new ActivityDto(
        element.id,
        element.project,
        element.name,
        element.description,
        element.created,
        element.code,
        element.exportId
      );
      activities.push(activity);
    });
    return activities;
  }

  async saveUserDataServiceSelection(selection) {
    const response = await fetch(this.getApiUrl("UserDataServiceSelection/"), {
      method: "POST",
      body: JSON.stringify(selection),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewUser(user) {
    const response = await fetch(this.getApiUrl("User/"), {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200 || response.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedUser(dto) {
    await this.putDtoWithId("User", dto, dto.userId);
  }

  async getTimeReportsForOrganization(id) {
    let result = await fetch(
      this.getApiUrl("TimeReport/ForOrganization/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let timeReports = [];
    json.forEach((element) => {
      let timeReport = new TimeReportDTO(
        element.id,
        element.user,
        element.from,
        element.to,
        element.created,
        element.sent,
        element.approved,
        element.approver,
        element.approverComment,
        element.userComment
      );
      timeReports.push(timeReport);
    });
    return timeReports;
  }

  async saveNewActivityKeyword(dto) {
    const response = await fetch(this.getApiUrl("ActivityKeyword/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedActivityKeyword(dto) {
    const response = await fetch(this.getApiUrl("ActivityKeyword/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteActivityKeyword(id) {
    let response = await fetch(this.getApiUrl("ActivityKeyword/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getKeywordsForActivity(id) {
    let result = await fetch(
      this.getApiUrl("ActivityKeyword/ForActivity/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let timeReports = [];
    json.forEach((element) => {
      let timeReport = new TimeReportDTO(
        element.id,
        element.user,
        element.from,
        element.to,
        element.created,
        element.sent,
        element.approved,
        element.approver,
        element.approverComment,
        element.userComment
      );
      timeReports.push(timeReport);
    });
    return timeReports;
  }

  async getUsersForOrganization(id, includeInactiveUsers = false) {
    let url = this.getApiUrl("User/ForOrganization/" + id);
    if(includeInactiveUsers) {
      url += "?includeInactiveUsers=true";
    }
    let result = await fetch(url);
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getGroupsForUser(email) {
    let result = await fetch(this.getApiUrl("Group/ForUser/" + email));
    let text = await result.text();
    let json = JSON.parse(text);
    let groups = [];
    json.forEach((element) => {
      let group = new GroupDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created
      );
      groups.push(group);
    });
    return groups;
  }

  async getProjectSettingsForUser(email) {
    let result = await fetch(this.getApiUrl("ProjectSetting/ForUser/" + email));
    let text = await result.text();
    let json = JSON.parse(text);
    let settings = [];
    json.forEach((element) => {
      let setting = new ProjectSettingDto(
        element.id,
        element.project,
        element.user,
        element.name,
        element.value,
        element.created
      );
      settings.push(setting);
    });
    return settings;
  }

  async getProjectSettingsForProject(id) {
    let result = await fetch(this.getApiUrl("ProjectSetting/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let settings = [];
    json.forEach((element) => {
      let setting = new ProjectSettingDto(
        element.id,
        element.project,
        element.user,
        element.name,
        element.value,
        element.created
      );
      settings.push(setting);
    });
    return settings;
  }

  async getActivityKeywordsForUser(email) {
    let result = await fetch(
      this.getApiUrl("ActivityKeyword/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let keywords = [];
    json.forEach((element) => {
      let keyword = new ActivityKeywordDto(
        element.id,
        element.keyword,
        element.activity,
        element.user,
        element.created
      );
      keywords.push(keyword);
    });
    return keywords;
  }

  async connectToUserDataService(
    service,
    selectedScopesForService,
    accessCode,
    userEmail
  ) {
    let type = "";
    if (service.id === "b361afbc-ba14-4c8f-adc7-8f65df5f12e4") {
      type = "Google";
    }
    if (service.id === "ec083eb6-6cf8-471b-8f8e-18c1e8e60f5d") {
      type = "Microsoft";
    }

    var dataToSend = {
      code: accessCode,
      type: type,
      userEmail: userEmail,
      scopes: selectedScopesForService,
    };
    console.log(dataToSend);

    const response = await fetch(this.getApiUrl("Auth/"), {
      method: "POST",
      body: JSON.stringify(dataToSend),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async connectToUserDataServiceWithPersonalAccessToken(
    service,
    selectedScopesForService,
    accessCode,
    userEmail,
    accountIdentifier,
    previousAccountIdentifier,
    username,
    previousUsername
  ) {
    let type = "AzureDevOps";

    var dataToSend = {
      code: accessCode,
      type: type,
      userEmail: userEmail,
      scopes: selectedScopesForService,
      accountIdentifier: accountIdentifier,
      previousAccountIdentifier: previousAccountIdentifier,
      username: username,
      previousUsername: previousUsername
    };
    console.log(dataToSend);

    const response = await fetch(this.getApiUrl("Auth/AzureDevOps/"), {
      method: "POST",
      body: JSON.stringify(dataToSend),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteUser(id) {
    let result = await fetch(this.getApiUrl("User/") + id, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200 || result.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewProjectSetting(setting) {
    const response = await fetch(this.getApiUrl("ProjectSetting/"), {
      method: "POST",
      body: JSON.stringify(setting),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedProjectSetting(setting) {
    const response = await fetch(
      this.getApiUrl("ProjectSetting/" + setting.id),
      {
        method: "PUT",
        body: JSON.stringify(setting),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteProjectSetting(id) {
    let result = await fetch(this.getApiUrl("ProjectSetting/") + id, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200 || result.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewProject(project) {
    const response = await fetch(this.getApiUrl("Project/"), {
      method: "POST",
      body: JSON.stringify(project),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedProject(project) {
    const response = await fetch(this.getApiUrl("Project/" + project.id), {
      method: "PUT",
      body: JSON.stringify(project),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteProject(id) {
    let response = await fetch(this.getApiUrl("Project/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewGroup(group) {
    const response = await fetch(this.getApiUrl("Group/"), {
      method: "POST",
      body: JSON.stringify(group),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedGroup(group) {
    const response = await fetch(this.getApiUrl("Group/" + group.id), {
      method: "PUT",
      body: JSON.stringify(group),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteGroup(id) {
    let response = await fetch(this.getApiUrl("Group/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewActivity(activity) {
    const response = await fetch(this.getApiUrl("Activity/"), {
      method: "POST",
      body: JSON.stringify(activity),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedActivity(activity) {
    const response = await fetch(this.getApiUrl("Activity/" + activity.id), {
      method: "PUT",
      body: JSON.stringify(activity),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteActivity(id) {
    let response = await fetch(this.getApiUrl("Activity/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewUserProjectSelection(dto) {
    const response = await fetch(this.getApiUrl("UserProjectSelection/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteUserProjectSelection(id) {
    let response = await fetch(this.getApiUrl("UserProjectSelection/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getUserProjectSelectionsForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserProjectSelection/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new UserProjectSelectionDto(
        element.id,
        element.user,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getUserProjectSelectionsForProject(id) {
    let result = await fetch(
      this.getApiUrl("UserProjectSelection/ForProject/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new UserProjectSelectionDto(
        element.id,
        element.user,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewGroupProjectSelection(dto) {
    const response = await fetch(this.getApiUrl("GroupProjectSelection/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteGroupProjectSelection(id) {
    let response = await fetch(this.getApiUrl("GroupProjectSelection/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getGroupProjectSelectionsForGroup(id) {
    let result = await fetch(
      this.getApiUrl("GroupProjectSelection/ForGroup/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new GroupProjectSelectionDto(
        element.id,
        element.group,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getGroupProjectSelectionsForProject(id) {
    let result = await fetch(
      this.getApiUrl("GroupProjectSelection/ForProject/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new GroupProjectSelectionDto(
        element.id,
        element.group,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewUserInGroup(dto) {
    const response = await fetch(this.getApiUrl("UserInGroup/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteUserInGroup(id) {
    let response = await fetch(this.getApiUrl("UserInGroup/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getUserInGroupForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserInGroup/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new UserInGroupDto(
        element.id,
        element.group,
        element.user,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getUserInGroupForGroup(id) {
    let result = await fetch(
      this.getApiUrl("UserInGroup/ForGroup/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new UserInGroupDto(
        element.id,
        element.group,
        element.user,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewStandardTime(dto) {
    const response = await fetch(this.getApiUrl("StandardTime/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedStandardTime(dto) {
    const response = await fetch(this.getApiUrl("StandardTime/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteStandardTime(id) {
    let response = await fetch(this.getApiUrl("StandardTime/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200 || response.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteUserDataServiceSelection(id) {
    let response = await fetch(
      this.getApiUrl("UserDataServiceSelection/") + id,
      {
        method: "DELETE",
      }
    );
    let text = await response.text();
    if (response.status === 200 || response.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getUserDataServiceScopeSelectionsForUser(email) {
    let result = await fetch(
      this.getApiUrl("UserDataServiceScopeSelection/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new UserDataServiceScopeSelectionDto(
        element.id,
        element.userDataServiceScope,
        element.userDataServiceSelection,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getUsersForGroup(id) {
    let result = await fetch(this.getApiUrl("User/ForGroup/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectsForGroup(id) {
    let result = await fetch(this.getApiUrl("Project/ForGroup/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async getProjectsForFileExporter(id) {
    let result = await fetch(this.getApiUrl("Project/ForFileExporter/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async getProjectsForEmailExporter(id) {
    let result = await fetch(this.getApiUrl("Project/ForEmailExporter/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new ProjectDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.activeFrom,
        element.activeTo,
        element.frameworkAgreement,
        element.externalId,
        element.clientId,
        element.clientContactPerson,
        element.role,
        element.extentMinimumPerMonth,
        element.extentMaximumPerMonth,
        element.hourlyRateToInvoice,
        element.hourlyRateToSubcontractor,
        element.linkToFolder,
        element.status,
        element.isTimeReportingAllowedForAll,
        element.exportId
      );
      projects.push(project);
    });
    return projects;
  }

  async rejectTimeReport(dto) {
    const response = await fetch(this.getApiUrl("TimeReport/RejectSentReport/" + dto.id + "/?comment=" + dto.comment));
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async unlockApprovedTimeReport(dto) {
    const response = await fetch(this.getApiUrl("TimeReport/UnlockApprovedReport/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getHolidaysForOrganization(id, year) {
    let result = await fetch(this.getApiUrl("Organization/Holidays/" + id + "/" + year + "/"));
    let text = await result.text();
    let json = JSON.parse(text);
    let projects = [];
    json.forEach((element) => {
      var project = new HolidayDto(
        element.id,
        element.name,
        new Date(element.date),
        element.percentage,
        element.organization,
        element.created
      );
      projects.push(project);
    });
    return projects;
  }

  async getHolidaysForOrganization(id) {
    let result = await fetch(this.getApiUrl("Holiday/ForOrganization/" + id + "/"));
    let text = await result.text();
    let json = JSON.parse(text);
    let holidays = [];
    json.forEach((element) => {
      var holiday = new HolidayDto(
        element.id,
        element.name,
        new Date(element.date),
        element.percentage,
        element.organization,
        element.created
      );
      holidays.push(holiday);
    });
    return holidays;
  }

  async getKeywordSuggestionsForUserData(id) {
    let result = await fetch(this.getApiUrl("KeywordSuggestion/ForUserData/" + id + "/"));
    let text = await result.text();
    let json = JSON.parse(text);
    let keywords = [];
    json.forEach((element) => {
      keywords.push(element);
    });
    return keywords;
  }

  async getEmailsForUser(email, from, to) {
    let result = await fetch(this.getApiUrl("UserData/Emails/" + email + "/" + from + "/" + to));
    let text = await result.text();
    let json = JSON.parse(text);
    let emails = [];
    json.forEach((element) => {
      var email = new EmailDto(
        element.id,
        new Date(element.lastModifiedDateTime),
        element.subject,
        element.body,
        element.from,
        element.to,
        element.categories,
        element.userData,
      );
      emails.push(email);
    });
    return emails;
  }

  async getEmailForUser(userEmail, userDataId) {
    let result = await fetch(this.getApiUrl("UserData/Email/" + userEmail + "/" + userDataId));
    let text = await result.text();
    let element = JSON.parse(text);
        console.log(element);
      var email = new EmailDto(
        element.id,
        new Date(element.lastModifiedDateTime),
        element.subject,
        element.body,
        element.from,
        element.to,
        element.categories,
        element.userData,
      );
   return email;
  }

  async getMeetingsForUser(email, from, to) {
    let result = await fetch(this.getApiUrl("UserData/Meetings/" + email + "/" + from + "/" + to));
    let text = await result.text();
    let json = JSON.parse(text);
    let meetings = [];
    json.forEach((element) => {
      let meeting = new MeetingDto(
        element.id,
        new Date(element.startTime),
        new Date(element.endTime),
        element.title,
        element.description,
        element.location,
        element.from,
        element.to,
        element.categories,
        element.userData,
      );
      meetings.push(meeting);
    });
    return meetings;
  }

  async getMeetingForUser(userEmail, userDataId) {
    let result = await fetch(this.getApiUrl("UserData/Meeting/" + userEmail + "/" + userDataId));
    let text = await result.text();
    let element = JSON.parse(text);
        console.log(element);
      let meeting = new MeetingDto(
        element.id,
        new Date(element.startTime),
        new Date(element.endTime),
        element.title,
        element.description,
        element.location,
        element.from,
        element.to,
        element.categories,
        element.userData,
      );
   return meeting;
  }

  async getFilesForUser(email, from, to) {
    let result = await fetch(this.getApiUrl("UserData/Files/" + email + "/" + from + "/" + to));
    let text = await result.text();
    let json = JSON.parse(text);
    let files = [];
    json.forEach((element) => {
      var file = new FileDto(
        element.id,
        new Date(element.lastModifiedDateTime),
        element.name,
        element.path,
        element.userData,
      );
      files.push(file);
    });
    return files;
  }

  async getFileForUser(userEmail, userDataId) {
    let result = await fetch(this.getApiUrl("UserData/File/" + userEmail + "/" + userDataId));
    let text = await result.text();
    let element = JSON.parse(text);
      let file = new FileDto(
        element.id,
        new Date(element.lastModifiedDateTime),
        element.name,
        element.path,
        element.userData,
      );
   return file;
  }

  async getTicketForUser(userEmail, userDataId) {
    let result = await fetch(this.getApiUrl("UserData/Ticket/" + userEmail + "/" + userDataId));
    let text = await result.text();
    let element = JSON.parse(text);
      let ticket = new TicketDto(
        element.id,
        element.title,
        element.project,
        element.description,
        element.type,
        new Date(element.changeDateTime),
        element.userData,
      );
   return ticket;
  }

  async getTicketsForUser(email, from, to) {
    let result = await fetch(this.getApiUrl("UserData/Tickets/" + email + "/" + from + "/" + to));
    let text = await result.text();
    let json = JSON.parse(text);
    let tickets = [];
    json.forEach((element) => {
      var ticket = new TicketDto(
        element.id,
        element.title,
        element.project,
        element.description,
        element.type,
        new Date(element.changeDateTime),
        element.userData,
      );
      tickets.push(ticket);
    });
    return tickets;
  }

  async getTasksForUser(email, from, to) {
    let result = await fetch(this.getApiUrl("UserData/Tasks/" + email + "/" + from + "/" + to));
    let text = await result.text();
    let json = JSON.parse(text);
    let tasks = [];
    json.forEach((element) => {
      var task = new TaskDto(
        element.id,
        new Date(element.completedDateTime),
        element.title,
        element.description,
        element.taskListName,
        element.userData,
      );
      tasks.push(task);
    });
    return tasks;
  }

  async getTaskForUser(userEmail, userDataId) {
    let result = await fetch(this.getApiUrl("UserData/Task/" + userEmail + "/" + userDataId));
    let text = await result.text();
    let element = JSON.parse(text);
    var task = new TaskDto(
      element.id,
      new Date(element.completedDateTime),
      element.title,
      element.description,
      element.taskListName,
      element.userData,
    );
   return task;
  }

  async causeExceptionInApi() {
    let result = await fetch(this.getApiUrl("TimeReportGeneration/ErrorNoTryCatch"));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getUserSettingsForUser(email) {
    let result = await fetch(this.getApiUrl("UserSetting/ForUser/" + email));
    let text = await result.text();
    let element = JSON.parse(text);
    let setting = new UserSettingDto(
        element.id,
        element.user,
        element.created,
        element.defaultProjectManualTimeEntry,
        element.defaultTimeReportView
      );
    return setting;
  }

  async getOrganizationSettingsForOrganization(id) {
    let result = await fetch(this.getApiUrl("OrganizationSetting/ForOrganization/" + id));
    let text = await result.text();
    let element = JSON.parse(text);
    let setting = new OrganizationSettingDto(
        element.id,
        element.organization,
        element.created,
        element.absenceProject,
        element.reportingInterval,
        element.useFlexTime,
        element.flexWithdrawalActivity
      );
    return setting;
    
  }

  async saveUpdatedUserSetting(dto) {
    const response = await fetch(this.getApiUrl("UserSetting/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedOrganizationSetting(dto) {
    const response = await fetch(this.getApiUrl("OrganizationSetting/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getCurrentFlexTime(email, date) {
    let result = await fetch(
      this.getApiUrl("FlexTimeTransaction/CurrentFlexTimeForUser/" + email + "/" + date)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getFlexTimeDifferenceForUser(email, date) {
    let result = await fetch(
      this.getApiUrl("FlexTimeTransaction/FlexTimeDifferenceForUser/" + email + "/" + date)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getFileExportersForOrganization(id) {
    let result = await fetch(this.getApiUrl("FileExporter/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.email,
        element.fileFormat,
        element.trigger,
        element.triggerDayInMonth
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getEmailExportersForOrganization(id) {
    let result = await fetch(this.getApiUrl("EmailExporter/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.email,
        element.format,
        element.trigger,
        element.triggerDayInMonth
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getFileExportersForProject(id) {
    let result = await fetch(this.getApiUrl("FileExporter/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.email,
        element.fileFormat,
        element.trigger,
        element.triggerDayInMonth
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getEmailExportersForProject(id) {
    let result = await fetch(this.getApiUrl("EmailExporter/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterDto(
        element.id,
        element.name,
        element.description,
        element.organization,
        element.created,
        element.email,
        element.format,
        element.trigger,
        element.triggerDayInMonth
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewFileExporter(dto) {
    const response = await fetch(this.getApiUrl("FileExporter/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedFileExporter(dto) {
    const response = await fetch(this.getApiUrl("FileExporter/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getPropertiesForFileExporter(id) {
    let result = await fetch(this.getApiUrl("FileExporterProperty/ForExporter/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterPropertyDto(
        element.id,
        element.name,
        element.fileExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async deleteFileExporterProperty(id) {
    let response = await fetch(this.getApiUrl("FileExporterProperty/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewFileExporterProperty(dto) {
    const response = await fetch(this.getApiUrl("FileExporterProperty/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedFileExporterProperty(dto) {
    const response = await fetch(this.getApiUrl("FileExporterProperty/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewFileExporterProjectSelection(dto) {
    const response = await fetch(this.getApiUrl("FileExporterProjectSelection/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteFileExporterProjectSelection(id) {
    let response = await fetch(this.getApiUrl("FileExporterProjectSelection/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getFileExporterProjectSelectionsForFileExporter(id) {
    let result = await fetch(
      this.getApiUrl("FileExporterProjectSelection/ForFileExporter/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterProjectSelectionDto(
        element.id,
        element.fileExporter,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getFileExporterPropertiesForProject(id) {
    let result = await fetch(this.getApiUrl("FileExporterProperty/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterPropertyDto(
        element.id,
        element.name,
        element.fileExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getFileExporterPropertiesForActivity(id) {
    let result = await fetch(this.getApiUrl("FileExporterProperty/ForActivity/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new FileExporterPropertyDto(
        element.id,
        element.name,
        element.fileExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async deleteFileExporter(id) {
    let response = await fetch(this.getApiUrl("FileExporter/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewActivitySetting(setting) {
    const response = await fetch(this.getApiUrl("ActivitySetting/"), {
      method: "POST",
      body: JSON.stringify(setting),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedActivitySetting(setting) {
    const response = await fetch(
      this.getApiUrl("ActivitySetting/" + setting.id),
      {
        method: "PUT",
        body: JSON.stringify(setting),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteActivitySetting(id) {
    let result = await fetch(this.getApiUrl("ActivitySetting/") + id, {
      method: "DELETE",
    });
    let text = await result.text();
    if (result.status === 200 || result.status === "204") {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(result.status);
      console.log(text);
    }
    return "Error";
  }

  async getActivitySettingsForActivity(id) {
    let result = await fetch(this.getApiUrl("ActivitySetting/ForActivity/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let settings = [];
    json.forEach((element) => {
      let setting = new ActivitySettingDto(
        element.id,
        element.activity,
        element.user,
        element.name,
        element.value,
        element.created
      );
      settings.push(setting);
    });
    return settings;
  }

  async saveNewEmailExporter(dto) {
    const response = await fetch(this.getApiUrl("EmailExporter/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedEmailExporter(dto) {
    const response = await fetch(this.getApiUrl("EmailExporter/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getPropertiesForEmailExporter(id) {
    let result = await fetch(this.getApiUrl("EmailExporterProperty/ForExporter/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterPropertyDto(
        element.id,
        element.name,
        element.emailExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async deleteEmailExporterProperty(id) {
    let response = await fetch(this.getApiUrl("EmailExporterProperty/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewEmailExporterProperty(dto) {
    const response = await fetch(this.getApiUrl("EmailExporterProperty/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveUpdatedEmailExporterProperty(dto) {
    const response = await fetch(this.getApiUrl("EmailExporterProperty/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async saveNewEmailExporterProjectSelection(dto) {
    const response = await fetch(this.getApiUrl("EmailExporterProjectSelection/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteEmailExporterProjectSelection(id) {
    let response = await fetch(this.getApiUrl("EmailExporterProjectSelection/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getEmailExporterProjectSelectionsForEmailExporter(id) {
    let result = await fetch(
      this.getApiUrl("EmailExporterProjectSelection/ForEmailExporter/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterProjectSelectionDto(
        element.id,
        element.emailExporter,
        element.project,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getEmailExporterPropertiesForProject(id) {
    let result = await fetch(this.getApiUrl("EmailExporterProperty/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterPropertyDto(
        element.id,
        element.name,
        element.emailExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getEmailExporterPropertiesForActivity(id) {
    let result = await fetch(this.getApiUrl("EmailExporterProperty/ForActivity/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      var dto = new EmailExporterPropertyDto(
        element.id,
        element.name,
        element.emailExporter,
        element.requiredForActivities,
        element.requiredForProjects,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async deleteEmailExporter(id) {
    let response = await fetch(this.getApiUrl("EmailExporter/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async postDto(controller, dto){
    const response = await fetch(this.getApiUrl(controller + "/"), {
      method: "POST",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async putDto(controller, dto) {
    const response = await fetch(this.getApiUrl(controller + "/" + dto.id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async putDtoWithId(controller, dto, id) {
    const response = await fetch(this.getApiUrl(controller + "/" + id), {
      method: "PUT",
      body: JSON.stringify(dto),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("ErrorOccured");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async deleteDto(controller, id) {
    let response = await fetch(this.getApiUrl(controller + "/") + id, {
      method: "DELETE",
    });
    let text = await response.text();
    if (response.status === 200) {
      return "Ok";
    } else {
      console.log("Error occured for DELETE");
      console.log(response.status);
      console.log(text);
    }
    return "Error";
  }

  async getReduceWorkingTimeKeywordsForUser(email) {
    let result = await fetch(
      this.getApiUrl("ReduceWorkingTimeKeyword/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let keywords = [];
    json.forEach((element) => {
      let keyword = new ReduceWorkingTimeKeywordDto(
        element.id,
        element.keyword,
        element.user,
        element.created
      );
      keywords.push(keyword);
    });
    return keywords;
  }

  async saveNewReduceWorkingTimeKeyword(dto) {
    await this.postDto("ReduceWorkingTimeKeyword",dto);
  }

  async saveUpdatedReduceWorkingTimeKeyword(dto) {
    await this.putDto("ReduceWorkingTimeKeyword", dto);
  }

  async deleteReduceWorkingTimeKeyword(id) {
    return await this.deleteDto("ReduceWorkingTimeKeyword", id);
  }

  async getNormalWorkingTimesForUser(email) {
    let result = await fetch(
      this.getApiUrl("NormalWorkingTime/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      let dto = new NormalWorkingTimeDto(
        element.id,
        element.user,
        element.weekday,
        element.startTime,
        element.endTime,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewNormalWorkingTime(dto) {
    await this.postDto("NormalWorkingTime",dto);
  }

  async saveUpdatedNormalWorkingTime(dto) {
    await this.putDto("NormalWorkingTime", dto);
  }

  async deleteNormalWorkingTime(id) {
    return await this.deleteDto("NormalWorkingTime", id);
  }

  async getMinutesToWorkForTimeReport(id) {
    let result = await fetch(
      this.getApiUrl("TimeReport/GetMinutesToWork/" + id)
    );
    let text = await result.text();
    return text;
  }

  async getUserDataForUser(email, from, to) {
    let result = await fetch(
      this.getApiUrl("UserData/ForUser/" + email + "/" + from + "/" + to)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      let dto = new UserDataDto(
        element.id,
        element.title,
        element.dataObjectId,
        element.dateTime,
        element.created,
        element.userDataServiceScopeSelection,
        element.duration,
        element.type,
        element.eventType,
        element.ignore
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async getExperimentsSelected(type, email) {
    let result = await fetch(
      this.getApiUrl("Experiments/Selected/" + type + "/" + email)
    );
    let text = await result.text();
    return [];
  }

  async getExperimentsRated(type, email, opinion) {
    let result = await fetch(
      this.getApiUrl("Experiments/Rated/" + type + "/" + email + "/" + opinion)
    );
    let text = await result.text();
    return [];
  }

  async saveNewHoliday(dto) {
    await this.postDto("Holiday",dto);
  }

  async saveUpdatedHoliday(dto) {
    await this.putDto("Holiday", dto);
  }

  async deleteHoliday(id) {
    return await this.deleteDto("Holiday", id);
  }

  async getUserTargetWorkingTime(email) {
    let result = await fetch(
      this.getApiUrl("UserTargetWorkingTime/ForUser/" + email)
    );
    let text = await result.text();
    let element = JSON.parse(text);
    let dto = new UserTargetWorkingTimeDto(
      element.id,
      element.user,
      element.mondayMinutesToWork,
      element.tuesdayMinutesToWork,
      element.wednesdayMinutesToWork,
      element.thursdayMinutesToWork,
      element.fridayMinutesToWork,
      element.saturdayMinutesToWork,
      element.sundayMinutesToWork
    );
    return dto;
  }
  async saveUpdatedUserTargetWorkingTime(dto) {
    await this.putDto("UserTargetWorkingTime", dto);
  }

  async getIgnoreKeywordsForUser(email) {
    let result = await fetch(
      this.getApiUrl("IgnoreKeyword/ForUser/" + email)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      let dto = new IgnoreKeywordDto(
        element.id,
        element.keyword,
        element.scope,
        element.user,
        element.created
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewIgnoreKeyword(dto) {
    await this.postDto("IgnoreKeyword",dto);
  }

  async saveUpdatedIgnoreKeyword(dto) {
    await this.putDto("IgnoreKeyword", dto);
  }

  async deleteIgnoreKeyword(id) {
    return await this.deleteDto("IgnoreKeyword", id);
  }

  async getTimeReportFilesForTimeReport(id) {
    let result = await fetch(
      this.getApiUrl("TimeReportFile/ForTimeReport/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    let dtos = [];
    json.forEach((element) => {
      let dto = new TimeReportFileDto(
        element.id,
        element.timeReport,
        element.name,
        element.comment,
        element.type,
        element.created,
        element.amountExcludingVAT,
        element.amountIncludingVAT
      );
      dtos.push(dto);
    });
    return dtos;
  }

  async saveNewTimeReportFile(dto) {
    await this.postDto("TimeReportFile",dto);
  }

  async saveUpdatedTimeReportFile(dto) {
    await this.putDto("TimeReportFile", dto);
  }

  async deleteTimeReportFile(id) {
    return await this.deleteDto("TimeReportFile", id);
  }

  async getLogEvents() {
    let result = await fetch(
      this.getApiUrl("Logs/LogEvents/")
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getLoginLogEvents() {
    let result = await fetch(
      this.getApiUrl("Logs/LoginLogEvents/")
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getTimeReportGenerationLog() {
    let result = await fetch(
      this.getApiUrl("Logs/TimeReportGenerationLog/")
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async reGenerateTimeReport(id) {
    let result = await fetch(
      this.getApiUrl("TimeReportGeneration/ReGenerateTimeReport/" + id)
    );
    let text = await result.text();
  }

  async reGenerateCurrentTimeReportForUser(userId) {
    let result = await fetch(
      this.getApiUrl("TimeReportGeneration/ReGenerateCurrentTimeReportForUser/" + userId)
    );
    let text = await result.text();
  }

  async clearTimeReport(id) {
    let result = await fetch(
      this.getApiUrl("TimeReportGeneration/ClearTimeReport/" + id), {
        method: "DELETE",
      }
    );
    let text = await result.text();
  }

  async getKeywordSuggestions(id) {
    let result = await fetch(
      this.getApiUrl("TimeReportGeneration/SuggestKeywords/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getAssignmentsForUser(id) {
    let result = await fetch(
      this.getApiUrl("Assignment/ForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getAssignmentsForOrganization(id) {
    let result = await fetch(
      this.getApiUrl("Assignment/ForOrganization/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getSkillExperienceOverviewForOrganization(id) {
    let result = await fetch(
      this.getApiUrl("SkillExperienceInAssignment/OverviewForOrganization/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getSkillExperienceOverviewForUser(id) {
    let result = await fetch(
      this.getApiUrl("SkillExperienceInAssignment/OverviewForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewAssignment(dto) {
    await this.postDto("Assignment",dto);
  }

  async saveNewSkillExperienceInAssignment(dto) {
    await this.postDto("SkillExperienceInAssignment",dto);
  }

  async getSkillsForOrganization(id) {
    let result = await fetch(
      this.getApiUrl("Skill/ForOrganization/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getAssignment(id) {
    let result = await fetch(
      this.getApiUrl("Assignment/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveUpdatedAssignment(dto) {
    await this.putDto("Assignment", dto);
  }

  async deleteAssignment(id) {
    return await this.deleteDto("Assignment", id);
  }

  async getSkillExperienceForAssignment(id) {
    let result = await fetch(
      this.getApiUrl("SkillExperienceInAssignment/ForAssignment/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewSkill(dto) {
    await this.postDto("Skill",dto);
  }

  async saveUpdatedSkill(dto) {
    await this.putDto("Skill", dto);
  }

  async deleteSkill(id) {
    return await this.deleteDto("Skill", id);
  }

  async deleteSkillExperienceForAssignment(id) {
    return await this.deleteDto("SkillExperienceInAssignment/ForAssignment", id);
  }

  async deleteSkillExperienceInAssignment(id) {
    return await this.deleteDto("SkillExperienceInAssignment", id);
  }

  async getSkillExperienceForUser(id) {
    let result = await fetch(
      this.getApiUrl("SkillExperienceInAssignment/ForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewEducation(dto) {
    await this.postDto("Education",dto);
  }

  async saveUpdatedEducation(dto) {
    await this.putDto("Education", dto);
  }

  async deleteEducation(id) {
    return await this.deleteDto("Education", id);
  }

  async getEducationsForUser(id) {
    let result = await fetch(
      this.getApiUrl("Education/ForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewCourse(dto) {
    await this.postDto("Course", dto);
  }
  
  async saveUpdatedCourse(dto) {
    await this.putDto("Course", dto);
  }
  
  async deleteCourse(id) {
    return await this.deleteDto("Course", id);
  }
  
  async getCoursesForUser(id) {
    let result = await fetch(
      this.getApiUrl("Course/ForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }
  
  async saveNewCertificate(dto) {
    await this.postDto("Certificate", dto);
  }
  
  async saveUpdatedCertificate(dto) {
    await this.putDto("Certificate", dto);
  }
  
  async deleteCertificate(id) {
    return await this.deleteDto("Certificate", id);
  }
  
  async getCertificatesForUser(id) {
    let result = await fetch(
      this.getApiUrl("Certificate/ForUser/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectById(id) {
    let result = await fetch(
      this.getApiUrl("Project/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectExtensionsForProject(id) {
    let result = await fetch(
      this.getApiUrl("ProjectExtension/ForProject/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectExtensionsForOrganization(id) {
    let result = await fetch(
      this.getApiUrl("ProjectExtension/ForOrganization/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectExtensionById(id) {
    let result = await fetch(
      this.getApiUrl("ProjectExtension/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewProjectExtension(dto) {
    await this.postDto("ProjectExtension", dto);
  }

  async saveUpdatedProjectExtension(dto) {
    await this.putDto("ProjectExtension", dto);
  }

  async deleteProjectExtension(id) {
    return await this.deleteDto("ProjectExtension", id);
  }

  async getUserById(id) {
    let result = await fetch(
      this.getApiUrl("User/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getRevenueForecastForOrganization(id, fromDate, toDate) {
    let result = await fetch(
      this.getApiUrl("Project/GetRevenueForecast?organizationId=" + id + "&fromDate=" + new Date(fromDate).toLocaleDateString("sv-SE") + "&toDate=" + new Date(toDate).toLocaleDateString("sv-SE"))
    );
    let text = await result.text();
    let json = JSON.parse(text);
    console.log(json);
    return json;
  }

  async saveNewProjectToDoTask(dto) {
    await this.postDto("ProjectToDoTask", dto);
  }

  async saveUpdatedProjectToDoTask(dto) {
    await this.putDto("ProjectToDoTask", dto);
  }

  async deleteProjectToDoTask(id) {
    return await this.deleteDto("ProjectToDoTask", id);
  }

  async getProjectToDoTasksForProject(id) {
    let result = await fetch(
      this.getApiUrl("ProjectToDoTask/ForProject/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewApiExporter(dto) {
    await this.postDto("ApiExporter", dto);
  }

  async saveUpdatedApiExporter(dto) {
    await this.putDto("ApiExporter", dto);
  }

  async deleteApiExporter(id) {
    return await this.deleteDto("ApiExporter", id);
  }

  async saveNewApiExporterProjectSelection(dto) {
    await this.postDto("ApiExporterProjectSelection", dto);
  }

  async deleteApiExporterProjectSelection(id) {
    return await this.deleteDto("ApiExporterProjectSelection", id);
  }

  async getApiExporterProjectSelectionsForApiExporter(id) {
    let result = await fetch(
      this.getApiUrl("ApiExporterProjectSelection/ForApiExporter/" + id)
    );
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getProjectsForApiExporter(id) {
    let result = await fetch(this.getApiUrl("Project/ForApiExporter/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getApiExportersForOrganization(id) {
    let result = await fetch(this.getApiUrl("ApiExporter/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json
  }

  async getApiExportersForProject(id) {
    let result = await fetch(this.getApiUrl("ApiExporter/ForProject/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async getDefaultActivitiesForOrganization(id) {
    let result = await fetch(this.getApiUrl("DefaultActivity/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json;
  }

  async saveNewDefaultActivity(dto) {
    await this.postDto("DefaultActivity", dto);
  }

  async saveUpdatedDefaultActivity(dto) {
    await this.putDto("DefaultActivity", dto);
  }

  async deleteDefaultActivity(id) {
    return await this.deleteDto("DefaultActivity", id);
  }

  async getClientsForOrganization(id) {
    let result = await fetch(this.getApiUrl("Client/ForOrganization/" + id));
    let text = await result.text();
    let json = JSON.parse(text);
    return json.sort((a, b) => (a.name > b.name) ? 1 : -1);
  }

  async saveNewClient(dto) {
    await this.postDto("Client", dto);
  }

  async saveUpdatedClient(dto) {
    await this.putDto("Client", dto);
  }

  async deleteClient(id) {
    return await this.deleteDto("Client", id);
  }
  
}
export default class NormalWorkingTimeDto {
  constructor(id, user, weekday, startTime, endTime, created) {
    this.id = id;
    this.user = user;
    this.weekday = weekday;
    this.startTime = startTime;
    this.endTime = endTime;
    this.created = created;
  }
}

export default class ProjectExtensionDto {
  constructor(id, project, startDate, endDate, activated, extensionNumber, created, description) {
    this.id = id;
    this.project = project;
    this.startDate = startDate;
    this.endDate = endDate;
    this.activated = activated;
    this.extensionNumber = extensionNumber;
    this.created = created;
    this.description = description;
  }
}

export default class DefaultActivityDto {
  constructor(id, organizationId, name, description, created, code) {
    this.id = id;
    this.organizationId = organizationId;
    this.name = name;
    this.description = description;
    this.created = created;
    this.code = code;
  }
}

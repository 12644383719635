export default class ProjectSettingDto {
  constructor(id, project, user, name, value, created) {
    this.id = id;
    this.project = project;
    this.user = user;
    this.name = name;
    this.value = value;
    this.created = created;
  }
}

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ApplicationState from "./State/ApplicationState";
import DynamicTimeDataService from "./DataServices/DynamicTimeDataService";
import MainPage from "./Components/MainPage";

ApplicationState.dataService = new DynamicTimeDataService();
if (!ApplicationState.hasState()) {
  ApplicationState.clearState();
}

ReactDOM.render(
  <React.StrictMode>
      <MainPage />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import LabelIcon from "@mui/icons-material/Assignment";
import ActivityKeywordDto from "../Dtos/ActivityKeywordDto";

function ActivityView() {
  let history = useHistory();

  const [keywords, setKeywords] = useState([]);
  const [keywordToAdd, setKeywordToAdd] = useState(undefined);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let keywordsForActivity = await ApplicationState.getUsersForProject(
        ApplicationState.getActivityToView().id
      );
      setKeywords(keywordsForActivity);
    }
    load();
  }, []);

  function configActivity(activityToEdit) {
    history.push({
      pathname: "/activity",
      activityToEdit,
    });
  }

  function addKeyword(activityToEdit) {
    history.push({
      pathname: "/activity",
      activityToEdit,
    });
  }

  async function deleteKeyword(keywordToDelete) {
    var result = await ApplicationState.deleteActivityKeyword(keywordToDelete.id);
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete keyword with id: " +
        keywordToDelete.id
      );
      return;
    }
  }

  async function saveNewKeyword() {
    let dto = new ActivityKeywordDto(
      ApplicationState.generateGuid(),
      keywordToAdd,
      ApplicationState.getActivityToView(),
      undefined, //ApplicationState.getState().user,
      new Date(Date.now())
    );

    await ApplicationState.saveNewActivityKeyword(dto);
  }

  function addKeyword(keyword) {
    let primaryText = keyword.name;
    let secondaryText = keyword.description;

    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        button
        onClick={() => configActivity(keyword)}
      >
        <ListItemAvatar>
          <Avatar>
            <LabelIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
    );
  }

  function addKeywords() {
    return keywords.sort((a, b) => (a.keyword > b.keyword) ? 1 : -1).map((element) => {
      return addKeyword(element);
    });
  }

  function editActivityClick(activityToEdit) {
    ApplicationState.setActivityToEdit(activityToEdit);
    history.push("/activityedit");
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
        <Box style={{margin: "8px"}}>
          <Paper elevation={7} style={{paddingTop: "15px", padding: "5px"}}>
            <Box p={1}>
              <Typography variant="h5">{ApplicationState.getActivityToView().name}</Typography>
              <Typography variant="body1">{ApplicationState.getActivityToView().description}</Typography>
              <Button
                style={{margin: "4px"}}
                variant="contained"
                color="primary"
                onClick={() =>
                  editActivityClick(ApplicationState.getActivityToView())
                }
              >
                Edit activity
              </Button>
            </Box>
          </Paper>
        </Box>

        <Box style={{margin: "8px"}}>
          <Paper elevation={7} style={{paddingTop: "15px", padding: "5px"}}>
            <Box p={1}>
              <Box p={1}>
                <Typography variant="h5">Keywords</Typography>
              </Box>
              <Grid container alignItems="center">
                <TextField
                  id="outlined-basic"
                  label="Keyword"
                  variant="outlined"
                />
                <Button
                  style={{margin: "4px"}}
                  variant="contained"
                  color="primary"
                  onClick={saveNewKeyword}
                >
                  Add keyword
                </Button>
              </Grid>
              <List>{addKeywords()}</List>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ActivityView;

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Dialog, IconButton, Paper, Select } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import ProjectExtensionCrudView from "./ProjectExtensionCrudView";
import { PrimaryButton } from "./PrimaryButton";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { StyledDataGrid } from "./StyledDataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ActivityCrudView from "./ActivityEditView";
import CourseCrudView from "./CourseCrudView";
import CertificateCrudView from "./CertificateCrudView";
import ProjectEditView from "./ProjectEditView";
import { Folder } from "@mui/icons-material";
import GroupProjectSelectionDto from "../Dtos/GroupProjectSelectionDto";
import UserProjectSelectionDto from "../Dtos/UserProjectSelectionDto";
import EmailExporterProjectSelectionDto from "../Dtos/EmailExporterProjectSelectionDto";
import FileExporterProjectSelectionDto from "../Dtos/FileExporterProjectSelectionDto";
import ProjectToDoTaskCrudView from "./ProjectToDoTaskCrudView";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ProjectToDoTaskDto from "../Dtos/ProjectToDoTaskDto";

const CustomGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  margin: "8px",
  marginBottom: "16px",
  marginTop: "16px",
});

function ProjectView() {
  let history = useHistory();
  const [project, setProject] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [activitys, setActivitys] = useState([]);
  const [projectSettings, setProjectSettings] = useState([]);
  const [openExtensionDialog, setOpenExtensionDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [openActivityDialog, setOpenActivityDialog] = useState(false);
  const [openCourseDialog, setOpenCourseDialog] = useState(false);
  const [openCertificateDialog, setOpenCertificateDialog] = useState(false);
  const [openProjectToDoTaskDialog, setOpenProjectToDoTaskDialog] = useState(false);
  const [groupsForProject, setGroupsForProject] = useState([]);
  const [groupsForSelection, setGroupsForSelection] = useState([]);
  const [groupsForOrganization, setGroupsForOrganization] = useState([]);
  const [groupToAdd, setGroupToAdd] = useState(undefined);
  const [usersForProject, setUsersForProject] = useState([]);
  const [usersForSelection, setUsersForSelection] = useState([]);
  const [usersForOrganization, setUsersForOrganization] = useState([]);
  const [userToAdd, setUserToAdd] = useState(undefined);
  const [exportersForProject, setExportersForProject] = useState([]);
  const [exportersForOrganization, setExportersForOrganization] = useState([]);
  const [exportersForSelection, setExportersForSelection] = useState([]);
  const [exporterToAdd, setExporterToAdd] = useState(undefined);
  const [projectToDoTasks, setProjectToDoTasks] = useState([]); 
  const { id } = useParams();

  const activityColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "code",
      headerName: "Code",
      maxWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "exportId",
      headerName: "Export Id",
      maxWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
  ];

  const projectToDoTaskColumns = [
    {
      field: "completed",
      headerName: " ",
      maxWidth: 25,
      editable: false,
      renderCell: (params) => {
        
        if(params.row.completed) {
          return (
            <Grid container justifyContent="flex-start" alignItems="center" direction="row">
          <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            toogleProjectToDoTask(params.row);
            }}><CheckCircleOutlineIcon style={{color: "#d8d8d8"}}  />
          </IconButton>
          </Grid>
          );
        } else {
          return (
            <Grid container justifyContent="center" alignItems="center" direction="row">
          <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            toogleProjectToDoTask(params.row);
            }}><CircleOutlinedIcon/>
          </IconButton>
          </Grid>
          );
        }
      },
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        if(params.row.completed) {
          return (
            <Typography variant="body1" style={{color: "#d8d8d8", textDecoration: "line-through"}}>{params.row.title}</Typography>
          );
        } else {
          return (
            <Typography variant="body1">{params.row.title}</Typography>
          );
        }
      }
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        if(params.row.completed) {
          return (
            <Typography variant="body1" style={{color: "#d8d8d8", textDecoration: "line-through"}}>{params.row.description}</Typography>
          );
        } else {
          return (
            <Typography variant="body1">{params.row.description}</Typography>
          );
        }
      }
    },
  ];

  const extensionColumns = [
    {
      field: "extensionNumber",
      headerName: "No.",
      maxWidth: 50,
      flex: 1,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Start",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) =>{
        return new Date(params.row.startDate).toLocaleDateString("sv-SE");
      }
    },
    {
      field: "endDate",
      headerName: "End",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) =>{
        return new Date(params.row.endDate).toLocaleDateString("sv-SE");
      }
    },
    {
      field: "activated",
      headerName: "Activated",
      minWidth: 150,
      flex: 1,
      editable: false,
      valueGetter: (params) => params.row.activated.toString(),
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
  ];

  const groupColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "foo",
      headerName: "",
      maxWidth: 30,
      editable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row  
            editGroupClick(params);
            }}
        >
          <DeleteForeverIcon />
          </IconButton>
          </Grid>
          );
      }
    },
  ];

  const userColumns = [
    {
      field: "firstName",
      headerName: "First name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "foo",
      headerName: "",
      maxWidth: 30,
      editable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center" alignItems="center" direction="row">
        <IconButton

          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row
            editUserClick(params);
            }}
        >
          <DeleteForeverIcon />
          </IconButton>
          </Grid>
          );
      }
    },
  ];

  const exporterColumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      editable: false,

    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: "foo",
      headerName: "",
      maxWidth: 30,
      editable: false,
      renderCell: (params) => {
        return (
          <Grid container justifyContent="center" alignItems="center" direction="row">
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();  // Prevents click event from reaching the row
            editExporterClick(params);
            }}
        >
          <DeleteForeverIcon />
          </IconButton>
          </Grid>
          );
      }
    },
  ];

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      loadProjectInfo();
      showSkillExperience();
    };
    load1();
  }, []);

  function addExtensionsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Extensions
            </Typography>
            <Box>{addAddExtensionButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={extensionColumns}
          rows={extensions}
          onRowClick={editExtensionClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

async function toogleProjectToDoTask(projectToDoTask) {
  projectToDoTask.completed = !projectToDoTask.completed;
  await ApplicationState.saveUpdatedProjectToDoTask(projectToDoTask);
  loadProjectInfo();
}

  async function loadProjectInfo() {
    let p = await ApplicationState.getProjectById(id);
    setProject(p);

    let ps = await ApplicationState.getProjectSettingsForProject(id);
    setProjectSettings(ps);

    let ext = await ApplicationState.getProjectExtensionsForProject(id);
    ext = ext.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
    setExtensions(ext);

    let toDos = await ApplicationState.getProjectToDoTasksForProject(id);
    toDos = toDos.sort((a, b) => (a.title.localeCompare(b.title)));
    setProjectToDoTasks(toDos);

    let edu = await ApplicationState.getActivitiesForProject(id);
    edu = edu.sort((a, b) => (a.name < b.name ? 1 : -1));
    setActivitys(edu);

    let allGroups = await ApplicationState.getGroupsForOrganization(p.organization.organizationId);
    setGroupsForOrganization(allGroups);

    let groupsForProject = await ApplicationState.getGroupsForProject(id);
    groupsForProject.sort((a, b) => a.name.localeCompare(b.name));
    setGroupsForProject(groupsForProject);

    let groupsNotInProjectSelection = allGroups.filter(
      (orgGroup) => !groupsForProject.some((projectGroup) => projectGroup.id === orgGroup.id)
    );
    groupsNotInProjectSelection.sort((a, b) => a.name.localeCompare(b.name));
    setGroupsForSelection(groupsNotInProjectSelection);

    let allUsers = await ApplicationState.getUsersForOrganization(p.organization.organizationId);
    setUsersForOrganization(allUsers);

    let usersForProject = await ApplicationState.getUsersForProject(id);
    usersForProject.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setUsersForProject(usersForProject);

    let usersNotInProjectSelection = allUsers.filter(
      (orgUser) => !usersForProject.some((projectUser) => projectUser.userId === orgUser.userId)
    );
    usersNotInProjectSelection.sort((a, b) => a.firstName.localeCompare(b.firstName));
    setUsersForSelection(usersNotInProjectSelection);

    let allExporters = await ApplicationState.getExportersForOrganization(p.organization.organizationId);
    setExportersForOrganization(allExporters);

    let exportersForProject = await ApplicationState.getExportersForProject(id);
    exportersForProject.sort((a, b) => a.name.localeCompare(b.name));
    setExportersForProject(exportersForProject);

    let exportersNotInProjectSelection = allExporters.filter(
      (orgExporter) => !exportersForProject.some((projectExporter) => projectExporter.id === orgExporter.id)
    );
    exportersNotInProjectSelection.sort((a, b) => a.name.localeCompare(b.name));
    setExportersForSelection(exportersNotInProjectSelection);

  }

  async function showSkillExperience() {
    // TODO: FIx for user
  }

  const addExporters = () => {
    if (!exportersForProject) {
      return;
    }
    return addExportersBox();
  };

  function addExportersBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Exporters
            </Typography>
            <Box>
            <Grid container justifyContent="center" alignItems="center">
              <Select
              native
              value={exporterToAdd}
              onChange={(event) => {
                setExporterToAdd(exportersForOrganization.filter((exporter) => exporter.id === event.target.value)[0].id);
              }}
              inputProps={{
                name: "exporter",
                id: "exporter-native-simple",
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <option aria-label="None" value="" label="--Select exporter to add--" />
              {exportersForSelection.map((exporter) => (
                <option value={exporter.id}>{exporter.name}</option>
              ))}
            </Select>
              {addAddExporterButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={exporterColumns}
          rows={exportersForProject}
          onRowClick={exporterRowClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  async function exporterRowClick(params) {
    //ToDo - Fix
    //history.push("/exporter2/" + params.row.id);
    //window.scrollTo(0, 0);
  }

  async function editExporterClick(params) {
    if(params.row.type == "EmailExporter"){
      let selections = await ApplicationState.getEmailExporterProjectSelectionsForEmailExporter(params.row.exporter.id);
      let selection = selections.find((selection) => selection.project.id === id);
      await ApplicationState.deleteEmailExporterProjectSelection(selection.id);
      loadProjectInfo();
    }
    if(params.row.type == "FileExporter"){
      let selections = await ApplicationState.getFileExporterProjectSelectionsForFileExporter(params.row.exporter.id);
      let selection = selections.find((selection) => selection.project.id === id);
      await ApplicationState.deleteFileExporterProjectSelection(selection.id);
      loadProjectInfo();
    }
  }

  function addAddExporterButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add exporter"
          buttonClick={() => addExporterClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  async function addExporterClick() {
    let exporter2 = exportersForSelection.find(
      (exporter) => exporter.id === exporterToAdd
    );

    if(exporter2.type === "EmailExporter"){
      let selection = new EmailExporterProjectSelectionDto(
        ApplicationState.generateGuid(),
        exporter2.exporter,
        project,
        new Date()
      );
      await ApplicationState.saveNewEmailExporterProjectSelection(selection);
    }
    if(exporter2.type === "FileExporter"){
      let selection = new FileExporterProjectSelectionDto(
        ApplicationState.generateGuid(),
        exporter2.exporter,
        project,
        new Date()
      );
      await ApplicationState.saveNewFileExporterProjectSelection(selection);
    }
    loadProjectInfo();
  }     

  const addUsers = () => {
    if (!usersForProject) {
      return;
    }
    return addUsersBox();
  };

  function addUsersBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Users
            </Typography>
            <Box>
            <Grid container justifyContent="center" alignItems="center">
              <Select
              native
              value={userToAdd}
              onChange={(event) => {
                setUserToAdd(usersForOrganization.filter((user) => user.userId === event.target.value)[0].userId);
              }}
              inputProps={{
                name: "user",
                id: "user-native-simple",
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <option aria-label="None" value="" label="--Select user to add--" />
              {usersForSelection.map((user) => (
                <option value={user.userId}>{user.firstName + " " + user.lastName}</option>
              ))}
            </Select>
                
              {addAddUserButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={userColumns}
          rows={usersForProject}
          onRowClick={userRowClick}
          useUserIdAsRowId={true}>
        </StyledDataGrid>
      </CustomGrid>
    );
  }

  async function userRowClick(params) {
    //ToDo - Fix
    history.push("/user/" + params.row.userId);
    window.scrollTo(0, 0);
  }

  async function editUserClick(params) {
    let selections = await ApplicationState.getUserProjectSelectionsForProject(id);
    let selection = selections.find((selection) => selection.user.userId === params.row.userId);
    await ApplicationState.deleteUserProjectSelection(selection.id);
    loadProjectInfo();
  }

  function addAddUserButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"  
          text="Add user"
          buttonClick={() => addUserClick()}
        ></PrimaryButton>
      </Box>
    );
  }
  
  async function addUserClick() {
    let user2 = usersForSelection.find(
      (user) => user.userId === userToAdd
    );
    let selection = new UserProjectSelectionDto(
      ApplicationState.generateGuid(),
      user2,
      project,
      new Date()
    );
    await ApplicationState.saveNewUserProjectSelection(selection);
    loadProjectInfo();
  }

	const addGroups = () => {
    if (!groupsForProject) {
      return;
    }
    return addGroupsBox();
  };

  function addGroupsBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Groups
            </Typography>
            <Box>
            <Grid container justifyContent="center" alignItems="center">
              <Select
              native
              value={groupToAdd}
              onChange={(event) => {
                setGroupToAdd(groupsForOrganization.filter((group) => group.id === event.target.value)[0].id);
              }}
              inputProps={{
                name: "group",
                id: "group-native-simple",
              }}
              style={{ backgroundColor: "#fff" }}
            >
              <option aria-label="None" value="" label="--Select group to add--" />
              {groupsForSelection.map((group) => (
                <option value={group.id}>{group.name}</option>
              ))}
            </Select>
              {addAddGroupButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid

          columns={groupColumns}
          rows={groupsForProject}
          onRowClick={groupRowClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  async function groupRowClick(params) {
    //ToDo - Fix
    //history.push("/group2/" + params.row.id);
    //window.scrollTo(0, 0);
  }

  async function editGroupClick(params) {
    let selections = await ApplicationState.getGroupProjectSelectionsForProject(id);
    let selection = selections.find((selection) => selection.group.id === params.row.id);
    await ApplicationState.deleteGroupProjectSelection(selection.id);
    loadProjectInfo();
  }

  function addAddGroupButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add group"
          buttonClick={() => addGroupClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  async function addGroupClick() {
    let group2 = groupsForSelection.find(
      (group) => group.id === groupToAdd
    );
    let selection = new GroupProjectSelectionDto(
      ApplicationState.generateGuid(),
      group2,
      project,
      new Date()
    );
    await ApplicationState.saveNewGroupProjectSelection(selection);
    loadProjectInfo();
  }
  
  const addProjectToDoTasks = () => {
    if (!projectToDoTasks) {
      return;
    }
    return addProjectToDoTasksBox();
  };

  function addProjectToDoTasksBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              ToDo-list
            </Typography>
            <Box>
            <Grid container justifyContent="center" alignItems="center">
              {addAddProjectToDoTaskButton()}
            </Grid>
            </Box>
          </Grid>
        </Box>
        <StyledDataGrid

          columns={projectToDoTaskColumns}
          rows={projectToDoTasks}
          onRowClick={editProjectToDoTaskClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editProjectToDoTaskClick(params) {
    ApplicationState.setProjectToDoTaskToEdit(params.row);
    ApplicationState.setProjectToAddProjectToDoTaskFor(undefined);
    setOpenProjectToDoTaskDialog(true);
  }

  const addActivitys = () => {
    if (!activitys) {
      return;
    }
    return addActivitysBox();
  };

  function addActivitysBox() {
    return (
      <CustomGrid item component={Card} xs>
        <Box style={{ backgroundColor: "#93264a", padding: "16px" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ color: "#fff", margin: "8px" }}>
              Activities
            </Typography>
            <Box>{addAddActivityButton()}</Box>
          </Grid>
        </Box>
        <StyledDataGrid
          columns={activityColumns}
          rows={activitys}
          onRowClick={editActivityClick}
        ></StyledDataGrid>
      </CustomGrid>
    );
  }

  function editActivityClick(params) {
    ApplicationState.setActivityToEdit(params.row);
    ApplicationState.setProjectForActivityToAdd(undefined);
    setOpenActivityDialog(true);
  }

  const addExtensions = () => {
    if (!extensions) {
      return;
    }
    return addExtensionsBox();
  };

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>{addEditProjectButton(project)}</Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <React.Fragment>
        <Typography variant="h4">
          <Box paddingRight={2}>
            <Typography variant="h4">Project</Typography>
          </Box>
        </Typography>
      </React.Fragment>
    );
  }

  function addAddProjectToDoTaskButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add task"
          buttonClick={() => addProjectToDoTaskClick()}
        ></PrimaryButton>
        <PrimaryButton
          icon="add"
          text="Add Template Tasks"
          buttonClick={() => addProjectToDoTaskTemplateTasksClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  async function addProjectToDoTaskTemplateTasksClick() {
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Avtal på Drive"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Visma"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Billecta"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Gruppen DynamicFriends"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Gruppen - LunchEd"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Intro - Dynamic Time"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Dynamic Time - Användare"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Dynamic Time - Projekt"));
    await ApplicationState.saveNewProjectToDoTask(getProjectDto("Dynamic Time - Vismainställningar"));
    loadProjectInfo();
  }

  function getProjectDto(title) {
    let dto = new ProjectToDoTaskDto(
      ApplicationState.generateGuid(),
      project,
      title,
      "",
      false,
      new Date()
    );
    return dto;
  }

  function addProjectToDoTaskClick() {
    ApplicationState.setProjectToDoTaskToEdit(undefined);
    ApplicationState.setProjectToAddProjectToDoTaskFor(project);
    setOpenProjectToDoTaskDialog(true);
  }

  function addAddActivityButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add activity"
          buttonClick={() => addActivityClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addActivityClick() {
    ApplicationState.setActivityToEdit(undefined);
    ApplicationState.setProjectForActivityToAdd(project);
    setOpenActivityDialog(true);
  }

  function addAddExtensionButton() {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="add"
          text="Add extension"
          buttonClick={() => addExtensionClick()}
        ></PrimaryButton>
      </Box>
    );
  }

  function addExtensionClick() {
    ApplicationState.setProjectExtensionToEdit(undefined);
    ApplicationState.setProjectToAddProjectExtensionFor(project);
    setOpenExtensionDialog(true);
  }

  function addEditProjectButton(project) {
    return (
      <Box p={1}>
        <PrimaryButton
          icon="edit"
          text="Edit"
          buttonClick={() => editProjectClick(project)}
        ></PrimaryButton>
      
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{
            maxHeight: "58px",
            minHeight: "58px",
            maxWidth: "58px",
            minWidth: "58px",
            marginRight: "8px",
          }}
          onClick={(event) => {
            window.location.href = project.linkToFolder;
            }}
        >
          <Folder />
          </Button>
      
      </Box>
    );
  }

  function editProjectClick(projectToEdit) {
    ApplicationState.setProjectToEdit(projectToEdit);
    setOpenProjectDialog(true);
  }

  function editExtensionClick(params) {
    ApplicationState.setProjectExtensionToEdit(params.row);
    ApplicationState.setProjectToAddProjectExtensionFor(undefined);
    setOpenExtensionDialog(true);
  }

  async function editedExtension() {
    loadProjectInfo();
    setOpenExtensionDialog(false);
  }

  async function addedExtension() {
    loadProjectInfo();
    setOpenExtensionDialog(false);
  }

  async function editedProject() {
    loadProjectInfo();
    setOpenProjectDialog(false);
  }

  async function deletedExtension() {
    loadProjectInfo();
    setOpenExtensionDialog(false);
  }

  async function addedActivity() {
    loadProjectInfo();
    setOpenActivityDialog(false);
  }

  async function editedActivity() {
    loadProjectInfo();
    setOpenActivityDialog(false);
  }

  async function deletedActivity() {
    loadProjectInfo();
    setOpenActivityDialog(false);
  }

  async function addedCourse() {
    loadProjectInfo();
    setOpenCourseDialog(false);
  }

  async function editedCourse() {
    loadProjectInfo();
    setOpenCourseDialog(false);
  }

  async function deletedCourse() {
    loadProjectInfo();
    setOpenCourseDialog(false);
  }

  async function addedCertificate() {
    loadProjectInfo();
    setOpenCertificateDialog(false);
  }

  async function editedCertificate() {
    loadProjectInfo();
    setOpenCertificateDialog(false);
  }

  async function deletedCertificate() {
    loadProjectInfo();
    setOpenCertificateDialog(false);
  }

  async function addedProjectToDoTask() {
    loadProjectInfo();
    setOpenProjectToDoTaskDialog(false);
  }

  async function editedProjectToDoTask() {
    loadProjectInfo();
    setOpenProjectToDoTaskDialog(false);
  }

  async function deletedProjectToDoTask() {
    loadProjectInfo();
    setOpenProjectToDoTaskDialog(false);
  }

  function addProjectInfo() {
    return (
      <Box p={3}>
              <Typography variant="h4">{project.name}</Typography>
      <Typography variant="body1">{project.description}</Typography>

      <List>
        <ListItem>
          <ListItemText primary="Status" secondary={project.status} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Role" secondary={project.role} />
        </ListItem>
      </List>

      <Typography variant="h6">Detailed Information</Typography>
      <List>
        <ListItem>
          <ListItemText primary="External Id" secondary={project.externalId} />
        </ListItem>
        <ListItem>
        {(project.isTimeReportingAllowedForAll &&
      (
        <ListItemText primary="Time reporting permission1" secondary="All"></ListItemText>
      )
      )}
      {(!project.isTimeReportingAllowedForAll &&
      (
        <ListItemText primary="Time reporting permission2" secondary="Only project members"></ListItemText>
      )
      )}
        </ListItem>
        <ListItem>
        {project.activeFrom &&
                project.activeTo && (
                  <ListItemText primary="Active" secondary={
                    new Date(project.activeFrom).toLocaleDateString("sv-SE") +
                    " to " +
                    new Date(project.activeTo).toLocaleDateString("sv-SE")
                  } />
                )}
              {project.activeFrom &&
                !project.activeTo && (
                  <ListItemText primary="Active from" secondary={
                    new Date(project.activeFrom).toLocaleDateString("sv-SE")
                  } />
                )}
              {!project.activeFrom &&
                project.activeTo && (
                  <ListItemText primary="Active to" secondary={
                    new Date(project.activeTo).toLocaleDateString("sv-SE")
                  } />
                )}
                </ListItem>
      </List>

      <Typography variant="h6">Client Information</Typography>
      <List>
      {project.clientId && (
        <ListItem>
          <ListItemText primary="Client" secondary={project.clientId} />
        </ListItem>
        )}
        <ListItem>
          <ListItemText primary="Contact person" secondary={project.clientContactPerson} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Export Id" secondary={project.exportId} />
        </ListItem>
      </List>

      <Typography variant="h6">Economy</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Framework Agreement" secondary={project.frameworkAgreement} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Extent" secondary={project.extentMinimumPerMonth + "% - " + project.extentMaximumPerMonth + "%"} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Hourly Rate To Invoice" secondary={project.hourlyRateToInvoice + " SEK"} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Hourly Rate To Sub Contractor" secondary={project.hourlyRateToSubcontractor + " SEK"} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Margin" secondary={((project.hourlyRateToInvoice - project.hourlyRateToSubcontractor) / project.hourlyRateToInvoice * 100).toFixed(1) + "%"} />
        </ListItem>
        </List>

        <Typography variant="h6">Properties</Typography>
        <List>
          {projectSettings.map((projectSetting) => (
            <ListItem key={projectSetting.id}>
              <ListItemText primary={projectSetting.name} secondary={projectSetting.value} />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "0px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
            <Box style={{backgroundColor: "#fff"}}>{addProjectInfo()}</Box>
          </Paper>
        </Box>

        <Box>{addProjectToDoTasks()}</Box>
        <Box>{addExtensions()}</Box>
        <Box>{addActivitys()}</Box>
        <Box>{addGroups()}</Box>
        <Box>{addUsers()}</Box>
        <Box>{addExporters()}</Box>

        <Dialog
          open={openExtensionDialog}
          onClose={() => setOpenExtensionDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ProjectExtensionCrudView
            deletedProjectExtension={deletedExtension}
            addedProjectExtension={addedExtension}
            editedProjectExtension={editedExtension}
            cancelClick={() => setOpenExtensionDialog(false)}
          ></ProjectExtensionCrudView>
        </Dialog>
        <Dialog
          open={openProjectDialog}
          onClose={() => setOpenProjectDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ProjectEditView
            cancelClick={() => setOpenProjectDialog(false)}
            editedProject={editedProject}
          ></ProjectEditView>
        </Dialog>
        <Dialog
          open={openActivityDialog}
          onClose={() => setOpenActivityDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ActivityCrudView
            deletedActivity={deletedActivity}
            addedActivity={addedActivity}
            editedActivity={editedActivity}
            cancelClick={() => setOpenActivityDialog(false)}
          ></ActivityCrudView>
        </Dialog>

        <Dialog
          open={openCourseDialog}
          onClose={() => setOpenCourseDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <CourseCrudView
            deletedCourse={deletedCourse}
            addedCourse={addedCourse}
            editedCourse={editedCourse}
            cancelClick={() => setOpenCourseDialog(false)}
          ></CourseCrudView>
        </Dialog>

        <Dialog
          open={openCertificateDialog}
          onClose={() => setOpenCertificateDialog(false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <CertificateCrudView
            deletedCertificate={deletedCertificate}
            addedCertificate={addedCertificate}
            editedCertificate={editedCertificate}
            cancelClick={() => setOpenCertificateDialog(false)}
          ></CertificateCrudView>
        </Dialog>

        <Dialog
          open={openProjectToDoTaskDialog}
          onClose={() =>  (false)}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ProjectToDoTaskCrudView
            deletedProjectToDoTask={deletedProjectToDoTask}
            addedProjectToDoTask={addedProjectToDoTask}
            editedProjectToDoTask={editedProjectToDoTask}
            cancelClick={() => setOpenProjectToDoTaskDialog(false)}
          ></ProjectToDoTaskCrudView>
        </Dialog>

      </Grid>
    </Grid>
  );
}

export default ProjectView;

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import GroupProjectSelectionDto from "../Dtos/GroupProjectSelectionDto";
import UserInGroupDto from "../Dtos/UserInGroupDto";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import GroupEditView from "./GroupEditView";
import Divider from "@mui/material/Divider";
import { PrimaryButton } from '../Components/PrimaryButton';

function GroupView(props) {
  
  const [projectsAdded, setProjectsAdded] = useState([]);
  const [projectToAdd, setProjectToAdd] = useState("");
  const [projectsForOrganization, setProjectsForOrganization] = useState([]);
  const [projectsNotAdded, setProjectsNotAdded] = useState([]);
  const [usersAdded, setUsersAdded] = useState([]);
  const [userToAdd, setUserToAdd] = useState("");
  const [usersInOrganization, setUsersInOrganization] = useState([]);
  const [usersNotAdded, setUsersNotAdded] = useState([]);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  
    useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      await loadProjects();
      await loadUsersForGroup();
    }
    load();
  }, [props.selectedGroup]);

  const loadUser = async () => {
    await loadProjects();
    await loadUsersForGroup();
  };

  async function loadProjects() {
    let projectsForOrganization = await ApplicationState.getProjectsForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setProjectsForOrganization(projectsForOrganization);

    let projectsForGroup = await ApplicationState.getProjectsForGroup(
      props.selectedGroup.id
    );
    setProjectsAdded(projectsForGroup);

    let projectsNotAddedForUser = [];
    projectsForOrganization.map((element) => {
      if (!projectsForGroup.find((x) => x.id === element.id)) {
        projectsNotAddedForUser.push(element);
      }
    });
    setProjectsNotAdded(projectsNotAddedForUser);
    if (projectsNotAddedForUser && projectsNotAddedForUser.length > 0) {
      setUserToAdd(projectsNotAddedForUser[0]);
    }
  }

  async function loadUsersForGroup() {
    let usersForOrganization = await ApplicationState.getUsersForOrganization(
      ApplicationState.getState().user.organization.organizationId
    );
    setUsersInOrganization(usersForOrganization);

    let usersToAddInGroup = await ApplicationState.getUsersForGroup(
      props.selectedGroup.id
    );
    setUsersAdded(usersToAddInGroup);

    let usersNotAddedToAdd = [];
    usersForOrganization.map((element) => {
      if (!usersToAddInGroup.find((x) => x.userId === element.userId)) {
        usersNotAddedToAdd.push(element);
      }
    });
    setUsersNotAdded(usersNotAddedToAdd);
    if (usersNotAddedToAdd && usersNotAddedToAdd.length > 0) {
      setUserToAdd(usersNotAddedToAdd[0]);
    }
  }

  function editGroupClick() {
    ApplicationState.setGroupToEdit(props.selectedGroup);
    setOpenGroupDialog(true);
  }

  function addUser(user) {
    let primaryText = user.firstName + " " + user.lastName;
    let secondaryText = user.email;

    return (
      <Box>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
        >
          <ListItemText primary={primaryText} secondary={secondaryText} />
          <Button onClick={() => deleteUserFromGroup(user)}>
            <DeleteForeverIcon
              style={{color: "#93264A"}}
            ></DeleteForeverIcon>
          </Button>
        </ListItem>
        <Divider></Divider>
      </Box>
    );
  }

  function addProjectInSelect(project) {
    return (
      <option
        key={project.id}
        label={project.name}
        value={project.id}
      ></option>
    );
  }

  function addUserInSelect(user) {
    return <option key={user.userId} label={user.firstName + " " + user.lastName} value={user.userId}></option>;
  }

  function addProject(project) {
    let primaryText = project.name;

    return (
      <Box>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
        >
          <ListItemText primary={primaryText} />
          <Button onClick={() => deleteProjectFromGroup(project)}>
            <DeleteForeverIcon
              style={{color: "#93264A"}}
            ></DeleteForeverIcon>
          </Button>
        </ListItem>
        <Divider></Divider>
      </Box>
    );
  }

  function addUsers() {
    if (usersAdded.length < 1) {
      return (
        <Box p={2}>
          <i>No users have been added</i>
        </Box>
      );
    }
    return usersAdded
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .map((element) => {
        return addUser(element);
      });
  }

  function addProjects() {
    if (projectsAdded.length < 1) {
      return (
        <Box p={2}>
          <i>No projects have been added</i>
        </Box>
      );
    }
    return projectsAdded
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((element) => {
        return addProject(element);
      });
  }

  async function addUserToGroup() {
    let user = usersInOrganization.find((x) => x.userId === userToAdd);
    if (!user) {
      user = usersNotAdded[0];
    }

    let dto = new UserInGroupDto(
      ApplicationState.generateGuid(),
      props.selectedGroup,
      user,
      new Date()
    );
    await ApplicationState.saveNewUserInGroup(dto);
    await loadUsersForGroup();
  }

  async function addProjectToGroup() {
    let project = projectsForOrganization.find((x) => x.id === projectToAdd);
    if (!project) {
      project = projectsNotAdded[0];
    }

    let dto = new GroupProjectSelectionDto(
      ApplicationState.generateGuid(),
      props.selectedGroup,
      project,
      new Date()
    );
    await ApplicationState.saveNewGroupProjectSelection(dto);
    await loadProjects();
  }

  async function deleteProjectFromGroup(project) {
    let selections = await ApplicationState.getGroupProjectSelectionsForGroup(
      props.selectedGroup.id
    );
    let idToDelete = selections.find(
      (x) => x.project.id === project.id
    ).id;

    await ApplicationState.deleteGroupProjectSelection(idToDelete);
    await loadProjects();
  }

  async function deleteUserFromGroup(user) {
    let selections = await ApplicationState.getUserInGroupForGroup(
      props.selectedGroup.id
    );
    let idToDelete = selections.find(
      (x) => x.user.userId === user.userId
    ).id;

    await ApplicationState.deleteUserInGroup(idToDelete);
    await loadUsersForGroup();
  }

  function addHeader(headerText, buttonText) {
    return (
      <Box style={{padding: "16px", backgroundColor: "#d8d8d8", color: "#000"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText(headerText)}
              </Grid>
            </Box>
            {buttonText && buttonText.length > 0 && (
              <Box>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {addAddButton(buttonText)}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText(headerText) {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">{headerText}</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddButton(buttonText) {
    let func = editGroupClick;
    let edit = false;
    if (buttonText === "Edit") {
      func = editGroupClick;
      edit = true;
    }

    return (
      <Box p={0}>
        {edit && (
          <PrimaryButton
          icon="edit"
          text={buttonText}
          buttonClick={() => func()}
        >
        </PrimaryButton>
        )}
        {!edit && (
          <PrimaryButton
            icon="add"
            text={buttonText}
            buttonClick={() => func()}
          >
          </PrimaryButton>
        )}
      </Box>
    );
  }

  const editedGroup = async () => {
    await loadUser();
    setOpenGroupDialog(false);
    props.editedGroup();
  };

  const deletedGroup = async () => {
    setOpenGroupDialog(false);
    props.deletedGroup();
  };

  return (
    <Box>
      <Box p={0}>
        {addHeader("Group information", "Edit")}
        <Box p={2}>
          <Typography paragraph>{props.selectedGroup.name}</Typography>
          <Typography paragraph>{props.selectedGroup.description}</Typography>
        </Box>
      </Box>

      <Box p={0}>
        {addHeader("Users", "")}
        <Grid container alignItems="center" style={{ padding: "16px" }}>
          <Select
            native
            onChange={(event) => {
              setUserToAdd(event.target.value);
            }}
            id="usersSelect"
            variant="outlined"
            style={{margin: "8px"}}
            value={userToAdd}
          >
            {usersNotAdded
              .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
              .map((user) => {
                return addUserInSelect(user);
              })}
          </Select>
          <Button
            style={{margin: "4px"}}
            variant="text"
            color="primary"
            onClick={addUserToGroup}
          >
            Add user
          </Button>
        </Grid>
        <List style={{padding: "0px"}}>{addUsers()}</List>
      </Box>

      <Box p={0}>
        {addHeader("Projects", "")}
        <Grid container alignItems="center" style={{ padding: "16px" }}>
          <Select
            native
            onChange={(event) => {
              setProjectToAdd(event.target.value);
            }}
            id="projectSelect"
            variant="outlined"
            style={{margin: "8px"}}
            value={projectToAdd}
          >
            {projectsNotAdded
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((project) => {
                return addProjectInSelect(project);
              })}
          </Select>
          <Button
            style={{margin: "4px"}}
            variant="text"
            color="primary"
            onClick={addProjectToGroup}
          >
            Add project
          </Button>
        </Grid>
        <List style={{padding: "0px"}}>{addProjects()}</List>
      </Box>

      <Dialog
        open={openGroupDialog}
        onClose={() => setOpenGroupDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <GroupEditView
          deletedGroup={deletedGroup}
          editedGroup={editedGroup}
          cancelClick={() => setOpenGroupDialog(false)}
        ></GroupEditView>
      </Dialog>    
      </Box>

  );
}

export default GroupView;

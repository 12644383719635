import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from "./PrimaryButton";
import { DeleteButton } from "./DeleteButton";
import CourseDto from "../Dtos/CourseDto";
import {
  Autocomplete,
  Chip,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

function CourseCrudView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [schoolField, setSchoolField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [years, setYears] = useState(getYears());
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [ongoing, setOngoing] = useState(false);
  useEffect(() => {
    const load1 = async () => {
    };
    load1();

    if (ApplicationState.getCourseToEdit()) {
      setEditMode(true);
      setNameField(ApplicationState.getCourseToEdit().name);
      setSchoolField(ApplicationState.getCourseToEdit().school);
      setDescriptionField(ApplicationState.getCourseToEdit().description);
      setStartYear(ApplicationState.getCourseToEdit().startYear);
      
      if (ApplicationState.getCourseToEdit().endYear) {
        setEndYear(ApplicationState.getCourseToEdit().endYear);
      } else {
        setOngoing(true);
      }
    }
  }, []);

  function getYears() {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let year = 1970; year <= currentYear + 10; year++) {
      years.push(year);
    }
    return years;
  }

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new CourseDto(
      ApplicationState.generateGuid(),
      ApplicationState.getUserToAddCourseFor(),
      nameField,
      schoolField,
      descriptionField,
      startYear,
      new Date(Date.now())
    );
    if (ApplicationState.getCourseToEdit()) {
      dto.id = ApplicationState.getCourseToEdit().id;
      dto.user = ApplicationState.getCourseToEdit().user;
      dto.created = ApplicationState.getCourseToEdit().created;
      await ApplicationState.saveUpdatedCourse(dto);
      props.editedCourse();
    } else {
      await ApplicationState.saveNewCourse(dto);
      props.addedCourse();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteCourse(
      ApplicationState.getCourseToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete course with id: " +
          ApplicationState.getCourseToEdit().id
      );
      return;
    }
    props.deletedCourse();
  }

  const getHeaderText = () => {
    let headerText = "Add course";
    if (editMode) {
      headerText = "Edit course";
    }
    return headerText;
  };

  const renderYearOptions = (year) => {
    return <option key={year} value={year} label={year}></option>;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="titleTextField"
          label="Title"
          type="text"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="schoolTextField"
          label="School"
          type="text"
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setSchoolField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={schoolField}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          multiline
          rows={3}
          style={{ margin: "8px", width: "400px" }}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box>
            <Select
              native
              id="startYearSelect"
              variant="outlined"
              value={startYear}
              onChange={(event) => setStartYear(event.target.value)}
              style={{ margin: "8px" }}
            >
              {years.map((year) => {
                return renderYearOptions(year);
              })}
            </Select>
          </Box>
        </Grid>
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              ></PrimaryButton>
              {editMode && <DeleteButton onClick={deleteClick}></DeleteButton>}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default CourseCrudView;

import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { PrimaryButton } from '../Components/PrimaryButton';

const faqs = [
  {
    category: "General",
    questions: [
      { 
        q: "What is your service?", 
        a: "Our service provides an exceptional experience tailored to your needs." 
      },
      { 
        q: "How to sign up?", 
        a: "You can sign up by visiting our website and filling out the registration form." 
      },
      { 
        q: "Where do you operate?", 
        a: "We operate in various countries around the globe, providing worldwide service." 
      },
    ],
  },
  {
    category: "Pricing",
    questions: [
      { 
        q: "What is the cost?", 
        a: "The cost depends on the specific services you select. We offer a range of packages." 
      },
      { 
        q: "Are there any discounts?", 
        a: (
          <>
            Yes, we offer discounts for early sign-ups. The discount rates are as follows:
            <ul>
              <li>10% off for 6 months subscription</li>
              <li>15% off for 12 months subscription</li>
              <li>20% off for 24 months subscription</li>
            </ul>
          </>
        )
      },
      { 
        q: "What payment methods are accepted?", 
        a: "We accept all major credit cards, PayPal, and bank transfers." 
      },
    ],
  },
  // Add more categories and questions here
];

export default function FaqView() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFaqs = faqs.map(category => ({
    ...category,
    questions: category.questions.filter(q =>
      typeof q.q === 'string' && q.q.toLowerCase().includes(searchTerm.toLowerCase()) ||
      typeof q.a === 'string' && q.a.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (q.a.props && JSON.stringify(q.a.props.children).toLowerCase().includes(searchTerm.toLowerCase()))
    ),
  })).filter(category => category.questions.length > 0);

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addContactSupportButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
        {/* </Grid> */}
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Help and FAQ</Typography>
        </Box>
      </Typography>
    );
  }
  function addContactSupportButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="send"
                text="Contact support"
                buttonClick={() => contactSupportClick()}
              >
              </PrimaryButton>
      </Box>
    );
  }

  function contactSupportClick() {
    let supportEmail = "support@dynamictime.se";
    const mailtoLink = `mailto:${supportEmail}`;
    window.location.href = mailtoLink;
  }

  function addFaqInfo() {
    return(
        <Box p={2}>
      <TextField
        fullWidth
        label="Search FAQs"
        variant="outlined"
        onChange={e => setSearchTerm(e.target.value)}
        style={{ backgroundColor: "#fff"}}
        sx={{ mb: 2 }}
      />
      {filteredFaqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{faq.category}</Typography>
          </AccordionSummary>
          {faq.questions.map((q, i) => (
            <AccordionDetails key={i}>
              <Typography component="div">
                <strong>{q.q}</strong><br />
                {typeof q.a === 'string' ? q.a : q.a}
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Box>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      {/* //   <Paper elevation={8}> */}
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Box sx={{ margin: { xs: "0px", md: "8px" } }}>
          <Paper
            elevation={7}
            style={{
              paddingTop: "15px",
              padding: "0px",
              backgroundColor: "#93264A",
            }}
          >
            <Box>{addHeader()}</Box>
            <Box style={{backgroundColor: "#eee"}}>{addFaqInfo()}</Box>
          </Paper>
        </Box>
    </Grid>
    </Grid>
  );
}

export default class UserDto {
  constructor(userId, firstName, lastName, email, description, organization, isAdmin, employmentNumber, minutesToWorkPerDay, isTimeReportApprover, timeReportApprover, secondaryEmail, firstWorkingDay, onboardingCompleted, city, phoneNumber, isSubContractor, subContractorCompanyName, subContractorManagerName, subContractorManagerEmail, subContractorManagerPhoneNumber, subContractorInvoiceEmail, subContractorGiroNumber, useFlexTime, subContractorIsSolo, isActive) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.description = description;
    this.organization = organization;
    this.isAdmin = isAdmin;
    this.employmentNumber = employmentNumber;
    this.minutesToWorkPerDay = minutesToWorkPerDay;
    this.isTimeReportApprover = isTimeReportApprover;
    this.timeReportApprover = timeReportApprover;
    this.secondaryEmail = secondaryEmail;
    this.firstWorkingDay = firstWorkingDay;
    this.onboardingCompleted = onboardingCompleted;
    this.city = city;
    this.phoneNumber = phoneNumber;
    this.isSubContractor = isSubContractor;
    this.subContractorCompanyName = subContractorCompanyName;
    this.subContractorManagerName = subContractorManagerName;
    this.subContractorManagerEmail = subContractorManagerEmail;
    this.subContractorManagerPhoneNumber = subContractorManagerPhoneNumber;
    this.subContractorInvoiceEmail = subContractorInvoiceEmail;
    this.subContractorGiroNumber = subContractorGiroNumber;
    this.useFlexTime = useFlexTime;
    this.subContractorIsSolo = subContractorIsSolo;
    this.isActive = isActive;
  }
}

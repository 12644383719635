import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";
import DefaultActivityDto from "../Dtos/DefaultActivityDto";
import ApplicationState from "../State/ApplicationState";


function DefaultActivityCrudView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [codeField, setCodeField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const load2 = async () => {
      if (ApplicationState.getDefaultActivityToEdit()) {
        setEditMode(true);
        setNameField(ApplicationState.getDefaultActivityToEdit().name);
        setCodeField(ApplicationState.getDefaultActivityToEdit().code);
        setDescriptionField(ApplicationState.getDefaultActivityToEdit().description);
      }
    };
    load2();
  }, []);

  const cancelClick = () => {
    props.cancelClick();
  };

  const saveClick = async () => {
    let dto = new DefaultActivityDto(
      ApplicationState.generateGuid(),
      ApplicationState.getUser().organization.organizationId,
      nameField,
      descriptionField,
      new Date(),
      codeField
    );
    if (ApplicationState.getDefaultActivityToEdit()) {
      dto.id = ApplicationState.getDefaultActivityToEdit().id;
      await ApplicationState.saveUpdatedDefaultActivity(dto);
      ApplicationState.setDefaultActivityToEdit(undefined);
      props.editedActivity();
    } else {
      await ApplicationState.saveNewDefaultActivity(dto);
      ApplicationState.setDefaultActivityToEdit(undefined);
      props.addedActivity();
    }
  };

  const deleteClick = async () => {
    await ApplicationState.deleteDefaultActivity(ApplicationState.getDefaultActivityToEdit().id);
    props.deletedActivity();
  };

  const getHeaderText = () => {
    return editMode ? "Edit Default Activity" : "Add Default Activity";
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <IconButton variant="contained" color="primary" onClick={cancelClick}>
            <CloseIcon fontSize="medium" style={{ color: "#93264A" }} />
          </IconButton>
        </Grid>
      </Box>
      <Box style={{ backgroundColor: "#f6f6f6", paddingBottom: "12px" }}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Typography variant="h5" style={{ paddingLeft: "24px" }}>
            <b>{getHeaderText()}</b>
          </Typography>
        </Grid>
      </Box>
      <Box p={2}>
        <TextField
          label="Name"
          value={nameField}
          onChange={(e) => setNameField(e.target.value)}
          variant="outlined"
          style={{ margin: "8px" }}
        />
        <TextField
          label="Code"
          value={codeField}
          onChange={(e) => setCodeField(e.target.value)}
          variant="outlined"
          style={{ margin: "8px" }}
        />
        <TextField
          label="Description"
          value={descriptionField}
          onChange={(e) => setDescriptionField(e.target.value)}
          variant="outlined"
          style={{ margin: "8px" }}
        />
      </Box>
      <Box style={{ backgroundColor: "#93264A", paddingBottom: "12px", paddingTop: "12px" }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box p={1}>
            <PrimaryButton
              icon="save"
              text="Save"
              buttonClick={saveClick}
            />
            {editMode && (
              <DeleteButton onClick={deleteClick} />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default DefaultActivityCrudView;

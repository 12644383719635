export default class ClientDto {
    constructor(id, name, organizationId, exportId, clientInvoiceReference, internalInvoiceReference, created) {
      this.id = id;
      this.name = name;
      this.organizationId = organizationId;
      this.exportId = exportId;
      this.clientInvoiceReference = clientInvoiceReference;
      this.internalInvoiceReference = internalInvoiceReference;
      this.created = created;
    }
  }
export default class ActivityDto {
  constructor(id, project, name, description, created, code, exportId) {
    this.id = id;
    this.project = project;
    this.name = name;
    this.description = description;
    this.created = created;
    this.code = code;
    this.exportId = exportId;
  }
}

import Week from "../Dtos/Week";

class DateTimeHelper {
  static getLastDayInMonth(year, month) {
    let date = new Date(year, month + 1, 0);
    return date;
  }

  static getHourMinuteStringFromHours(seconds) {
    let minutes = parseInt(seconds) / 60;
    let m = parseInt(minutes) % 60;
    let h = parseInt(minutes - m) / 60;

    m = Math.abs(m);
    h = Math.abs(h);
    
    let isNegative = false;
    if(seconds < 0){
      isNegative = true;
    }

    if (h === 0 && m === 0) {
      return "0h";
    }
    if (h < 1 && m > 0) {
      if(isNegative){
        m = m - m*2;
      }
      return m + "m";
    }
    if (h > 0 && m > 0) {
      if(isNegative){
        m = m - m*2;
        h = h - h*2;
      }
      return h + "h " + (m + "m").replace("-", "");
    }
    if (h > 0 && m < 1) {
      if(isNegative){
        h = h - h*2;
      }
      return h + "h";
    }

    if (h < 1 && m > 0) {
      return m + "m";
    }
    if (h > 0 && m > 0) {
      return h + "h " + m + "m";
    }
    if (h > 0 && m < 1) {
      return h + "h";
    }
    
  }

  static getDayNameFromWeekday(weekdayNumber) {
    switch (weekdayNumber) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      default:
        throw new Error(`Invalid dayNumber ${weekdayNumber}`);
    }
  }

  static getDayNameFromWeekdayShort(weekdayNumber) {
    switch (weekdayNumber) {
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      case 0:
        return "Sun";
      default:
        throw new Error(`Invalid dayNumber ${weekdayNumber}`);
    }
  }

  static getMonthName(monthNr) {
    switch (monthNr) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        throw new Error(`Invalid month number ${monthNr}`);
    }
  }

  static getMonthNameShort(monthNr) {
    switch (monthNr) {
      case 0:
        return "Jan.";
      case 1:
        return "Feb.";
      case 2:
        return "Mar.";
      case 3:
        return "Apr.";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "Aug.";
      case 8:
        return "Sept.";
      case 9:
        return "Oct.";
      case 10:
        return "Nov.";
      case 11:
        return "Dec.";
      default:
        throw new Error(`Invalid month number ${monthNr}`);
    }
  }

  static getWeekName(week) {
    if(new Date(week.startDate).toLocaleDateString("sv-SE") === new Date(week.endDate).toLocaleDateString("sv-SE")){
      return (
        "Week " +
        week.number +
        ": " +
        week.startDate.getDate() +
        " " +
        this.getMonthNameShort(week.startDate.getMonth())
      );
    }
    return (
      "Week " +
      week.number +
      ": " +
      week.startDate.getDate() +
      " " +
      this.getMonthNameShort(week.startDate.getMonth()) +
      " - " +
      week.endDate.getDate() +
      " " +
      this.getMonthNameShort(week.endDate.getMonth())
    );
  }

  static getWeekNameOnly(week) {
    return (
      "Week " +
      week.number
    );
  }

  static getWeekDateRangeOnly(week) {
    return (
      week.startDate.getDate() +
      " " +
      this.getMonthName(week.startDate.getMonth()) +
      " - " +
      week.endDate.getDate() +
      " " +
      this.getMonthName(week.endDate.getMonth())
    );
  }

  static getDateRangeText(from, to) {
    return (
      from.getDate() +
      " " +
      this.getMonthName(from.getMonth()) +
      " - " +
      to.getDate() +
      " " +
      this.getMonthName(to.getMonth())
    );
  }

  static printAllDatesAndWeeks(year) {
    let startDate = new Date(year, 0, 1);
    let endDate = new Date(year, 11, 31);
    var arr = [];
 
    let daysToSubtract = 0;
    switch(startDate.getDay()){
      case 0: 
        daysToSubtract = 6;
        break;
      case 1: 
        daysToSubtract = 0;
        break;
      case 2: 
        daysToSubtract = 1;
        break;
      case 3: 
        daysToSubtract = 2;
        break;
      case 4: 
        daysToSubtract = 3;
        break;
      case 5: 
        daysToSubtract = 4;
        break;
      case 6: 
        daysToSubtract = 5;
        break;
      default:
        break;
    }
    startDate.setDate(startDate.getDate() - daysToSubtract);

    let daysToAdd = 0;
    switch(endDate.getDay()){
      case 0: 
        daysToAdd = 0;
        break;
      case 1: 
        daysToAdd = 6;
        break;
      case 2: 
        daysToAdd = 5;
        break;
      case 3: 
        daysToAdd = 4;
        break;
      case 4: 
        daysToAdd = 3;
        break;
      case 5: 
        daysToAdd = 2;
        break;
      case 6: 
        daysToAdd = 1;
        break;
      default:
        break;
    }
    endDate.setDate(endDate.getDate() + daysToAdd);
    let dt = startDate;
    let currentWeek = this.getWeekNumber(dt);
    let currentStartDate = new Date(dt);
    let currentEndDate = new Date(dt);
    var newWeek;

    while (dt <= endDate) {
      let weekForDate = this.getWeekNumber(dt);
      if (weekForDate !== currentWeek) {
          newWeek = new Week(
          currentWeek,
          new Date(currentStartDate),
          new Date(currentEndDate - 1)
        );
        arr.push(newWeek);
        currentStartDate = new Date(dt);
        currentWeek = weekForDate;
      }
      dt.setDate(dt.getDate() + 1);
      currentEndDate = new Date(dt);
    }
      newWeek = new Week(
      currentWeek,
      new Date(currentStartDate),
      new Date(currentEndDate - 1)
    );
    arr.push(newWeek);

    return arr;
  }

  static getWeekNumber(dt) {
    //ISO8601_week_no
    var tdt = new Date(dt.valueOf());
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
  }

  static getWeeksForMonth(year, month) {
    let startDate = new Date(year, month, 1);
    let endDate = this.getLastDayInMonth(year, month);
    var arr = [];
    let dt = startDate;
    var newWeek;
    let currentWeek = this.getWeekNumber(dt);
    let currentStartDate = new Date(dt);
    let currentEndDate = new Date(dt);
    while (dt <= endDate) {
      let weekForDate = this.getWeekNumber(dt);
      if (weekForDate !== currentWeek) {
          newWeek = new Week(
          currentWeek,
          new Date(currentStartDate),
          new Date(currentEndDate - 1)
        );
        arr.push(newWeek);
        currentStartDate = new Date(dt);
        currentWeek = weekForDate;
      }
      dt.setDate(dt.getDate() + 1);
      currentEndDate = new Date(dt);
    }
    newWeek = new Week(
      currentWeek,
      new Date(currentStartDate),
      new Date(currentEndDate - 1)
    );
    arr.push(newWeek);

    return arr;
  }

  static getWeeksForPeriod(startDate, endDate) {
    var arr = [];
    let dt = new Date(startDate);
    var newWeek;
    let currentWeek = this.getWeekNumber(dt);
    let currentStartDate = new Date(dt);
    let currentEndDate = new Date(dt);
    while (dt <= endDate) {
      let weekForDate = this.getWeekNumber(dt);
      if (weekForDate !== currentWeek) {
          newWeek = new Week(
          currentWeek,
          new Date(currentStartDate),
          new Date(currentEndDate - 1)
        );
        arr.push(newWeek);
        currentStartDate = new Date(dt);
        currentWeek = weekForDate;
      }
      dt.setDate(dt.getDate() + 1);
      currentEndDate = new Date(dt);
    }
      newWeek = new Week(
      currentWeek,
      new Date(currentStartDate),
      new Date(currentEndDate - 1)
    );
    arr.push(newWeek);

    return arr;
  }

  static getTimeStringFromDate(date) {
    date = new Date(date).toString();
    let time = new Date(date).getTime();
    let m = new Date(time).getMinutes();
    let h = new Date(time).getHours(); 
    if(h < 10){
      h = "0" + h;
    }
    if(m < 10){
      m = "0" + m;
    }
    return h + ":" + m;
  }

  static getDurationBetweenDates(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    var milliseconds = endDate.getTime() - startDate.getTime();
    var minutes = (milliseconds / 1000) / 60;
    return minutes;
  }

  static getDatesInMonth(year, month) {
    let endDay = this.getLastDayInMonth(year, month).getDate();
    let currentDay = 1;
    let dates = [];
    while(currentDay <= endDay) {
      dates.push(new Date(year, month, currentDay));
      currentDay++;
    }
    return dates;
  }

  static getMinutesFromTimeInputString(input) {
    let colonArray = input.split(":");
    if(input.includes("-")){
      let res = colonArray[0].replace("-","") * 60 + parseInt(colonArray[1].replace("-",""));
      return parseInt("-" + res);
    } else{
      return parseInt(colonArray[0]) * 60 + parseInt(colonArray[1]);
    }
  }

  static convertMinutesToTimeInputString (inputMinutes) {
    let hours = parseInt(inputMinutes / 60);
    let minutes = inputMinutes % 60;
    if(minutes === 0){
      minutes = "00";
    }
    if(minutes > 0 && minutes < 10){
      minutes = "0" + minutes;
    }
    minutes = minutes + "";
    if(parseInt(minutes) < 0 && hours >= 0) {
      return "-" + hours + ":" + minutes.replace("-","");  
    }
    return hours + ":" + minutes.replace("-","");
  }

  static convertTimeInputStringToMinutes (input) {
    let hours = 0;
    let minutes = 0;
    let digitsOnlyRegEx = /^\d+$/;
    if(digitsOnlyRegEx.test(input.replace("-", "")))
    {
        hours = input;
        return hours * 60;
    }

    if(input.includes(":")) {
      let colonArray = input.split(":");
      if(colonArray.length !== 2){
        return "ERROR";
      }
      let beforeColon = colonArray[0];
      if(!digitsOnlyRegEx.test(beforeColon.replace("-",""))){
        return "ERROR";
      }
      let afterColon = colonArray[1];
      if(!digitsOnlyRegEx.test(afterColon)){
        return "ERROR";
      }
      hours = beforeColon.replace("-","");
      minutes = afterColon.replace("-","");
      let res = (parseInt(hours) * 60) + parseInt(minutes);
      if(input.includes("-")) {
        res = parseInt("-" + res);
      }
      return res;
    }

    if(input.includes(",") || input.includes(".")) {
      input = input.replace(",",".");
      let dotArray = input.split(".");
      if(dotArray.length !== 2){
        return "ERROR";
      }
      let beforeDot = dotArray[0];
      if(!digitsOnlyRegEx.test(beforeDot.replace("-", ""))){
        return "ERROR";
      }
      let afterDot = dotArray[1];
      if(!digitsOnlyRegEx.test(afterDot)){
        return "ERROR";
      }
      hours = beforeDot.replace("-","");
      minutes = afterDot.replace("-","");
      let decimalMinutes = parseFloat("0." + minutes);
      let res = parseInt(hours) * 60 + Math.floor(60 * decimalMinutes);
      if(input.includes("-")){
        res = parseInt("-" + res);
      }
      return res;
    }
    return "ERROR";
  }
}
export default DateTimeHelper;

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import CelebrationIcon from '@mui/icons-material/Celebration';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';

const PButton = styled(Button)({
  margin: "8px",
  backgroundColor: "#fff",
  color: "#111",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "#fff",
  },
});

const IButton = styled(IconButton)({
  margin: "0px",
  backgroundColor: "#fff",
  color: "#111",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "#fff",
  },
});

export const PrimaryButton = (props) => {
  if(props.icon.toLowerCase() === "save"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<SaveIcon style={{color: "#93264A"}}></SaveIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "12px" }}
                  ></Divider>
  </PButton>;
  }
  if(props.icon.toLowerCase() === "edit"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<EditIcon style={{color: "#93264A"}}></EditIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "12px" }}
                  ></Divider>
  </PButton>;
  }
  if(props.icon.toLowerCase() === "reject"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<CancelOutlinedIcon style={{color: "#93264A"}}></CancelOutlinedIcon>}
    onClick={props.buttonClick}
  >
    {props.text}
  </PButton>;
  }
  if(props.icon.toLowerCase() === "unlock"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<LockOpenIcon style={{color: "#93264A"}}></LockOpenIcon>}
    onClick={props.buttonClick}
  >
    {props.text}
  </PButton>;
  }
  if(props.icon.toLowerCase() === "approve"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<CheckCircleOutlineIcon style={{color: "#93264A"}}></CheckCircleOutlineIcon>}
    onClick={props.buttonClick}
  >
    {props.text}
  </PButton>;
  }
  if(props.icon.toLowerCase() === "download"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<CloudDownloadIcon style={{color: "#93264A"}}></CloudDownloadIcon>}
    onClick={props.buttonClick}
  >
    {props.text}
  </PButton>;
  }
  if(props.icon.toLowerCase() === "upload"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    component="span"
    type="submit"
    endIcon={<CloudUploadIcon style={{color: "#93264A"}}></CloudUploadIcon>}
    onClick={props.buttonClick}
  >
    {props.text}
  </PButton>;
  }
  if(props.icon.toLowerCase() === "send"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    style={{fontSize: "1.4em"}}
    endIcon={<SendIcon style={{color: "#93264A"}}></SendIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "previous"){
    return <IButton
    variant="contained"
    color="primary"
    size="large"
    onClick={props.buttonClick}
  >
    <ArrowBackIcon style={{color: "#93264A"}}></ArrowBackIcon>
  </IButton>;
  }
  if(props.icon.toLowerCase() === "next"){
    return <IButton
    variant="contained"
    color="primary"
    size="large"
    onClick={props.buttonClick}
  >
    <ArrowForwardIcon style={{color: "#93264A"}}></ArrowForwardIcon>
  </IButton>;
  }
  if(props.icon.toLowerCase() === "previouswhitearrow"){
    return <IButton
    variant="contained"
    color="primary"
    size="large"
    style={{backgroundColor: "#93264A"}}
    onClick={props.buttonClick}
  >
    <ArrowBackIcon style={{color: "#fff"}}></ArrowBackIcon>
  </IButton>;
  }
  if(props.icon.toLowerCase() === "nextwhitearrow"){
    return <IButton
    variant="contained"
    color="primary"
    size="large"
    style={{backgroundColor: "#93264A"}}
    onClick={props.buttonClick}
  >
    <ArrowForwardIcon style={{color: "#fff"}}></ArrowForwardIcon>
  </IButton>;
  }
  if(props.icon.toLowerCase() === "fun"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    style={{fontSize: "1.4em"}}
    endIcon={<CelebrationIcon style={{color: "#93264A"}}></CelebrationIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "like"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    style={{fontSize: "1.4em"}}
    endIcon={<ThumbUpOutlinedIcon style={{color: "#93264A"}}></ThumbUpOutlinedIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "dislike"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    style={{fontSize: "1.4em"}}
    endIcon={<ThumbDownOutlinedIcon style={{color: "#93264A"}}></ThumbDownOutlinedIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "exit"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    style={{fontSize: "1.4em"}}
    endIcon={<LogoutIcon style={{color: "#93264A"}}></LogoutIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "cv"){
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<WorkHistoryOutlinedIcon style={{color: "#93264A"}}></WorkHistoryOutlinedIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "8px" }}
                  ></Divider>
    </PButton>;
  }
  if(props.icon.toLowerCase() === "cancel"){
    return <Button
    variant="outlined"
    color="secondary"
    size="large"
    onClick={props.buttonClick}
    style={{padding: "16px"}}
  >
    {props.text}
    </Button>;
  }
  else {
    return <PButton
    variant="contained"
    color="primary"
    size="large"
    endIcon={<AddBoxOutlinedIcon style={{color: "#93264A"}}></AddBoxOutlinedIcon>}
    onClick={props.buttonClick}
  >
    {props.text}<Divider
                    orientation="vertical"
                    style={{ height: "42px", paddingLeft: "12px" }}
                  ></Divider>
  </PButton>;
  }
};
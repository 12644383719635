export default class TimeReportWeek {
  constructor(number, duration, startDate) {
    this.number = number;
    this.duration = duration;
    this.startDate = startDate;
    let date = new Date(startDate);
    date.setDate(date.getDate() + 6);
    this.endDate = date;
  }
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Dialog from "@mui/material/Dialog";
import ExporterView from "./ExporterView";
import FileExporterEditView from "./FileExporterEditView";
import EmailExporterEditView from "./EmailExporterEditView";
import { PrimaryButton } from '../Components/PrimaryButton';
import ApiExporterCrudView from "./ApiExporterCrudView";

function ExportersView() {
  const [exporters, setExporters] = useState([]);
  const [exporterToView, setExporterToView] = useState(undefined);
  const [openFileExporterDialog, setOpenFileExporterDialog] = useState(false);
  const [openEmailExporterDialog, setOpenEmailExporterDialog] = useState(false);
  const [openApiExporterDialog, setOpenApiExporterDialog] = useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let orgId = ApplicationState.getState().user.organization.organizationId;
      let exporters = await ApplicationState.getExportersForOrganization(orgId);
      setExporters(exporters);
      setExporterToView(exporters[0]);
    }
    load();
  }, []);

  async function editedExporter() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let exporters = await ApplicationState.getExportersForOrganization(orgId);
    setExporters(exporters);
    setExporterToView(exporters.find((x) => x.id === exporterToView.id));
    setOpenFileExporterDialog(false);
    setOpenEmailExporterDialog(false);
  }

  async function addedFileExporter() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let exporters = await ApplicationState.getExportersForOrganization(orgId);
    setExporters(exporters);
    setExporterToView(
      exporters.find((x) => x.id === ApplicationState.getFileExporterToView().id)
    );
    setOpenFileExporterDialog(false);
  }

  async function addedEmailExporter() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let exporters = await ApplicationState.getExportersForOrganization(orgId);
    setExporters(exporters);
    setExporterToView(
      exporters.find((x) => x.id === ApplicationState.getEmailExporterToView().id)
    );
    setOpenEmailExporterDialog(false);
  }

  async function addedApiExporter() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let exporters = await ApplicationState.getExportersForOrganization(orgId);
    setExporters(exporters);
    setExporterToView(
      exporters.find((x) => x.id === ApplicationState.getApiExporterToView().id)
    );
    setOpenApiExporterDialog(false);
  }

  async function deletedExporter() {
    let orgId = ApplicationState.getState().user.organization.organizationId;
    let exporters = await ApplicationState.getExportersForOrganization(orgId);
    setExporters(exporters);
    setExporterToView(exporters[0]);
    setOpenFileExporterDialog(false);
    setOpenEmailExporterDialog(false);
  }

  function viewExporter(exporterToView2) {
    setExporterToView(exporterToView2);
  }

  function newFileExporterClick() {
    ApplicationState.setFileExporterToEdit(undefined);
    setOpenFileExporterDialog(true);
  }

  function newEmailExporterClick() {
    ApplicationState.setEmailExporterToEdit(undefined);
    setOpenEmailExporterDialog(true);
  }

  function newApiExporterClick() {
    ApplicationState.setApiExporterToEdit(undefined);
    setOpenApiExporterDialog(true);
  }

  function addExporter(exporter) {
    let primaryText = exporter.name;

    return (
      <Box>
        {exporterToView && exporterToView.id === exporter.id && (
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            onClick={() => viewExporter(exporter)}
            style={{
              backgroundColor: "#781938",
              color: "white",
              padding: "16px",
            }}
          >
            <ListItemAvatar></ListItemAvatar>
            <ListItemText primary={primaryText} />
          </ListItem>
        )}
        {!exporterToView ||
          (exporterToView.id != exporter.id && (
            <ListItem
              key={ApplicationState.generateGuid()}
              button
              onClick={() => viewExporter(exporter)}
            >
              <ListItemAvatar></ListItemAvatar>
              <ListItemText primary={primaryText} />
            </ListItem>
          ))}
      </Box>
    );
  }

  function addExporters() {
    if(!exporters || exporters.length < 1){
      return <Box p={2}>No exporters have been added</Box>
    }
    
    return exporters
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((element) => {
        return addExporter(element);
      });
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Exporters</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add file exporter"
                buttonClick={() => newFileExporterClick()}
              >
        </PrimaryButton>
        <PrimaryButton
          icon="add"
          text="Add email exporter"
          buttonClick={() => newEmailExporterClick()}
        >
        </PrimaryButton>
        <PrimaryButton
          icon="add"
          text="Add api exporter"
          buttonClick={() => newApiExporterClick()}
        >
        </PrimaryButton>
      </Box>
    );
  }

  return (
    <Box p={0}>
      <Box>{addHeader()}</Box>
      <Grid container justifyContent="space-between">
        <Grid item xs={4} style={{ backgroundColor: "#f6f6f6" }}>
          <List style={{padding: "0px"}}>{addExporters()}</List>
        </Grid>
        <Grid item xs={8}>
          {exporterToView && (
            <ExporterView
              selectedExporter={exporterToView}
              editedExporter={editedExporter}
              deletedExporter={deletedExporter}
            ></ExporterView>
          )}
          {!exporterToView && <Box style={{padding: "16px"}}>No exporter is selected</Box>}
          <Dialog
            open={openFileExporterDialog}
            onClose={() => setOpenFileExporterDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <FileExporterEditView
              deletedExporter={deletedExporter}
              addedExporter={addedFileExporter}
              editedExporter={editedExporter}
              cancelClick={() => setOpenFileExporterDialog(false)}
            ></FileExporterEditView>
          </Dialog>
          <Dialog
            open={openEmailExporterDialog}
            onClose={() => setOpenEmailExporterDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <EmailExporterEditView
              deletedExporter={deletedExporter}
              addedExporter={addedEmailExporter}
              editedExporter={editedExporter}
              cancelClick={() => setOpenEmailExporterDialog(false)}
            ></EmailExporterEditView>
          </Dialog>
          <Dialog
            open={openApiExporterDialog}
            onClose={() => setOpenApiExporterDialog(false)}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
          >
            <ApiExporterCrudView
              deletedExporter={deletedExporter}
              addedExporter={addedApiExporter}
              editedExporter={editedExporter}
              cancelClick={() => setOpenApiExporterDialog(false)}
            ></ApiExporterCrudView>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ExportersView;

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Menu, MenuList } from "@mui/material";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import DateTimeHelper from "../Utils/DateTimeHelper";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TimeReportDTO from "../Dtos/TimeReportDto";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Divider from "@mui/material/Divider";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EventIcon from "@mui/icons-material/Event";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CardActionArea from "@mui/material/CardActionArea";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import TimeEntryView from "./TimeEntryView";
import TimeEntryKeywordView from "./TimeEntryKeywordView";
import queryString from "query-string";
import MyDayView from "./MyDayView";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { PrimaryButton } from "../Components/PrimaryButton";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import UserDataItemDto from "../Dtos/UserDataItemDto";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Badge from "@mui/material/Badge";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TimeEntryDto from "../Dtos/TimeEntryDto";
import TimeReportFileEditView from "./TimeReportFileEditView";
import Week from "../Dtos/Week";
import CommitOutlinedIcon from '@mui/icons-material/CommitOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AssistantIcon from '@mui/icons-material/Assistant';

const TableHeaderBox = styled(Box)({
  backgroundColor: "#eee",
  margin: "8px",
  paddingLeft: "16px",
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  borderRadius: "4px",
  lineHeight: "1.4",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

const CustomTab = styled(Tab)({
  textTransform: "capitalize",
  color: "#ddd",
});

const CustomTabPanel = styled(TabPanel)({
  padding: "0px",
});

const finalTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
        },
        arrow: {
          fontSize: 20,
          color: "#4A4A4A",
          "&::before": {
            backgroundColor: "white",
            border: "1px solid #d8d8d8",
            boxShadow: 3,
          },
        },
        tooltipPlacementRight: {
          marginLeft: "-40px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#93264A",
    },
    secondary: {
      main: "#fff",
    },
    info: {
      main: "#d6d6d6",
    },
  },
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
    color: black;
    background-color: white;
    font-size: 1.5em;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    margin-right: -40px;
    font-weight: 500;
`);

function TimeReportView(props) {
  const [timeEntries, setTimeEntries] = useState(undefined);
  const [reportSent, setReportSent] = useState(false);
  const [reportSentMessage, setReportSentMessage] = useState(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const [weekNrSelected, setWeekNrSelected] = useState(ApplicationState.getTimeReportFilter().weekNr);
  const [weeksForYear, setWeeksForYear] = useState(ApplicationState.getTimeReportFilter().weeksForYear);
  const [timeReportSelected, setTimeReportSelected] = useState(ApplicationState.getTimeReportFilter().timeReportId);
  const [timerReportSent, setTimeReportSent] = useState(false);
  const [timerReportHaveFile, setTimeReportHaveFile] = useState(false);
  const [timerReportFiles, setTimeReportFiles] = useState([]);
  const [timerReportForSelectedMonth, setTimerReportForSelectedMonth] =
    useState(undefined);
  const [sendingReport, setSendingReport] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openTimeEntryDialog, setOpenTimeEntryDialog] = React.useState(false);
  const [
    openTimeEntryKeywordSuggestionDialog,
    setOpenTimeEntryKeywordSuggestionDialog,
  ] = React.useState(false);
  const [openSendReportWarningDialog, setOpenSendReportWarningDialog] =
    React.useState(false);
  const [openTimeReportFileDialog, setOpenTimeReportFileDialog] =
    React.useState(false);
  const [listMode, setListMode] = React.useState(
    ApplicationState.getDefaultTimeReportViewMode()
  );
  const [dayForDayMode, setDayForDayMode] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1
    ).toLocaleDateString("sv-SE")
  );
  const [flexTime, setFlexTime] = useState("0h");
  const [flexTimeDifference, setFlexTimeDifference] = useState("0h");
  const [flexTimeMaxDate, setFlexTimeMaxDate] = useState(
    new Date().toLocaleDateString("sv-SE")
  );
  const [userDataItems, setUserDataItems] = useState([]);
  const [userDataItemsAll, setUserDataItemsAll] = useState([]);
  const [loadingUserDataItems, setloadingUserDataItems] = useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [warningSendReportText, setWarningSendReportText] = React.useState("");
  const [selectedWeekNrTab, setSelectedWeekNrTab] = React.useState(0);
  const [showEvents, setShowEvents] = React.useState(false);
  const [commentField, setCommentField] = useState(undefined);
  const [expandedActivities, setExpandedActivities] = React.useState([]);
  const [tabWeekday, setTabWeekday] = React.useState("1");
  const [zoomLevel, setZoomLevel] = React.useState(
    ApplicationState.getDefaultTimeReportViewMode()
  );
  const [selectedTab, setSelectedTab] = React.useState("timeentries");
  const [loadingTimereport, setLoadingTimereport] = React.useState(true);
  const openListMenu = Boolean(anchorEl);
  const [loadText, setLoadText] = React.useState("Loading...");
  const [onboardingMode, setOnboardingMode] = React.useState(false);
  const history = useHistory();

  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      //Filtering is done when loading data for the user to avoid uneccessary re-renders
      if(props.isOnboarding) {
        setOnboardingMode(true);
      }
      setLoadingTimereport(true);
      await ApplicationState.loadDataForUser();
      await ApplicationState.loadTimeReportsForUser();
    };
    load1();
  }, []);

  useEffect(() => {
    const load2 = async () => {
      setLoadingTimereport(true);
      await loadTimeEntriesAndUserDataItems();
      setLoadingTimereport(false);
    };
    load2();
  }, [weekNrSelected, dayForDayMode, listMode, timeReportSelected]);

  useEffect(() => {
    const load2 = async () => {
      await showFlexTime();
    };
    load2();
  }, [timeEntries]);

  // This abomination is a hack that gets the auth code that is
  // passed from microsoft login as a query parameter after a successful login.
  // It then extracts the selected scopes from "state" parameter and calls the DT-api
  // to set them up. It then redirects the user to the services page.
  useEffect(() => {
    async function load() {
      let params = queryString.parse(window.location.search);
      if (!params.state || !params.code) {
        return;
      }
      let selectedScopes = params.state.split(" ");
      if (selectedScopes.length === 0) {
        throw new Error("No scopes selected");
      }
      let scopesAll = await ApplicationState.getAllUserDataServiceScopes();
      if (!scopesAll || scopesAll.length === 0) {
        throw new Error("No scopes retreived from API");
      }
      let service = ApplicationState.getUserDataServiceToAdd();
      if (!service) {
        service =
          ApplicationState.getUserDataServiceSelectionToEdit().userDataService;
      }
      let scopeIds = [];
      selectedScopes.forEach((element) => {
        let scope = scopesAll.find((x) => x.scope.toLowerCase() === element);
        if (scope) {
          scopeIds.push(scope.id);
        }
      });
      scopeIds = ApplicationState.getUserDataServiceScopesToAdd();
      await ApplicationState.connectToUserDataService(
        service,
        scopeIds,
        params.code,
        ApplicationState.getState().user.email
      );
      //Ugly fix for problem where the redirect URL from Microsoft is not
      //removed when navigating. Can't be set to # or empty or space.
      window.history.replaceState(null, null, "pm");
      history.push("/settings", selectedScopes);
    }
    let params = queryString.parse(window.location.search);
    if (params && params.state && params.code) {
      load();
    }
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function addWeek(week) {
    let weekSelected = weeksForYear.find((x) => x.number === week.number && new Date(x.startDate).toLocaleDateString("sv-SE") === new Date(week.startDate).toLocaleDateString("sv-SE"));
    return <Box>{addDaysForWeek(weekSelected)}</Box>;
  }

  function addWeekForDesktopMode_Week(week, sortedWeeks) {

    let weeksInSortedWeeks = [];
    sortedWeeks.map((element) => {
      weeksInSortedWeeks.push(element.number);
    });
    let minWeek = sortedWeeks[0].number;
    let maxWeek = sortedWeeks[sortedWeeks.length - 1].number;

    let maxStartDate = sortedWeeks[0].startDate;
    sortedWeeks.forEach((x) => {
      if(new Date(x.startDate) > new Date(maxStartDate)){
        maxStartDate = new Date(x.startDate);  
      }
    });

    let minStartDate = sortedWeeks[0].startDate;
    sortedWeeks.forEach((x) => {
      if(new Date(x.startDate) > new Date(minStartDate)){
        minStartDate = new Date(x.startDate);  
      }
    });

    let weekSelected = week;
    if (!weekSelected) {
      return;
    }
    let from = new Date(weekSelected.startDate);
    let to = new Date(weekSelected.endDate);
    let days = ApplicationState.getDaysFromTimeEntriesInWeek(
      timeEntries,
      week.number,
      from,
      to
    );
    let i = 0;

    let toDateWithTime = new Date(to);
    let timeEntriesForWeek = timeEntries.filter(
      (x) => new Date(x.date) >= from && new Date(x.date) <= toDateWithTime
    );
    
    let userDataItemsForWeek = userDataItems.filter(
      (x) => new Date(x.dateTime) >= from && new Date(x.dateTime) <= toDateWithTime
    );

    let newDays = [];
    days.forEach((element) => {
      let dayForElement = new Date(element.date).getDay();
      if (dayForElement != 0 && dayForElement != 6) {
        newDays.push(element);
      } else if (
        timeEntriesForWeek.some(
          (x) => dayForElement === new Date(x.date).getDay()
        )
      ) {
        newDays.push(element);
      } else if (
        userDataItemsForWeek.some(
          (x) => dayForElement === new Date(x.dateTime).getDay()
        )
      ) {
        newDays.push(element);
      }
    });
    days = newDays;

    let projectsForTimeEntries = [];
    let activitiesForTimeEntries = [];
    timeEntries
      .sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((timeEntry) => {
        if (
          !projectsForTimeEntries.some(
            (foo) => foo.id === timeEntry.activity.project.id
          )
        ) {
          projectsForTimeEntries.push(timeEntry.activity.project);
        }
        if (
          !activitiesForTimeEntries.some((x) => x.id === timeEntry.activity.id)
        ) {
          activitiesForTimeEntries.push(timeEntry.activity);
        }
      });

    return (
      <Grid
        item
        component={Card}
        xs
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          margin: "8px",
          marginBottom: "16px",
          marginTop: "16px",
        }}
      >
        <Box
          style={{
            padding: "8px",
            backgroundColor: "#93264a",
            color: "white",
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Box p={1}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Box
                  style={{
                    fontWeight: "500",
                    color: "#fff",
                    fontSize: "x-large",
                    backgroundColor: "#53001B",
                    borderRadius: "8px",
                    padding: "16px",
                    lineHeight: "1.4",
                  }}
                >
                  {DateTimeHelper.getHourMinuteStringFromHours(
                    ApplicationState.getDurationForTimeEntries(
                      timeEntriesForWeek
                    )
                  )}
                </Box>
              </Grid>
            </Box>
            <Box>
                  <Grid container justify="flex-end" alignItems="center">
                    {selectedWeekNrTab != minWeek && (
                      <Box>
                        <PrimaryButton
                          icon="previouswhitearrow"
                          text=""
                          buttonClick={() => {
                            let w = sortedWeeks.find(x => x.number === selectedWeekNrTab);
                            let indexOfWeek = sortedWeeks.indexOf(w);
                            let previousWeek = sortedWeeks[indexOfWeek - 1];
                            setSelectedWeekNrTab(parseInt(previousWeek.number));
                          }}
                        ></PrimaryButton>
                      </Box>
                    )}
                    <Box p={0}>
                      <Box style={{ float: "left" }}>
                        <Select
                          native
                          id="weekSelect2"
                          variant="outlined"
                          sx={{
                            margin: "8px",
                            fontWeight: "500",
                            backgroundColor: "#fff",
                            fontSize: "large",
                          }}
                          onChange={handleChangeOfWeekTab}
                          value={selectedWeekNrTab}
                        >
                          {sortedWeeks.map((week) => {
                            return addWeekOptionForSelectBoxArrow(week);
                          })}
                        </Select>
                      </Box>
                    </Box>

                    {selectedWeekNrTab != maxWeek && (
                      <Box>
                        <PrimaryButton
                          icon="nextwhitearrow"
                          text=""
                          buttonClick={() => {
                            let w = sortedWeeks.find(x => x.number === selectedWeekNrTab);
                            let indexOfWeek = sortedWeeks.indexOf(w);
                            let nextWeek = sortedWeeks[indexOfWeek + 1];
                            setSelectedWeekNrTab(parseInt(nextWeek.number));
                          }}
                        ></PrimaryButton>
                      </Box>
                    )}
                  </Grid>
                </Box>
            <Box p={1}>
              <Box p={1}>
                <PrimaryButton
                  icon="add"
                  text="Add time"
                  buttonClick={() => 
                    {
                      let date = new Date(weekSelected.startDate);
                      let report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
                      let from = new Date(report.from);
                      if(ApplicationState.getReportingInterval() === "Month"){
                        while(date.getMonth() != from.getMonth()){
                          date.setDate(new Date(date).getDate() + 1);
                        }
                      }
                      navigateToTimeEntryView(date);
                    }
                  }
                ></PrimaryButton>
              </Box>
            </Box>
          </Grid>
        </Box>
        {zoomLevel === "Week" && (
          <React.Fragment>
            <Box p={0}>
              {addTableForWeek(
                week,
                days,
                projectsForTimeEntries,
                timeEntriesForWeek,
                userDataItemsForWeek,
                activitiesForTimeEntries
              )}
            </Box>
          </React.Fragment>
        )}
        {zoomLevel === "Day" && (
          <Box p={0}>
            {addTableForWeekZoomedInOnDay(
              week,
              days,
              projectsForTimeEntries,
              timeEntriesForWeek,
              userDataItemsForWeek
            )}
          </Box>
        )}
      </Grid>
    );
  }

  const addTableForWeekZoomedInOnDay = (
    week,
    days,
    projectsForTimeEntries,
    timeEntriesForWeek,
    userDataItemsForWeek
  ) => {
    return (
      <Box p={0}>
        <TabContext value={tabWeekday}>
          <AppBar position="static">
            <TabList
              onChange={changeTabWeekday}
              aria-label="simple tabs example"
              style={{ backgroundColor: "#53001B" }}
              variant="fullWidth"
              textColor="secondary"
              indicatorColor="secondary"
            >
              {getTabHeaderForWeekday("Monday", days, "1")}
              {getTabHeaderForWeekday("Tuesday", days, "2")}
              {getTabHeaderForWeekday("Wednesday", days, "3")}
              {getTabHeaderForWeekday("Thursday", days, "4")}
              {getTabHeaderForWeekday("Friday", days, "5")}
              {days.some((x) => new Date(x.date).getDay() === 6) &&
                getTabHeaderForWeekday("Saturday", days, "6")}
              {days.some((x) => new Date(x.date).getDay() === 0) &&
                getTabHeaderForWeekday("Sunday", days, "0")}
            </TabList>
          </AppBar>
          <CustomTabPanel value="1">
            <MyDayView
              changedTimeEntries={showTimeEntries}
              parentDay={
                new Date(days.find((x) => x.name.includes("Monday")).date)
              }
            ></MyDayView>
          </CustomTabPanel>
          <CustomTabPanel value="2">
            <MyDayView
              changedTimeEntries={showTimeEntries}
              parentDay={
                new Date(days.find((x) => x.name.includes("Tuesday")).date)
              }
            ></MyDayView>
          </CustomTabPanel>
          <CustomTabPanel value="3">
            <MyDayView
              changedTimeEntries={showTimeEntries}
              parentDay={
                new Date(days.find((x) => x.name.includes("Wednesday")).date)
              }
            ></MyDayView>
          </CustomTabPanel>
          <CustomTabPanel value="4">
            <MyDayView
              changedTimeEntries={showTimeEntries}
              parentDay={
                new Date(days.find((x) => x.name.includes("Thursday")).date)
              }
            ></MyDayView>
          </CustomTabPanel>
          <CustomTabPanel value="5">
            <MyDayView
              changedTimeEntries={showTimeEntries}
              parentDay={
                new Date(days.find((x) => x.name.includes("Friday")).date)
              }
            ></MyDayView>
          </CustomTabPanel>
          {days.some((x) => new Date(x.date).getDay() === 6) && (
            <CustomTabPanel value="6">
              <MyDayView
                changedTimeEntries={showTimeEntries}
                parentDay={
                  new Date(days.find((x) => x.name.includes("Saturday")).date)
                }
              ></MyDayView>
            </CustomTabPanel>
          )}
          {days.some((x) => new Date(x.date).getDay() === 0) && (
            <CustomTabPanel value="0">
              <MyDayView
                changedTimeEntries={showTimeEntries}
                parentDay={
                  new Date(days.find((x) => x.name.includes("Sunday")).date)
                }
              ></MyDayView>
            </CustomTabPanel>
          )}
        </TabContext>
      </Box>
    );
  };

  const getTabHeaderForWeekday = (weekday, days, value) => {
    let day = days.find((x) => x.name.includes(weekday));
    let date = new Date(day.date);
    let isHoliday = false;
    if (
      ApplicationState.getHolidays().some(
        (x) =>
          new Date(x.date).toLocaleDateString() ===
          new Date(day.date).toLocaleDateString()
      )
    ) {
      isHoliday = true;
    }
    if (weekday.includes("Saturday") || weekday.includes("Sunday")) {
      isHoliday = true;
    }

    let header = weekday;
    if (isHoliday) {
      header = "HOL" + weekday;
    }

    let style = {
      backgroundColor: "#781938",
      color: "#fff",
      margin: "8px",
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "8px",
      paddingBottom: "8px",
      borderRadius: "4px",
      lineHeight: "1.4",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "85%",
    };
    if (isHoliday) {
      style = {};
    }
    return (
      <CustomTab
        label={
          <Box style={style}>
            <Box style={{ fontSize: "large" }}>
              {DateTimeHelper.getDayNameFromWeekdayShort(date.getDay())}{" "}
              {date.getDate()}{" "}
              {DateTimeHelper.getMonthNameShort(date.getMonth())}
            </Box>
            <Box style={{ fontSize: "x-large", marginTop: "4px" }}>
              {DateTimeHelper.getHourMinuteStringFromHours(day.duration)}
            </Box>
          </Box>
        }
        value={value}
      ></CustomTab>
    );
  };

  const handleChangeZoomLevel = (event, newValue) => {
    setZoomLevel(newValue);
  };

  function addWeekOptionForSelectBoxArrow(week) {
    let weekName = DateTimeHelper.getWeekName(week);
    if(ApplicationState.getReportingInterval() === "Month"){
      let report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
      let from = new Date(report.from);
      if(new Date(week.startDate).getMonth() != from.getMonth() || new Date(week.endDate).getMonth() != from.getMonth()) {
        let date = new Date(week.startDate);
        let validDates = [];
        while(new Date(date) <= new Date(week.endDate)) {
          if(date.getMonth() === from.getMonth()) {
            validDates.push(new Date(date));
          }
          date.setDate(new Date(date).getDate() + 1);
        }
        if(validDates.length > 0) {
          var newWeek = new Week(
            week.number,
            new Date(validDates[0]),
            new Date(validDates[validDates.length - 1])
          );
          weekName = DateTimeHelper.getWeekName(newWeek);
        }
      }
    }

    return (
      <option
        key={week.number}
        label={weekName}
        value={week.number}
      ></option>
    );
  }

  function addWeekForDesktopMode_Month(week) {

    let weekSelected = weeksForYear.find((x) => x.number === week.number);
    if (!weekSelected) {
      return;
    }
    let from = new Date(weekSelected.startDate);
    let to = new Date(weekSelected.endDate);
    let days = ApplicationState.getDaysFromTimeEntriesInWeek(
      timeEntries,
      week.number,
      from,
      to
    );
    let i = 0;

    let timeEntriesForWeek = timeEntries.filter(
      (x) => new Date(x.date) >= from && new Date(x.date) <= to
    );

    let userDataItemsForWeek = userDataItems.filter(
      (x) => new Date(x.dateTime) >= from && new Date(x.dateTime) <= to
    );

    let newDays = [];
    days.forEach((element) => {
      let dayForElement = new Date(element.date).getDay();
      if (dayForElement != 0 && dayForElement != 6) {
        newDays.push(element);
      } else if (
        timeEntriesForWeek.some(
          (x) => dayForElement === new Date(x.date).getDay()
        )
      ) {
        newDays.push(element);
      } else if (
        userDataItemsForWeek.some(
          (x) => dayForElement === new Date(x.dateTime).getDay()
        )
      ) {
        newDays.push(element);
      }
    });
    days = newDays;

    let projectsForTimeEntries = [];
    let activitiesForTimeEntries = [];
    timeEntries
      .sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((timeEntry) => {
        if (
          !projectsForTimeEntries.some(
            (x) => x.id === timeEntry.activity.project.id
          )
        ) {
          projectsForTimeEntries.push(timeEntry.activity.project);
        }
        if (
          !activitiesForTimeEntries.some((x) => x.id === timeEntry.activity.id)
        ) {
          activitiesForTimeEntries.push(timeEntry.activity);
        }
      });

    return (
      <Grid
        item
        component={Card}
        xs
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            padding: "8px",
            backgroundColor: "#781938",
            color: "white",
          }}
        >
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Box p={1}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box
                  style={{
                    fontWeight: "500",
                    color: "#fff",
                    fontSize: "x-large",
                    backgroundColor: "#53001B",
                    borderRadius: "8px",
                    padding: "16px",
                    lineHeight: "1.4",
                    marginRight: "8px",
                  }}
                >
                  {DateTimeHelper.getHourMinuteStringFromHours(
                    ApplicationState.getDurationForTimeEntries(
                      timeEntriesForWeek
                    )
                  )}
                </Box>
              </Grid>
            </Box>
            <Box p={1}>
              <PrimaryButton
                icon="add"
                text="Add time"
                buttonClick={() =>
                  navigateToTimeEntryView(weekSelected.startDate)
                }
              ></PrimaryButton>
            </Box>
          </Grid>
        </Box>
        <Box p={0}>
          {addTableForWeek(
            week,
            days,
            projectsForTimeEntries,
            timeEntriesForWeek,
            userDataItemsForWeek,
            activitiesForTimeEntries
          )}
        </Box>
      </Grid>
    );
  }

  function addTableForWeek(
    week,
    days,
    projectsForTimeEntries,
    timeEntriesForWeek,
    userDataItemsForWeek,
    activitiesForTimeEntries
  ) {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#fff", borderBottom: "1px solid #eee" }}>
              <TableCell
                align="left"
                style={{
                  padding: "8px",
                  borderBottomWidth: "0px",
                  fontSize: "16px",
                }}
              >
                <TableHeaderBox style={{backgroundColor: "#fff", border: "2px solid #eee", padding: "16px"}}>
                  
                  <Box
                    style={{
                      fontWeight: "500",
                      fontSize: "larger",
                    }}
                  >
                    Activities
                  </Box>
                </TableHeaderBox>
              </TableCell>

              {getTableHeaderForWeekday("Monday", days)}
              {getTableHeaderForWeekday("Tuesday", days)}
              {getTableHeaderForWeekday("Wednesday", days)}
              {getTableHeaderForWeekday("Thursday", days)}
              {getTableHeaderForWeekday("Friday", days)}
              {days.some((x) => new Date(x.date).getDay() === 6) && (
                <React.Fragment>
                  {getTableHeaderForWeekday("Saturday", days)}
                </React.Fragment>
              )}
              {days.some((x) => new Date(x.date).getDay() === 0) && (
                <React.Fragment>
                  {getTableHeaderForWeekday("Sunday", days)}
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {showEvents && (
              <React.Fragment>
                {addEventsNotInTimeReport(
                  week,
                  days,
                  timeEntriesForWeek,
                  userDataItemsForWeek
                )}
              </React.Fragment>
            )}
            {getTableRowsForProjects(
              projectsForTimeEntries,
              timeEntriesForWeek,
              days,
              activitiesForTimeEntries
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function addEventsNotInTimeReport(
    week,
    days,
    timeEntriesForWeek,
    userDataItemsForWeek
  ) {
    return (
      <React.Fragment>
        <TableRow style={{ backgroundColor: "#53001B" }}>
          <TableCell
            align="left"
            style={{
              overflowWrap: "break-word",
              padding: "8px",
              borderBottomWidth: "0px",
            }}
            colSpan={8}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
              </Grid>
              <Grid item style={{ padding: "8px" }}>
                {loadingUserDataItems && (
                  <React.Fragment>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ padding: "8px" }}
                    >
                      <CircularProgress
                        size={30}
                        color="secondary"
                        style={{ paddingLeft: "4px" }}
                      />
                      <Typography style={{ color: "#fff", paddingLeft: "8px" }}>
                        <i>Loading...</i>
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
                {!loadingUserDataItems && (
                  <Typography
                    variant="h6"
                    style={{ color: "white", fontWeight: "500" }}
                  >
                    {userDataItemsForWeek.length + " "}events not in time report
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        {getTableRowsForUserDataItems(
          timeEntriesForWeek,
          userDataItemsForWeek,
          days,
          week
        )}
        <TableRow style={{ backgroundColor: "#53001B" }}>
          <TableCell
            align="left"
            style={{
              overflowWrap: "break-word",
              padding: "8px",
              borderBottomWidth: "0px",
            }}
            colSpan={8}
          >
            <Box style={{ padding: "8px" }}>
              <Typography
                variant="h6"
                style={{ color: "white", fontWeight: "500" }}
              >
                Time report
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  async function loadTimeEntriesAndUserDataItems() {
    if(!timeReportSelected) {
      return;
    }
    var today = new Date();
    
    var report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
    let from = new Date(report.from);
    let to = new Date(report.to);
    if (report && ApplicationState.getReportingInterval() === "Month") {
      if(new Date(report.from).getMonth() === today.getMonth()){
        setSelectedWeekNrTab(DateTimeHelper.getWeekNumber(today));
      } else {
        setSelectedWeekNrTab(DateTimeHelper.getWeekNumber(from));
      }
    }

    if (ApplicationState.getReportingInterval() === "Week") {
      let weekSelected = weeksForYear.find((x) => x.number === weekNrSelected);
      if (weekSelected) {
        from = new Date(weekSelected.startDate);
        to = new Date(weekSelected.endDate);
      }
    }

    var entriesForUser = await ApplicationState.getTimeEntriesForUser(
      ApplicationState.getUser().email,
      from.toLocaleDateString("sv-SE"),
      to.toLocaleDateString("sv-SE")
    );
    setTimeEntries(entriesForUser);
    await ApplicationState.loadTimeReportsForUser();
    await toogleSendReportButton();

    setloadingUserDataItems(true);
    let userDataDtos = await ApplicationState.getUserDataForUser(
      ApplicationState.getState().user.email,
      new Date(from).toLocaleDateString("sv-SE"),
      new Date(to).toLocaleDateString("sv-SE")
    );

    userDataDtos = userDataDtos.filter(x => x.ignore === false);

    let dtos = [];
    userDataDtos.map((element) => {
      let dto = getUserDataItemDtoFromUserData(element);
      dtos.push(dto);
    });

    let dtosNotInTimeReport = [];
    dtos.map((element) => {
      if (
        !entriesForUser ||
        !entriesForUser.some(
          (x) => x.userData && x.userData.id === element.userDataId
        )
      ) {
        dtosNotInTimeReport.push(element);
      }
    });
    setUserDataItems(dtosNotInTimeReport);
    setUserDataItemsAll(dtos);
    setloadingUserDataItems(false);
  }

  function getUserDataItemDtoFromUserData(data) {
    return new UserDataItemDto(
      data.dataObjectId,
      data.title,
      data.duration,
      new Date(data.dateTime),
      getTypeFromUserData(data),
      data,
      data.id,
      data.eventType
    );
  }

  function getTypeFromUserData(data) {
    let scope = data.userDataServiceScopeSelection.userDataServiceScope.name;
    if (scope === "Google Calendar" || scope === "Outlook Calendar") {
      return "meeting";
    } else if (scope === "Gmail" || scope === "Outlook Email") {
      return "email";
    } else if (scope === "Google Drive" || scope === "OneDrive") {
      return "file";
    } else if (scope === "Work Items") {
      return "ticket";
    } else if (
      scope === "Google Tasks" ||
      scope === "To Do Tasks" ||
      scope === "Planner Tasks"
    ) {
      return "task";
    } else {
      return "?";
    }
  }

  function getTableRowsForUserDataItems(
    timeEntriesForWeek,
    userDataItemsForWeek,
    days,
    week
  ) {
    let counter = 1;
    let types = [];
    types = ApplicationState.getUserDataItemTypes();
    return types.map((type) =>
      getTableRowForUserDataType(
        type,
        week,
        timeEntriesForWeek,
        userDataItemsForWeek,
        days,
        counter++
      )
    );
  }

  function getTableRowForUserDataType(
    type,
    week,
    timeEntriesForWeek,
    userDataItemsForWeek,
    days,
    rowNr
  ) {
    let nrOfItems = getUserDataTypeCount(type, userDataItemsForWeek);
    return (
      <React.Fragment>
        {getTabelSeparatorRow(
          getTextForUserDataItemType(type),
          type,
          nrOfItems
        )}
        {nrOfItems > 0 && (
          <TableRow key={type}>
            {loadingUserDataItems && (
              <CustomTableCell colspan={8} p={1}>
                <CircularProgress color="primary" />
                <i>Loading...</i>
              </CustomTableCell>
            )}
            {!loadingUserDataItems && (
              <React.Fragment>
                {getTableCellsForUserDataType(type, days)}
              </React.Fragment>
            )}
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  function getTextForUserDataItemType(type) {
    if (type === "email") {
      return "emails";
    }
    if (type === "meeting") {
      return "meetings";
    }
    if (type === "file") {
      return "files";
    }
    if (type === "task") {
      return "tasks";
    }
    if (type === "ticket") {
      return "tickets";
    }
    return "?";
  }

  function getUserDataTypeCount(type, userDataItemsForWeek) {
    return userDataItemsForWeek.filter((x) => x.type === type).length;
  }

  function getTableCellsForUserDataType(type, days) {
    return days.map((day) => {
      return getTableCellForUserDataTypeAndDate(type, day);
    });
  }

  function getTableCellForUserDataTypeAndDate(type, day) {
    let userDataItemsForDate = userDataItems.filter(
      (x) =>
        x.type === type &&
        new Date(x.dateTime).toLocaleDateString("sv-SE") ===
          new Date(day.date).toLocaleDateString("sv-SE")
    );

    let width = getWidthForTableCell();

    if (day.name.includes("Sunday")) {
      return (
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            borderBottomWidth: "0px",
            verticalAlign: "top",
          }}
        >
          {getCardActionAreaForUserDataItems(userDataItemsForDate)}
        </TableCell>
      );
    }
    return (
      <CustomTableCell
        align="left"
        style={{ maxWidth: width, minWidth: width, overflowWrap: "break-word" }}
      >
        {getCardActionAreaForUserDataItems(userDataItemsForDate)}
      </CustomTableCell>
    );
  }

  function getCardActionAreaForUserDataItems(userDataItemsForDate) {
    let counter = 0;
    return userDataItemsForDate
      .sort((a, b) => (a.title > b.title ? 1 : -1))
      .map((userDataItem) => {
        counter++;
        return (
          <Box
            sx={{
              "& .hidden-button": {
                display: "none",
              },
              "&:hover .hidden-button": {
                display: "flex",
                color: "#666",
              },
            }}
          >
            <Box paddingTop={1.5} paddingBottom={1.5}>
              <CardActionArea
                style={{
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  paddingTop: "4px",
                  paddingBottom: "4px",
                  fontFamily: "Roboto",
                  fontSize: "15px",
                }}
                onClick={() => showDialogForAddingTimeEntry(userDataItem)}
              >
                <b>{userDataItem.title}</b>
                <br />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{ paddingTop: "4px" }}
                >
                  {getIconForUserDataItem(userDataItem.type)}
                  <Box style={{ paddingLeft: "4px" }}>
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      userDataItem.duration * 60
                    )}
                  </Box>
                </Grid>
              </CardActionArea>
            </Box>

            {counter < userDataItemsForDate.length && <Divider />}
          </Box>
        );
      });
  }

  function getIconForUserDataItem(type) {
    if (type === "meeting") {
      return <EventIcon style={{ color: "#666" }}></EventIcon>;
    }
    if (type === "email") {
      return <EmailOutlinedIcon style={{ color: "#666" }}></EmailOutlinedIcon>;
    }
    if (type === "file") {
      return (
        <InsertDriveFileOutlinedIcon
          style={{ color: "#666" }}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (type === "ticket") {
      return <StickyNote2OutlinedIcon style={{ color: "#666" }}></StickyNote2OutlinedIcon>;
    }
    if (type === "task") {
      return (
        <AssignmentTurnedInOutlinedIcon
          style={{ color: "#666" }}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    return <Box></Box>;
  }

  function showDialogForAddingTimeEntry(timelineDto) {
    if (timerReportSent) {
      return;
    }
    ApplicationState.setTimeEntryToEdit(undefined);
    ApplicationState.setDateToAddTimeEntryFor(undefined);
    ApplicationState.setProjectToAddTimeEntryFor(undefined);
    ApplicationState.setUserDataItemToAddTimeEntryFor(
      timelineDto
    );
    ApplicationState.setInSummaryModeTimeEntry(false);
    setOpenTimeEntryDialog(true);
  }

  function getTableRowsForProjects(
    projectsForTimeEntries,
    timeEntriesForWeek,
    days,
    activitiesForTimeEntries
  ) {
    let counter = 1;
    let projectsWithTimeEntries = [];
    projectsForTimeEntries.map((project) => {
      if (timeEntriesForWeek.some((x) => x.activity.project.id === project.id)) {
        projectsWithTimeEntries.push(project);
      }
    });

    let activitiesWithTimeEntries = [];
    activitiesForTimeEntries.map((activity) => {
      if (timeEntriesForWeek.some((x) => x.activity.id === activity.id)) {
        activitiesWithTimeEntries.push(activity);
      }
    });

    return activitiesWithTimeEntries.map((activity) =>
      getTableRowForActivity(
        activity,
        timeEntriesForWeek,
        days,
        counter++,
        activitiesForTimeEntries.filter(
          (x) => x.project.id === activity.project.id
        ).length > 1
      )
    );
  }

  function getTableRowForActivity(
    activity,
    timeEntriesForWeek,
    days,
    rowNr,
    showActivityText
  ) {
    let color = "#fff";
    if (rowNr % 2 === 0) {
      color = "#f6f6f6";
    }

    
      return (
        <React.Fragment>
          <TableRow>
            <td colSpan={8} style={{ backgroundColor: color }}>
              &nbsp;
            </td>
          </TableRow>
          <TableRow style={{ backgroundColor: color }}>
            <CustomTableCell component="th" scope="row">
            
            <Box style={{paddingLeft: "8px"}}>
              <b>{activity.project.name}</b>
              <br />
              <i>{activity.name}</i>
            </Box>

            <Button
              variant="text"
              color="primary"
              disabled="true"
              style={{
                color: "#111",
                fontSize: "large",
                justifyContent: "flex-start",
                textTransform: "none",
                disabled: true
              }}
            >{getActivityDuration(activity, timeEntriesForWeek)}
            </Button>        
          </CustomTableCell>
            {getTableCellsForActivity(activity, days)}
          </TableRow>
          <TableRow>
            <td colSpan={8} style={{ backgroundColor: color }}>
              &nbsp;
            </td>
          </TableRow>
        </React.Fragment>
      );
    
  }

  function getTabelSeparatorRow(
    text,
    type,
    duration,
    showActivityText,
    activityText,
    backgroundColor
  ) {
    return (
      <TableRow style={{ backgroundColor: backgroundColor }}>
        <TableCell
          align="left"
          style={{
            overflowWrap: "break-word",
            padding: "8px",
            borderBottomWidth: "0px",
          }}
          colSpan={8}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "500", padding: "8px" }}
          >
            {type === "" && (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box style={{ paddingLeft: "0px" }}>
                    {text}
                    
                      <React.Fragment>
                        <br />
                        <i style={{ fontWeight: "400" }}>{activityText}</i>
                      </React.Fragment>
                  </Box>
                  <Box style={{ paddingLeft: "0px" }}>
                    <span>{duration}</span>{" "}
                  </Box>
                </Grid>
              </React.Fragment>
            )}
            {type != "" && (
              <React.Fragment>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {getIconForUserDataItem(type)}
                  <Box style={{ paddingLeft: "8px" }}>
                    {duration + " " + text}
                  </Box>
                </Grid>
              </React.Fragment>
            )}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  function getTableHeaderForWeekday(weekday, days) {
    let day = days.find((x) => x.name.includes(weekday));
    //TODO: Fix this bug that happens last week in 2021
    if (!day) {
      return (
        <TableCell
          align="left"
          style={{
            padding: "8px",
            borderBottomWidth: "0px",
            fontSize: "16px",
          }}
        >
          <TableHeaderBox>
            Missing
            <Box style={{ fontWeight: "400" }}>{"?"}</Box>
            <Box
              style={{
                color: "#d8d8d8",
                fontWeight: "500",
                fontSize: "larger",
              }}
            >
              {"?"}
            </Box>
          </TableHeaderBox>
        </TableCell>
      );
    }
    let date = new Date(day.date);
    let isHoliday = false;
    if (
      ApplicationState.getHolidays().some(
        (x) =>
          new Date(x.date).toLocaleDateString() ===
          new Date(day.date).toLocaleDateString()
      )
    ) {
      isHoliday = true;
    }
    if (weekday.includes("Saturday") || weekday.includes("Sunday")) {
      isHoliday = true;
    }
    let width = getWidthForTableCell();
    let report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
    let from = new Date(report.from);
    if(date.getMonth() != from.getMonth() && ApplicationState.getReportingInterval() === "Month") {
      return(
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            padding: "8px",
            borderBottomWidth: "0px",
            fontSize: "16px",
            color: "#888",
          }}
        >
              <TableHeaderBox
                style={{ color: "#ddd", backgroundColor: "#fff" }}
              >
                <Box> 
                  {DateTimeHelper.getDayNameFromWeekdayShort(date.getDay())}{" "}
                  {date.getDate()}{" "}
                  {DateTimeHelper.getMonthNameShort(date.getMonth())}
                </Box>
              </TableHeaderBox>
        </TableCell>
      );
    }

    if (isHoliday) {
      return (
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            padding: "8px",
            borderBottomWidth: "0px",
            fontSize: "16px",
            color: "#888",
          }}
        >
          <ThemeProvider theme={finalTheme}>
            <LightTooltip
              arrow="true"
              interactive
              placement="right-start"
              TransitionComponent={Zoom}
              title={
                <Box>
                  <Typography
                    style={{
                      padding: "8px",
                      paddingTop: "4px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <b>{day.name}</b>
                  </Typography>
                  <Divider />
                  <MenuList>
                    <MenuItem onClick={() => navigateToTimeEntryView(date)}>
                      <ListItemIcon>
                        <AddBoxOutlinedIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Add time</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => deleteTimeEntriesForDate(date)}>
                      <ListItemIcon>
                        <DeleteForeverIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete all</ListItemText>
                    </MenuItem>
                  </MenuList>
                </Box>
              }
            >
              <TableHeaderBox
                style={{ color: "#C30F16", backgroundColor: "#fff", border: "2px solid #eee" }}
              >
                <Box> 
                  {DateTimeHelper.getDayNameFromWeekdayShort(date.getDay())}{" "}
                  {date.getDate()}{" "}
                  {DateTimeHelper.getMonthNameShort(date.getMonth())}
                </Box>
                <Box
                  style={{
                    color: "#d8d8d8",
                    fontWeight: "500",
                    fontSize: "larger",
                  }}
                >
                  {DateTimeHelper.getHourMinuteStringFromHours(day.duration)}
                </Box>
              </TableHeaderBox>
            </LightTooltip>
          </ThemeProvider>
        </TableCell>
      );
    }
    return (
      <TableCell
        align="left"
        style={{
          maxWidth: width,
          minWidth: width,
          overflowWrap: "break-word",
          padding: "8px",
          borderBottomWidth: "0px",
          fontSize: "16px",
        }}
      >
        <ThemeProvider theme={finalTheme}>
          <LightTooltip
            arrow="true"
            interactive
            placement="right-start"
            TransitionComponent={Zoom}
            title={
              <Box>
                <Typography
                  style={{
                    padding: "8px",
                    paddingTop: "4px",
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  <b>{day.name}</b>
                </Typography>
                <Divider />
                <MenuList>
                  <MenuItem onClick={() => navigateToTimeEntryView(date)}>
                    <ListItemIcon>
                      <AddBoxOutlinedIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Add time</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => deleteTimeEntriesForDate(date)}>
                    <ListItemIcon>
                      <DeleteForeverIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete all</ListItemText>
                  </MenuItem>
                </MenuList>
              </Box>
            }
          >
            <TableHeaderBox>
              <Box>
                {DateTimeHelper.getDayNameFromWeekdayShort(date.getDay())}{" "}
                {date.getDate()}{" "}
                {DateTimeHelper.getMonthNameShort(date.getMonth())}
              </Box>
              <Box
                style={{
                  fontWeight: "500",
                  fontSize: "larger",
                }}
              >
                {DateTimeHelper.getHourMinuteStringFromHours(day.duration)}
              </Box>
            </TableHeaderBox>
          </LightTooltip>
        </ThemeProvider>
      </TableCell>
    );
  }

  function getActivityDuration(activity, timeEntriesForWeek) {
    let duration = 0;
    timeEntriesForWeek.map((timeEntry) => {
      if (timeEntry.activity.id === activity.id) {
        duration += ApplicationState.getDurationForTimeEntry(timeEntry);
      }
    });
    return DateTimeHelper.getHourMinuteStringFromHours(duration);
  }

  function getTableCellsForActivity(activity, days) {
    return days.map((day) => {
      return getTableCellForActivityAndDate(activity, day);
    });
  }

  function getTableCellForActivityAndDate(activity, day) {
    let timeEntriesForDate = timeEntries.filter(
      (x) =>
        x.activity.id === activity.id &&
        new Date(x.date).toLocaleDateString("sv-SE") ===
          new Date(day.date).toLocaleDateString("sv-SE")
    );

    let width = getWidthForTableCell();

    if (day.name.includes("Sunday")) {
      return (
        <TableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
            borderBottomWidth: "0px",
            verticalAlign: "top",
          }}
        >
          {getSummaryActivityPerDayBox(timeEntriesForDate, activity, day)}
          {getCardActionAreaForTimeEntries(timeEntriesForDate, activity)}
        </TableCell>
      );
    }
    return (
      <React.Fragment>
        <CustomTableCell
          align="left"
          style={{
            maxWidth: width,
            minWidth: width,
            overflowWrap: "break-word",
          }}
        >
          {getSummaryActivityPerDayBox(timeEntriesForDate, activity, day)}
          {getCardActionAreaForTimeEntries(timeEntriesForDate, activity)}
        </CustomTableCell>
      </React.Fragment>
    );
  }

  function getWidthForTableCell() {
    let width = "100px";
    let w = window.innerWidth;
    width = w / 14 + "px";
    return width;
  }

  function getListItemsForProjects() {
    let counter = 1;
    if (timeEntries && timeEntries.length > 0) {
      return ApplicationState.getTimeItemsViewFromTimeEntries(timeEntries).map(
        (element) => {
          return getListItemForProject(element, counter++);
        }
      );
    }
    return (
      <Box p={2}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography>
            <i>No projects have time registered</i>
          </Typography>
          <Box style={{ paddingRight: "4px" }}></Box>
          <SentimentDissatisfiedIcon />
        </Grid>
      </Box>
    );
  }

  function getListItemForProject(timeItemView, rowNr) {
    let entries = timeEntries.filter(
      (x) => x.activity.id === timeItemView.activity.id
    );

    var report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
    let from = new Date(report.from);
    let to = new Date(report.to);

    //Make sure it's not saturday or sundag because that adds extra days to the GUI
    if (from.getDay() === 6) {
      from = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 2);
    }
    if (from.getDay() === 0) {
      from = new Date(from.getFullYear(), from.getMonth(), from.getDate() + 1);
    }

    if (rowNr % 2 === 0) {
      return (
        <CardActionArea
          onClick={async () =>
            await editTimeEntrySummary(
              new Date(from),
              timeItemView.activity,
              entries,
              false
            )
          }
        >
          <ListItem
            key={timeItemView.id}
            style={{
              padding: "12px",
              paddingLeft: "24px",
              paddingRight: "24px",
              backgroundColor: "#f6f6f6",
            }}
          >
            <ListItemText key={timeItemView.id + "a"}>
              <strong>{timeItemView.project.name}</strong>
              {timeItemView.showActivityText === true && (
                <React.Fragment>
                  <br />
                  <i>{timeItemView.activity.name}</i>
                  {addOverTimeDetails(entries, false)}
                </React.Fragment>
              )}
            </ListItemText>

            <Typography>
              <strong>
                {DateTimeHelper.getHourMinuteStringFromHours(
                  timeItemView.duration
                )}
              </strong>
            </Typography>
          </ListItem>
        </CardActionArea>
      );
    }
    return (
      <CardActionArea
        onClick={async () =>
          await editTimeEntrySummary(
            new Date(from),
            timeItemView.activity,
            entries,
            false
          )
        }
      >
        <ListItem
          key={timeItemView.id}
          style={{ padding: "12px", paddingLeft: "24px", paddingRight: "24px" }}
        >
          <ListItemText key={timeItemView.id + "a"}>
            <strong>{timeItemView.project.name}</strong>
            {timeItemView.showActivityText === true && (
              <React.Fragment>
                <br />
                <i>{timeItemView.activity.name}</i>
                {addOverTimeDetails(entries, false)}
              </React.Fragment>
            )}
          </ListItemText>
          <Typography>
            <strong>
              {DateTimeHelper.getHourMinuteStringFromHours(
                timeItemView.duration
              )}
            </strong>
          </Typography>
        </ListItem>
      </CardActionArea>
    );
  }

  function getSummaryActivityPerDayBox(timeEntriesForDate, activity, day) {
    let summaryString = DateTimeHelper.getHourMinuteStringFromHours(
      ApplicationState.getDurationForTimeEntries(timeEntriesForDate)
    );
    
    if (summaryString === "0h") {
      summaryString = "-";
    }
    let report = ApplicationState.getState().timeReports.find(x => x.id === timeReportSelected);
    let from = new Date(report.from);

    if(new Date(day.date).getMonth() != from.getMonth() && ApplicationState.getReportingInterval() === "Month"){
      return (<Box></Box>);
    }

    let meetings = 0;
    let emails = 0;
    let files = 0;
    let tickets = 0;
    let tasks = 0;
    let events = 0;
    let uniqueTypes = 0;

    timeEntriesForDate.forEach((entry) => {
      if (entry.userData) {
        if (entry.userData.type === "meeting") {
          meetings++;
          if (meetings === 1) {
            uniqueTypes++;
          }
        }
        if (entry.userData.type === "email") {
          emails++;
          if (emails === 1) {
            uniqueTypes++;
          }
        }
        if (entry.userData.type === "file") {
          files++;
          if (files === 1) {
            uniqueTypes++;
          }
        }
        if (entry.userData.type === "task") {
          tasks++;
          if (tasks === 1) {
            uniqueTypes++;
          }
        }
        if (entry.userData.type === "ticket") {
          tickets++;
          if (tickets === 1) {
            uniqueTypes++;
          }
        }
        events++;
      }
    });

    let showSummaryBadge = false;
    if (uniqueTypes > 1) {
      showSummaryBadge = true;
    }

    return (
      <ThemeProvider theme={finalTheme}>
                <LightTooltip
                  arrow
                  interactive
                  placement="right"
                  TransitionComponent={Zoom}
                  title={
                    <Box>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Grid container>
                            <IconButton
                              onClick={async () =>
                                await editTimeForTimeEntry(activity, new Date(day.date), timeEntriesForDate, 15)
                              }
                              size="large"
                            >
                              <Typography
                                style={{
                                  textTransform: "lowercase",
                                  fontWeight: "500",
                                  color: "black",
                                  opacity: "0.75",
                                }}
                              >
                                +15m
                              </Typography>
                            </IconButton>
                            <IconButton
                              onClick={async () =>
                                await editTimeForTimeEntry(activity, new Date(day.date), timeEntriesForDate, -15)
                              }
                              size="large"
                            >
                              <Typography
                                style={{
                                  textTransform: "lowercase",
                                  fontWeight: "500",
                                  color: "black",
                                  opacity: "0.75",
                                }}
                              >
                                -15m
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <IconButton size="large">
                            <DeleteForeverIcon
                              color="primary"
                              onClick={async () => await deleteTimeEntriesForDateAndActivity(activity, new Date(day.date))}
                            ></DeleteForeverIcon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                >
        <Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              color: "#93264A",
              borderRadius: "8px",
              fontSize: "large",
              fontWeight: "500",
            }}
          >
            <Box>
              <Button
                onClick={async () =>
                  await editTimeEntrySummary(
                    new Date(day.date),
                    activity,
                    timeEntriesForDate,
                    true
                  )
                }
                style={{
                  color: "#93264A",
                  fontSize: "large",
                  justifyContent: "flex-start",

                  textTransform: "none"
                }}
              >
                {summaryString}
              </Button>
            </Box>
            <Box style={{ color: "#93264A" }}>
              {showSummaryBadge && events > 0 && (
                <Grid container justifyContent="flex-start">
                  <IconButton onClick={() => toogleExpandedActivity(activity)}>
                    {addBadge("events", events)}
                  </IconButton>
                </Grid>
              )}
              {!showSummaryBadge && events > 0 && (
                <Grid container justifyContent="flex-start">
                  <IconButton onClick={() => toogleExpandedActivity(activity)}>
                    {addBadge("meeting", meetings)}
                    {addBadge("email", emails)}
                    {addBadge("file", files)}
                    {addBadge("task", tasks)}
                    {addBadge("ticket", tickets)}
                  </IconButton>
                </Grid>
              )}
            </Box>
          </Grid>
          {addOverTimeDetails(timeEntriesForDate, true)}
          {expandedActivities.some((x) => x === activity.id) && (
            <Divider></Divider>
          )}
        </Box>
        </LightTooltip>
      </ThemeProvider>
    );
  }

  const addOverTimeDetails = (timeEntriesForDate, usePadding) => { 
    let overtime1 = 0;
    let overtime2 = 0;
    let normaltime = 0;
    timeEntriesForDate.forEach((element) => {
      normaltime += element.durationMinutes;
      if(element.durationOvertime1 > 0){
        overtime1 += element.durationOvertime1;
      }
      if(element.durationOvertime2 > 0){
        overtime2 += element.durationOvertime2;
      }
    })
    normaltime = normaltime - overtime1 - overtime2;
    let padding = "0px";
    if(usePadding === true){
      padding = "8px";
    }
    return(
      <Typography variant="subtitle2" style={{paddingLeft: padding, fontWeight: "400", color: "#111"}}>
        {(overtime1 > 0 || overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(normaltime * 60)}{" Normal time"}<br />
            </React.Fragment>
        )}
        {(overtime1 > 0 && overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime1 * 60)}{" Overtime1 "}<br />
              {DateTimeHelper.getHourMinuteStringFromHours(overtime2 * 60)}{" Overtime2 "}<br />
            </React.Fragment>
        )}
        {(overtime1 > 0 && overtime2 <= 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime1 * 60)}{" Overtime1"}<br />
            </React.Fragment>
        )}
        {(overtime1 <= 0 && overtime2 > 0) && (
            <React.Fragment>
              {DateTimeHelper.getHourMinuteStringFromHours(overtime2 * 60)}{" Overtime2"}<br />
            </React.Fragment>
        )}
      </Typography>
    );
  }

  function toogleExpandedActivity(activity) {
    if (expandedActivities.some((x) => x === activity.id)) {

      const index = expandedActivities.indexOf(activity.id);
      if (index > -1) {
        expandedActivities.splice(index, 1);
      }
      setExpandedActivities(expandedActivities.slice());
    } else {
      expandedActivities.push(activity.id);
    }
    handleExpandClick();
  }

  function addBadge(type, count) {
    if (count <= 0) {
      return <React.Fragment></React.Fragment>;
    }
    if (type === "events") {
      return (
        <Badge
          badgeContent={count}
          color="info"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          style={{ marginRight: "10px" }}
        >
          <FolderOpenIcon style={{ color: "#666" }}></FolderOpenIcon>
        </Badge>
      );
    }
    return (
      <Badge
        badgeContent={count}
        color="info"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ marginRight: "10px" }}
      >
        {getIconForUserDataItem(type)}
      </Badge>
    );
  }

  function getCardActionAreaForTimeEntries(timeEntriesForDate, activity) {
    if (!expandedActivities.some((x) => x === activity.id)) {
      return <React.Fragment></React.Fragment>;
    }
    let counter = 0;
    let timeEntriesWithUserData = timeEntriesForDate
    .sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1))
    .filter(x => x.userData);//hide time entries withour user data because only entries with user data is relevant

    return timeEntriesWithUserData 
      .map((timeEntry) => {
        counter++;
        return (
          <Box
            sx={{
              "& .hidden-button": {
                display: "none",
              },
              "&:hover .hidden-button": {
                display: "flex",
                color: "#666",
              },
            }}
          >
            <Box paddingTop={1.5} paddingBottom={1.5}>
              <ThemeProvider theme={finalTheme}>
                <LightTooltip
                  arrow
                  interactive
                  placement="right"
                  TransitionComponent={Zoom}
                  title={
                    <Box>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <IconButton size="large">
                            <DeleteForeverIcon
                              color="primary"
                              onClick={() => deleteTimeEntry(timeEntry)}
                            ></DeleteForeverIcon>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  }
                >
                  <CardActionArea
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                      paddingTop: "4px",
                      paddingBottom: "4px",
                      fontFamily: "Roboto",
                      fontSize: "15px",
                    }}
                    onClick={() => editTimeEntry(timeEntry)}
                  >
                    {!timeEntry.description && <b>{timeEntry.activity.name}</b>}
                    {timeEntry.description && <b>{timeEntry.description}</b>}

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ paddingTop: "4px" }}
                    >
                      {getIconForTimeEntry(timeEntry)}
                      {timeEntry.userData.eventType === "attend" && (
                      <i>
                        {"Attended "}
                        {DateTimeHelper.getTimeStringFromDate(timeEntry.dateTime)}
                      </i>
                      )}

                      {timeEntry.userData.eventType != "attend" && (
                        <i>{getTextForEventType(timeEntry.userData.eventType)}{" "}{DateTimeHelper.getTimeStringFromDate(timeEntry.dateTime)}</i>
                      )}
                    </Grid>                    
                  </CardActionArea>
                </LightTooltip>
              </ThemeProvider>
            </Box>

            {counter < timeEntriesWithUserData.length && <Divider />}
          </Box>
        );
      });
  }

  function getIconForTimeEntry(entry) {
    return getIcon(entry);
  }

  function getIcon(entry, dataType, eventType) {
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Outlook Calendar"
    ) {
      return (
        <EventIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px" }}
        ></EventIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Calendar"
    ) {
      return (
        <EventIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></EventIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Drive"
    ) {
      return (
        <InsertDriveFileOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px" }}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "OneDrive"
    ) {
      return (
        <InsertDriveFileOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></InsertDriveFileOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Gmail"
    ) {
      return (
        <EmailOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></EmailOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Outlook Email"
    ) {
      return (
        <EmailOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></EmailOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Google Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px" }}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "To Do Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Planner Tasks"
    ) {
      return (
        <AssignmentTurnedInOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px"}}
        ></AssignmentTurnedInOutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Work Items" &&
      entry.userData.eventType != "commit"
    ) {
      return (
        <StickyNote2OutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px" }}
        ></StickyNote2OutlinedIcon>
      );
    }
    if (
      entry.userData &&
      entry.userData.userDataServiceScopeSelection.userDataServiceScope.name ===
        "Work Items" &&
      entry.userData.eventType === "commit"
    ) {
      return (
        <CommitOutlinedIcon
          fontSize="medium"
          style={{ color: "#93264A", opacity: "0.75", paddingRight: "4px" }}
        ></CommitOutlinedIcon>
      );
    }
    return <Box></Box>;
  }

  function addDaysForWeek(weekSelected) {
    if (!weekSelected) {
      return;
    }
    let from = new Date(weekSelected.startDate);
    let to = new Date(weekSelected.endDate);
    let days = ApplicationState.getDaysFromTimeEntriesInWeek(
      timeEntries,
      weekSelected.Number,
      from,
      to
    );
    let i = 0;
    days = days.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
    return days.map((element) => {
      const today = new Date(new Date().setHours(0,0,0,0));
      if(new Date(element.date).setHours(0,0,0,0) <= today) {
          return addDay(element, i++);
      }
    });
  }

  function addDay(day, index) {
    let isHoliday = false;
    if (
      ApplicationState.getHolidays().some(
        (x) =>
          new Date(x.date).toLocaleDateString() ===
          new Date(day.date).toLocaleDateString()
      )
    ) {
      isHoliday = true;
    }

    let projects2 = [];
    let activities = [];
    let timeEntriesForDate = ApplicationState.getTimeEntriesForDate(
      timeEntries,
      day.date
    ).sort((a, b) => {
      if (a.activity.project.name > b.activity.project.name) {
        return 1;
      }
      if (a.activity.project.name < b.activity.project.name) {
        return -1;
      }
      if (a.activity.name > b.activity.name) {
        return 1;
      }
      if (a.activity.name < b.activity.name) {
        return -1;
      }
      return 0;
    });
    timeEntriesForDate.map((timeEntry) => {
      if (!projects2.some((foo) => foo.id === timeEntry.activity.project.id)) {
        projects2.push(timeEntry.activity.project);
      }
      if (!activities.some((foo) => foo.id === timeEntry.activity.id)) {
        activities.push(timeEntry.activity);
      }
    });

    if (timeEntriesForDate.length < 1) {
      return;
    }

    return (
      <Box key={day.name + index}>
        <List style={{ padding: "0px", color: "white" }}>
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            style={{ backgroundColor: "#93264a", padding: "16px" }}
          >
            <ListItemText>
              {(day.name.includes("Saturday") ||
                day.name.includes("Sunday") ||
                isHoliday) && (
                <Box style={{ color: "#d8d8d8" }}>
                  <Box style={{ fontSize: "x-large" }}>{day.name}</Box>
                </Box>
              )}
              {!day.name.includes("Saturday") &&
                !day.name.includes("Sunday") &&
                !isHoliday && (
                  <Box>
                    <Box style={{ fontSize: "x-large" }}>{day.name}</Box>
                  </Box>
                )}
            </ListItemText>

            <Box
              style={{
                fontSize: "x-large",
                fontWeight: "500",
                paddingLeft: "12px",
              }}
            >
              {DateTimeHelper.getHourMinuteStringFromHours(day.duration)}
            </Box>
          </ListItem>
        </List>

        <Divider></Divider>
        {activities.map((activity) => {
          let activitiesForProject = activities.filter(
            (x) => x.project.id === activity.project.id
          );
          return addActivityForDayMobileView(
            activity,
            timeEntriesForDate,
            activitiesForProject.length,
            day
          );
        })}
      </Box>
    );
  }

  function addActivityForDayMobileView(
    activity,
    timeEntriesForDate,
    activitiesForProject,
    day
  ) {
    return (
      <Box>
        <List style={{ padding: "0px" }}>
          <ListItem
            key={ApplicationState.generateGuid()}
            button
            style={{ backgroundColor: "#f6f6f6", padding: "16px" }}
            onClick={async () =>
              await editTimeEntrySummary(
                new Date(day.date),
                activity,
                timeEntriesForDate.filter(x => x.activity.id === activity.id),
                true
              )
            }
          >
            <ListItemText
              style={{
                paddingRight: "16px",
                wordBreak: "break-word",
                fontSize: "larger",
              }}
            >
              <strong style={{ fontSize: "larger" }}>
                {activity.project.name}
              </strong>
                <React.Fragment>
                  <br />
                  <i style={{ fontSize: "larger" }}>{activity.name}</i>
                </React.Fragment>
            </ListItemText>
            

            <strong style={{ fontSize: "larger" }}>
              {getActivityDuration(activity, timeEntriesForDate)}
            </strong>
          </ListItem>
        </List>
        <Divider></Divider>
        {addTimeEntriesForActivityOnDay(activity, timeEntriesForDate)}
      </Box>
    );
  }

  function addTimeEntriesForActivityOnDay(activity, timeEntriesForDate) {
    return timeEntriesForDate
      .filter((x) => x.activity.id === activity.id && x.userData)
      .sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1))
      .map((timeEntry) => {
        return (
          <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            {addTimeEntry(timeEntry)}
            <Divider />
          </List>
        );
      });
  }

  async function ignoreEventNotInReport(item) {
    var result = await ApplicationState.ignoreUserDataOnce(item.object.id);
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete user data with id: " + item.object.id
      );
      return;
    }
    const index = userDataItems.indexOf(item);
    if (index > -1) {
      userDataItems.splice(index, 1);
    }
    setUserDataItems(userDataItems.slice());
  }

  async function deleteTimeEntry(entry) {
    if (timerReportSent) {
      return;
    }
    var result = await ApplicationState.deleteTimeEntry(entry.id);
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete time entry with id: " + entry.id
      );
      return;
    }
    const index = timeEntries.indexOf(entry);
    if (index > -1) {
      timeEntries.splice(index, 1);
    }
    setTimeEntries(timeEntries.slice());
    handleUserDataItemsWhenDelitingTimeEntries(entry);
  }

  async function deleteTimeEntriesForDate(date) {
    if (timerReportSent) {
      return;
    }
    var result = await ApplicationState.deleteTimeEntriesForUserOnDate(
      ApplicationState.getState().user.email,
      new Date(date).toLocaleDateString("sv-SE")
    );
    if (result != "Ok") {
      alert("Error occured when trying to delete time entries for: " + date);
      return;
    }
    let timeEntriesToRemove = [];
    timeEntries.forEach((entry) => {
      if (
        new Date(entry.date).toDateString("sv-SE") ===
        new Date(date).toDateString("sv-SE")
      ) {
        timeEntriesToRemove.push(entry);
      }
    });
    timeEntriesToRemove.forEach((entry) => {
      const index = timeEntries.indexOf(entry);
      if (index > -1) {
        timeEntries.splice(index, 1);
      }
      setTimeEntries(timeEntries.slice());
    });
  }

  async function deletedTimeEntriesForDateAndActivity() {
    await deleteTimeEntriesForDateAndActivity(ApplicationState.getTimeEntryToEdit().activity, new Date(ApplicationState.getTimeEntryToEdit().date));
    setOpenTimeEntryDialog(false);
  }
  async function deleteTimeEntriesForDateAndActivity(activity, date) {
    if (timerReportSent) {
      return;
    }
    var result =
      await ApplicationState.deleteTimeEntriesForUserAndActivityOnDate(
        ApplicationState.getState().user.email,
        new Date(date).toDateString("sv-SE"),
        activity.id
      );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete time entries for: " +
          date +
          " and activity " +
          activity.name
      );
      return;
    }
    let timeEntriesToRemove = [];
    timeEntries.forEach((entry) => {
      if (
        new Date(entry.date).toDateString("sv-SE") ===
          new Date(date).toDateString("sv-SE") &&
        entry.activity.id === activity.id
      ) {
        timeEntriesToRemove.push(entry);
      }
    });
    timeEntriesToRemove.forEach((entry) => {
      const index = timeEntries.indexOf(entry);
      if (index > -1) {
        timeEntries.splice(index, 1);
      }
      setTimeEntries(timeEntries.slice());
    });
  }

  function addTimeEntry(entry) {
    let text = entry.description;
    if (!text) {
      text = entry.activity.name;
    }
    return (
      <Box key={entry.id} style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <ListItem
          key={ApplicationState.generateGuid()}
          button
          onClick={() => editTimeEntry(entry)}
        >
          <ListItemIcon style={{ minWidth: "32px" }}>
            {getIconForTimeEntry(entry)}
          </ListItemIcon>
          <ListItemText
            style={{ paddingRight: "16px", wordBreak: "break-word" }}
          >
            <Grid item>
              <strong>{text}</strong>
              <br />
              {entry.userData.eventType === "attend" && (
                <i>
                  {"Attended "}
                  {DateTimeHelper.getTimeStringFromDate(entry.dateTime)}
                </i>
              )}
              {entry.userData.eventType != "attend" && (
              <i>{getTextForEventType(entry.userData.eventType)}{" "}{DateTimeHelper.getTimeStringFromDate(entry.dateTime)}</i>
              )}
            </Grid>
          </ListItemText>
        </ListItem>
      </Box>
    );
  }

  async function acceptSendReportWarning() {
    sendReport();
    handleCloseSendReportWarningDialog();
  }

  async function trySendReport() {
    let timeReports = ApplicationState.getState().timeReports;
    let timeReport = timeReports.find(
      (x) => x.id === timeReportSelected
    );

    let minutesToWork = 0;
    if (timeReport) {
      minutesToWork = await ApplicationState.getMinutesToWorkForTimeReport(
        timeReport.id
      );
    }
    let secondsToWork = minutesToWork * 60;
    let secondsWorked = ApplicationState.getDurationForTimeEntries(timeEntries);

    let warningText = undefined;
    if (secondsWorked < secondsToWork) {
      let seconds = secondsToWork - secondsWorked;
      warningText =
        "Your time report is missing " +
        DateTimeHelper.getHourMinuteStringFromHours(seconds) +
        "";
    } else if (secondsWorked === 0) {
      warningText = "Your time report is empty";
    }

    if (warningText && !timerReportHaveFile) {
      warningText += " and have no uploaded file";
    } else if (!warningText && !timerReportHaveFile) {
      warningText = "Your time report have no uploaded file";
    }

    if (warningText) {
      setWarningSendReportText(warningText);
      setOpenSendReportWarningDialog(true);
    } else {
      sendReport();
    }
  }

  async function sendReport() {
    setSendingReport(true);
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    let from = new Date(report.from);
    let to = new Date(report.to);
    let result = await ApplicationState.sendReport(
      ApplicationState.getState().user.email,
      from,
      to,
      commentField
    );
    if (result === "Ok") {
      setReportSent(true);
      setReportSentMessage(
        "Report sent for: " +
          from.toLocaleDateString() +
          " - " +
          to.toLocaleDateString()
      );
      await ApplicationState.loadTimeReportsForUser(
        from.toLocaleDateString("sv-SE"),
        to.toLocaleDateString("sv-SE")
      );
      await toogleSendReportButton();
      setSendingReport(false);
      ApplicationState.setLastSentTimeReportFromDate(new Date(from));
      history.push("/timereportsent");
    } else {
      setErrorOccured(true);
      setReportSentMessage("ERROR: " + result);
      setSendingReport(false);
    }
  }

  async function showTimeEntries() {
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    let from = new Date(report.from);
    let to = new Date(report.to);

    await ApplicationState.loadTimeEntriesForUser(
      from.toLocaleDateString("sv-SE"),
      to.toLocaleDateString("sv-SE")
    );
    setTimeEntries(ApplicationState.getState().timeEntries);
    await ApplicationState.loadTimeReportsForUser(
      from.toLocaleDateString("sv-SE"),
      to.toLocaleDateString("sv-SE")
    );
    await toogleSendReportButton();
  }

  async function showFlexTime() {
    let maxDate = new Date(2000, 1);
    if (!timeEntries) {
      return;
    }
    timeEntries.forEach((element) => {
      let date = new Date(element.date);
      if (date > maxDate) {
        maxDate = date;
      }
    });

    let cft = await ApplicationState.getCurrentFlexTime(
      ApplicationState.getState().user.email,
      maxDate.toLocaleDateString("sv-SE")
    );
    cft = cft * 60;
    let isNegative = false;
    if (cft < 0) {
      cft = Math.abs(cft);
      isNegative = true;
    }
    cft = DateTimeHelper.getHourMinuteStringFromHours(cft);
    if (isNegative) {
      cft = "-" + cft;
    } else if (cft === "0h") {
      cft = cft;
    } else {
      cft = "+" + cft;
    }
    setFlexTime(cft);
    setFlexTimeMaxDate(new Date(maxDate).toLocaleDateString("sv-SE"));

    let cft2 = await ApplicationState.getFlexTimeDifferenceForUser(
      ApplicationState.getState().user.email,
      maxDate.toLocaleDateString("sv-SE")
    );
    cft2 = cft2 * 60;
    let isNegative2 = false;
    if (cft2 < 0) {
      cft2 = Math.abs(cft2);
      isNegative2 = true;
    }
    cft2 = DateTimeHelper.getHourMinuteStringFromHours(cft2);
    if (isNegative2) {
      cft2 = "-" + cft2;
    } else if (cft2 === "0h") {
      cft2 = cft2;
    } else {
      cft2 = "+" + cft2;
    }
    setFlexTimeDifference(cft2);
  }

  function addWeekOption(week) {
    return (
      <option
        key={week.number}
        label={DateTimeHelper.getWeekName(week)}
        value={week.number}
        style={{ color: "#111" }}
      ></option>
    );
  }

  function addTimereportOption(report) {
    return (
      <option
        key={report.id}
        label={getLabelForReport(report)}
        value={report.id}
        style={{ color: "#111" }}
      ></option>
    );
  }

  function getLabelForReport(report) {
    let from = new Date(report.from);
    let to = new Date(report.to);
    let year = from.getFullYear();
    let month = from.getMonth();
    let firstDayInMonth = new Date(year, month, 1)
    let lastDayInMonth = DateTimeHelper.getLastDayInMonth(year, month);
    if(from.toLocaleDateString("sv-SE") === firstDayInMonth.toLocaleDateString("sv-SE") 
     && to.toLocaleDateString("sv-SE") === lastDayInMonth.toLocaleDateString("sv-SE")) {
      return DateTimeHelper.getMonthName(month) + " " + year;
    }
    return new Date(report.from).toLocaleDateString("sv-SE") + " - " + new Date(report.to).toLocaleDateString("sv-SE");
  }

  const handleChangedWeek = (event) => {
    setWeekNrSelected(event.target.value);
  };
  
  const handleChangedTimeReport = (event) => {
    setTimeReportSelected(event.target.value);
  }

  function navigateToTimeEntryView(dateToAddEntryFor, projectToAddEntryFor) {
    if (timerReportSent) {
      return;
    }
    ApplicationState.setTimeEntryToEdit(undefined);
    ApplicationState.setDateToAddTimeEntryFor(undefined);
    ApplicationState.setProjectToAddTimeEntryFor(undefined);
    ApplicationState.setUserDataItemToAddTimeEntryFor(undefined);
    ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(undefined);
    ApplicationState.setInSummaryModeTimeEntry(false);
    ApplicationState.setShowDateWhenAddingTimeEntry(true);
    if (listMode === "Simple") {
      ApplicationState.setShowDateWhenAddingTimeEntry(false);
    }
    ApplicationState.setDateToAddTimeEntryFor(
      new Date(dateToAddEntryFor).toLocaleDateString("sv-SE")
    );
    if (!dateToAddEntryFor) {
      ApplicationState.setDateToAddTimeEntryFor(
        new Date().toLocaleDateString("sv-SE")
      );
    }
    if (projectToAddEntryFor) {
      ApplicationState.setProjectToAddTimeEntryFor(projectToAddEntryFor);
    }
    setOpenTimeEntryDialog(true);
  }

  function editTimeEntry(timeEntryToEdit) {
    if (timerReportSent || sendingReport) {
      return;
    }

    ApplicationState.setTimeEntryToEdit(timeEntryToEdit);
    ApplicationState.setUserDataItemToAddTimeEntryFor(undefined);
    ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(undefined);
    ApplicationState.setInSummaryModeTimeEntry(false);
    setOpenTimeEntryDialog(true);
  }

  async function editTimeEntrySummary(
    date,
    activity,
    timeEntriesForProject,
    showDateOnTimeEntry
  ) {
    if (timerReportSent || sendingReport) {
      return;
    }

    let summaryEntry = timeEntries.find(
      (x) =>
        x.summaryEntry === true &&
        x.activity.id === activity.id &&
        new Date(x.date).toLocaleDateString("sv-SE") ===
          new Date(date).toLocaleDateString("sv-SE")
    );
    if (!summaryEntry) {
      await createNewSummaryTimeEntry(activity, date);
      summaryEntry = timeEntries.find(
        (x) =>
          x.summaryEntry === true &&
          x.activity.id === activity.id &&
          new Date(x.date).toLocaleDateString("sv-SE") ===
            new Date(date).toLocaleDateString("sv-SE")
      );
    }

    if (!summaryEntry) {
      alert("Error! Summary Entry is missing!");
      return;
    }

    let durationForTimeEntriesOnProject = 0;
    timeEntriesForProject.forEach((element) => {
      if (element.id != summaryEntry.id) {
        durationForTimeEntriesOnProject += element.durationMinutes;
      }
    });
    ApplicationState.setTimeEntryToEdit(summaryEntry);
    ApplicationState.setUserDataItemToAddTimeEntryFor(undefined);
    ApplicationState.setDurationForTimeEntriesNotInSummaryEntry(
      durationForTimeEntriesOnProject
    );
    ApplicationState.setInSummaryModeTimeEntry(true);
    ApplicationState.setShowDateWhenAddingTimeEntry(showDateOnTimeEntry);
    setOpenTimeEntryDialog(true);
  }

  async function getSummaryTimeEntryForActivityAndDate(activity, date, timeEntriesForActivity){
    let summaryEntry = timeEntriesForActivity.find(
      (x) =>
        x.summaryEntry === true &&
        x.activity.id === activity.id &&
        new Date(x.date).toLocaleDateString("sv-SE") ===
          new Date(date).toLocaleDateString("sv-SE")
    );
    if (!summaryEntry) {
      let dto = await createNewSummaryTimeEntry(activity, date);
      summaryEntry = dto;
    }
    return summaryEntry;
  }
  async function createNewSummaryTimeEntry(activity, date) {
    let dto = new TimeEntryDto(
      ApplicationState.generateGuid(),
      activity,
      undefined,
      new Date(date),
      0,
      new Date(Date.now()),
      ApplicationState.getState().user,
      undefined,
      new Date(new Date(date).toUTCString()),
      undefined,
      false,
      false,
      false,
      false,
      0,
      true,
      0,
      0
    );
    await ApplicationState.saveNewTimeEntry(dto);
    timeEntries.push(dto);
    return dto;
  }

  async function toogleSendReportButton() {
    let timeReports = ApplicationState.getState().timeReports;
    let timeReport = timeReports.find(
      (x) => x.id === timeReportSelected
    );

    setTimerReportForSelectedMonth(timeReport);
    if (!timeReport) {
      setTimeReportHaveFile(false);
      setTimeReportSent(false);
      setTimeReportFiles([]);
      let from = new Date(timeReport.from);
      let to = new Date(timeReport.to);
      let timeReport = new TimeReportDTO(
        ApplicationState.generateGuid(),
        ApplicationState.getState().user,
        new Date(from).toLocaleDateString("sv-SE"),
        new Date(to).toLocaleDateString("sv-SE"),
        new Date(),
        null,
        null,
        null,
        null,
        null
      );
      await ApplicationState.saveNewTimeReport(timeReport);
      await ApplicationState.loadTimeReportsForUser();
      timeReport = ApplicationState.getState().timeReports.find(x => new Date(from).toLocaleDateString("sv-SE") === new Date(x.from).toLocaleDateString("sv-SE") && new Date(to).toLocaleDateString("sv-SE") === new Date(x.to).toLocaleDateString("sv-SE"));
      setTimerReportForSelectedMonth(timeReport);
    }
    if (timeReport) {
      let files = await ApplicationState.getTimeReportFilesForTimeReport(timeReport.id);
      setTimeReportFiles(files);
      if (files && files.length > 0) {
        setTimeReportHaveFile(true);
      } else {
        setTimeReportHaveFile(false);
      }
      if (timeReport.sent) {
        setTimeReportSent(true);
      } else {
        setTimeReportSent(false);
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setReportSent(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOccured(false);
  };

  async function deleteFile(fileExtension, fileId) {
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    //TODO: Se till att ta bort filen
    var result = await ApplicationState.deleteFileForTimeReport(
      ApplicationState.getState().user.email,
      new Date(report.from).getFullYear(),
      new Date(report.from).getMonth(),
      fileExtension,
      fileId
    );
    await ApplicationState.deleteTimeReportFile(fileId);
    setTimeReportHaveFile(false);
    await toogleSendReportButton();
  }

  const addTimeEntriesFooter = () => {
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    let from = new Date(report.from);
    return (
      <Box style={{ padding: "16px", backgroundColor: "#93264a" }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box p={1}>
            <PrimaryButton
              icon="add"
              text="Add time"
              buttonClick={() => navigateToTimeEntryView(from)}
            ></PrimaryButton>
          </Box>
        </Grid>
      </Box>
    );
  };

  const addAddTimeButton = () => {
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    let from = new Date(report.from);
    return (
            <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    size="large"
                    onClick={() => {
                      navigateToTimeEntryView(from)
                    }}
                    style={{marginTop: "0px", marginBottom: "0px"}}
                  >Add Time</Button>
    );
  };

  function addLoadingItemsBox() {
    return (
      <Box p={2}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingBottom: "8px" }}
        >
          <CircularProgress></CircularProgress>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Typography variant="body1">Loading items... </Typography>
        </Grid>
      </Box>
    );
  }

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const addMobileTabContainer = () => {
    return (
        <Box p={0}>
          <TabContext value={selectedTab}>
            <AppBar position="static">
              <TabList
                onChange={handleChangeTab}
                aria-label="simple tabs example"
                style={{ backgroundColor: "#53001B" }}
                sx={{ color: "red" }}
                variant="fullWidth"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <CustomTab
                  label={userDataItems.filter(x => new Date(x.dateTime) < new Date()).length + " " + "EVENTS NOT IN TIME REPORT"}
                  value="events"
                  style={{ backgroundColor: "#53001B" }}
                />
                <CustomTab
                  label="TIME ENTRIES"
                  value="timeentries"
                  style={{ backgroundColor: "#53001B" }}
                />
              </TabList>
            </AppBar>
            <CustomTabPanel value="events">
              <Box p={0}>
                {loadingTimereport && (
                  <React.Fragment>{addLoadingItemsBox()}</React.Fragment>
                )}
                {!loadingTimereport && (
                  <React.Fragment>
                    {addEventsNotInTimeReport2("0px", "#93264A", false)}
                  </React.Fragment>
                )}
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value="timeentries">
              <Box p={0}>
                {loadingTimereport && (
                  <React.Fragment>{addLoadingItemsBox()}</React.Fragment>
                )}
                {!loadingTimereport && (
                  <React.Fragment>
                    {addWeeks()}
                  </React.Fragment>
                )}
              </Box>
            </CustomTabPanel>
          </TabContext>
        </Box>
    );
  };

  const addWeeks = () => {
    if (!timeEntries || timeEntries.length < 1) {
      return (
        <Box p={2}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography>
              <i>No time have been added</i>
            </Typography>
            <Box style={{ paddingRight: "4px" }}></Box>
            <SentimentDissatisfiedIcon />
          </Grid>
        </Box>
      );
    }
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    return ApplicationState.getWeeks(
      timeEntries,
      weekNrSelected,
      ApplicationState.getReportingInterval() === "Week",
      new Date(report.from).getFullYear(),
      new Date(report.from).getMonth(),
      weeksForYear
    )
      .sort((a, b) => (new Date(a.startDate) < new Date(b.startDate) ? 1 : -1))
      .map((element) => {
        return addWeek(element);
      });
  };

  const handleChangeOfWeekTab = (event) => {
    setSelectedWeekNrTab(parseInt(event.target.value));
  };

  const addWeeksForDesktopMode = () => {
    if (!timeEntries) {
      return;
    }
    let report = ApplicationState.getState().timeReports.find(
      (x) => x.id === timeReportSelected
    );
    let sortedWeeks = ApplicationState.getWeeks(
      timeEntries,
      weekNrSelected,
      ApplicationState.getReportingInterval() === "Week",
      new Date(report.from).getFullYear(),
      new Date(report.from).getMonth(),
      weeksForYear
    ).sort((a, b) => (a.startDate > b.startDate ? 1 : -1));
    return sortedWeeks.map((week) => {
      if (week.number === selectedWeekNrTab) {
        return addWeekForDesktopMode_Week(week, sortedWeeks);
      }
    });
  };

  function closeListMenu(){
    setAnchorEl(null);
  }

  async function reGenerateTimeReport(){
    setLoadText("Re-generating timereport for " + new Date(timerReportForSelectedMonth.from).toLocaleDateString("sv-SE") + " to " + new Date(timerReportForSelectedMonth.to).toLocaleDateString("sv-SE"));
    setLoadingTimereport(true);
    
    await ApplicationState.reGenerateTimeReport(timerReportForSelectedMonth.id);
    await ApplicationState.loadTimeReportsForUser();
      
    await loadTimeEntriesAndUserDataItems();  
    setLoadingTimereport(false);
    setLoadText("Loading...");
  }

  async function clearTimeReport(){
    setLoadText("Removing all time and events for timereport for " + new Date(timerReportForSelectedMonth.from).toLocaleDateString("sv-SE") + " to " + new Date(timerReportForSelectedMonth.to).toLocaleDateString("sv-SE"));
    setLoadingTimereport(true);

    await ApplicationState.clearTimeReport(timerReportForSelectedMonth.id);
    await ApplicationState.loadTimeReportsForUser();
    
    await loadTimeEntriesAndUserDataItems();  
    setLoadingTimereport(false);
    setLoadText("Loading...");
  }

  function addOverviewBox() {
    return (
      <Grid
        item
        component={Card}
        xs
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          margin: { xs: "0px", md: "8px"},
          marginBottom: "0px",
          marginTop: "8px",
        }}
      >
        <Box p={3}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box>
                  {ApplicationState.getReportingInterval() === "Week" && (
                    <FormControl variant="outlined">
                      <Select
                        native
                        style={{
                          margin: "8px",
                          minWidth: "80px",
                          backgroundColor: "#fff",
                          fontWeight: "500",
                          fontSize: "1.45rem",
                        }}
                        value={weekNrSelected}
                        onChange={handleChangedWeek}
                      >
                        {weeksForYear.map((week) => {
                          return addWeekOption(week);
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Box>
                
                {ApplicationState.getState().timeReports && (
                  <FormControl variant="outlined">
                  <Select
                    native
                    style={{
                      margin: "8px",
                      minWidth: "80px",
                      backgroundColor: "#fff",
                      fontWeight: "500",
                      fontSize: "1.45rem",
                    }}
                    value={timeReportSelected}
                    onChange={handleChangedTimeReport}
                    >
                        {ApplicationState.getState().timeReports
                        .sort((a, b) => (new Date(a.from) < new Date(b.from) ? 1 : -1))
                        .map((report) => {
                          return addTimereportOption(report);
                        })}
                    </Select>
                    </FormControl>
                )}
                
                <Box>
                  {!reportSent && ( 
                    <React.Fragment>
                      <IconButton color="primary" onClick={(event) => setAnchorEl(event.currentTarget)} size="large">
                    <MoreVertIcon />
                  </IconButton>
                    
                    <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openListMenu}
                          onClose={closeListMenu}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                  <MenuList>
                  <MenuItem onClick={() => {
                      closeListMenu();
                      history.push("/keywordsuggestions");
                      }}>
                      <ListItemIcon>
                        <AssistantIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Suggest keywords</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={timeReportIsClosed()} onClick={() => {
                      closeListMenu();
                      reGenerateTimeReport();
                      }}>
                      <ListItemIcon>
                        <AutoFixHighIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Re-generate time report</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={timeReportIsClosed()} onClick={() => {
                      closeListMenu();
                      clearTimeReport();
                    }}>
                      <ListItemIcon>
                        <DeleteForeverIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Remove all time entries and events</ListItemText>
                    </MenuItem>
                  </MenuList>
                </Menu>
                </React.Fragment>
                  )}
                </Box>

                {listMode === "Simple" && (
                  <Box style={{margin: "8px"}}>
                  {addAddTimeButton()}
                  </Box>
                )}
              </Grid>
            </Box>
            <Box p={1}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              ></Grid>
            </Box>
          </Grid>
        </Box>

        <Divider />

        <List style={{ paddingBottom: "0px", paddingTop: "0px" }}>
          {getListItemsForProjects()}
        </List>

        <Box
          p={0}
          style={{
            backgroundColor: "#111",
            color: "#fff",
            position: "relative",
            top: "1px",
            marginTop: "auto"
          }}
          bottom
        >
          {ApplicationState.getUseFlexTime() && ApplicationState.getUser().useFlexTime && showFlexBalanceInGui() && (
            <Grid container justifyContent="space-between">
              <Grid
                item
                xs={6}
                style={{ backgroundColor: "#444", padding: "16px" }}
              >
                <Box p={1}>
                  <Typography variant="h6">
                    Flex {flexTime}
                    {"  "}
                    <Tooltip
                      title={
                        <Typography variant="subtitle1">
                          Flex time balance based on time entries up to and
                          including {flexTimeMaxDate}
                        </Typography>
                      }
                      aria-label="flex tooltip"
                    >
                      <InfoOutlinedIcon
                        style={{ verticalAlign: "super", fontSize: "large" }}
                      ></InfoOutlinedIcon>
                    </Tooltip>
                  </Typography>
                  <Typography>
                    Diff {flexTimeDifference}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end", padding: "29px" }}>
                <Box p={1}>
                  <Typography variant="h5">
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      ApplicationState.getDurationForTimeEntries(timeEntries)
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {(!ApplicationState.getUseFlexTime() || !ApplicationState.getUser().useFlexTime || !showFlexBalanceInGui()) && (
            <Grid container justifyContent="space-between">
              <Grid item xs={6} style={{ textAlign: "start", padding: "29px" }}>
                <Box p={1}>
                  <Typography variant="h6">Total</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "end", padding: "29px" }}>
                <Box p={1}>
                  <Typography variant="h5">
                    {DateTimeHelper.getHourMinuteStringFromHours(
                      ApplicationState.getDurationForTimeEntries(timeEntries)
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    );
  }

  function timeReportIsClosed() {  
    if (timerReportForSelectedMonth && (timerReportForSelectedMonth.approved || timerReportForSelectedMonth.sent)) {
      return true;
    }
    return false;
  }

  function showFlexBalanceInGui() {
    return true;
  }

  function addOverviewBoxForFileUploadAndSendReport() {
    return (
      <Grid
        item
        component={Card}
        xs
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          margin: { xs: "0px", md: "8px"},
          marginBottom: "0px",
          marginTop: { xs: "16px", md: "8px"},
        }}
      >
            {timerReportForSelectedMonth && (
              <React.Fragment>
                {addFileList()}
              </React.Fragment>
            )}

        {timerReportFiles.length <= 0 &&
          !timerReportSent &&
          !sendingReport && (
            <Typography variant="subtitle1" style={{ padding: "16px", paddingTop: "24px", textAlign: "center"}}>
              <i>Do you need to add an attachment?</i>
            </Typography>
          )}
        {timerReportSent && (
          <Typography variant="subtitle1" style={{ padding: "16px", paddingTop: "24px", textAlign: "center"}}>
            <i>It's no longer possible to edit attachments!</i>
          </Typography>
        )}
        <Box>
        {!timerReportSent && (
          <Grid container justifyContent="center" alignItems="center">
        <Button
          variant="text"
          color="primary"
          component="span"
          size="large"
          onClick={() => {
            setOpenTimeReportFileDialog(true)
          }}
          style={{marginTop: "12px", marginBottom: "12px"}}
        >Add attachment</Button></Grid>
        )}
        <Box
          style={{
            backgroundColor: "#781938",
            paddingTop: "24px",
          }}
          p={1}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >    
                <Box>
                  <Grid container justifyContent="center" alignItems="center">
                    {timerReportSent &&
                      timerReportForSelectedMonth &&
                      timerReportForSelectedMonth.sent && (
                        
                        <Box style={{padding: "29px", paddingRight: "0px"}}>
                          <Typography variant="h6" style={{ color: "#fff" }}>
                          Time report sent{" "}
                          {new Date(
                            timerReportForSelectedMonth.sent
                          ).toLocaleDateString()}
                        </Typography>
                        </Box>
                      )}
                  </Grid>
                </Box>
                <Box>
                  <Grid container justifyContent="center" alignItems="center">
                    {!timerReportSent && !sendingReport && (
                      
                        <Box
                          p={1}
                          style={{ marginLeft: "24px", marginRight: "24px" }}
                        >
                          <PrimaryButton
                            icon="send"
                            text="Send report"
                            buttonClick={() => trySendReport()}
                          ></PrimaryButton>
                        </Box>
                      
                    )}
                    {sendingReport && (
                      <Box style={{padding: "26px"}}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress color="secondary" />
                          <Box style={{ color: "white", paddingLeft: "12px" }}>
                            <i>sending report...</i>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                    {timerReportSent &&
                      timerReportForSelectedMonth &&
                      timerReportForSelectedMonth.sent && (
                        <Box p={1}>
                          <CheckCircleOutlineIcon
                            style={{ color: "#fff", fontSize: "xx-large" }}
                          ></CheckCircleOutlineIcon>
                        </Box>
                      )}
                  </Grid>
                </Box> 
          </Grid>
          </Box>
        </Box>
      </Grid>
    );
  }

function addFileList() {
  let counter = 1;
  
  return(
      <List style={{ padding: "0px", borderBottom: "2px solid #eee" }}>
      <Box style={{backgroundColor: "#f6f6f6", padding: "50px 48px 48px 24px", paddingLeft: "24px"}}>
        <Typography variant="h5">
          <b>Attachments</b>
        </Typography>
      </Box>
      <Divider />
    {timerReportFiles.map((file) => {
      let color = "#fff";
      if(counter % 2 === 0){
        color = "#f6f6f6"
      }
      counter++;
      let report = ApplicationState.getState().timeReports.find(
        (x) => x.id === timeReportSelected
      );
      let from = new Date(report.from);
      return( 
      
        <ListItem
            button
            style={{ backgroundColor: color, padding: "12px 24px" }}
          >
            <ListItemText
              style={{
                paddingRight: "16px",
                wordBreak: "break-word",
              }}
              onClick={() => 
                ApplicationState.getFileForTimeReport(
                  ApplicationState.getState().user.email,
                  from.getFullYear(),
                  from.getMonth(),
                  file.name.split(".").pop(),
                  file.id
                )}
            >
              <strong>
              {file.name}
              </strong>
              {file.type === "expenseToBeInvoiced" && (
                <React.Fragment>
                  <br />
                  Expense to be invoiced
                </React.Fragment>
              )}
              {file.type === "reportFromOtherSystem" && (
                <React.Fragment>
                  <br />
                  Report from other system
                </React.Fragment>
              )}
              {file.amountExcludingVAT && file.amountExcludingVAT > 0 && (
                <React.Fragment>
                  {" with "}{file.amountExcludingVAT} SEK Ex VAT
                </React.Fragment>
              )}
              {file.amountIncludingVAT && file.amountIncludingVAT > 0 && (
                <React.Fragment>
                  {" and "}{file.amountIncludingVAT} SEK Inc VAT
                </React.Fragment>
              )}
              {file.comment && file.comment.length > 1 && (
                <React.Fragment>
                  <br />
                  <i>{file.comment}</i>
                </React.Fragment>
              )}
            </ListItemText>
         {!timerReportSent && !sendingReport && (               
           <IconButton
             variant="contained"
             color="primary"
             onClick={() => deleteFile(file.name.split(".").pop(), file.id)}
             size="large"
           >
             <DeleteForeverIcon></DeleteForeverIcon>
           </IconButton>)}
          </ListItem>
      )
    })}
    </List>
    
  ) 
}

  async function deletedTimeEntry() {
    let timeEntryDeleted = timeEntries.find(
      (x) => x.id === ApplicationState.getTimeEntryToEdit().id
    );
    const index = timeEntries.indexOf(timeEntryDeleted);
    if (index > -1) {
      timeEntries.splice(index, 1);
    }
    setTimeEntries(timeEntries.slice());
    handleUserDataItemsWhenDelitingTimeEntries(timeEntryDeleted);
    setOpenTimeEntryDialog(false);
    await showFlexTime();
  }

  async function ignoredUserData() {
    let timeEntryDeleted = userDataItems.find(
      (x) => x.object.id === ApplicationState.getUserDataItemToAddTimeEntryFor().object.id
    );
    const index = userDataItems.indexOf(timeEntryDeleted);
    if (index > -1) {
      userDataItems.splice(index, 1);
    }
    setUserDataItems(userDataItems.slice());
    setOpenTimeEntryDialog(false);
    await showFlexTime();
  }

  const handleUserDataItemsWhenDelitingTimeEntries = (timeEntryDeleted) => {
    if (timeEntryDeleted.userData) {
      let userDataItem = userDataItemsAll.find(
        (x) => x.id === timeEntryDeleted.userData.dataObjectId
      );
      if (userDataItem) {
        userDataItems.push(userDataItem);
      }
    }
  };

  const handleCloseTimeEntryDialog = () => {
    setOpenTimeEntryDialog(false);
  };

  const handleCloseTimeEntryKeywordSuggestionDialog = () => {
    setOpenTimeEntryKeywordSuggestionDialog(false);
  };

  const handleCloseSendReportWarningDialog = () => {
    setOpenSendReportWarningDialog(false);
  };

  const handleCloseTimeReportFileDialog = () => {
    toogleSendReportButton();
    setOpenTimeReportFileDialog(false);
  };

  async function addedTimeEntry() {
    timeEntries.push(ApplicationState.getTimeEntryToEdit());
    setOpenTimeEntryDialog(false);
    await showFlexTime();
  }

  function editedTimeEntry() {
    let newTimeEntries = timeEntries.slice();
    let timeEntryEdited = timeEntries.find(
      (x) => x.id === ApplicationState.getTimeEntryToEdit().id
    );
    const index = newTimeEntries.indexOf(timeEntryEdited);
    if (index > -1) {
      newTimeEntries.splice(index, 1);
    }
    newTimeEntries.push(ApplicationState.getTimeEntryToEdit());

    if (ApplicationState.getUserDataItemToAddTimeEntryFor()) {
      let newUserDataItems = userDataItems.slice();
      let userDataItemDeleted = userDataItems.find(
        (x) => x.id === ApplicationState.getUserDataItemToAddTimeEntryFor().id
      );
      const index = newUserDataItems.indexOf(userDataItemDeleted);
      if (index > -1) {
        newUserDataItems.splice(index, 1);
      }
      setUserDataItems(newUserDataItems);
    }

    setTimeEntries(newTimeEntries);
    setOpenTimeEntryDialog(false);
  }

  const editTimeForTimeEntry = async (activity, day, timeEntriesForActivity, minutes) => {
    if (timerReportSent) {
      return;
    }
    let timeEntry = await getSummaryTimeEntryForActivityAndDate(activity, new Date(day), timeEntriesForActivity);
    timeEntry.durationMinutes += minutes;
    timeEntry.date = new Date(
      new Date(timeEntry.date).toLocaleDateString("sv-SE")
    );
    timeEntry.dateTime = new Date(new Date(timeEntry.dateTime).toUTCString());
    await ApplicationState.saveUpdatedTimeEntry(timeEntry);
    ApplicationState.setTimeEntryToEdit(timeEntry);
    editedTimeEntry();
  };

  const editedTimeEntryShowKeywordSuggestions = () => {
    editedTimeEntry();
    setOpenTimeEntryKeywordSuggestionDialog(false);
    setOpenTimeEntryKeywordSuggestionDialog(true);
  };

  const changeTabWeekday = (event, newValue) => {
    setTabWeekday(newValue);
  };

function getTextForEventType(type){
  switch(type){
    case "commit":
      return "Commited";
    case "send":
      return "Sent";
    case "attend":
      return "Attended";
    case "create":
      return "Created";
    case "edit":
      return "Edited";
    case "complete":
      return "Completed";
    case "comment":
      return "Commented";
    default:
      return "?";
  }
}

  function addEventsNotInTimeReport2(margin, bgColor, addHeader) {
    let fontColor = "#fff";
    if(bgColor === "#d8d8d8"){
      fontColor = "#111";
    }
    let counter = 1;
    let dates = [];
    userDataItems.filter(x => new Date(x.dateTime) < new Date()).sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1)).forEach((element) => {
      if(!dates.some(x => x === element.dateTime.toLocaleDateString("sv-SE"))){
        dates.push(element.dateTime.toLocaleDateString("sv-SE"));
      }
    });
  return(
     <List style={{ padding: "0px", borderBottom: "2px solid #eee", margin: margin }}>
      {addHeader === true && (
        <Box style={{ backgroundColor: "#93264A", color: "#fff", padding: "16px"}}>
          <Typography variant="h6">
            {userDataItems.filter(x => new Date(x.dateTime) < new Date()).length}{" "} events not in time report
          </Typography>
        </Box>
      )}
     {dates.map((date) => {
      return(
        <React.Fragment>
        <Box style={{ backgroundColor: bgColor, color: fontColor, fontSize: "x-large", padding: "16px"}}>
       <Typography variant="h6">
        {DateTimeHelper.getDayNameFromWeekday(new Date(date).getDay())}{" "}
              {new Date(date).getDate()}{" "}
              {DateTimeHelper.getMonthName(new Date(date).getMonth())} 
       </Typography>    
     </Box>
     
     {userDataItems.filter(x => x.dateTime.toLocaleDateString("sv-SE") === date).sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1)).map((item) => {
      let color = "#fff";
      if(counter % 2 === 0){
        color = "#f6f6f6"
      }
      counter++;
      return( 
      
        <ListItem
            button
            style={{ backgroundColor: color, padding: "16px" }}
          >
            <ListItemIcon>
             {getIconForUserDataItem(item.type)}
           </ListItemIcon>
            <ListItemText
              style={{
                paddingRight: "16px",
                wordBreak: "break-word",
              }}
              onClick={() => showDialogForAddingTimeEntry(item)}
            >
              <strong>
              {item.title}
              </strong>
              <br />
              <i>
              {getTextForEventType(item.eventType)}
              {" "}
              {DateTimeHelper.getTimeStringFromDate(item.dateTime)}
              {item.eventType === "attend" && (
               <React.Fragment>
                 {" for "}{DateTimeHelper.getHourMinuteStringFromHours(item.duration * 60)}
               </React.Fragment>
              )}
              </i>
            </ListItemText>
            <Box>
              <IconButton size="large">
                <DeleteForeverIcon
                  color="primary"
                  onClick={() => ignoreEventNotInReport(item)}
                ></DeleteForeverIcon>
              </IconButton>
            </Box>
          </ListItem>
      )
    })}</React.Fragment>
      );
     })}
   </List>
   );
  }

  function userHaveProjects() {
    if(!ApplicationState.getState().projects) {
      return false;
    }
    if(ApplicationState.getState().projects.length < 1) {
      return false;
    }
    if(!ApplicationState.getUser().firstWorkingDay) {
      return false;
    }
    return true;
  }

  function userHaveTimeReports() {
    if(!ApplicationState.getState().timeReports || ApplicationState.getState().timeReports.length < 1) {
      return false;
    }
    return true;
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={reportSent}
          autoHideDuration={6000}
          onClose={handleClose}
          message={reportSentMessage}
        ></Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={errorOccured}
          autoHideDuration={6000}
          onClose={handleCloseError}
          message={reportSentMessage}
        ></Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={reportSent}
          autoHideDuration={6000}
          onClose={handleClose}
          message={reportSentMessage}
        ></Snackbar>
        {(loadingTimereport) && (
          <Box>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{ marginBottom: "24px", marginTop: "24px" }}
            >
              <Box style={{height: "240px", width: "240px"}}>
              <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{height: "240px", width: "240px"}}
            >
                
                <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column">
                <CircularProgress size={60}
                                  color="secondary"
                                  style={{ paddingLeft: "4px" }}>
                                    
                </CircularProgress>
                
                <Typography style={{marginTop: "12px", color: "white"}}>
                {loadText}
                </Typography>
                
                </Grid>
                </Grid>
              </Box>
          </Grid>
          </Box>
        )}
        {!loadingTimereport && !userHaveProjects() && (
          <Box>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{ marginBottom: "24px", marginTop: "24px" }}
            >
              <Box style={{height: "240px", width: "300px"}}>
              <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{height: "240px", width: "300px"}}
            >
                
                <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column">
                
                <Typography variant="h5" style={{marginTop: "0px", color: "white"}}>
                  <center>
                  Time reports can't be added because you have no projects assigned or your first working day have not been configured. <br /><br />Talk to your admin to fix this.
                  </center>
                </Typography>
                </Grid>
                </Grid>
              </Box>
          </Grid>
          </Box>
        )}
        {!loadingTimereport && !userHaveTimeReports() && (
          <Box>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{ marginBottom: "24px", marginTop: "24px" }}
            >
              <Box style={{height: "240px", width: "300px"}}>
              <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{height: "240px", width: "300px"}}
            >
                
                <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column">
                
                <Typography variant="h5" style={{marginTop: "0px", color: "white"}}>
                  <center>
                  You have no time reports to edit.<br /><br />Talk to your admin to fix this.
                  </center>
                </Typography>
                </Grid>
                </Grid>
              </Box>
          </Grid>
          </Box>
        )}
        {!loadingTimereport && onboardingMode && (
          <React.Fragment>
            {addEventsNotInTimeReport2("8px", "#d8d8d8", true)}</React.Fragment>
        )}
        {useMediaQuery(useTheme().breakpoints.down("lg")) && !loadingTimereport && userHaveProjects() && !onboardingMode &&
          listMode === "Simple" && userHaveTimeReports() &&(
            <Box>
              {addOverviewBox()}
              {addOverviewBoxForFileUploadAndSendReport()}
              <Box marginBottom={2} marginTop={2}>
                <Card style={{ margin: "8px" }}>
                </Card>
              </Box>
            </Box>
          )}
        {useMediaQuery(useTheme().breakpoints.up("lg")) && !loadingTimereport && userHaveProjects() && !onboardingMode &&
          listMode === "Simple" && userHaveTimeReports() &&(
            <Box>
              <Grid
                container
                alignItems="stretch"
                justifyContent="space-between"
                direction="row"
                style={{ marginBottom: "24px", marginTop: "24px" }}
              >
                {addOverviewBox()}
                {addOverviewBoxForFileUploadAndSendReport()}
              </Grid>
              <Box marginBottom={2} marginTop={2}>
                <Card style={{ margin: "8px" }}>
                </Card>
              </Box>
            </Box>
          )}
        {useMediaQuery(useTheme().breakpoints.down("lg")) && !loadingTimereport && userHaveProjects() && !onboardingMode &&
          listMode != "Simple" && userHaveTimeReports() &&(
            <Box>
              {addOverviewBox()}
              {addOverviewBoxForFileUploadAndSendReport()}
              <Box marginBottom={2} marginTop={2}>
                <Card sx={{ margin: { xs: "0px", md: "8px"},}}>
                  {addMobileTabContainer()}
                  {addTimeEntriesFooter()}
                </Card>
              </Box>
            </Box>
          )}
        {useMediaQuery(useTheme().breakpoints.up("lg")) && !loadingTimereport && userHaveProjects() && !onboardingMode &&
          listMode != "Simple" && userHaveTimeReports() && (
            <Box>
              <Grid
                container
                alignItems="stretch"
                justifyContent="space-between"
                direction="row"
                style={{ marginBottom: "24px", marginTop: "24px" }}
              >
                {addOverviewBox()}
                {addOverviewBoxForFileUploadAndSendReport()}
              </Grid>
              {ApplicationState.getReportingInterval() === "Month" && (
                <React.Fragment>{addWeeksForDesktopMode()}</React.Fragment>
              )}
              {ApplicationState.getReportingInterval() === "Week" && (
                <React.Fragment>{addWeeksForDesktopMode()}</React.Fragment>
              )}
              {userDataItems && userDataItems.length > 0 && (
                <React.Fragment>{addEventsNotInTimeReport2("8px", "#d8d8d8", true)}</React.Fragment>
              )}
            </Box>
          )}

        <Dialog
          open={openTimeEntryDialog}
          onClose={handleCloseTimeEntryDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <TimeEntryView
            deletedTimeEntry={deletedTimeEntry}
            addedTimeEntry={addedTimeEntry}
            editedTimeEntry={editedTimeEntry}
            cancelClick={handleCloseTimeEntryDialog}
            editedTimeEntryShowKeywordSuggestions={
              editedTimeEntryShowKeywordSuggestions
            }
            ignoredUserData={ignoredUserData}
            deletedTimeEntriesForDateAndActivity={deletedTimeEntriesForDateAndActivity}
          ></TimeEntryView>
        </Dialog>
        <Dialog
          open={openTimeEntryKeywordSuggestionDialog}
          onClose={handleCloseTimeEntryKeywordSuggestionDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <TimeEntryKeywordView
            cancelClick={handleCloseTimeEntryKeywordSuggestionDialog}
          ></TimeEntryKeywordView>
        </Dialog>
        <Dialog
          open={openSendReportWarningDialog}
          onClose={handleCloseSendReportWarningDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            {warningSendReportText}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure that you want to send it anyway?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={acceptSendReportWarning}>Yes</Button>
            <Button onClick={handleCloseSendReportWarningDialog} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openTimeReportFileDialog}
          onClose={handleCloseTimeReportFileDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <TimeReportFileEditView
            addedTimeReportFile={handleCloseTimeReportFileDialog}
            cancelClick={handleCloseTimeReportFileDialog}
            timeReportToAddFileTo={timerReportForSelectedMonth}
          ></TimeReportFileEditView>
        </Dialog>
        {!loadingTimereport && userHaveProjects() && userHaveTimeReports() && (
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            style={{ color: "White", padding: "8px", paddingBottom: "16px"}}
          >
            Back to top
          </Link>
          </Grid>  
        )}
      </Grid>
    </Grid>
  );
}

export default TimeReportView;

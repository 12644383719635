import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from "../Components/PrimaryButton";
import { DeleteButton } from "./DeleteButton";
import SkillDto from "../Dtos/SkillDto";
import { styled } from "@mui/material/styles";
import { InputLabel, Select } from "@mui/material";

const InputLabelSelect = styled(InputLabel)({
  paddingLeft: "8px",
  position: "relative",
  backgroundColor: "#fff",
  marginLeft: "4px",
  top: "32px",
  width: "75px",
});

function SkillCrudView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [categoryField, setCategoryField] = useState(undefined);
  const [synonymsField, setSynonymsField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (ApplicationState.getSkillToEdit()) {
      setEditMode(true);
      setNameField(ApplicationState.getSkillToEdit().name);
      setCategoryField(ApplicationState.getSkillToEdit().category);
      setSynonymsField(ApplicationState.getSkillToEdit().synonyms);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new SkillDto(
      ApplicationState.generateGuid(),
      nameField,
      categoryField,
      synonymsField,
      new Date(Date.now())
    );
    if (ApplicationState.getSkillToEdit()) {
      dto.id = ApplicationState.getSkillToEdit().id;
      await ApplicationState.saveUpdatedSkill(dto);
      props.editedSkill();
    } else {
      await ApplicationState.saveNewSkill(dto);
      props.addedSkill();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteSkill(
      ApplicationState.getSkillToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete skill with id: " +
          ApplicationState.getSkillToEdit().id
      );
      return;
    }
    props.deletedSkill();
  }

  const getHeaderText = () => {
    let headerText = "Add skill";
    if (editMode) {
      headerText = "Edit skill";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{ margin: "8px", minWidth: "300px" }}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="synonymsTextField"
          label="Synonyms"
          type="text"
          style={{ margin: "8px", minWidth: "300px" }}
          onChange={(event) => setSynonymsField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={3}
          variant="outlined"
          value={synonymsField}
        />
        <br />
        <InputLabelSelect
          id="categorySelectLabel"
          htmlFor="categorySelect"
          variant="outlined"
          shrink={true}
        >
          Category
        </InputLabelSelect>
        <Select
          native
          onChange={(event) => {
            setCategoryField(event.target.value);
          }}
          id="categorySelect"
          variant="outlined"
          sx={{
            margin: "8px",
            fontWeight: "500",
            width: { xs: "300px" },
          }}
          value={categoryField}
        >
          <option key={"?"} label={"?"} value={"?"}></option>
          <option key={"Method/Process"} label={"Method/Process"} value={"Method/Process"}></option>;
          <option
            key={"Platform"}
            label={"Platform"}
            value={"Platform"}
          ></option>
          ;<option key={"Role"} label={"Role"} value={"Role"}></option>;
          <option
            key={"Technique"}
            label={"Technique"}
            value={"Technique"}
          ></option>
          ;
          <option key={"Tool/Product"} label={"Tool/Product"} value={"Tool/Product"}></option>;
        </Select>
        <br />
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{ backgroundColor: "#f6f6f6" }}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large"
            >
              <CloseIcon
                fontSize="medium"
                style={{ color: "#93264A" }}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              ></PrimaryButton>
              {editMode && <DeleteButton onClick={deleteClick}></DeleteButton>}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default SkillCrudView;

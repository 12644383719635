export default class HolidayDto {
  constructor(id, name, date, percentage, organization, created) {
    this.id = id;
    this.name = name;
    this.date = date;
    this.percentage = percentage;
    this.organization = organization;
    this.created = created;
  }
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { PrimaryButton } from './PrimaryButton';
import IgnoreKeywordDto from "../Dtos/IgnoreKeywordDto";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { DeleteButton } from "./DeleteButton";

function IgnoreKeywordEditView(props) {
  const [keywordField, setKeywordField] = useState(undefined);
  const [scopeField, setScopeField] = useState("word");
  const [editMode, setEditMode] = useState(false);
  const [userToAddKeywordFor, setUserToAddKeywordFor] = useState(false);
  
  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      
      if (ApplicationState.getUserToAddIgnoreKeywordFor()) {
        setUserToAddKeywordFor(
          ApplicationState.getUserToAddIgnoreKeywordFor()
        );
      }
      if (ApplicationState.getIgnoreKeywordToEdit()) {
        setEditMode(true);
        setKeywordField(ApplicationState.getIgnoreKeywordToEdit().keyword);
        setUserToAddKeywordFor(
          ApplicationState.getIgnoreKeywordToEdit().user
        );
        setScopeField(
          ApplicationState.getIgnoreKeywordToEdit().scope
        );
      }
    }
    load();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new IgnoreKeywordDto(
      ApplicationState.generateGuid(),
      keywordField,
      scopeField,
      userToAddKeywordFor,
      new Date()
    );
    if (ApplicationState.getIgnoreKeywordToEdit()) {
      dto.id = ApplicationState.getIgnoreKeywordToEdit().id;
      await ApplicationState.saveUpdatedIgnoreKeyword(dto);
      props.addedKeyword();
    } else {
      await ApplicationState.saveNewIgnoreKeyword(dto);
      props.editedKeyword();
    }

    ApplicationState.setUserToAddIgnoreKeywordFor(undefined);
    ApplicationState.setIgnoreKeywordToEdit(undefined);
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteIgnoreKeyword(
      ApplicationState.getIgnoreKeywordToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete ignore keyword with id: " +
          ApplicationState.getIgnoreKeywordToEdit().id
      );
      return;
    }
    props.deletedKeyword();
    ApplicationState.setUserToAddIgnoreKeywordFor(undefined);
    ApplicationState.setIgnoreKeywordToEdit(undefined);
  }

  const getHeaderText = () => {
    let headerText = "Add keyword";
    if (editMode) {
      headerText = "Edit keyword";
    }
    return headerText;
  };

  const handleChangeScope = (event) => {
    setScopeField(event.target.value);
  };

  const addForm = () => {
    return (
      <Box>
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">Scope</FormLabel>
            <RadioGroup
              aria-label="scope for keyword"
              name="Scope"
              value={scopeField}
              onChange={handleChangeScope}
            >
              <FormControlLabel
                value="word"
                control={<Radio color="primary" />}
                label="Word"
              />
              <FormControlLabel
                value="event"
                control={<Radio color="primary" />}
                label="Event"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box style={{marginTop: "16px"}}>
        <TextField
          id="keywordTextField"
          label="Keyword"
          type="text"
          onChange={(event) => setKeywordField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={keywordField}
        />
        </Box>
      </Box>
    );
  };
  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default IgnoreKeywordEditView;

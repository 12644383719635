import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from "@mui/material/Dialog";
import ActivityKeywordEditView from "./ActivityKeywordEditView";
import { PrimaryButton } from '../Components/PrimaryButton';

function UserKeywordsView() {
  
  const [keywords, setKeywords] = useState([]);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {
      let keywordsForActivity = await ApplicationState.getActivityKeywordsForUser(
        ApplicationState.getState().user.email
      );
      setKeywords(keywordsForActivity);
    }
    load();
  }, []);
  
  function addNewActivityKeyword(activityKeywordToEdit) {
    ApplicationState.setActivityKeywordToEdit(undefined);
    ApplicationState.setUserToAddActivityKeywordFor(ApplicationState.getState().user);
    setOpenAddEditDialog(true);
  }

  function editActivityKeyword(activityKeywordToEdit) {
    ApplicationState.setActivityKeywordToEdit(activityKeywordToEdit);
    ApplicationState.setUserToAddActivityKeywordFor(undefined);
    setOpenAddEditDialog(true);
  }

  async function deleteKeyword(keywordToDelete) {
    await ApplicationState.deleteActivityKeyword(keywordToDelete.id);
    let keywords2 = keywords.slice();
    const index = keywords2.indexOf(keywordToDelete);
    if (index > -1) {
      keywords2.splice(index, 1);
    }
    setKeywords(keywords2);
  }

  function addProject(project, rowNr) {
    if (rowNr % 2 === 0) {
    return (
      <ListItem
        key={ApplicationState.generateGuid()}
        style={{backgroundColor: "#f6f6f6"}}
      >
        <ListItemText primary={<Typography variant="h6" style={{margin: "8px", marginBottom: "0px", paddingLeft: "4px"}}>{project.name}</Typography>} secondary={addKeywordsForProject(project)} />
      </ListItem>
    );
  }
  return (
    <ListItem
      key={ApplicationState.generateGuid()}
    >
      <ListItemText primary={<Typography variant="h6" style={{margin: "8px",  marginBottom: "0px", paddingLeft: "4px"}}>{project.name}</Typography>} secondary={addKeywordsForProject(project)} />
    </ListItem>
  );
  }

  function addKeywordsForProject(project) {
    return keywords.filter(x => x.activity.project.id === project.id).sort((a, b) => (a.keyword > b.keyword) ? 1 : -1).map((element) => {
      return addExistingKeyword(element);
    });
  }

  function addKeywords() {

    let projectsForKeywords = [];
    keywords
      .sort((a, b) => {
        if (a.activity.project.name > b.activity.project.name) {
          return 1;
        }
        if (a.activity.project.name < b.activity.project.name) {
          return -1;
        }
        if (a.activity.name > b.activity.name) {
          return 1;
        }
        if (a.activity.name < b.activity.name) {
          return -1;
        }
        return 0;
      })
      .map((standardTime) => {
        if (
          !projectsForKeywords.some(
            (foo) => foo.name === standardTime.activity.project.name
          )
        ) {
          projectsForKeywords.push(standardTime.activity.project);
        }
      });

    let rowNr = 0;
    return projectsForKeywords.sort((a, b) => (a.name > b.name) ? 1 : -1).map((element) => {
      return addProject(element, rowNr++);
    });
    
  }

  function addHeader() {
    return (
      <Box style={{padding: "16px", backgroundColor: "#93264a", color: "#fff"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">Keywords</Typography>
          <Typography>
            Associate words and phrases with projects and activities to improve time report accuracy. <br />E.g. add the clients e-mail to make sure all interaction with the client is associated with the correct activity. 
          </Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1}>
        <PrimaryButton
                icon="add"
                text="Add keyword"
                buttonClick={() => addNewActivityKeyword()}
              >
              </PrimaryButton>
      </Box>
    );
  }

  function addExistingKeyword(k) {
    return (
      <Chip
        clickable={true}
        style={{
          backgroundColor: "#93264A",
          color: "#fff",
          margin: "8px",
          fontSize: "larger",
          padding: "4px",
          height: "42px",
          "&:hover": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
          "&:focus": {
            backgroundColor: "#93264A",
            color: "#fff",
          },
        }}
        onDelete={() => deleteKeyword(k)}
        onClick={() => editActivityKeyword(k)}
        label={<b style={{fontWeight: "400"}}>{k.keyword}</b>}
        deleteIcon={
          <DeleteForeverIcon style={{ color: "#fff" }}></DeleteForeverIcon>
        }
      />
    );
  }

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
  };

  const deletedKeyword = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };
  const editedKeyword  = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };
  const addedKeyword = async () => {
    await loadKeywords();
    setOpenAddEditDialog(false);
  };

  const loadKeywords = async () => {
    let keywordsForActivity = await ApplicationState.getActivityKeywordsForUser(
      ApplicationState.getState().user.email
    );
    keywordsForActivity = keywordsForActivity.sort((a, b) => {
      if (a.activity.project.name > b.activity.project.name) {
        return 1;
      }
      if (a.activity.project.name < b.activity.project.name) {
        return -1;
      }
      if (a.activity.name > b.activity.name) {
        return 1;
      }
      if (a.activity.name < b.activity.name) {
        return -1;
      }
      return 0;
    });
    setKeywords(keywordsForActivity);
  }

  return (
    <Grid>
            <Box p={0}>
                <Box>
                  <Grid
                    container
                    alignItems="stretch"
                    justifyContent="space-between"
                    direction="row"
                  ></Grid>
                  {addHeader()}
                  <List style={{padding: "0px"}}>{addKeywords()}</List>
                </Box>
            </Box>
        <Dialog
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <ActivityKeywordEditView
            cancelClick={handleCloseAddEditDialog}
            addedKeyword={addedKeyword}
            editedKeyword={editedKeyword}
            deletedKeyword={deletedKeyword}
          ></ActivityKeywordEditView>
        </Dialog>
      </Grid>
  );
}

export default UserKeywordsView;

export default class StandardTimeDto {
  constructor(
    id,
    activity,
    user,
    weekday,
    maxHours,
    maxMinutes,
    priority,
    created
  ) {
    this.id = id;
    this.activity = activity;
    this.user = user;
    this.weekday = weekday;
    this.maxHours = maxHours;
    this.maxMinutes = maxMinutes;
    this.priority = priority;
    this.created = created;
  }
}

import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { PrimaryButton } from "./PrimaryButton";
import ReduceWorkingTimeKeywordsView from "./ReduceWorkingTimeKeywordsView";
import NormalWorkingTimeView from "./NormalWorkingTimeView";

function AutoFlexView() {
  const [workingTimes, setWorkingTimes] = useState([]);
  const [openAddEditDialog, setOpenAddEditDialog] = React.useState(false);

  //Kör vid uppstart
  useEffect(() => {
    async function load() {}
    load();
  }, []);

  function addHeader() {
    return (
      <Box
        style={{ padding: "16px", backgroundColor: "#93264a", color: "#fff" }}
      >
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {addHeaderText()}
              </Grid>
            </Box>
            <Box>
              <Grid container justifyContent="flex-end" alignItems="center">
                {addAddTimeButton()}
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText() {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">AutoFlex</Typography>
          <Typography>Track your flex automatically</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddTimeButton() {
    return (
      <Box p={1} style={{visibility: "hidden"}}>
        <PrimaryButton
          icon="add"
          text="Add working time"
        ></PrimaryButton>
      </Box>
    );
  }

  return (
    <Grid>
        <Box >
          
              <Box>
                <Grid
                  container
                  alignItems="stretch"
                  justifyContent="space-between"
                  direction="row"
                ></Grid>
                {addHeader()}
              </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} md={6} style={{borderRight: "1px solid #eee"}}>
            <NormalWorkingTimeView></NormalWorkingTimeView>
          </Grid>
          <Grid item xs={12} md={6}>
            <ReduceWorkingTimeKeywordsView></ReduceWorkingTimeKeywordsView>
          </Grid>
        </Grid>
      </Grid>
  );
}

export default AutoFlexView;

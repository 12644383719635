export default class UserDataItemDto {
  constructor(id, title, duration, dateTime, type, object, userDataId, eventType) {
    this.id = id;
    this.title = title;
    this.duration = duration;
    this.dateTime = dateTime;
    this.type = type;
    this.object = object;
    this.userDataId = userDataId;
    this.eventType = eventType;
  }
}

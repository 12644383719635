import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FileExporterDto from "../Dtos/FileExporterDto";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "60px",
});

function FileExporterEditView(props) {
  let history = useHistory();

  const [nameField, setNameField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [fileFormat, setFileFormat] = useState("PAXml");
  const [email, setEmail] = useState(undefined);
  const [trigger, setTrigger] = useState("SendReport");
  const [triggerDayInMonth, setTriggerDayInMonth] = useState(1);

  useEffect(() => {
    if (ApplicationState.getFileExporterToEdit()) {
      setEditMode(true);
      setNameField(ApplicationState.getFileExporterToEdit().name);
      setDescriptionField(ApplicationState.getFileExporterToEdit().description);
      setEmail(ApplicationState.getFileExporterToEdit().email);
      setFileFormat(ApplicationState.getFileExporterToEdit().fileFormat);
      setTrigger(ApplicationState.getFileExporterToEdit().trigger);
      setTriggerDayInMonth(ApplicationState.getFileExporterToEdit().triggerDayInMonth);
    }
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new FileExporterDto(
      ApplicationState.generateGuid(),
      nameField,
      descriptionField,
      ApplicationState.getState().user.organization,
      new Date(Date.now()),
      email,
      fileFormat,
      trigger, 
      triggerDayInMonth
    );
    if (ApplicationState.getFileExporterToEdit()) {
      dto.id = ApplicationState.getFileExporterToEdit().id;
      await ApplicationState.saveUpdatedFileExporter(dto);
      ApplicationState.setFileExporterToView(dto);
      props.editedExporter();
    } else {
      await ApplicationState.saveNewFileExporter(dto);
      ApplicationState.setFileExporterToView(dto);
      props.addedExporter();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteFileExporter(
      ApplicationState.getFileExporterToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete file exporter with id: " +
          ApplicationState.getFileExporterToEdit().id
      );
      return;
    }
    props.deletedExporter();
  }

  const getHeaderText = () => {
    let headerText = "Add file exporter";
    if (editMode) {
      headerText = "Edit file exporter";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
        <FormControl variant="outlined">
          <Box style={{float: "left"}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Format
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setFileFormat(event.target.value);
              }}
              id="triggerSelect"
              variant="outlined"
              style={{minWidth: "100%", margin: "8px", fontWeight: "500"}}
              value={fileFormat}
              labelId="triggerSelectLabel"
            >
              {addOption("PAXml", "PAXml")}
            </Select>
          </Box>
        </FormControl>
        <br />
        <TextField
          id="emailTextField"
          label="Email"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setEmail(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={email}
        />
        <br />
        <FormControl variant="outlined">
          <Box style={{float: "left"}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Trigger
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setTrigger(event.target.value);
              }}
              id="triggerSelect"
              variant="outlined"
              style={{minWidth: "100%", margin: "8px", fontWeight: "500"}}
              value={trigger}
              labelId="triggerSelectLabel"
            >
              {addOption("Day in month", "DayInMonth")}
            </Select>
          </Box>
        </FormControl>
        <br />
        <FormControl variant="outlined">
          <Box style={{float: "left"}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Day
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setTriggerDayInMonth(event.target.value);
              }}
              id="triggerSelect"
              variant="outlined"
              style={{minWidth: "100%", margin: "8px", fontWeight: "500"}}
              value={triggerDayInMonth}
              labelId="triggerSelectLabel"
            >
              {addOption("1", 1)}
              {addOption("2", 2)}
              {addOption("3", 3)}
              {addOption("4", 4)}
              {addOption("5", 5)}
              {addOption("6", 6)}
              {addOption("7", 7)}
              {addOption("8", 8)}
              {addOption("9", 9)}
              {addOption("10", 10)}
              {addOption("11", 11)}
              {addOption("12", 12)}
              {addOption("13", 13)}
              {addOption("14", 14)}
              {addOption("15", 15)}
              {addOption("16", 16)}
              {addOption("17", 17)}
              {addOption("18", 18)}
              {addOption("19", 19)}
              {addOption("20", 20)}
              {addOption("21", 21)}
              {addOption("22", 22)}
              {addOption("23", 23)}
              {addOption("24", 24)}
              {addOption("25", 25)}
              {addOption("26", 26)}
              {addOption("27", 27)}
              {addOption("28", 28)}
            </Select>
          </Box>
        </FormControl>
        <br />
      </Box>
    );
  };

function addOption(text, value){
  return (<option value={value} style={{ color: "#111" }}>
  {text}
</option>);
}

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default FileExporterEditView;

export default class TicketDto {
  constructor(id, title, project, description, type, changeDateTime, userData) {
    this.id = id;
    this.title = title;
    this.project = project;
    this.changeDateTime = changeDateTime;
    this.type = type;
    this.description = description;
    this.userData = userData;
  }
}

export default class TimelineDto {
  constructor(id, title, duration, dateTime, type, object) {
    this.id = id;
    this.title = title;
    this.duration = duration;
    this.dateTime = dateTime;
    this.type = type;
    this.object = object;
  }
}

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import ActivityDto from "../Dtos/ActivityDto";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ActivitySettingDto from "../Dtos/ActivitySettingDto";
import { PrimaryButton } from '../Components/PrimaryButton';
import { DeleteButton } from "./DeleteButton";

function ActivityEditView(props) {
  const [nameField, setNameField] = useState(undefined);
  const [codeField, setCodeField] = useState(undefined);
  const [descriptionField, setDescriptionField] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useState(undefined);
  const [exporterProps, setExportProps] = useState([]);
  const [activitySettings, setActivitySettings] = useState([]);
  const [exportIdField, setExportIdField] = useState(undefined);

  useEffect(() => {
    const load2 = async () => {
      setProject(ApplicationState.getProjectForActivityToAdd());
      if (ApplicationState.getActivityToEdit()) {
        setEditMode(true);
        let exportProperties = [];
        let fileExportProperties =
          await ApplicationState.getFileExporterPropertiesForActivity(
            ApplicationState.getActivityToEdit().id
          );
        let emailExportProperties =
          await ApplicationState.getEmailExporterPropertiesForActivity(
            ApplicationState.getActivityToEdit().id
          );

        fileExportProperties.map((element) => {
          exportProperties.push(element);
        });
        emailExportProperties.map((element) => {
          exportProperties.push(element);
        });

        setExportProps(exportProperties);
        let settings = await ApplicationState.getActivitySettingsForActivity(
          ApplicationState.getActivityToEdit().id
        );
        await Promise.all(
          exportProperties.map(async (element) => {
            if (!settings.some((s) => s.name === element.name)) {
              let as = new ActivitySettingDto(
                ApplicationState.generateGuid(),
                ApplicationState.getActivityToEdit(),
                undefined,
                element.name,
                "-",
                new Date()
              );
              await ApplicationState.saveNewActivitySetting(as);
            }
          })
        );
        settings = await ApplicationState.getActivitySettingsForActivity(
          ApplicationState.getActivityToEdit().id
        );
        setActivitySettings(settings);
        setNameField(ApplicationState.getActivityToEdit().name);
        setCodeField(ApplicationState.getActivityToEdit().code);
        setExportIdField(ApplicationState.getActivityToEdit().exportId);
        setDescriptionField(ApplicationState.getActivityToEdit().description);
        setProject(ApplicationState.getActivityToEdit().project);
      }
    };
    load2();
  }, []);

  function cancelClick() {
    props.cancelClick();
  }

  async function saveClick() {
    let dto = new ActivityDto(
      ApplicationState.generateGuid(),
      project,
      nameField,
      descriptionField,
      new Date(),
      codeField,
      exportIdField
    );
    if (ApplicationState.getActivityToEdit()) {
      dto.id = ApplicationState.getActivityToEdit().id;
      await ApplicationState.saveUpdatedActivity(dto);
      ApplicationState.setProjectForActivityToAdd(undefined);
      ApplicationState.setActivityToEdit(undefined);
      ApplicationState.setActivityToView(dto);
      activitySettings.forEach((element) => {
        ApplicationState.saveUpdatedActivitySetting(element);
      });
      props.editedActivity();
    } else {
      await ApplicationState.saveNewActivity(dto);
      ApplicationState.setProjectForActivityToAdd(undefined);
      ApplicationState.setActivityToEdit(undefined);
      props.addedActivity();
    }
  }

  async function deleteClick() {
    var result = await ApplicationState.deleteActivity(
      ApplicationState.getActivityToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete activity with id: " +
          ApplicationState.getActivityToEdit().id
      );
      return;
    }

    ApplicationState.setProjectForActivityToAdd(undefined);
    ApplicationState.setActivityToEdit(undefined);
    props.deletedActivity();
  }

  const getHeaderText = () => {
    let headerText = "Add activity";
    if (editMode) {
      headerText = "Edit activity";
    }
    return headerText;
  };

  const addForm = () => {
    return (
      <Box>
        <TextField
          id="nameTextField"
          label="Name"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setNameField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={nameField}
        />
        <br />
        <TextField
          id="codeTextField"
          label="Code"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setCodeField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={codeField}
        />
        <br />
        <TextField
          id="exportIdTextField"
          label="Export Id"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setExportIdField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={exportIdField}
        />
        <br />
        <TextField
          id="descriptionTextField"
          label="Description"
          type="text"
          style={{margin: "8px"}}
          onChange={(event) => setDescriptionField(event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={descriptionField}
        />
        <br />
      </Box>
    );
  };

  const onSettingChange = (value, index) => {
    let al = [];
    activitySettings[index].value = value;
    activitySettings.forEach((element) => {
      al.push(element);
    });
    setActivitySettings(al);
  };

  const addSettings = () => {
    let foo = activitySettings;
    return (
      <Box>
        {foo
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((input, index) => (
          <Box>
            <TextField
              id="nameTextField"
              label={input.name}
              type="text"
              style={{margin: "8px"}}
              onChange={(event) => onSettingChange(event.target.value, index)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={input.value}
            />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      {exporterProps && exporterProps.length > 0 && (
        <Box p={2}>{addSettings()}</Box>
      )}

      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveClick()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteClick}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default ActivityEditView;

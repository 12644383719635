export default class UserTargetWorkingTimeDto {
  constructor(id, user, mondayMinutesToWork, tuesdayMinutesToWork, wednesdayMinutesToWork, thursdayMinutesToWork, fridayMinutesToWork, saturdayMinutesToWork, sundayMinutesToWork) {
    this.id = id;
    this.user = user;
    this.mondayMinutesToWork = mondayMinutesToWork;
    this.tuesdayMinutesToWork = tuesdayMinutesToWork;
    this.wednesdayMinutesToWork = wednesdayMinutesToWork;
    this.thursdayMinutesToWork = thursdayMinutesToWork;
    this.fridayMinutesToWork = fridayMinutesToWork;
    this.saturdayMinutesToWork = saturdayMinutesToWork;
    this.sundayMinutesToWork = sundayMinutesToWork;
  }
}

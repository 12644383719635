import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import StandardTimeDto from "../Dtos/StandardTimeDto";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { PrimaryButton } from '../Components/PrimaryButton';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DateTimeHelper from "../Utils/DateTimeHelper";
import { DeleteButton } from "./DeleteButton";

const InputLabelSelect = styled(InputLabel)({
    paddingLeft: "8px",
    position: "relative",
    backgroundColor: "#fff",
    marginLeft: "4px",
    top: "32px",
    width: "60px",
});

function StandardTimeEditView(props) {
  const [weekdayField, setWeekdayField] = useState(1);
  const [priorityField, setPriorityField] = useState(3);
  const [maxTimeField, setMaxTimeField] = useState("8:00");
  const [projectField, setProjectField] = useState(undefined);
  const [activityField, setActivityField] = useState(undefined);
  const [activitiesForProject, setActivitiesForProject] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [maxTimeErrorMessage, setMaxTimeErrorMessage] = useState("");
  const [showMaxTimeErrorMessage, setShowMaxTimeErrorMessage] = useState(false);

  useEffect(() => {
        setWeekdayField(props.maxWeekday);
    
    let projects = ApplicationState.getState().activeProjects.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (projects !== undefined || projects.length !== 0) {
      setProjectField(projects[0].id);
      fillActivitiesForProject(projects[0].id);
    }
    
    let absenceProjectId = ApplicationState.getAbsenceProject();
    if(projects.length > 1 && projects[0].id === absenceProjectId){
      setProjectField(projects[1].id);
      fillActivitiesForProject(projects[1].id);
    }

    if (ApplicationState.getStandardTimeToEdit()) {
      setEditMode(true);
      setMaxTimeField(
        DateTimeHelper.convertMinutesToTimeInputString(parseInt(ApplicationState.getStandardTimeToEdit().maxHours) * 60 + parseInt(ApplicationState.getStandardTimeToEdit().maxMinutes))
      );
      setWeekdayField(ApplicationState.getStandardTimeToEdit().weekday);
      setPriorityField(ApplicationState.getStandardTimeToEdit().priority);
      setProjectField(
        ApplicationState.getStandardTimeToEdit().activity.project.id
      );
      fillActivitiesForProject(
        ApplicationState.getStandardTimeToEdit().activity.project.id
      );
      setActivityField(ApplicationState.getStandardTimeToEdit().activity.id);
    }
  }, []);

  function fillActivitiesForProject(projectId) {
    let activities = ApplicationState.getState().activities.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (activities !== undefined || activities.length !== 0) {
    }
    let activitiesForProject = activities.filter(
      (x) => x.project.id === projectId
    );
    let activitiesForProjectOptions = [];
    activitiesForProject.map((val) => {
      activitiesForProjectOptions.push(
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
    setActivitiesForProject(activitiesForProjectOptions);
    setActivityField(activitiesForProject[0].id);
  }

  function addProject(project) {
    return (
      <option key={project.id} label={project.name} value={project.id}></option>
    );
  }

  function cancelClick() {
    props.cancelClick();
  }

  async function saveNewStandardTime() {
    let activity = ApplicationState.getState().activities.filter(
      (a) => a.id === activityField
    )[0];


    let totalMinutes = DateTimeHelper.getMinutesFromTimeInputString(maxTimeField);
    let maxMinutes = totalMinutes % 60;
    let maxHours = parseInt(parseInt(totalMinutes) / parseInt(60));

    let dto = new StandardTimeDto(
      ApplicationState.generateGuid(),
      activity,
      ApplicationState.getState().user,
      weekdayField,
      maxHours,
      maxMinutes,
      priorityField,
      new Date(Date.now())
    );
    if(!dto.maxMinutes){
      dto.maxMinutes = 0;
    }
    if (ApplicationState.getStandardTimeToEdit()) {
      dto.id = ApplicationState.getStandardTimeToEdit().id;
      await ApplicationState.saveUpdatedStandardTime(dto);
      props.editedStandardTime();
    } else {
      await ApplicationState.saveNewStandardTime(dto);
      props.addedStandardTime();
    }
  }

  async function deleteStandardTime() {
    var result = await ApplicationState.deleteStandardTime(
      ApplicationState.getStandardTimeToEdit().id
    );
    if (result != "Ok") {
      alert(
        "Error occured when trying to delete standard time with id: " +
          ApplicationState.getStandardTimeToEdit().id
      );
      return;
    }
    props.deletedStandardTime();
  }

  const getHeaderText = () => {
    let headerText = "Add standard time";
    if (editMode) {
      headerText = "Edit standard time";
    }
    return headerText;
  };

  const addTimeToMaxTime = () => {
    let currentMinutes = DateTimeHelper.getMinutesFromTimeInputString(maxTimeField);
    if(currentMinutes % 15 === 0){
      currentMinutes += 15;
    } else{
      currentMinutes += 15 - currentMinutes % 15;
    }
    setMaxTimeField(DateTimeHelper.convertMinutesToTimeInputString(currentMinutes));
  }

  const removeTimeFromMaxTime = () => {
    let currentMinutes = DateTimeHelper.getMinutesFromTimeInputString(maxTimeField);
    if(currentMinutes % 15 === 0){
      currentMinutes -= 15;
    } else{
      currentMinutes -= currentMinutes % 15;
    }
    setMaxTimeField(DateTimeHelper.convertMinutesToTimeInputString(currentMinutes));
  }

  const addForm = () => {
    return (
      <Box>
        <Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
            <InputLabelSelect
              id="projectSelectLabel"
              htmlFor="projectSelect"
              variant="outlined"
              shrink={true}
            >
              Project
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setProjectField(event.target.value);
                fillActivitiesForProject(event.target.value);
              }}
              id="projectSelect"
              variant="outlined"
              sx={{
                margin: "8px",
                fontWeight: "500",
                width: {xs: "95%", sm: "300px"},
              }}
              value={projectField}
            >
              {ApplicationState.getState().activeProjects.sort((a, b) => (a.name > b.name ? 1 : -1)).map((project) => {
                return addProject(project);
              })}
            </Select>
          </Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
            <InputLabelSelect
              id="activitySelectLabel"
              htmlFor="activitySelect"
              variant="outlined"
              shrink={true}
            >
              Activity
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setActivityField(event.target.value);
              }}
              id="activitySelect"
              variant="outlined"
              sx={{
                margin: "8px",
                fontWeight: "500",
                width: {xs: "95%", sm: "300px"},
              }}
              value={activityField}
            >
              {activitiesForProject}
            </Select>
          </Box>
        </Box>
        <Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
            <InputLabelSelect
              id="weekdaySelectLabel"
              htmlFor="weekdaySelect"
              variant="outlined"
              style={{ width: "32px" }}
              shrink={true}
            >
              Day
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setWeekdayField(event.target.value);
              }}
              id="weekdaySelect"
              variant="outlined"
              sx={{
                width: "142px",
                margin: "8px",
                fontWeight: "500",
                width: {xs: "95%", sm: "142px"},
              }}
              value={weekdayField}
            >
              <option key={1} label={"Monday"} value={1}></option>
              <option key={2} label={"Tuesday"} value={2}></option>
              <option key={3} label={"Wednesday"} value={3}></option>
              <option key={4} label={"Thursday"} value={4}></option>
              <option key={5} label={"Friday"} value={5}></option>
              <option key={6} label={"Saturday"} value={6}></option>
              <option key={0} label={"Sunday"} value={0}></option>
            </Select>
          </Box>
          <Box sx={{float: {sm: "none", md: "left" }}}>
            <InputLabelSelect
              id="prioritySelectLabel"
              htmlFor="prioritySelect"
              variant="outlined"
              style={{ width: "32px" }}
              shrink={true}
            >
              Prio
            </InputLabelSelect>
            <Select
              native
              onChange={(event) => {
                setPriorityField(event.target.value);
              }}
              id="prioritySelect"
              variant="outlined"
              sx={{
                width: "142px",
                margin: "8px",
                fontWeight: "500",
                width: {xs: "95%", sm: "142px"},
              }}
              value={priorityField}
            >
              <option key={1} label={"Highest"} value={1}></option>
              <option key={2} label={"High"} value={2}></option>
              <option key={3} label={"Normal"} value={3}></option>
              <option key={4} label={"Low"} value={4}></option>
              <option key={5} label={"Lowest"} value={5}></option>
            </Select>
          </Box>
        </Box>
        <Box>
          
          <Grid container justifyContent="center" alignItems="center" 
          sx={{
            fontWeight: "500",
            width: {xs: "100%", sm: "300px"},
          }}>
          <IconButton style={{marginTop: "32px"}} onClick={() => {removeTimeFromMaxTime()}}>
                <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
              </IconButton>
          <TextField
                id="hoursTextField"
                label="Max time"
                style={{width: "104px", margin: "8px", marginTop: "32px"}}
                error={showMaxTimeErrorMessage}
                helperText={maxTimeErrorMessage}
                onBlur = {(event) => {
                  let minutes = DateTimeHelper.convertTimeInputStringToMinutes(event.target.value);
                  if(minutes != "ERROR"){
                    setShowMaxTimeErrorMessage(false);
                    setMaxTimeErrorMessage("");
                    setMaxTimeField(DateTimeHelper.convertMinutesToTimeInputString(minutes));
                  } else{
                    setShowMaxTimeErrorMessage(true);
                    setMaxTimeErrorMessage("FEL");
                  }
                }}
                onChange={(event) => setMaxTimeField(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  style: {
                    fontWeight: "900",
                  },
                }}
                placeholder="hh:mm"
                variant="outlined"
                value={maxTimeField}
              />
              <IconButton style={{ marginTop: "32px"}} onClick={() => {addTimeToMaxTime()}}>
                <AddCircleOutlineIcon></AddCircleOutlineIcon>
              </IconButton>
              
              </Grid>
          </Box>
      </Box>
    );
  };

  return (
    <Box p={0}>
      <Box style={{backgroundColor: "#f6f6f6"}}>
        <Grid container justifyContent="flex-end" direction="row">
          <Box>
            <IconButton
              variant="contained"
              color="primary"
              onClick={cancelClick}
              size="large">
              <CloseIcon
                fontSize="medium"
                style={{color: "#93264A"}}
              ></CloseIcon>
            </IconButton>
          </Box>
        </Grid>
      </Box>
      <Box
        style={{
          backgroundColor: "#f6f6f6",
          paddingBottom: "12px",
          paddingTop: "0px",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "0px",
              paddingBottom: "16px",
            }}
          >
            <Typography variant="h5">
              <b>{getHeaderText()}</b>
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box p={2}>{addForm()}</Box>
      <Box
        style={{
          backgroundColor: "#93264A",
          paddingBottom: "12px",
          paddingTop: "12px",
        }}
      >
        <Grid container justifyContent="flex-end" alignItems="center">
          <Box
            style={{
              paddingLeft: "24px",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <Box p={1}>
              <PrimaryButton
                icon="save"
                text="Save"
                buttonClick={() => saveNewStandardTime()}
              >
              </PrimaryButton>
              {editMode && (
                <DeleteButton
                  onClick={deleteStandardTime}
                >
                </DeleteButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export default StandardTimeEditView;

import Box from "@mui/material/Box";
import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import UsersView from "./UsersView";
import OrganizationSettingsView from "./OrganizationSettingsView";
import GroupsView from "./GroupsView";
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SettingsIcon from '@mui/icons-material/Settings';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ExportersView from "./ExportersView";
import EventIcon from '@mui/icons-material/Event';
import HolidaysView from "./HolidaysView";
import ApplicationState from "../State/ApplicationState";
import ClientsView from "./ClientsView";
import BusinessIcon from '@mui/icons-material/Business';

function AdminSettingsView() {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [value, setValue] = React.useState("1");

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#ddd",
  });

  const CustomTabPanel = styled(TabPanel)({
    padding: "0px",
  });


  //Kör vid uppstart
  useEffect(() => {}, [fromDate, toDate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
      <Typography variant="h4" style={{color: "#fff", margin: "8px"}}>{ApplicationState.getState().user.organization.name}</Typography>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7}>
            <Box p={0}>
              <TabContext value={value}>
                <AppBar position="static">
                  <TabList
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    style={{backgroundColor: "#781938"}}
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <CustomTab color="" label="General" value="1" icon={<SettingsIcon />} /> 
                    <CustomTab label="Groups" value="2" icon={<GroupWorkIcon />}  />
                    <CustomTab label="Exports" value="3" icon={<ImportExportIcon />} />
                    <CustomTab label="Holidays" value="4" icon={<EventIcon />} />
                    <CustomTab label="Clients" value="5" icon={<BusinessIcon />} />
                  </TabList>
                </AppBar>
                <CustomTabPanel value="1">
                  <OrganizationSettingsView></OrganizationSettingsView>
                </CustomTabPanel>
                <CustomTabPanel value="2"><GroupsView></GroupsView></CustomTabPanel>
                <CustomTabPanel value="3"><ExportersView></ExportersView></CustomTabPanel>
                <CustomTabPanel value="4"><HolidaysView /></CustomTabPanel>
                <CustomTabPanel value="5"><ClientsView /></CustomTabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AdminSettingsView;

import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ApplicationState from "../State/ApplicationState";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const CustomTableCell = styled(TableCell)({
  borderRightColor: "#eee",
  borderRightWidth: "2px",
  borderRightStyle: "solid",
  verticalAlign: "top",
  borderBottomWidth: "0px",
  fontSize: "16px",
});

function AdminSettingsView() {
  const [logEvents, setLogEvents] = useState([]);
  const [loginLogEvents, setLoginLogEvents] = useState([]);
  const [timeReportGenerationLog, setTimeReportGenerationLog] = useState([]);
  const [value, setValue] = React.useState("3");

  const CustomTab = styled(Tab)({
    textTransform: "capitalize",
    color: "#ddd",
  });

  const CustomTabPanel = styled(TabPanel)({
    padding: "0px",
  });


  //Kör vid uppstart
  useEffect(() => {
    const load1 = async () => {
      let le = await ApplicationState.getLogEvents();
      setLogEvents(le);

      let lle = await ApplicationState.getLoginLogEvents();
      setLoginLogEvents(lle);

      let trgl = await ApplicationState.getTimeReportGenerationLog();
      setTimeReportGenerationLog(trgl);
    };
    load1();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  function addLogEventRows(){
    let rowNr = 0;
    return logEvents.map((logEvent) => {
      rowNr++;
      return addLogEventRow(logEvent, rowNr);
    })
  }
  function addLogEventRow(logEvent, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0) {
      bgColor = "#f6f6f6"
    }
    return (
      <TableRow key={logEvent.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {logEvent.title}
          </CustomTableCell>
          <CustomTableCell align="left">
            {logEvent.message}
          </CustomTableCell>
          <CustomTableCell align="left">
            {logEvent.type}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ minWidth: "150px"}}>
            {logEvent.created.slice(0, 19).replace("T", " ")}
          </CustomTableCell>
      </TableRow>
    );
  }

  function addLoginLogEventRows() {
    let rowNr = 0;
    return loginLogEvents.map((logEvent) => {
      rowNr++;
      return addLoginLogEventRow(logEvent, rowNr);
    })
  }

  function addLoginLogEventRow(logEvent, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0) {
      bgColor = "#f6f6f6"
    }
    return (
      <TableRow key={logEvent.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {logEvent.user.email}
          </CustomTableCell>
          <CustomTableCell align="left">
            {logEvent.method}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ minWidth: "150px", width: "150px"}}>
            {logEvent.created.slice(0, 19).replace("T", " ")}
          </CustomTableCell>
      </TableRow>
    );
  }

  function addTimeReportGenerationLogRows() {
    let rowNr = 0;
    return timeReportGenerationLog.map((logEvent) => {
      rowNr++;
      return addTimeReportGenerationLogRow(logEvent, rowNr);
    })
  }

  function addTimeReportGenerationLogRow(logEvent, rowNr) {
    let bgColor = "#fff";
    if(rowNr % 2 === 0) {
      bgColor = "#f6f6f6"
    }
    return (
      <TableRow key={logEvent.id} style={{backgroundColor: bgColor}}>
          <CustomTableCell align="left">
            {logEvent.user.email}
          </CustomTableCell>
          <CustomTableCell align="left">
            {logEvent.lastGenerationDate.slice(0, 10)}
          </CustomTableCell>
      </TableRow>
    );
  }

  function getTableHeaderForReportData(name) {
    return (
      <TableCell style={{padding: "8px", borderBottomWidth: "0px", fontSize: "16px"}} align="left">
        <Box style={{
          backgroundColor: "#d8d8d8",
          margin: "8px",
          padding: "4px",
          borderRadius: "8px",
          lineHeight: "1.4"}}>
          {name}
        </Box>
      </TableCell>
    );
  }

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
        <Box sx={{margin: { xs: "0px", md: "8px"}}}>
          <Paper elevation={7}>
            <Box p={0}>
              <TabContext value={value}>
                <AppBar position="static">
                  <TabList
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    style={{backgroundColor: "#781938"}}
                    variant="fullWidth"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <CustomTab color="" label="Logevents" value="1" icon={<BookOutlinedIcon />} /> 
                    <CustomTab label="Logins" value="2" icon={<LoginOutlinedIcon />} />
                    <CustomTab label="TimeReportGeneration" value="3" icon={<ListAltOutlinedIcon />} />
                  </TabList>
                </AppBar>
                <CustomTabPanel value="1">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{backgroundColor: "#d8d8d8"}}>
                          {getTableHeaderForReportData("Title")}
                          {getTableHeaderForReportData("Message")}
                          {getTableHeaderForReportData("Type")}
                          {getTableHeaderForReportData("Date")}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addLogEventRows()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CustomTabPanel>
                <CustomTabPanel value="2">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{backgroundColor: "#d8d8d8"}}>
                          {getTableHeaderForReportData("User")}
                          {getTableHeaderForReportData("Method")}
                          {getTableHeaderForReportData("Date")}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addLoginLogEventRows()}
                      </TableBody>
                    </Table>
                  </TableContainer>                  
                </CustomTabPanel>
                <CustomTabPanel value="3">
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow style={{backgroundColor: "#d8d8d8"}}>
                          {getTableHeaderForReportData("User")}
                          {getTableHeaderForReportData("Last generation date")}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addTimeReportGenerationLogRows()}
                      </TableBody>
                    </Table>
                  </TableContainer>  
                </CustomTabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AdminSettingsView;

import Box from "@mui/material/Box";
import ApplicationState from "../State/ApplicationState";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import ClientEditView from "./ClientEditView";
import { PrimaryButton } from './PrimaryButton';

function ClientView(props) {

  const [openClientDialog, setOpenClientDialog] = useState(false);
  
  useState(false);

  function editClientClick() {
    ApplicationState.setClientToEdit(props.selectedClient);
    setOpenClientDialog(true);
  }

  function addHeader(headerText, buttonText) {
    return (
      <Box style={{padding: "16px", backgroundColor: "#d8d8d8", color: "#000"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText(headerText)}
              </Grid>
            </Box>
            {buttonText && buttonText.length > 0 && (
              <Box>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {addAddButton(buttonText)}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }

  function addHeaderText(headerText) {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">{headerText}</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddButton(buttonText) {
    let func = editClientClick;
    let edit = false;
    if (buttonText === "Edit") {
      func = editClientClick;
      edit = true;
    }

    return (
      <Box p={0}>
        {edit && (
          <PrimaryButton
          icon="edit"
          text={buttonText}
          buttonClick={() => func()}
        >
        </PrimaryButton>
        )}
        {!edit && (
          <PrimaryButton
            icon="add"
            text={buttonText}
            buttonClick={() => func()}
          >
          </PrimaryButton>
        )}
      </Box>
    );
  }

  const addedClient = async () => {
    setOpenClientDialog(false);
    props.addedClient();
  };

  const editedClient = async () => {
    setOpenClientDialog(false);
    props.editedClient();
  };

  const deletedClient = async () => {
    setOpenClientDialog(false);
    props.deletedClient();
  };

  return (
    <Box>
      <Box p={0}>
        {addHeader("Client information", "Edit")}
        <Box p={2}>
          <Typography paragraph><b>{props.selectedClient.name}</b></Typography>
          <Typography paragraph>{props.selectedClient.exportId}</Typography>
          <Typography paragraph>{props.selectedClient.clientInvoiceReference}</Typography>
          <Typography paragraph>{props.selectedClient.internalInvoiceReference}</Typography>
          <Typography paragraph>Created at {props.selectedClient.created}</Typography>
        </Box>
      </Box>

      <Dialog
        open={openClientDialog}
        onClose={() => setOpenClientDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <ClientEditView
          deletedClient={deletedClient}
          editedClient={editedClient}
          addedClient={addedClient}
          cancelClick={() => setOpenClientDialog(false)}
        ></ClientEditView>
      </Dialog>    
      </Box>

  );
}

export default ClientView;

import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import HolidayEditView from "./HolidayEditView";
import { PrimaryButton } from '../Components/PrimaryButton';
import ApplicationState from "../State/ApplicationState";

function HolidayView(props) {

  const [openHolidayDialog, setOpenHolidayDialog] = useState(false);
  
  useState(false);

  function addHeader(headerText, buttonText) {
    return (
      <Box style={{padding: "16px", backgroundColor: "#d8d8d8", color: "#000"}}>
        <Box p={1}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
          >
            <Box>
              <Grid container justifyContent="flex-start" alignItems="flex-start">
                {addHeaderText(headerText)}
              </Grid>
            </Box>
            {buttonText && buttonText.length > 0 && (
              <Box>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {addAddButton(buttonText)}
                </Grid>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    );
  }

  function editHolidayClick() {
    ApplicationState.setHolidayToEdit(props.selectedHoliday);
    setOpenHolidayDialog(true);
  }

  function addHeaderText(headerText) {
    return (
      <Typography variant="h5">
        <Box paddingRight={2}>
          <Typography variant="h5">{headerText}</Typography>
        </Box>
      </Typography>
    );
  }
  function addAddButton(buttonText) {
    let func = editHolidayClick;
    let edit = false;
    if (buttonText === "Edit") {
      func = editHolidayClick;
      edit = true;
    }

    return (
      <Box p={0}>
        {edit && (
          <PrimaryButton
          icon="edit"
          text={buttonText}
          buttonClick={() => func()}
        >
        </PrimaryButton>
        )}
        {!edit && (
          <PrimaryButton
            icon="add"
            text={buttonText}
            buttonClick={() => func()}
          >
          </PrimaryButton>
        )}
      </Box>
    );
  }

  const addedHoliday = async () => {
    setOpenHolidayDialog(false);
    props.addedHoliday();
  };

  const editedHoliday = async () => {
    setOpenHolidayDialog(false);
    props.editedHoliday();
  };

  const deletedHoliday = async () => {
    setOpenHolidayDialog(false);
    props.deletedHoliday();
  };

  return (
    <Box>
      <Box p={0}>
        {addHeader("Holiday information", "Edit")}
        <Box p={2}>
          <Typography paragraph><b>{props.selectedHoliday.name}</b></Typography>
          <Typography paragraph>{new Date(props.selectedHoliday.date).toLocaleDateString("sv-SE")}</Typography>
          <Typography paragraph>{props.selectedHoliday.percentage}%</Typography>
          <Typography paragraph>Created at {props.selectedHoliday.created}</Typography>
        </Box>
      </Box>

      <Dialog
        open={openHolidayDialog}
        onClose={() => setOpenHolidayDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <HolidayEditView
          deletedHoliday={deletedHoliday}
          editedHoliday={editedHoliday}
          addedHoliday={addedHoliday}
          cancelClick={() => setOpenHolidayDialog(false)}
        ></HolidayEditView>
      </Dialog>    
      </Box>

  );
}

export default HolidayView;
